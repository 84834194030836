<template>
    <v-container>
        <v-alert v-if="alert.show" :type="alert.type" text :icon="alert.icon">{{ alert.msg }}</v-alert>
        <list-autocomplete
            id="dropdown-role"
            :label="'Role'"
            :list="roles"
            :value="roleSelected.value"
            :disabled="alert.show && alert.type == 'error'"
            @value="updateRoleSelected"
        />
        <template v-if="showPermissions">
            <v-container class="list__container">
                <title-action
                    :prefix="'role'"
                    :title="`${roleSelected.text} 's Permissions`"
                    :can-edit="canEdit"
                    @canEdit="updateCanEdit"
                />
                <list-checkbox
                    :prefix="'role'"
                    :show-label="true"
                    :list="permissions"
                    :can-edit="canEdit"
                    @change="updatePermission"
                />
            </v-container>
        </template>
    </v-container>
</template>

<script>
import * as HttpStatus from 'http-status-codes'
import { api, requestApi } from '../../../store/api/api'
import { iconsConst } from '../../../store/utill'
import { ListCheckbox, ListAutocomplete, TitleAction } from '../../molecules'

export default {
    components: {
        ListCheckbox,
        ListAutocomplete,
        TitleAction
    },
    data() {
        return {
            roles: [],
            roleSelected: {
                value: '',
                text: ''
            },
            showPermissions: false,
            permissions: [],
            permissionsChanged: [],
            canEdit: false,
            alert: {},
            iconsConst
        }
    },
    watch: {
        roleSelected: {
            async handler(newVal) {
                await this.getRolePermissions(newVal)
            },
            deep: true
        }
    },
    async beforeMount() {
        try {
            // TODO: [Next Phase] move to store
            let response = await requestApi(api.permissionConfigAPI.roleList)
            if (response.status == HttpStatus.OK) {
                let roleList = response.response.data
                for (let role of roleList) {
                    this.roles.push({
                        value: role.name,
                        text: role.display_name
                    })
                }
            } else {
                this.alert = {
                    show: true,
                    type: 'error',
                    msg: "Can't load role list",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            this.alert = {
                show: true,
                type: 'error',
                msg: `Internal Server error when get role list`,
                icon: iconsConst.EXCLAM_TRI,
                redirect: null,
                err: e.response ? e.response : e
            }
        }
    },
    methods: {
        async getRolePermissions(role) {
            try {
                // TODO: [Next phase] move to store
                let response = await requestApi(api.permissionConfigAPI.rolePermission, {
                    variable: role.value
                })
                if (response.status == HttpStatus.OK) {
                    this.permissions = []
                    this.permissionsChanged = []
                    let permissionList = response.response.data
                    for (let permission of permissionList) {
                        this.permissions.push({
                            id: permission.permission_id,
                            text: permission.permission_name,
                            checked: permission.has_access
                        })
                        this.permissionsChanged.push(permission.has_access)
                    }
                    this.showPermissions = true
                } else {
                    this.alert = {
                        show: true,
                        type: 'error',
                        msg: `Can't get ${role.text}'s permissions`,
                        icon: iconsConst.EXCLAM_TRI,
                        redirect: null
                    }
                }
            } catch (e) {
                this.alert = {
                    show: true,
                    type: 'error',
                    msg: `Internal Server error when get ${role.text}'s permissions`,
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null,
                    err: e.response ? e.response : e
                }
            }
        },
        async updateCanEdit(newVal) {
            this.alert = {
                show: false,
                type: '',
                msg: '',
                icon: '',
                redirect: null
            }

            if (this.canEdit) {
                try {
                    let data = {
                        add: [],
                        remove: []
                    }
                    this.permissions.forEach((permission, idx) => {
                        if (permission.checked != this.permissionsChanged[idx]) {
                            if (permission.checked) {
                                data['remove'].push(permission.id)
                            } else {
                                data['add'].push(permission.id)
                            }
                        }
                    })

                    if (data.add.length > 0 || data.remove.length > 0) {
                        // TODO: [Next Phase] move to store
                        let response = await requestApi(api.permissionConfigAPI.updateRolePermission, {
                            variable: this.roleSelected.value,
                            data
                        })
                        if (response.status == HttpStatus.OK) {
                            this.alert = {
                                show: true,
                                type: 'success',
                                msg: `Update ${this.roleSelected.text}'s permissions Success`,
                                icon: iconsConst.CHECK_CIRCLE,
                                redirect: null
                            }
                            this.getRolePermissions(this.roleSelected)
                        } else {
                            this.alert = {
                                show: true,
                                type: 'error',
                                msg: `Can't Update ${this.roleSelected.text}'s permissions`,
                                icon: iconsConst.EXCLAM_TRI,
                                redirect: null
                            }
                        }
                    } else {
                        this.alert = {
                            show: true,
                            type: 'info',
                            msg: `No data to update ${this.roleSelected.text}'s permissions`,
                            icon: iconsConst.INFO_CIRCLE,
                            redirect: null
                        }
                    }
                } catch (e) {
                    this.alert = {
                        show: true,
                        type: 'error',
                        msg: `Internal Server error when update ${this.roleSelected.text}'s permissions`,
                        icon: iconsConst.EXCLAM_TRI,
                        redirect: null,
                        err: e.response ? e.response : e
                    }
                }
            }

            this.canEdit = newVal
        },
        async updateRoleSelected(newVal) {
            this.roleSelected = {
                value: newVal,
                text: await this.getRoleText(newVal)
            }
            this.showPermissions = false
            this.alert = {
                show: false,
                type: '',
                msg: '',
                icon: '',
                redirect: null
            }
        },
        async getRoleText(roleValue) {
            for (let role of this.roles) {
                if (role.value == roleValue) {
                    return role.text
                }
            }
            return ''
        },
        updatePermission(value) {
            this.permissionsChanged[value.idx] = value.boolean
        }
    }
}
</script>
