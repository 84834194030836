import { type, keyValidator, typeValidator } from '../../../utill'
import { orderType } from '../../../utill/config'

const actionTypeValidator = actionType => {
    if (!Object.values(orderType).includes(actionType)) {
        return false
    }
    return true
}

const changeListValidator = changeList => {
    for (let change of changeList) {
        if (!typeValidator([{ value: change, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(change, ['fund_shortcode', 'percent_old', 'percent_new', 'content_view'])) {
            return false
        }
        if (
            !typeValidator([
                { value: change.fund_shortcode, type: type.STRING },
                { value: change.percent_old, type: type.NUMBER },
                { value: change.percent_new, type: type.NUMBER }
            ])
        ) {
            return false
        }
    }
    return true
}

const praCurrentValidator = current => {
    if (!keyValidator(current, ['pra', 'dca', 'investment_view'])) {
        return false
    }
    if (
        !typeValidator([
            { value: current.pra, type: type.OBJECT },
            { value: current.dca, type: type.OBJECT },
            { value: current.investment_view, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return true
}

const praUpcomingValidator = current => {
    if (!keyValidator(current, ['pra', 'dca', 'investment_view', 'plan'])) {
        return false
    }
    if (
        !typeValidator([
            { value: current.pra, type: type.OBJECT },
            { value: current.dca, type: type.OBJECT },
            { value: current.investment_view, type: type.OBJECT },
            { value: current.plan, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return true
}

const praValidator = current => {
    if (
        !keyValidator(current, [
            'suggest_type',
            'fund_shortcode',
            'fund_uid',
            'sw_fund_shortcode',
            'sw_fund_uid',
            'amount',
            'unit',
            'order_date'
        ])
    ) {
        return false
    }
    if (
        !typeValidator([
            { value: current.suggest_type, type: type.STRING },
            { value: current.fund_shortcode, type: type.STRING },
            { value: current.fund_uid, type: type.STRING },
            { value: current.order_date, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

export { actionTypeValidator, changeListValidator, praCurrentValidator, praUpcomingValidator, praValidator }
