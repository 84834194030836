<template>
    <div class="row__container item__align--center" :class="!showName ? 'horizon__align--center' : ''">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div>
                    <img
                        v-if="profile.profile_picture.thumbnail"
                        :class="
                            profile.display_circle
                                ? isAdvisor
                                    ? isIcon
                                        ? 'display__advisor--icon'
                                        : 'display__advisor--circle'
                                    : isIcon
                                    ? 'display__profile--icon'
                                    : 'display__profile--circle'
                                : 'display__icon--normal'
                        "
                        :src="profile.profile_picture.thumbnail"
                        v-on="showTooltip && !showName && on"
                    />
                    <div
                        v-else
                        :class="
                            profile.display_circle
                                ? isAdvisor
                                    ? isIcon
                                        ? 'display__advisor--icon'
                                        : 'display__advisor--circle'
                                    : isIcon
                                    ? 'display__profile--icon'
                                    : 'display__profile--circle'
                                : 'display__icon--normal'
                        "
                        v-on="showTooltip && !showName && on"
                    >
                        {{ profile.abbr }}
                    </div>
                </div>
            </template>
            <span class="normal__text--white font-bold">{{ profile.full }}</span>
        </v-tooltip>
        <span v-if="showName" class="normal__text--primary font-bold left__margin--small">{{ profile.full }}</span>
    </div>
</template>

<script>
import { userProfileValidator } from '../../../store/modules/general/validator'
export default {
    props: {
        profile: {
            type: Object,
            required: true,
            validator: userProfileValidator
        },
        isAdvisor: {
            type: Boolean,
            required: false,
            default: false
        },
        isIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        showName: {
            type: Boolean,
            required: false,
            default: true
        },
        showTooltip: {
            type: Boolean,
            required: false,
            default: true
        },
        displayCircle: {
            type: Boolean,
            required: false,
            default: false
        },
        dataAlias: {
            type: String,
            default: null
        }
    }
}
</script>
