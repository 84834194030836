import { custInfoTopic } from './index'
import {
    getFirstUpperText,
    getHaveOrNotText,
    getNumberFormat,
    getPhoneFormat,
    getDateFormat,
    getYesNoText
} from '../../../utill'

const getInvestStatusText = numberStatus => {
    switch (numberStatus) {
        case 2:
            return 'Account Pending'
        case 3:
            return 'Account Pending'
        case 4:
            return 'Ready to Invest'
        case 5:
            return 'Invested'
        case 6:
            return 'Invested'
        default:
            return '-'
    }
}

const getServiceLevel = level => {
    const getServiceLevelText = internal_service_level => {
        switch (internal_service_level) {
            case 'Exclusive':
                return 'Exclusive'
            case 'Exclusive Prime':
                return 'Exclusive Prime'
            case 'Private Wealth (FPW)':
                return 'FPW'
            case 'Private Wealth Plus (FPW Plus)':
                return 'FPW Plus'
            case 'Private Banking (FPB)':
                return 'FPB'
            case 'Private Banking ex (FPB Ex)':
                return 'FPB Ex'
            case 'Private Banking Ultra (FPB Ultra)':
                return 'FPB Ultra'
            case 'Club Member':
                return 'Member'
            case 'Club':
                return 'Club'
            case 'Private':
                return 'Private'
            case 'Private Plus':
                return 'Private Plus'
            case 'Ultra':
                return 'Ultra'
            case 'Ultra Ex':
                return 'Ultra Ex'
            case 'Ultra Ultra':
                return 'Ultra Ultra'
            default:
                return '-'
        }
    }

    if (level && level.internal_service_level) {
        return getServiceLevelText(level.internal_service_level).replace(/\(|\)/g, '')
    }

    return '-'
}

const getCustTypeText = value => {
    switch (value) {
        case 'individual':
            return 'บุคคลธรรมดา'
        default:
            return value
    }
}

const getConfirmByText = value => {
    // TODO: [Next Phase]
    // return value.replace('email', 'อีเมล์')
    return value ? value.replace('email', 'email') : ''
}

const getCountryText = (value, language) => {
    switch (value) {
        case 'Thai':
        case 'ไทย':
            return language == 'TH' ? 'ไทย' : 'Thailand'
        default:
            return value
    }
}

const getSourceIncomeText = value => {
    let sources = value.split(',')
    let sourceText = ''
    sources.forEach((source, ind) => {
        if (ind > 0) {
            sourceText += ', '
        }
        switch (source.trim().toUpperCase()) {
            case 'SALARY':
                sourceText += 'เงินเดือน/ค่าจ้าง/กำไรจากการดำเนินธุรกิจ'
                break
            case 'HERITAGE':
                sourceText += 'มรดก/เงินที่ได้รับโดยเสน่หา'
                break
            case 'INTEREST':
                sourceText += 'ดอกเบี้ย/เงินปันผล/ผลตอบแทนจากการลงทุน'
                break
            case 'RENT':
                sourceText += 'ค่าเช่า'
                break
            case 'RETIREMENT':
            case 'RESIGN':
                sourceText += 'เงินที่ได้รับจากการเกษียณอายุ/ออกจากงาน'
                break
            case 'SAVINGS':
                sourceText += 'เงินออม'
                break
            case 'INVESTMENT':
                sourceText += 'จากการลงทุน'
                break
            case 'BUSINESS':
                sourceText += 'รายได้จากการดำเนินธุรกิจ'
                break
            default:
                if (source.length > 0 && source !== null) {
                    if (source.includes('OTHER')) {
                        sourceText += source.slice(6).trim()
                    } else {
                        sourceText += source
                    }
                } else {
                    sourceText += '-'
                }
                break
        }
    })
    return sourceText
}

const getMaritalStatusText = value => {
    switch (value) {
        case 'Single':
            return 'โสด'
        case 'Married':
            return 'สมรส'
        case 'Divorce':
            return 'หย่า'
        case 'Widow':
            return 'หม้าย'
        default:
            return value
    }
}

const getInfoText = (key, value) => {
    switch (key) {
        case 'investment_status':
            return getInvestStatusText(value)
        case 'account_type':
            return getFirstUpperText(value)
        case 'customer_type':
            return getCustTypeText(value)
        case 'service_level':
            return getServiceLevel(value)
        case 'is_pp20':
        case 'is_wht':
            return getYesNoText(value)
        case 'is_accept_money_transfer':
            return value ? 'รับได้' : 'รับไม่ได้'
        case 'open_account_at':
        case 'suitability_date':
        case 'suitability_expiry_date':
        case 'kyc_date':
        case 'kyc_expiry_date':
        case 'birth_date':
        case 'pid_expiry_date':
        case 'personal_laser_id':
            return value ? getDateFormat(value) : '-'
        case 'mobile_phone':
        case 'phone':
            return value ? getPhoneFormat(value) : '-'
        case 'confirm_by':
            return getConfirmByText(value)
        case 'kyc_level':
            return value ? value : '-'
        case 'nationality':
        case 'spouse_nationality':
        case 'child_nationality':
            return getCountryText(value, 'TH')
        case 'sources_of_income':
            return getSourceIncomeText(value)
        case 'initial':
            return value ? getNumberFormat(value, false, 2, 'บาท') : '-'
        case 'fatca':
        case 'relation_with_politician':
        case 'scam_money':
        case 'is_sue':
        case 'is_rejected':
            return value != null ? getHaveOrNotText(value) : '-'
        case 'marital_status':
            return value ? getMaritalStatusText(value) : '-'
        case 'business_type':
        case 'citizen_address':
        case 'contact_address':
        case 'business_address':
        case 'current_address':
        case 'underage_children':
        case 'monthly_income':
            return value == null ? '-' : value
        case 'knowledge_assessment':
            return value == null ? 'None' : 'View'
        case 'call_status':
            return value ? 'รับ' : 'ไม่รับ'
        case 'pdpa_call_status':
        case 'pdpa_promotion_status':
        case 'pdpa_analytic_status':
            return value == null ? 'Retry' : value ? 'Allow' : 'Not Allow'
        case 'crs_expiry_date':
        case 'crs_status':
            return value == null ? 'RetryCRS' : value
        case 'is_ka_accept_result':
        case 'is_visually_impaired':
        case 'is_hearing_impaired':
        case 'is_age_over_60':
            return value == null ? 'RetryANEO' : value
        default:
            return value
    }
}

const getGeneralInfo = allInfo => {
    let generalSection = [
        [
            'customer_code',
            'investment_status',
            'account_type',
            'customer_type',
            'service_level',
            'is_pp20',
            'is_wht',
            'is_accept_money_transfer',
            'open_account_at',
            'confirm_by'
        ],
        [
            'assessment',
            'risk_level',
            'suitability_date',
            'suitability_expiry_date',
            'empty',
            'kyc_level',
            'kyc_date',
            'kyc_expiry_date',
            'empty',
            'crs_status',
            'crs_expiry_date',
            'space',
            'knowledge_assessment',
            'is_ka_accept_result'
        ],
        [
            'contact',
            'email',
            'mobile_phone',
            'phone',
            'empty',
            'privacy_consent',
            'pdpa_call_status',
            'pdpa_promotion_status',
            'pdpa_analytic_status'
        ]
    ]
    let generalInfo = []
    for (let section of generalSection) {
        let sectionInfo = []
        for (let info of section) {
            if (custInfoTopic.general_info[info] === custInfoTopic.general_info.service_level) {
                sectionInfo.push({
                    topic: custInfoTopic.general_info[info],
                    value: getServiceLevel(allInfo.general['level'])
                })
                continue
            }
            sectionInfo.push({
                topic: custInfoTopic.general_info[info],
                value: getInfoText(info, allInfo.general[info])
            })
        }
        generalInfo.push(sectionInfo)
    }
    return generalInfo
}

const getPersonalInfo = allInfo => {
    let personalSection = [
        ['name_th', 'name_en', 'birth_date', 'personal_id', 'sources_of_income', 'occupation', 'business_type'],
        [
            'work_place',
            'monthly_income',
            'load_spend',
            'initial',
            'fatca',
            'relation_with_politician',
            'scam_money',
            'is_rejected'
        ],
        ['current_address', 'is_sue', 'special_needs', 'is_visually_impaired', 'is_hearing_impaired', 'is_age_over_60']
    ]
    let personalInfo = []
    for (let section of personalSection) {
        let sectionInfo = []
        for (let info of section) {
            sectionInfo.push({
                topic: custInfoTopic.personal_info[info],
                value: getInfoText(info, allInfo.personal[info])
            })
        }
        personalInfo.push(sectionInfo)
    }
    return personalInfo
}

const getFamilyInfo = allInfo => {
    let familySection = ['marital_status', 'underage_children']
    let familyInfo = []
    let sectionInfo = []
    for (let info of familySection) {
        sectionInfo.push({
            topic: custInfoTopic.family_info[info],
            value: getInfoText(info, allInfo.personal[info])
        })
    }
    familyInfo.push(sectionInfo)

    if (allInfo.personal.spouse_citizen_id != null || allInfo.personal.spouse_nationality != null) {
        sectionInfo = []
        sectionInfo.push({
            relation: custInfoTopic.family_info['spouse_relation'],
            full_name: getInfoText('spouse_display_name', allInfo.personal['spouse_display_name']),
            personal_id: getInfoText('spouse_citizen_id', allInfo.personal['spouse_citizen_id']),
            country: getInfoText('spouse_nationality', allInfo.personal['spouse_nationality'])
        })
        familyInfo.push(sectionInfo)
    }

    // TODO: [Next Phase] handle child
    return familyInfo
}

export {
    getConfirmByText,
    getCountryText,
    getCustTypeText,
    getFamilyInfo,
    getGeneralInfo,
    getInfoText,
    getInvestStatusText,
    getMaritalStatusText,
    getPersonalInfo,
    getSourceIncomeText,
    getServiceLevel
}
