<template>
    <div>
        <span class="size__font--16px normal__text--primary">{{ label }} </span>
        <div class="row__container item__align--center">
            <v-autocomplete
                :value="value"
                :items="items"
                :search-input.sync="input"
                class="autocomplete"
                hide-no-data
                hide-selected
                :item-text="itemText"
                :item-value="itemValue"
                :placeholder="placeholder"
                return-object
                chips
                deletable-chips
                multiple
                :menu-props="menuProps"
                @change="handleChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: false,
            default: () => ''
        },
        placeholder: {
            type: String,
            required: false,
            default: () => ''
        },
        value: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        },
        maxSelected: {
            type: Number,
            required: false,
            default: () => {
                return 5
            }
        },
        dataAlias: {
            type: String,
            required: false,
            default: null
        },
        itemText: {
            type: String,
            required: false,
            default: () => 'text'
        },
        itemValue: {
            type: String,
            required: false,
            default: () => 'value'
        }
    },
    data() {
        return {
            input: null
        }
    },
    computed: {
        menuProps() {
            return {
                disabled: this.value.length === this.maxSelected
            }
        }
    },
    methods: {
        handleChange(val) {
            if (val.length <= this.maxSelected) {
                this.$emit('change', val)
            }
            this.input = null
        }
    }
}
</script>
<style lang="scss">
.autocomplete {
    &.v-text-field {
        padding-top: 0px !important;
        margin-top: 0px !important;
    }
    .v-input__icon .v-input__icon--append {
        display: none;
    }
    .v-input__control {
        .v-input__slot {
            margin-bottom: 0px;
            .v-messages {
                display: none;
            }
            .v-select__slot {
                .v-input__append-inner {
                    display: none;
                }
            }
            .v-select__selections {
                margin: 5px 0px;
                border-radius: 5px;
                border: 1px solid #e4e8ea;
                padding: 5px 10px;
                font-size: 16px;
                .theme--light.v-chip:not(.v-chip--active) {
                    background-color: transparent;
                }
            }
            &:before {
                display: none;
            }
            &:after {
                content: none !important;
            }
        }
    }
}
</style>
