<template>
    <article>
        <div class="background-full horizon__padding--normal">
            <div class="forgot-password-panel">
                <f-4-a-logo />
                <forgot-password class="vertical__margin--big" />
            </div>
        </div>
    </article>
</template>

<script>
import { F4ALogo } from '../../atoms'
import { ForgotPassword } from '../../organisms'

export default {
    components: { F4ALogo, ForgotPassword }
}
</script>

<style lang="scss" scoped>
.background-full {
    display: flex;
    justify-content: center;
    height: 100vh;
    padding-top: 10vh;
    padding-bottom: 30vh;
}
.forgot-password-panel {
    max-width: 416px !important;
    min-width: 288px !important;
    width: 100%;
}
</style>
