import { getNumberFormat, isNumber, validateMaxValue, validateMinValue } from '../../../utill'

const showValidate = (key, filterTmp, validation) => {
    let min, max
    switch (key) {
        case 'cost':
        case 'outstanding':
            min = filterTmp[key].min != '' ? parseInt(filterTmp[key].min.replace(/,*[^0-9-.]*/g, '')) : 0
            max = filterTmp[key].max != '' ? parseInt(filterTmp[key].max.replace(/,*[^0-9-.]*/g, '')) : 99999999999
            if (min > max) {
                validation[key] = true
                return true
            }
            return false
        case 'unrealized_pl':
            min = filterTmp[key].min != '' ? parseFloat(filterTmp[key].min.replace(/,*[^0-9-.]*/g, '')) : -100.0
            max = filterTmp[key].max != '' ? parseFloat(filterTmp[key].max.replace(/,*[^0-9-.]*/g, '')) : 2000.0
            if (min > max) {
                validation[key] = true
                return true
            }
            return false
        default:
            return false
    }
}
const updateValueFilter = (key, value, filterTmp, validation, featureServiceLevelRebranding) => {
    let min,
        max,
        numberOfDecimals = 0
    switch (key) {
        case 'cost':
            min = value[0]
            max = value[1]
            if (filterTmp.cost.min != min) {
                min = min.replace(/,*[^0-9-.]*/g, '')
                if (isNumber(min)) {
                    min = parseInt(min)
                    min = validateMinValue(0, min)
                    min = validateMaxValue(99999999999, min)
                    filterTmp.cost.min = getNumberFormat(min, false, numberOfDecimals, '')
                } else {
                    filterTmp.cost.min = ''
                }
            }
            if (filterTmp.cost.max != max) {
                max = max.replace(/,*[^0-9-.]*/g, '')
                if (isNumber(max)) {
                    max = parseInt(max)
                    max = validateMinValue(0, max)
                    max = validateMaxValue(99999999999, max)
                    filterTmp.cost.max = getNumberFormat(max, false, numberOfDecimals, '')
                } else {
                    filterTmp.cost.max = ''
                }
            }
            validation.cost = showValidate('cost', filterTmp, validation)
            break
        case 'outstanding':
            min = value[0]
            max = value[1]
            if (filterTmp.outstanding.min != min) {
                min = min.replace(/,*[^0-9-.]*/g, '')
                if (isNumber(min)) {
                    min = parseInt(min)
                    min = validateMinValue(0, min)
                    min = validateMaxValue(99999999999, min)
                    filterTmp.outstanding.min = getNumberFormat(min, false, numberOfDecimals, '')
                } else {
                    filterTmp.outstanding.min = ''
                }
            }
            if (filterTmp.outstanding.max != max) {
                max = max.replace(/,*[^0-9-.]*/g, '')
                if (isNumber(max)) {
                    max = parseInt(max)
                    max = validateMinValue(0, max)
                    max = validateMaxValue(99999999999, max)
                    filterTmp.outstanding.max = getNumberFormat(max, false, numberOfDecimals, '')
                } else {
                    filterTmp.outstanding.max = ''
                }
            }
            validation.outstanding = showValidate('outstanding', filterTmp, validation)
            break
        case 'unrealized_pl':
            min = value[0]
            max = value[1]
            if (filterTmp.unrealized_pl.min != min) {
                min = min.replace(/,*[^0-9-.]*/g, '')
                if (isNumber(min)) {
                    min = parseInt(min)
                    min = validateMinValue(-100, min)
                    min = validateMaxValue(2000, min)
                    filterTmp.unrealized_pl.min = getNumberFormat(min, false, numberOfDecimals, '')
                } else {
                    filterTmp.unrealized_pl.min = ''
                }
            }
            if (filterTmp.unrealized_pl.max != max) {
                max = max.replace(/,*[^-100-9-.]*/g, '')
                if (isNumber(max)) {
                    max = parseInt(max)
                    max = validateMinValue(-100, max)
                    max = validateMaxValue(2000, max)
                    filterTmp.unrealized_pl.max = getNumberFormat(max, false, numberOfDecimals, '')
                } else {
                    filterTmp.unrealized_pl.max = ''
                }
            }
            validation.unrealized_pl = showValidate('unrealized_pl', filterTmp, validation)
            break
        case 'investment_status':
            filterTmp.investment_status = {
                ready_to_invest: value[0],
                invested: value[1],
                closed: value[2],
                oa_reviewing: value[3],
                oa_rejected: value[4],
                ats: value[5],
                kyc: value[6]
            }
            break
        case 'favorite_status':
            filterTmp.favorite_status = {
                favorite: value[0],
                regular: value[1]
            }
            break
        case 'funds':
            filterTmp.funds = value
            break
        case 'plan_type':
            filterTmp.plan_type = value.map(v => v.plan_type_shortcode)
            break
        case 'others':
            filterTmp.others = {
                has_action_required: value[0],
                has_dca_profiles: value[1]
            }
            break
        case 'service_level':
            if (featureServiceLevelRebranding) {
                filterTmp.service_level = {
                    none: value[0],
                    club: value[1],
                    exclusive: value[2],
                    exclusive_prime: value[3],
                    private: value[4],
                    private_plus: value[5],
                    ultra: value[6],
                    ultra_ex: value[7],
                    ultra_ultra: value[8]
                }
            } else {
                filterTmp.service_level = {
                    none: value[0],
                    member: value[1],
                    exclusive: value[2],
                    exclusive_prime: value[3],
                    fpw: value[4],
                    fpw_plus: value[5],
                    fpb: value[6],
                    fpb_ex: value[7],
                    fpb_ultra: value[8]
                }
            }
            break
    }
}

export { showValidate, updateValueFilter }
