<template>
    <div class="row__container item__align--center bottom__margin--big wrap__content">
        <span class="normal__text--primary font-bold size__font--16px">
            {{ title }}
        </span>
        <v-spacer></v-spacer>
        <span v-if="showMore" class="btn__secondary--normal" @click="selectTab()">
            More
        </span>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        seeMoreLink: {
            type: String,
            required: false,
            default: ''
        },
        isExternalLink: {
            type: Boolean,
            required: false,
            default: false
        },
        dataAlias: {
            type: String,
            required: false,
            default: null
        }
    },
    methods: {
        redirectSeeMore() {
            if (this.isExternalLink) {
                window.location.href = this.seeMoreLink
                return
            }
            this.redirect(this.seeMoreLink)
        },
        selectTab() {
            if (this.seeMoreLink == '') {
                this.$emit('seeMore', this.seeMoreTab)
            } else {
                this.redirectSeeMore()
            }
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push({ name: pathName })
        }
    }
}
</script>
