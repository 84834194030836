<template>
    <div class="list__container left__border--2px left__padding--normal bottom__margin--small">
        <span class="topic__text--primary  topic__status--heigh">{{ title }}</span>
        <span class="normal__text--primary font-bold">{{ status }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.topic__status--heigh {
    line-height: 1.8;
}
</style>
