<template>
    <v-footer v-if="showAddToHomeScreen" class="pa-0 horizon__align--center background--none" fixed>
        <div
            id="box-add-to-home-screen"
            :class="[
                'row__container',
                'background--white',
                'vertical__padding--small',
                'horizon__align--center',
                'horizon__padding--normal'
            ]"
        >
            <div class="mr-3">
                <img
                    class="item__align--middle"
                    :src="require('../../../../public/img/icons/F4A-a2hs.png')"
                    width="55"
                />
            </div>
            <div data-fn-location="sticky-bottom">
                <div class="horizon__align--left">
                    <p class="mb-1 normal__text--black font-bold">Add FINNOMENA for Advisor to your Home screen?</p>
                    <p class="mb-1 normal__text--black">
                        Get to FINNOMENA for Advisor quickly and easily by adding it to your Home screen.
                    </p>
                </div>
                <div class="horizon__align--right">
                    <button class="font-bold horizon_padding--normal mr-5 my-2" @click="disableAddToHomeScreen">
                        Not Now
                    </button>
                    <button
                        id="btn-add-to-home-screen"
                        class="yellow__btn--normal font-bold horizon_padding--normal my-2"
                        data-fn-action="home-screen_add"
                        @click="showPromptAddToHomeScreen"
                    >
                        Add to Home screen
                    </button>
                </div>
            </div>
        </div>
    </v-footer>
</template>

<script>
export default {
    data() {
        return {
            showAddToHomeScreen: false,
            timeoutShowAddToHomeScreen: 3000,
            deferPrompt: null
        }
    },
    mounted() {
        // PWA - Add to Home screen
        window.addEventListener('beforeinstallprompt', e => {
            if (this.$cookies.get('request_add_to_home_screen') != 'cancel') {
                e.preventDefault()
                this.deferPrompt = e

                setTimeout(() => {
                    this.showAddToHomeScreen = true
                }, this.timeoutShowAddToHomeScreen)
            }
        })
    },
    methods: {
        showPromptAddToHomeScreen() {
            if (this.deferPrompt && this.deferPrompt.type == 'beforeinstallprompt') {
                this.deferPrompt.prompt()
                this.deferPrompt.userChoice.then(result => {
                    if (result && result.outcome == 'accepted') {
                        this.showAddToHomeScreen = false
                    }
                    this.deferPrompt = null
                })
            }
        },
        disableAddToHomeScreen() {
            this.$cookies.set('request_add_to_home_screen', 'cancel', '3d')
            this.showAddToHomeScreen = false
        }
    }
}
</script>

<style lang="scss" scoped>
#box-add-to-home-screen {
    box-shadow: 0 0 5px 0px #aaaaaa;
    @include responsive(phablet) {
        width: 100%;
    }
    @include responsive(mobile) {
        width: 100%;
    }
}
</style>
