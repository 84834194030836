<template>
    <base-modal
        title="Approval Stats"
        :visible="visible"
        :close="closeModal"
        :reset="() => {}"
        :apply="() => {}"
        prefix-alias="approval_stat-cross_mark"
    >
        <div class="general__modal--content">
            <span>{{ requestUnitLabel(requestCount) }} processed.</span>
            <span data-alias="approval_stat-approved-data"
                >Approved: <b>{{ requestUnitLabel(approvedCount) }}</b></span
            >
            <span data-alias="approval_stat-rejected-data"
                >Rejected: <b>{{ requestUnitLabel(rejectedCount) }}</b></span
            >
        </div>
        <div class="row__container sortby__content top__border--solid vertical__padding--normal horizon__padding--big">
            <v-spacer />
            <button
                class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small"
                @click="$emit('close')"
            >
                Close
            </button>
        </div>
    </base-modal>
</template>

<script>
import { BaseModal } from './index'
export default {
    components: {
        BaseModal
    },
    props: {
        visible: Boolean,
        requestCount: {
            type: Number,
            default: 0
        },
        approvedCount: {
            type: Number,
            default: 0
        },
        rejectedCount: {
            type: Number,
            default: 0
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        requestUnitLabel(count) {
            return `${count} ${count > 1 ? 'requests' : 'request'}`
        }
    }
}
</script>

<style lang="scss" scoped>
.general__modal {
    width: 416px;
    height: max-content;
    border-radius: 8px;
    border: 1px solid $greyPrimary;
    background: $white;
    @include responsive(mobile) {
        width: 100%;
        max-width: 320px;
    }
    .general__modal--content {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 59px;
        color: $textPrimary;
        font-size: 16px;
        min-height: 6rem;
        display: flex;
        flex-direction: column;
    }
}
</style>
