import dayjs from 'dayjs'
import { getDateFormat, getNumberFormat, DATE_PICKER_START, DATE_PICKER_END } from '../../../utill'
import { distributionType } from '../../../utill/config'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

const getDistributionList = distributionList => {
    let list = []
    for (let distribution of distributionList) {
        list.push({
            fund_name: distribution.fund_name || '-',
            order_id: distribution.order_id || '-',
            order_type: showDistributionType(distribution.order_type) || '-', //ประเภท
            amount: distribution.amount != null ? getNumberFormat(distribution.amount, false, 2, '฿') : '- ฿', //มูลค่า
            unit: distribution.unit != null ? getNumberFormat(distribution.unit, false, 4, 'หน่วย') : '- หน่วย', //จำนวนหน่วย
            nav: distribution.nav != null ? getNumberFormat(distribution.nav, false, 4, '฿') : '- ฿', //มูลค่า/หน่วย
            trade_date: distribution.trade_date ? getDateFormat(distribution.trade_date) : '-', //วันที่ทำรายการ
            payment_date: distribution.payment_date ? getDateFormat(distribution.payment_date) : '-', //วันที่จ่ายปันผล,วันที่ได้รับ
            book_closing_date: distribution.book_closing_date ? getDateFormat(distribution.book_closing_date) : '-', //วันที่ปิดสมุด
            payment_method: distribution.payment_method || '-', //วิธีรับเงิน
            bank_account: distribution.bank_account || '-' //บัญชีธนาคาร
        })
    }
    return list
}

const showDistributionType = type => {
    switch (type) {
        case 'dividend':
            return distributionType.DIVIDEND
        case 'auto_redemption':
            return distributionType.AR
        default:
            return type
    }
}

const filterFunc = (current, filterList) => {
    let isFilter = false
    if (!filterList) {
        return true
    }

    if (filterList.orderType && filterList.orderType.ar && current.order_type === distributionType.AR) {
        isFilter = true
    }
    if (filterList.orderType && filterList.orderType.dividend && current.order_type === distributionType.DIVIDEND) {
        isFilter = true
    }
    const parsePaymentDate = dayjs(current.payment_date).endOf('days')
    if (
        filterList.paymentDate &&
        filterList.paymentDate.startDate &&
        filterList.paymentDate.startDate !== DATE_PICKER_START
    ) {
        const parseStartDate = dayjs(filterList.paymentDate.startDate).endOf('days')
        if (!parseStartDate.isSameOrBefore(parsePaymentDate)) {
            return false
        }
    }
    if (
        filterList.paymentDate &&
        filterList.paymentDate.endDate &&
        filterList.paymentDate.endDate !== DATE_PICKER_END
    ) {
        const parseEndDate = dayjs(filterList.paymentDate.endDate).endOf('days')
        if (!parseEndDate.isSameOrAfter(parsePaymentDate)) {
            return false
        }
    }
    return isFilter
}

const searchFunc = (current, searchWord, filterList) => {
    // INFO: search by fund only
    let searchList = ['fund_name']
    for (let searchKey of searchList) {
        if (current[searchKey].toUpperCase().search(searchWord.toUpperCase()) >= 0 && filterFunc(current, filterList)) {
            return true
        }
    }
    return false
}

const getVisibledDistribution = (allDistributionList, { searchWord, filterList }) => {
    return allDistributionList.filter(current => searchFunc(current, searchWord, filterList))
}

export { getDistributionList, getVisibledDistribution }
