<template>
    <div>
        <div v-if="completedData">
            <div class="background--normal__grey content__page">
                <div class="big__text bottom__margin--big top__margin--big">
                    Link สำหรับยืนยันผู้ดูแล กรณีลืมกรอก Referral Code
                </div>
            </div>
            <div class="background__white bottom__padding--footer top__padding--big content__page">
                <div class="row__container item__align--center">
                    <div class="round__block--ref_code_link">
                        <div class="confirmation__link big__text text__link">
                            <input id="Link" type="hidden" :value="confirmationLink" />
                            {{ confirmationLink }}
                        </div>
                        <div class="copy__button">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <button
                                        v-bind="attrs"
                                        class="copy__icon fas fa-copy"
                                        v-on="on"
                                        @click="copyLink"
                                    />
                                </template>
                                <span>Copy to clipboard</span>
                            </v-tooltip>
                        </div>
                    </div>
                    <div class="display__tablet--none lessthan">
                        <template v-if="copied == true">
                            <div class="big__text">Copied!</div>
                        </template>
                    </div>
                </div>
                <div class="display__desktop--none">
                    <template v-if="copied == true">
                        <div class="big__text text__align--center">Copied!</div>
                    </template>
                </div>
            </div>
        </div>
        <div v-else class="background--full__grey horizon__align--center item__align--center">
            <spinner />
        </div>
    </div>
</template>

<script>
import { Spinner } from '../../atoms'
export default {
    components: { Spinner },
    props: {
        refCode: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            completedData: false,
            confirmationLink: '',
            copied: false,
            linkConfig: ''
        }
    },
    async created() {
        this.linkConfig = process.env.VUE_APP_CONFIRM_REF_CODE_LINK
        this.confirmationLink = this.linkConfig + this.refCode
    },
    mounted() {
        this.completedData = true
    },
    methods: {
        copyLink() {
            try {
                var link = document.getElementById('Link')
                link.setAttribute('type', 'text')
                link.select()
                document.execCommand('copy')
            } catch (err) {
                alert('Unable to copy')
            }
            link.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
            this.copied = true
        }
    }
}
</script>

<style>
.round__block--ref_code_link {
    display: flex;
    height: auto;
    min-height: auto !important;
    border: 1px solid;
    border-color: #e6e6e6;
    border-radius: 8px;
    margin: 16px !important;
    padding: 20px 30px !important;
    align-items: center;
    justify-content: center;
}

.big__text {
    color: #444444;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
}

.confirmation__link {
    display: inline-block;
    vertical-align: middle;
    padding-right: 40px;
}

.copy__button {
    display: inline-block;
    vertical-align: middle;
}

.text__link {
    word-break: break-all;
    padding-right: 30px;
}

.copy__icon {
    color: #f7a501 !important;
    text-decoration: none;
    padding: none !important;
    cursor: pointer;
    font-size: 40px;
}

.background__white {
    background: #fff !important;
    max-width: 100% !important;
    min-height: 27vh;
}
</style>
