<template>
    <div>
        <template v-if="actionList.length >= 1">
            <div v-for="(action, actIdx) in actionList" :key="`action-${actIdx}`">
                <pra-action v-if="action == actionsConst.PRA" :pra-status="`need-adjustment`" />
            </div>
        </template>
        <template v-else>
            -
        </template>
    </div>
</template>

<script>
import { actionsConst } from '../../../store/modules/customer/config'
import { PraAction } from '../../atoms'

export default {
    components: {
        PraAction
    },
    props: {
        actionList: {
            type: Array,
            required: true
        },
        dataAlias: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            actionsConst
        }
    }
}
</script>
