<template>
    <div class="row__container radius__border--normal all__padding--normal item__align--center wrap__content">
        <action-type :type="actionType"></action-type>
        <span class="normal__text--primary font-bold"
            >{{ funds[0] }}
            <external-link @click.native="$emit('open-fund-tab', funds[0])" />
            <span v-if="actionType == orderType.SWITCH">
                <i class="normal__text--blue font-bold" :class="iconsConst.LIGHT__ARROW__RIGHT" />
                {{ funds[1] }}</span
            >
        </span>
        <v-spacer />
        <span v-if="amount != -1" class="normal__text--primary">
            {{ getNumberFormat(amount, false, amount.toString().includes('.') ? 2 : 0, '฿') }}
        </span>
        <span v-else class="normal__text--primary">ทั้งหมด</span>
    </div>
</template>

<script>
import { actionTypeValidator } from '../../../store/modules/port/validator'
import { getNumberFormat } from '../../../store/utill'
import { iconsConst, orderType } from '../../../store/utill/config'
import { ActionType, ExternalLink } from '../../atoms'

export default {
    components: {
        ActionType,
        ExternalLink
    },
    props: {
        actionType: {
            type: String,
            required: true,
            validator: actionTypeValidator
        },
        funds: {
            type: Array,
            required: true
        },
        amount: {
            type: Number,
            required: true,
            default: -1
        }
    },
    data() {
        return {
            iconsConst,
            orderType
        }
    },
    methods: {
        getNumberFormat
    }
}
</script>

<style lang="scss" scoped>
.action__space {
    width: 70px;
}
</style>
