<template>
    <v-app>
        <v-main class="background--normal__white font--primary">
            <feature-provider>
                <router-view />
            </feature-provider>
        </v-main>
        <!-- <box-add-to-home-screen /> -->
    </v-app>
</template>

<script>
// import { BoxAddToHomeScreen } from './components/molecules'
import { FeatureProvider } from './components/templates'
export default {
    components: {
        // BoxAddToHomeScreen
        FeatureProvider
    }
}
</script>

<style lang="scss">
@import './styles/scss/main.scss';
@import '/fonts/DBHeaventRez.css';
@import '/fontawesome-v5/css/all.min.css';
</style>
