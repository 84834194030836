<template>
    <button class="affirmative__btn" @click="$emit('click')">
        <i :class="iconsConst.CHECKMARK"></i>
    </button>
</template>

<script>
import { iconsConst } from '../../../store/utill'
export default {
    props: {
        dataAlias: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            iconsConst
        }
    }
}
</script>

<style lang="scss" scoped>
.affirmative__btn {
    width: 48px;
    height: 32px;
    font-size: 16px;
    border-radius: 8px;
    background-color: $greenPrimary;
    color: white;
}
</style>
