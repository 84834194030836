export const getters = {
    getAdvisor(state) {
        return state.advisor
    },
    getBasicInfo(state) {
        return state.basicInfo
    },
    getOutstanding(state) {
        return state.outstanding
    },
    getDca(state) {
        return state.dca
    },
    getHistory(state) {
        return state.history
    },
    getDistribution(state) {
        return state.distribution
    },
    getModel(state) {
        return state.model
    },
    getPra(state) {
        return state.pra
    }
}
