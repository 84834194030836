<template>
    <div class="horizon__padding--normal">
        <table class="table__secondary--normal">
            <thead>
                <tr class="head__table--primary bottom__border--solid">
                    <th class="horizon__align--left">Date</th>
                    <th class="horizon__align--left">Type</th>
                    <th
                        class="horizon__align--left display__phablet--none lessthan"
                    >
                        Description
                    </th>
                </tr>
            </thead>
            <tbody v-if="logList != null && logList.length > 0">
                <template v-for="(row, idx) in sortDataWithDateDesc(logList)">
                    <tr :key="`row-${idx}`" class="bottom__border--solid border__tablet--none">
                        <td class="horizon__align--left">
                            <span class="normal__text--primary">{{
                                getDateFormat(row.date, '', 'd mmm yy | hh:mm:ss')
                            }}</span>
                        </td>
                        <td class="horizon__align--left">
                            <span class="normal__text--primary">{{
                                row.type
                            }}</span>
                        </td>
                        <td class="horizon__align--left display__phablet--none lessthan">
                            <span
                                v-if="row.subType"
                                class="normal__text--primary font-bold"
                            >
                                {{ row.subType }}:
                            </span>
                            <span
                                v-if="row.description.includes(row.account_code)"
                                class="normal__text--primary"
                            >
                                <span>{{ row.description.split(row.account_code)[0] }}</span>
                                <span>
                                    <link-anchor
                                        class-name="btn__secondary--normal font-bold link"
                                        :to="getPortUrl(row.account_code)"
                                        :external="false"
                                    >
                                        {{ row.account_code }}
                                    </link-anchor>
                                </span>
                                <span>{{ row.description.split(row.account_code)[1] }}</span>
                            </span>
                            <span v-else class="normal__text--primary">{{
                                row.description
                            }}</span>
                        </td>
                    </tr>
                    <!-- Row for responsive -->
                    <tr :key="`row-responsive-${idx}`" class="bottom__border--solid display__desktop--none">
                        <td colspan="10">
                            <div class="block__container">
                                <div class="display__tablet--none morethan block__col--responsive">
                                    <div class="subblock__col--responsive">
                                        <span
                                            class="normal__text--secondary font-weight-bold"
                                            >Description:
                                        </span>
                                        <span
                                            v-if="row.subType"
                                            class="normal__text--primary font-bold"
                                        >
                                            {{ row.subType }}:
                                        </span>
                                        <span
                                            v-if="row.description.includes(row.account_code)"
                                            class="normal__text--primary"
                                        >
                                            <span>{{ row.description.split(row.account_code)[0] }}</span>
                                            <span>
                                                <link-anchor
                                                    class-name="btn__secondary--normal font-bold link"
                                                    :to="getPortUrl(row.account_code)"
                                                    :external="false"
                                                >
                                                    {{ row.account_code }}
                                                </link-anchor>
                                            </span>
                                            <span>{{ row.description.split(row.account_code)[1] }}</span>
                                        </span>
                                        <span
                                            v-else
                                            class="normal__text--primary"
                                        >
                                            {{ row.description }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tbody v-else>
                <tr class="bottom__border--solid">
                    <td colspan="3" class="no__result">No Result</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { getDateFormat, pagesConst } from '../../../store/utill'
import LinkAnchor from '../../atoms/LinkAnchor'
export default {
    components: {
        LinkAnchor
    },
    props: {
        logList: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            subTypes: ['Identity Verification', 'OA', 'ATS']
        }
    },
    methods: {
        getDateFormat,
        getPortUrl(port) {
            return `${pagesConst.PORT_INFO}/${port}`
        },
        sortDataWithDateDesc(data) {
            data.sort(function(a, b) {
                return new Date(b.date) - new Date(a.date)
            })

            const resp = data.map(d => {
                const [subType, ...descriptions] = d.description.split(': ')
                if (this.subTypes.includes(subType)) {
                    return { ...d, subType, description: descriptions.join(': ') }
                } else {
                    return d
                }
            })
            return resp
        }
    }
}
</script>
<style lang="scss" scoped>
.border__tablet--none {
    @include multiple-responsive(mobile, phablet, tablet) {
        border-bottom: none !important;
    }
}
.block__col--responsive {
    line-height: 2;
    @include responsive(phablet) {
        display: inline-block;
        width: 100%;
    }
    @include responsive(mobile) {
        display: block;
        width: 100%;
    }
}
.subblock__col--responsive {
    display: inline-block;
    width: 50%;
    @include multiple-responsive(mobile, phablet) {
        width: 100%;
        display: block;
    }
}
.block__container {
    display: flex;
    flex-direction: column;
    @include responsive(phablet) {
        flex-direction: row;
    }
}
.link {
    text-decoration: none;
}

@media (max-width: $breakpoint-mobile-max) {
    .table__secondary--normal {
        border-collapse: collapse;
        max-width: $breakpoint-mobile-max;
    }

    .table__secondary--normal thead tr th:nth-child(1),
    tbody tr td:nth-child(1) {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(3),
    tbody tr td:nth-child(3) {
        width: 229px;
        min-width: 229px;
        max-width: 229px;
        word-break: normal;
        white-space: normal;
    }
}

@media (min-width: $breakpoint-phablet-min) {
    .table__secondary--normal {
        border-collapse: collapse;
        max-width: $breakpoint-phablet-min;
    }

    .table__secondary--normal thead tr th:nth-child(1),
    tbody tr td:nth-child(1) {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(3),
    tbody tr td:nth-child(3) {
        width: 230px;
        min-width: 230px;
        max-width: 230px;
        word-break: normal;
        white-space: normal;
    }
}

@media (min-width: $breakpoint-tablet-min) {
    .table__secondary--normal {
        border-collapse: collapse;
        max-width: $breakpoint-tablet-min;
    }

    .table__secondary--normal thead tr th:nth-child(1),
    tbody tr td:nth-child(1) {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(3),
    tbody tr td:nth-child(3) {
        width: 466px;
        min-width: 466px;
        max-width: 466px;
        word-break: normal;
        white-space: normal;
    }
}

@media (min-width: $breakpoint-desktop-min) {
    .table__secondary--normal {
        border-collapse: collapse;
        max-width: $breakpoint-desktop-min;
    }

    .table__secondary--normal thead tr th:nth-child(1),
    tbody tr td:nth-child(1) {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(2),
    tbody tr td:nth-child(2) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        word-break: normal;
        white-space: normal;
    }

    .table__secondary--normal thead tr th:nth-child(3),
    tbody tr td:nth-child(3) {
        width: 724px;
        min-width: 724px;
        max-width: 724px;
        word-break: normal;
        white-space: normal;
    }
}
</style>
