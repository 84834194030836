import { getNumberFormat, orderByConst } from '../../../utill'

const buildApprovalList = approvalList => {
    let list = []
    for (let approval of approvalList) {
        list.push({
            request_id: approval.request_id ? approval.request_id : -1,
            ia_name: approval.advisor_name ? approval.advisor_name : '-',
            customer_name: approval.customer_name ? approval.customer_name : '-',
            customer_code: approval.customer_code ? approval.customer_code : -1,
            outstanding: approval.outstanding ? getNumberFormat(approval.outstanding, false, 2) : '-',
            request_date: approval.request_date,
            note: approval.note ? approval.note : '-',
            status: approval.status
        })
    }
    return list
}

const getSortValueByKey = (a, b, key) => {
    switch (key) {
        case 'request_date':
            return { firstValue: a.request_date.text, secondValue: b.request_date.text }
        default:
            return { firstValue: a[key], secondValue: b[key] }
    }
}

const getVisibledApprovalList = (approvalList, { sorting, searchWord }) => {
    return approvalList.filter(current => searchFunc(current, searchWord)).sort((a, b) => sortFunc(a, b, sorting))
}

const searchFunc = (current, searchWord) => {
    const result =
        current.customer_name.toUpperCase().search(searchWord.toUpperCase()) >= 0 ||
        current.ia_name.toUpperCase().search(searchWord.toUpperCase()) >= 0
    return result
}

const sortFunc = (a, b, sorting) => {
    let { firstValue, secondValue } = getSortValueByKey(a, b, sorting.by)
    if (firstValue < secondValue) {
        return sorting.order == orderByConst.ASC ? -1 : 1
    } else if (firstValue > secondValue) {
        return sorting.order == orderByConst.ASC ? 1 : -1
    }
}

export { buildApprovalList, getVisibledApprovalList }
