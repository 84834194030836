<template>
    <div class="pra__status--wrapper mt-6">
        <optional
            v-if="praDisplayStatus == 'OPTIONAL'"
            :pra-list="getPraList()"
            :total-amount="getTotalAmount()"
            :time-stamp="getTotalAmountTimeStamp()"
        />
        <optional-and-top-up
            v-if="praDisplayStatus == 'OPTIONAL_AND_TOPUP'"
            :pra-list="getPraList()"
            :total-amount="getTotalAmount()"
            :time-stamp="getTotalAmountTimeStamp()"
            :bank-account="getBankAccount()"
            :top-up-amount="getTopUpAmount()"
        />
        <optional-and-invalid-min v-if="praDisplayStatus == 'OPTIONAL_AND_INVALID_MIN'" />
        <need-top-up v-if="praDisplayStatus == 'NEED_TOPUP'" :min-invest="getMinInvest()" />
    </div>
</template>
<script>
import { Optional, NeedTopUp, OptionalAndTopUp, OptionalAndInvalidMin } from './PraStatus/index'

import { praCurrentValidator, praUpcomingValidator } from '../../../../store/modules/port/validator'

export default {
    components: {
        Optional,
        NeedTopUp,
        OptionalAndTopUp,
        OptionalAndInvalidMin
    },
    props: {
        current: {
            type: Object,
            required: true,
            validator: praCurrentValidator
        },
        upcoming: {
            type: Object,
            default: () => {},
            validator: praUpcomingValidator
        },
        activeTab: {
            type: String,
            required: true
        },
        tabs: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {
        praDisplayStatus() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return this.upcoming.pra.display_status || ''
                }
                case this.tabs.CURRENT: {
                    return this.current.pra.display_status || ''
                }
                default: {
                    return ''
                }
            }
        }
    },
    mounted() {},
    methods: {
        getPraList() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.pra_list || []
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.pra_list || []
                    }
                    default: {
                        return []
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return []
            }
        },
        getTotalAmount() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.total_amount || 0
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.total_amount || 0
                    }
                    default: {
                        return 0
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return 0
            }
        },
        getTotalAmountTimeStamp() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.time_stamp || new Date()
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.time_stamp || new Date()
                    }
                    default: {
                        return new Date()
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return new Date()
            }
        },
        getApprovedDate() {
            try {
                if (this.upcoming && this.upcoming.investment_view && this.upcoming.investment_view.approved_at) {
                    return this.upcoming.investment_view.approved_at
                }
                return new Date()
            } catch (error) {
                console.error(new Date(), error)
                return new Date()
            }
        },
        getNoticeText() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return "This is what the customer will see. Buttons are clickable on customer's site/app."
                }
                case this.tabs.CURRENT: {
                    return "This is what the customer is seeing. Buttons are clickable on customer's site/app."
                }
                default: {
                    return ''
                }
            }
        },
        getBankAccount() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.bank_account || { no: '', name: '' }
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.bank_account || { no: '', name: '' }
                    }
                    default: {
                        return { no: '', name: '' }
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return { no: '', name: '' }
            }
        },
        getTopUpAmount() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.topup_amount || 0
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.topup_amount || 0
                    }
                    default: {
                        return 0
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return 0
            }
        },
        getMinInvest() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.min_invest || 0
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.min_invest || 0
                    }
                    default: {
                        return 0
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return 0
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pra__status--wrapper {
    background-color: #fff;
}
</style>
