<template>
    <div class="background--normal__grey content__page pt-23 pb-30">
        <div class="pb-34">
            <skeleton :width="144" :height="18" />
        </div>
        <div>
            <skeleton :width="177" :height="32" />
        </div>
    </div>
</template>

<script>
import { Skeleton } from '../../atoms'

export default {
    components: { Skeleton },
    props: {},
    data() {
        Skeleton
        return {}
    }
}
</script>

<style lang="scss" scoped>
.pb-34 {
    padding-bottom: 34px;
}
.pt-23 {
    padding-top: 23px;
}
.pb-30 {
    padding-bottom: 30px;
}
</style>
