import { type, keyValidator, typeValidator } from '../../../utill'
import { userProfileValidator } from '../../general/validator'

const personalInfoValidator = personalInfo => {
    if (!keyValidator(personalInfo, ['service_level', 'customer_code', 'phone', 'email'])) {
        return false
    }
    if (
        !typeValidator([
            { value: personalInfo.service_level, type: type.STRING },
            { value: personalInfo.customer_code, type: type.STRING },
            { value: personalInfo.phone, type: type.STRING },
            { value: personalInfo.email, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

const basicInfoValidator = basicInfo => {
    if (!userProfileValidator(basicInfo)) {
        return false
    }
    return personalInfoValidator(basicInfo)
}

export { basicInfoValidator, personalInfoValidator }
