<template>
    <div class="list__container">
        <span>{{ title }}</span>
        <div class="search--primary">
            <input
                v-model="inputValue"
                :type="type"
                :autocomplete="autocomplete"
                :class="[
                    validation.show ? 'input__primary--error' : 'input__primary--normal',
                    type === 'radio' ? 'radio-button' : '',
                    { 'input__primary--disable': disable }
                ]"
                :disabled="disable"
                class="full__width"
                :value="radioLabel"
                @change="updateValue"
                @input="parseStringToNumber"
                @click="selectAll"
            />
            <i
                v-if="icon.isIcon"
                :class="`${icon.icon} normal__text--secondary vertical__padding--normal icon__search--primary`"
            />
            <span v-else class="normal__text--secondary icon__search--primary">{{ icon.icon }}</span>
        </div>
        <span v-if="validation.show" class="normal__text--red">
            {{ validation.msg }}
        </span>
    </div>
</template>
<script>
import { iconValidator, validationValidator } from '../../../store/modules/general/validator'
import { getNumberFormat } from '../../../store/utill'

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: true
        },
        autocomplete: {
            type: String,
            required: false,
            default: ''
        },
        value: {
            type: [String, Number],
            required: false,
            default: ''
        },
        icon: {
            type: Object,
            required: false,
            default() {
                return { isIcon: true, icon: '' }
            },
            validator: iconValidator
        },
        checked: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        validation: {
            type: Object,
            required: false,
            default() {
                return {
                    show: false,
                    msg: '',
                    dataAlias: ''
                }
            },
            validator: validationValidator
        },
        disable: {
            type: Boolean,
            required: false,
            default: false
        },
        numberFormat: {
            type: Boolean,
            required: false,
            default: false
        },
        allowNegativeNumber: {
            type: Boolean,
            required: false,
            default: false
        },
        dataAlias: {
            type: String,
            required: false,
            default: null
        },
        radioLabel: {
            type: String,
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            inputValue: undefined,
            isNumberFormat: false,
            allowNegativeNumberValue: false
        }
    },
    watch: {
        value: {
            handler(value) {
                this.inputValue = value
            }
        },
        checked: {
            handler(value) {
                this.inputValue = value
            }
        }
    },
    mounted() {
        this.inputValue = this.value
        this.isNumberFormat = this.numberFormat
        this.allowNegativeNumberValue = this.allowNegativeNumber
    },
    methods: {
        selectAll(event) {
            this.$emit('click')
            event.target.select()
        },
        updateValue(event) {
            let result = event.target.value
            if (this.isNumberFormat) {
                result = getNumberFormat(event.target.value)
            }
            this.inputValue = result
            this.$emit('value', result)
        },
        parseStringToNumber() {
            if (this.isNumberFormat) {
                const isNegativeNumber = this.inputValue[0].startsWith('-')
                let result = this.inputValue.replace(/[^0-9]/g, '').slice(0, 10)
                if (this.allowNegativeNumberValue && isNegativeNumber) {
                    result = `-${result}`
                }
                this.inputValue = result
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.list__container {
    .search--primary {
        input:disabled {
            background-color: $greySecondary;
            border: solid 1px $greyPrimary;
            color: $textSecondary;
        }
    }
}
.radio-button {
    width: 16px;
    height: 16px;

    &:checked:before {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: $white;
        content: '';
        display: block;
        border: 1px solid $highlightPrimary;
        transition: border 120ms ease-in;
    }

    &:before {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: $white;
        content: '';
        display: block;
        border: 1px solid $greyPrimary;
        transition: border 80ms ease-in;
    }

    &:checked:after {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        top: -13px;
        left: 3px;
        position: relative;
        display: block;
        content: '';
        background-color: $highlightPrimary;
        transition: transform 120ms ease-in;
        transform: scale(1);
    }

    &:after {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        top: -13px;
        left: 3px;
        position: relative;
        display: block;
        content: '';
        background-color: $highlightPrimary;
        transition: transform 80ms ease-in;
        transform: scale(0);
    }
}
</style>
