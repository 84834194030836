<template>
    <span v-if="type == 'share'">
        <i class="hyperlink--primary" :class="iconsConst.EXTERNAL_LINK" />
    </span>
    <span v-else-if="type == 'underline'" class="underline">{{ text }}</span>
</template>

<script>
import { iconsConst } from '../../../store/utill'

export default {
    props: {
        type: {
            type: String,
            default: 'share'
        },
        text: {
            type: String,
            default: ''
        }
    },
    data: () => {
        return {
            iconsConst
        }
    }
}
</script>

<style lang="scss" scoped>
.underline {
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 6px;
    cursor: pointer;
}
</style>
