<template>
    <div>
        <div class="pra-container container-radius">
            <div>
                <i class="fas fa-exclamation-triangle mr-2 normal__text--orange size__font--16px"></i>
                <span class="normal__text--primary font-bold size__font--16px">มีคำแนะนำปรับพอร์ต</span>
            </div>
        </div>
        <div class="pra-container container-item">
            <p class="normal__text--primary size__font--16px">
                เพื่อให้พอร์ตของคุณเป็นไปตามโมเดลพอร์ต เราแนะนำให้คุณสร้างรายการดังนี้<br />
                <b>(ระบบจะคำนวณใหม่ทุกครั้ง และขึ้นอยู่กับ cut off time ของแต่ละกองทุน)</b>
            </p>
            <div v-for="(item, index) in praList" :key="index" class="full__width">
                <pra-item :pra="item" />
            </div>
            <div class="normal__text--primary margin__y--16px">
                คำแนะนำวันที่ {{ getDateFormat(timeStamp, '', 'dd m.m. yy - hh:mm', true) }} น. บนยอด
                {{ getNumberFormat(totalAmount, false, 2, '') }} บาท
                กรุณาตรวจสอบพอร์ตปัจจุบันและรายการที่อยู่ระหว่างดำเนินการก่อนทำรายการ
            </div>
            <div class="button-container">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <button
                            v-bind="attrs"
                            class="grey__btn-pra--disable btn__rounded--large font-bold size__font--16px margin__btn-responsive"
                            v-on="on"
                        >
                            ทำรายการเอง
                        </button>
                    </template>
                    <span>Buttons are clickable on customer's site/app.</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <button
                            v-bind="attrs"
                            class="yellow__btn-pra--disable btn__rounded--large font-bold size__font--16px"
                            v-on="on"
                        >
                            เพิ่มรายการทั้งหมดลงตะกร้า
                        </button>
                    </template>
                    <span>Buttons are clickable on customer's site/app.</span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { getNumberFormat, getDateFormat } from '../../../../../store/utill'

import { PraItem } from '../index'

export default {
    components: { PraItem },
    props: {
        praList: {
            type: Array,
            required: true
        },
        totalAmount: {
            type: Number,
            required: true
        },
        timeStamp: {
            type: [String, Date],
            required: true
        }
    },
    methods: {
        getNumberFormat,
        getDateFormat
    }
}
</script>

<style lang="scss" scoped>
.pra-container {
    border: 1px solid $greyPrimary;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    &.container-radius {
        border-radius: 4px 4px 0 0;
        border-bottom: 0;
    }
}

.button-container {
    display: flex;
    width: 100%;
    margin-top: 16px;
    justify-content: flex-end;
    flex-direction: column;

    @include responsive(tablet) {
        flex-direction: row;
        margin-top: 32px;
    }

    @include responsive(desktop) {
        flex-direction: row;
        margin-top: 32px;
    }
}

.margin__btn-responsive {
    margin-bottom: 8px;

    @include responsive(tablet) {
        margin-bottom: unset;
        margin-right: 8px;
    }

    @include responsive(desktop) {
        margin-bottom: unset;
        margin-right: 8px;
    }
}

.margin__y--16px {
    margin: 16px 0 !important;
}

.size__font--16px {
    font-size: 14px !important;

    @include responsive(phablet) {
        font-size: 16px !important;
    }

    @include responsive(tablet) {
        font-size: 16px !important;
    }

    @include responsive(desktop) {
        font-size: 16px !important;
    }
}
</style>
