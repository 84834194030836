var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"general__modal"},[_c('title-modal',{class:{ 'element--disable': _vm.loading },attrs:{"title":'New Lead Request'},on:{"close":_vm.close}}),_c('div',{class:[
            'horizon__padding--big',
            'top__padding--big',
            'position--relative',
            { 'element--disable': _vm.loading }
        ]},[(_vm.leadStatus.status === false)?_c('span',{staticClass:"normal__text--red",attrs:{"id":"error-msg-lead-request"}},[_c('i',{class:_vm.iconsConst.EXCLAM_TRI}),_vm._v(" "+_vm._s(_vm.leadStatus.msg)+" ")]):_vm._e(),_c('div',{staticClass:"list__container bottom__margin--big"},[_c('span',[_vm._v(" Port ID to Request "),(_vm.searchingCustomerInfo)?_c('spinner'):_vm._e()],1),_c('div',{staticClass:"search--primary"},[_c('cleave',{staticClass:"form__leadrequest input__primary--normal",attrs:{"disabled":_vm.searchingCustomerInfo,"options":_vm.patternAgentAccountID},model:{value:(_vm.agentAccountID),callback:function ($$v) {_vm.agentAccountID=$$v},expression:"agentAccountID"}})],1)]),_c('input-custom',{staticClass:"bottom__margin--big form__leadrequest",attrs:{"type":"text","title":"Customer Name","value":_vm.customerInfo.customer_name,"disable":true,"validation":_vm.warningMsg}}),_c('text-area-custom',{staticClass:"bottom__margin--big",attrs:{"title":"Note","resize":false,"rows":"3","maxlength":"500"},on:{"value":function (val) { return (_vm.note = val); }}})],1),_c('div',{staticClass:"row__container top__border--solid item__align--center vertical__padding--normal horizon__padding--big"},[_c('v-spacer'),(_vm.loading)?_c('spinner'):_vm._e(),_c('div',{class:{ 'element--disable': _vm.loading }},[_c('button',{staticClass:"grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small",on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('button',{class:[
                    'yellow__btn--normal',
                    'vertical__padding--small',
                    'horizon_padding--normal',
                    'font-bold',
                    { 'element--disable': !_vm.checkCanCreateLeadRequest }
                ],on:{"click":_vm.saveLeadRequest}},[_vm._v(" Create ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }