import { chartColors } from '../../../utill'

const getColorCategory = categoryId => {
    return categoryId >= 1 && categoryId <= 8 ? chartColors[categoryId - 1] : ''
}

const getAssetModelList = (assetModelList, initial, dca) => {
    let list = [],
        allFirstInvest = 0,
        allMonthly = 0
    for (let assetModel of assetModelList) {
        list.push({
            category_color: assetModel.category_id ? getColorCategory(assetModel.category_id) : '',
            fund: assetModel.fund || '',
            first_invest: assetModel.initial || null,
            monthly: assetModel.dca || null,
            percent: assetModel.percent || null
        })
        allFirstInvest += assetModel.initial || 0
        allMonthly += assetModel.dca || 0
    }
    list.push({
        category_color: '',
        fund: 'รวม',
        first_invest: initial || allFirstInvest,
        monthly: dca || allMonthly,
        percent: -1
    })
    return list
}

export { getAssetModelList, getColorCategory }
