<template>
    <base-modal
        title="Filter"
        :visible="visible"
        :reset="handleReset"
        :apply="handleApply"
        :cancel="handleClose"
        enable-line-button
        :close="handleClose"
    >
        <line-checkbox
            title="ประเภท"
            :list="[
                { checked: filterTmp.orderType.dividend, text: 'Dividend' },
                { checked: filterTmp.orderType.ar, text: 'Auto Redemption' }
            ]"
            class="bottom__margin--normal"
            @change="updateOrderTypeCheckbox"
        />
        <div class="datepicker__margin">
            Period
            <date-range-picker
                :date-range="filterTmp.paymentDate"
                @input="val => updateFilterValueByKey('paymentDate', val)"
            />
        </div>
    </base-modal>
</template>

<script>
import { BaseModal } from './index'
import { LineCheckbox, DateRangePicker } from '../../molecules'
import { defaultFilter } from '../../../store/modules/port/config'
import dayjs from 'dayjs'

export default {
    components: {
        BaseModal,
        LineCheckbox,
        DateRangePicker
    },
    props: {
        filterValues: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        toggleModal: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            defaultFilter,
            filterTmp: {
                orderType: {
                    ar: true,
                    dividend: true
                },
                paymentDate: {
                    startDate: '',
                    endDate: ''
                }
            },
            orderTypeMap: {
                DIVIDEND: 0,
                AR: 1
            }
        }
    },
    computed: {
        isFilter() {
            if (!(this.filterValues.orderType && this.filterValues.paymentDate)) return false
            let isFilter = !(
                this.filterTmp.orderType.ar === this.defaultFilter.orderType.ar &&
                this.filterTmp.orderType.dividend === this.defaultFilter.orderType.dividend &&
                this.filterTmp.paymentDate.startDate === this.defaultFilter.paymentDate.startDate &&
                this.filterTmp.paymentDate.endDate === this.defaultFilter.paymentDate.endDate
            )
            return isFilter
        }
    },
    created() {
        this.resetFilter()
        this.filterTmp = JSON.parse(JSON.stringify(this.filterValues))
    },
    methods: {
        updateOrderTypeCheckbox(updatedList) {
            let updateValue = this.filterTmp
            updatedList.forEach((type, index) => {
                if (this.orderTypeMap.AR === index) {
                    updateValue.orderType.ar = type
                } else if (this.orderTypeMap.DIVIDEND === index) {
                    updateValue.orderType.dividend = type
                }
            })
        },
        updateFilterValueByKey(key, val) {
            let updateValue = this.filterTmp
            const now = dayjs(new Date()).format('YYYY-MM-DD')
            if (val.startDate === '') val.startDate = now
            if (val.endDate === '') val.endDate = now
            updateValue[key] = val
        },
        handleReset() {
            this.resetFilter()
        },
        handleClose() {
            this.resetFilter()
            this.toggleModal()
        },
        handleApply() {
            let updateValue = this.filterTmp
            updateValue.isFilter = this.isFilter
            this.$emit('filter', updateValue)
            this.toggleModal()
        },
        resetFilter() {
            this.filterTmp = JSON.parse(JSON.stringify(this.defaultFilter))
        }
    }
}
</script>

<style lang="scss" scoped>
.datepicker__margin {
    margin: 32px 0 64px 0;
}
</style>
