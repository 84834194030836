<template>
    <div v-if="receivedProps" class="list__container">
        <div v-if="!hasOnlyCurrent" class="pra__investment__view-tab">
            <tab-bar
                :tabs="tabs"
                :active-tab="activeTab"
                data-fn-location="port_tab"
                :active-data-fn="true"
                @activeTab="updateActiveTab"
            />
        </div>
        <div v-if="activeTab === tabs.UPCOMING" class="mb-6">
            <pra-upcoming-notice :approved-date="getApprovedDate()" :published-date="getPublishedDate()" />
        </div>
        <div class="customer__view--container">
            <div class="customer__view--header">
                <h3>Customer’s View</h3>
                <span class="normal__text--primary">{{ getNoticeText() }} <b>on customer’s site/app</b>.</span>
            </div>
            <div class="customer__view--inner">
                <display-account-status :display-account-status="getDisplayStatus()" />
                <!-- Need-adjustment -->
                <pra-status
                    v-if="getDisplayStatus() == accountStatus.NONE"
                    :current="current"
                    :upcoming="upcoming"
                    :active-tab="activeTab"
                    :tabs="tabs"
                />
                <!-- Optional-adjustment -->
                <pra-status-optional
                    v-if="
                        getDisplayStatus() == accountStatus.NONE && getPRAStatus() == actionStatus.OPTIONAL_ADJUSTMENT
                    "
                    :current="current"
                    :upcoming="upcoming"
                    :active-tab="activeTab"
                    :tabs="tabs"
                />
                <div v-if="getDisplayStatus() == accountStatus.NONE" class="mt-6">
                    <pra-dca :current="current" :upcoming="upcoming" :active-tab="activeTab" :tabs="tabs" />
                </div>
                <trade-maintain v-if="getDisplayStatus() == actionStatus.TRADE_MAINTAIN" :bcp="bcp" />
            </div>
        </div>
        <div class="divider"></div>
        <a :href="getLinkUrl()" target="__blank" class="latest__investment-view">
            <i class="far fa-book normal__text--primary size__font--16px mr-2"></i>
            <span class="normal__text--primary font-bold size__font--16px"
                >Latest Investment View ({{ getPraDate() }})</span
            >
        </a>
        <div class="mt-9">
            <latest-port-change
                id="latest-port-change"
                :pra-date="getPraDate()"
                :change-list="getInvestmentAssets()"
                @openFundTab="openFundTab"
            />
        </div>
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import {
    TabBar,
    LatestPortChange,
    PraUpcomingNotice,
    PraStatus,
    PraDca,
    DisplayAccountStatus,
    PraStatusOptional,
    TradeMaintain
} from './Pra'
import { praCurrentValidator, praUpcomingValidator } from '../../../store/modules/port/validator'
import { displayAccountStatus, actionStatusKey } from '../../../store/utill/config/constant'
import { getDateFormat } from '../../../store/utill'
import dayjs from 'dayjs'

export default {
    components: {
        Spinner,
        TabBar,
        LatestPortChange,
        PraUpcomingNotice,
        PraDca,
        PraStatus,
        DisplayAccountStatus,
        PraStatusOptional,
        TradeMaintain
    },
    props: {
        current: {
            type: Object,
            default: () => {},
            validator: praCurrentValidator
        },
        upcoming: {
            type: Object,
            default: () => {},
            validator: praUpcomingValidator
        },
        bcp: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            receivedProps: false,
            tabs: {},
            activeTab: '',
            hasOnlyCurrent: true,
            accountStatus: displayAccountStatus,
            actionStatus: actionStatusKey
        }
    },
    mounted() {
        this.receivedProps = true
        // check available tabs
        if (this.upcoming && this.upcoming.pra) {
            this.tabs = {
                ...this.tabs,
                UPCOMING: 'Upcoming'
            }
        }
        if (this.current && this.current.pra) {
            this.tabs = {
                ...this.tabs,
                CURRENT: 'Current'
            }
        }
        if (this.tabs.UPCOMING) {
            this.activeTab = this.tabs.UPCOMING
            this.hasOnlyCurrent = false
        } else if (this.tabs.CURRENT) {
            this.activeTab = this.tabs.CURRENT
        }
    },
    methods: {
        getDateFormat,
        async updateActiveTab(newVal) {
            this.activeTab = newVal
        },
        async openFundTab(newVal) {
            this.$emit('openFundTab', newVal)
        },
        getInvestmentAssets() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return this.upcoming?.investment_view?.assets || []
                }
                case this.tabs.CURRENT: {
                    return this.current?.investment_view?.assets || []
                }
                default: {
                    return []
                }
            }
        },
        getPraDate() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return getDateFormat(this.upcoming?.investment_view.iv_date) || dayjs().format('YYYY-MM-DD')
                }
                case this.tabs.CURRENT: {
                    return getDateFormat(this.current?.investment_view.iv_date) || dayjs().format('YYYY-MM-DD')
                }
                default: {
                    return dayjs().format('YYYY-MM-DD')
                }
            }
        },
        getApprovedDate() {
            if (this.upcoming && this.upcoming.investment_view && this.upcoming.investment_view.approved_at) {
                return this.upcoming?.investment_view.approved_at
            }
            return ''
        },
        getPublishedDate() {
            if (this.upcoming && this.upcoming.investment_view && this.upcoming.investment_view.scheduled_publish_at) {
                return this.upcoming?.investment_view.scheduled_publish_at
            }
            return ''
        },
        getNoticeText() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return 'This is what the customer will see. Buttons are clickable'
                }
                case this.tabs.CURRENT: {
                    return 'This is what the customer is seeing. Buttons are clickable'
                }
                default: {
                    return 'This is what the customer is seeing. Buttons are clickable'
                }
            }
        },
        getLinkUrl() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return this.upcoming?.investment_view?.link_url || ''
                }
                case this.tabs.CURRENT: {
                    return this.current?.investment_view?.link_url || ''
                }
                default: {
                    return ''
                }
            }
        },
        getDisplayStatus() {
            if (this.bcp.length) {
                return this.actionStatus.TRADE_MAINTAIN
            }
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return this.upcoming?.display_account_status || ''
                }
                case this.tabs.CURRENT: {
                    return this.current?.display_account_status || ''
                }
                default: {
                    return ''
                }
            }
        },
        getPRAStatus() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return this.upcoming?.pra?.status || ''
                }
                case this.tabs.CURRENT: {
                    return this.current?.pra?.status || ''
                }
                default: {
                    return ''
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pra__investment__view-tab {
    display: flex;
    justify-content: center;
}

.customer__view--container {
    border: 1px solid #e4e8ea;
    border-radius: 8px;

    overflow: hidden;
}

.customer__view--header {
    padding: 24px 40px;

    @include responsive(mobile) {
        padding: 24px 12px !important;
    }

    border-radius: 8px 8px 0 0;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

    span {
        color: #999999;
    }
}

.customer__view--inner {
    background: rgba(242, 243, 244, 0.5);

    padding: 40px;

    @include responsive(mobile) {
        padding: 20px 8px !important;
    }
}

.divider {
    width: 100%;
    border-bottom: 1px solid $greyPrimary;
    margin: 26px 0;
}

.latest__investment-view {
    border-radius: 8px;
    width: 100%;
    max-width: 356px;
    background: $greySecondary;
    padding: 8px 0;
    text-align: center;
    text-decoration: none;
}

.size__font--16px {
    font-size: 14px !important;

    @include responsive(phablet) {
        font-size: 16px !important;
    }

    @include responsive(tablet) {
        font-size: 16px !important;
    }

    @include responsive(desktop) {
        font-size: 16px !important;
    }
}
</style>
