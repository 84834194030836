<template>
    <div class="background--normal__white bottom__padding--footer h-calc-full px-64">
        <div>
            <responsive-skeleton />
        </div>
    </div>
</template>

<script>
import { ResponsiveSkeleton } from '../../atoms'

export default {
    components: { ResponsiveSkeleton },
    props: {},
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.px-64 {
    padding: 64px;

    @media screen and (max-width: 480px) {
        padding: 32px 16px;
    }
}
.h-calc-full {
    height: calc(100vh - 191px);
}
</style>
