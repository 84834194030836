<template>
    <v-container class="list__container round__block--primary">
        <title-box :title="'Cost By Year'" :see-more-tab="''" :show-more="false" />
        <div class="list__container size__font--16px bottom__border--solid">
            <div class="row__container wrap__component item__align--baseline">
                <span>Year: </span>
                <input-select
                    class="dropdown__container left__margin--normal"
                    :list="Object.keys(taxCostDetails).reverse()"
                    :default-value="selectedYear"
                    @selectValue="updateSelectedYear"
                />
                <v-spacer />
                <span class="display__mobile--none lessthan">{{
                    getNumberFormat(taxCostDetails[selectedYear].sum, false, 2, '฿')
                }}</span>
            </div>
            <div class="row__container wrap__component bottom__margin--small display__phablet--none morethan">
                <span>Total: </span>
                <v-spacer />
                <span>{{ getNumberFormat(taxCostDetails[selectedYear].sum, false, 2, '฿') }}</span>
            </div>
        </div>
        <div>
            <div class="row__container item__align--center wrap__content top__margin--small normal__text--primary">
                <span class="normal__text--dark-cyan font-bold">SSF</span>
                <v-spacer />
                <span>{{ getNumberFormat(taxCostDetails[selectedYear].ssf, false, 2, '฿') }}</span>
            </div>
            <div class="row__container item__align--center wrap__content top__margin--small normal__text--primary">
                <span class="normal__text--light-purple font-bold">RMF </span>
                <v-spacer />
                <span>{{ getNumberFormat(taxCostDetails[selectedYear].rmf, false, 2, '฿') }}</span>
            </div>
            <div class="row__container item__align--center wrap__content top__margin--small normal__text--primary">
                <span class="normal__text--deep-green font-bold">ThaiESG </span>
                <v-spacer />
                <span>{{ getNumberFormat(taxCostDetails[selectedYear].tesg, false, 2, '฿') }}</span>
            </div>
        </div>
    </v-container>
</template>
<script>
import { TitleBox } from '../Title'
import { InputSelect } from '../../atoms'
import { getNumberFormat } from '../../../store/utill'

export default {
    components: {
        InputSelect,
        TitleBox
    },
    props: {
        taxCostDetails: {
            type: Object,
            required: true,
            default() {
                return {
                    2021: { rmf: 0, ssf: 0, tesg: 0, sum: 0 }
                }
            }
        }
    },
    data() {
        return {
            selectedYear: ''
        }
    },
    created() {
        this.setDefualtYear()
    },
    methods: {
        getNumberFormat,
        setDefualtYear() {
            let today = new Date()
            this.selectedYear = today.getFullYear().toString()
        },
        updateSelectedYear(val) {
            this.selectedYear = val
        }
    }
}
</script>

<style lang="scss" scoped>
.dropdown__container {
    margin-bottom: -16px;
    font-family: DBHeaventRez !important;
    font-size: 10px !important;
    width: 100px;
}
</style>
