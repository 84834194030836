<template>
    <div v-if="visible" class="modal__container">
        <div class="general__modal">
            <title-modal :title="title" :visible="visible" @close="close" />
            <div class="top__padding--big general__modal--content">
                <div class="assessment__container">
                    <span>
                        ถ้าวันที่ทำ Knowledge Assessment ก่อนวันที่ <u>15 พ.ค. 67</u> จะเป็นผลจากการทำ Knowledge
                        Assessment ของ Version เก่า
                    </span>
                </div>
                <span class="list__container bottom__margin--big">
                    <span>วันที่ทำแบบประเมิน</span>
                    <span class="font-bold top__margin--smallest knowledge__assessment-date">{{
                        getDateFormat(data.knowledge_date, '', 'full-thai-abbr-month')
                    }}</span>
                </span>
                <div
                    v-for="(knowledge, idx) in data.knowledge_choices"
                    :key="`${knowledge.key_question}`"
                    class="row__container bottom__margin--big"
                >
                    <div>{{ getQuestionNumber(idx) }}</div>
                    <div class="left__padding--smaller">
                        <div>{{ knowledge.text_question }}</div>
                        <span v-if="knowledge.key_question == 'ka_investment_product'" class="bottom__margin--normal">
                            <span
                                v-for="(choice, index) in knowledgeAssessmentChoiceList"
                                :key="`${index}`"
                                class="question__container"
                            >
                                <checkbox
                                    :key="`checkbox-${index}`"
                                    class="checkbox__padding"
                                    :can-edit="false"
                                    :item="getCheckboxItem(choice)"
                                    :show-label="false"
                                />
                                <span class="option__padding">{{ choice }}</span>
                            </span>
                        </span>
                        <span v-else class="font-bold top__margin--smallest">
                            {{ knowledge.answer.text }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TitleModal } from '../../molecules'
import { Checkbox } from '../../atoms'
import { knowledgeAssessmentChoiceList } from '../../../store/modules/customer/config'
import { getDateFormat } from '../../../store/utill'

export default {
    components: {
        TitleModal,
        Checkbox
    },
    props: {
        visible: Boolean,
        close: {
            type: Function,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            knowledgeAssessmentChoiceList
        }
    },
    methods: {
        getDateFormat,
        getQuestionNumber(idx) {
            return `${idx + 1}.`
        },
        getCheckboxItem(item) {
            const answerItems = this.data.knowledge_choices.find(
                question => question.key_question === 'ka_investment_product'
            )
            const newItem = {
                checked: answerItems.answer.some(choice => choice.text === item),
                text: item,
                dataAlias: ''
            }
            return newItem
        }
    }
}
</script>

<style lang="scss" scoped>
.general__modal {
    position: absolute;
    top: 70px;
    width: 92%;
    max-width: 720px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid $greyPrimary;
    background: $white;
    @include responsive(desktop) {
        width: 100%;
    }
    .general__modal--content {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        padding-left: 32px;
        padding-right: 32px;
        margin-bottom: 50px;
        @include responsive(mobile) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
.question__container {
    display: flex;
    align-items: flex-start;
}
.checkbox__padding {
    margin-left: 16px;
    padding-top: 20px;
    @include responsive(mobile) {
        margin-left: 0;
    }
}
.option__padding {
    padding-left: 32px;
    margin-top: 8px;
    @include responsive(mobile) {
        padding-left: 28px;
    }
}

.assessment__container {
    width: 100%;
    background: rgba($yellowPrimary, 0.1);
    border: 1px solid rgba($yellowPrimary, 0.5);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 20px;
}

.knowledge__assessment-date {
    @include responsive(mobile) {
        padding-left: 16px;
    }
}
</style>
