<template>
    <div>
        <div class="top__info">
            <profile-name
                class="info__blog"
                :profile="{
                    title: 'Customer',
                    display_circle: true,
                    profile_picture: customer.profile_picture,
                    abbr: customer.abbr,
                    full: customer.full
                }"
            />
            <status-display :title="'Investment Status'" :status="customer.investment_status" />
        </div>
        <div v-if="showFull" class="bottom__info">
            <personal-info
                class="info__blog"
                :info="{
                    service_level: customer.service_level,
                    customer_code: customer.customer_code,
                    phone: customer.phone,
                    email: customer.email
                }"
                :feature-service-level-rebranding="featureServiceLevelRebranding"
            />
            <advisors-info :see-more-tab="advisors.see_more" :info="advisors.advisors" @seeMore="updateActiveTab" />
        </div>
        <div id="btn-up-info" class="absolute__btn--primary" @click="showFull = !showFull">
            <i :class="showFull ? iconsConst.ARROW_UP : iconsConst.ARROW_DOWN"></i>
        </div>
    </div>
</template>
<script>
import { advisorBoxValidator, basicInfoValidator } from '../../../store/modules/customer/validator'
import { F4A_SERVICE_LEVEL_REBRANDING } from '../../../store/utill/config/constant'
import { AdvisorsInfo, PersonalInfo, ProfileName } from '../../molecules'
import { iconsConst } from '../../../store/utill'
import { StatusDisplay } from '../../atoms'
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        AdvisorsInfo,
        PersonalInfo,
        ProfileName,
        StatusDisplay
    },
    props: {
        customer: {
            type: Object,
            required: true,
            validator: basicInfoValidator
        },
        advisors: {
            type: Object,
            required: true,
            validator: advisorBoxValidator
        }
    },
    data() {
        return {
            showFull: true,
            iconsConst,
            featureServiceLevelRebranding: false
        }
    },
    computed: {
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateServiceLevelRebranding()
        }
    },
    created() {
        this.updateServiceLevelRebranding()
    },
    methods: {
        ...mapActions('feature', ['isEnabled']),
        async updateActiveTab(newVal) {
            this.$emit('activeTab', newVal)
        },
        updateServiceLevelRebranding() {
            this.isEnabled(F4A_SERVICE_LEVEL_REBRANDING).then(flag => {
                this.featureServiceLevelRebranding = flag
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.top__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    @include responsive(mobile) {
        flex-wrap: wrap;
    }
    .info__blog {
        min-width: 300px;
        margin-bottom: 18px;
        @include responsive(mobile) {
            width: 100%;
            max-width: 210px;
        }
    }
}

.bottom__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    .info__blog {
        min-width: 300px;
        @include responsive(mobile) {
            width: 100%;
            max-width: 210px;
        }
    }
}
</style>
