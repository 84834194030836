<template>
    <div class="horizon__padding--normal">
        <table class="table__secondary--normal">
            <thead>
                <tr class="head__table--primary bottom__border--solid">
                    <th class="horizon__align--left">DCA Order</th>
                    <th class="horizon__align--left display__tablet--none lessthan">Last Updated</th>
                    <th class="horizon__align--left display__tablet--none lessthan">Bank Acc.</th>
                    <th class="horizon__align--left display__phablet--none lessthan">Effective</th>
                    <th class="horizon__align--left display__mobile--none">Occurrence</th>
                    <th class="horizon__align--right">Value</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(dca, idx) in dcaList">
                    <tr :key="`row-${idx}`" class="bottom__border--solid border__tablet--none">
                        <td class="horizon__align--left">
                            <span class="normal__text--primary font-bold">
                                {{ dca.fund_name }}
                                <external-link @click.native="$emit('open-fund-tab', dca.fund_name)" />
                            </span>
                        </td>
                        <td class="horizon__align--left display__tablet--none lessthan">
                            <span class="normal__text--primary">{{ dca.update_date }}</span>
                        </td>
                        <td class="horizon__align--left display__tablet--none lessthan">
                            <span class="normal__text--primary">{{ dca.bank_account }}</span>
                        </td>
                        <td class="horizon__align--left display__phablet--none lessthan">
                            <span class="normal__text--primary">{{
                                `${dca.dca_start_date} - ${dca.dca_end_date}`
                            }}</span>
                        </td>
                        <td class="horizon__align--left display__mobile--none">
                            <span class="normal__text--primary">
                                {{ dca.occurrence }}
                            </span>
                        </td>
                        <td class="horizon__align--right">
                            <span class="normal__text--primary">
                                {{ getNumberFormat(dca.value, false, 2, '') }}
                            </span>
                        </td>
                    </tr>
                    <tr :key="`row-responsive-${idx}`" class="bottom__border--solid display__desktop--none">
                        <td colspan="6">
                            <table-responsive-display :title="'Last Updated:'" :value="dca.update_date" />
                            <table-responsive-display
                                class="display__phablet--none morethan"
                                :title="'Occurrence:'"
                                :value="dca.occurrence"
                            />
                            <table-responsive-display :title="'Bank Acc.:'" :value="dca.bank_account" />
                            <table-responsive-display
                                class="display__tablet--none"
                                :title="'Effective:'"
                                :value="`${dca.dca_start_date} - ${dca.dca_end_date}`"
                            />
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { dcaListValidator } from '../../../store/modules/port/validator'
import { getNumberFormat } from '../../../store/utill'
import { ExternalLink, TableResponsiveDisplay } from '../../atoms'

export default {
    components: {
        ExternalLink,
        TableResponsiveDisplay
    },
    props: {
        dcaList: {
            type: Array,
            required: true,
            validator: dcaListValidator
        }
    },
    methods: {
        getNumberFormat
    }
}
</script>
<style lang="scss" scoped>
.border__tablet--none {
    @include multiple-responsive(tablet, phablet, mobile) {
        border-bottom: none !important;
    }
}
</style>
