<template>
    <div class="list__container">
        <div
            :class="
                `${
                    featureServiceLevelRebranding ? 'service__level--container' : ''
                } row__container item__align--center bottom__margin--medium`
            "
        >
            <span class="topic__text--primary">Service Level: </span>
            <span
                :class="
                    `normal__text--primary font-bold ${featureServiceLevelRebranding ? 'service__level' : ''} ${
                        featureServiceLevelRebranding && info.service_level == '-' ? 'service__level--text' : ''
                    }`
                "
            >
                <service-level
                    :level="info.service_level"
                    :feature-service-level-rebranding="featureServiceLevelRebranding"
                />
            </span>
        </div>
        <div class="row__container item__align--center bottom__margin--medium">
            <span class="topic__text--primary">Customer ID: </span
            ><span class="normal__text--primary font-bold">{{ info.customer_code }}</span>
        </div>
        <div class="row__container item__align--center bottom__margin--small">
            <span class="topic__text--primary">Phone: </span
            ><span class="normal__text--primary font-bold">{{ info.phone }}</span>
        </div>
        <div class="row__container item__align--center">
            <span class="topic__text--primary">Email: </span><span class="bold__text--orange">{{ info.email }}</span>
        </div>
    </div>
</template>

<script>
import { ServiceLevel } from '../../atoms'
import { personalInfoValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        ServiceLevel
    },
    props: {
        info: {
            type: Object,
            required: true,
            validator: personalInfoValidator
        },
        featureServiceLevelRebranding: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
.service__level--container {
    position: relative;
    margin-bottom: 0.75rem;
}
.service__level {
    position: absolute;
    top: -2px;
    left: 94px;
}
.service__level--text {
    top: 0 !important;
}
</style>
