<template>
    <div v-if="completedData" data-fn-location="filter-modal" class="general__modal filter__modal">
        <title-modal :title="'Filter'" prefix-alias="filter" @close="close" />
        <div class="horizon__padding--big top__padding--big">
            <line-checkbox
                title="Favorite"
                :list="[
                    {
                        checked: filterTmp.favorite_status.favorite,
                        text: 'Favorite',
                        dataAlias: 'filter-favorite-check'
                    },
                    { checked: filterTmp.favorite_status.regular, text: 'Regular', dataAlias: 'filter-regular-check' }
                ]"
                class="bottom__margin--normal"
                @change="list => updateFilter('favorite_status', list)"
            />
            <range-input
                title="Cost"
                :min="filterTmp.cost.min"
                :max="filterTmp.cost.max"
                :icons="[
                    { isIcon: false, icon: '฿' },
                    { isIcon: false, icon: '฿' }
                ]"
                :validation="{
                    show: validation.cost,
                    msg: 'ค่า Cost ไม่ถูกต้อง'
                }"
                class="bottom__margin--normal"
                @range="range => updateFilter('cost', range)"
            />
            <range-input
                title="Outstanding"
                :min="filterTmp.outstanding.min"
                :max="filterTmp.outstanding.max"
                :icons="[
                    { isIcon: false, icon: '฿' },
                    { isIcon: false, icon: '฿' }
                ]"
                :validation="{
                    show: validation.outstanding,
                    msg: 'ค่า Outstanding ไม่ถูกต้อง'
                }"
                class="bottom__margin--normal"
                @range="range => updateFilter('outstanding', range)"
            />
            <range-input
                title="Unrealized P/L"
                :min="filterTmp.unrealized_pl.min"
                :max="filterTmp.unrealized_pl.max"
                :icons="[
                    { isIcon: true, icon: iconsConst.PERCENTAGE },
                    { isIcon: true, icon: iconsConst.PERCENTAGE }
                ]"
                :validation="{
                    show: validation.unrealized_pl,
                    msg: 'ค่า Unrealized P/L ไม่ถูกต้อง'
                }"
                :allow-negative-number="true"
                class="bottom__margin--normal"
                @range="range => updateFilter('unrealized_pl', range)"
            />
            <autocomplete
                label="Contain Fund:"
                :items="funds"
                item-text="fund"
                item-value="fund"
                :value="filterTmp.funds"
                @change="val => updateFilter('funds', val)"
            />
            <autocomplete
                label="Contain Plan:"
                :value="filterTmp.plan_type"
                item-text="default_name"
                item-value="plan_type_shortcode"
                :items="planTypes"
                :max-selected="1"
                @change="val => updateFilter('plan_type', val)"
            />
            <line-checkbox
                title="Account Status"
                :list="[
                    {
                        checked: filterTmp.investment_status.ready_to_invest,
                        text: 'Ready to Invest',
                        dataAlias: 'filter-ready_to_invest-check'
                    },
                    {
                        checked: filterTmp.investment_status.invested,
                        text: 'Invested',
                        dataAlias: 'filter-invested-check'
                    },
                    {
                        checked: filterTmp.investment_status.closed,
                        text: 'Freeze',
                        dataAlias: 'filter-oa_closed-check'
                    },
                    {
                        checked: filterTmp.investment_status.oa_reviewing,
                        text: 'Reviewing',
                        dataAlias: 'filter-oa_reviewing-check'
                    },
                    {
                        checked: filterTmp.investment_status.oa_rejected,
                        text: 'OA Rejected',
                        dataAlias: 'filter-oa_rejected-check'
                    },
                    {
                        checked: filterTmp.investment_status.ats,
                        text: 'ATS Required',
                        dataAlias: 'filter-ats_required-check'
                    }
                ]"
                class="bottom__margin--bigger"
                section="account_status"
                :feature-service-level-rebranding="featureServiceLevelRebranding"
                @change="list => updateFilter('investment_status', list)"
            />
            <line-checkbox
                v-if="featureServiceLevelRebranding"
                title="Service Level"
                :list="[
                    {
                        checked: filterTmp.service_level.none,
                        text: '-',
                        dataAlias: 'filter-service_level_none-check'
                    },
                    {
                        checked: filterTmp.service_level.club,
                        text: 'Club',
                        dataAlias: 'filter-service_level_club-check'
                    },
                    {
                        checked: filterTmp.service_level.exclusive,
                        text: 'Exclusive',
                        dataAlias: 'filter-service_level_exclusive-check'
                    },
                    {
                        checked: filterTmp.service_level.exclusive_prime,
                        text: 'Exclusive Prime',
                        dataAlias: 'filter-service_level_exclusive_prime-check'
                    },
                    {
                        checked: filterTmp.service_level.private,
                        text: 'Private',
                        dataAlias: 'filter-service_level_private-check'
                    },
                    {
                        checked: filterTmp.service_level.private_plus,
                        text: 'Private Plus',
                        dataAlias: 'filter-service_level_private_plus-check'
                    },
                    {
                        checked: filterTmp.service_level.ultra,
                        text: 'Ultra',
                        dataAlias: 'filter-service_level_ultra-check'
                    },
                    {
                        checked: filterTmp.service_level.ultra_ex,
                        text: 'Ultra Ex',
                        dataAlias: 'filter-service_level_ultra-check'
                    },
                    {
                        checked: filterTmp.service_level.ultra_ultra,
                        text: 'Ultra Ultra',
                        dataAlias: 'filter-service_level_ultra_ultra-check'
                    }
                ]"
                class="bottom__margin--bigger"
                section="service_level"
                :feature-service-level-rebranding="featureServiceLevelRebranding"
                @change="list => updateFilter('service_level', list)"
            />
            <line-checkbox
                v-else
                title="Service Level"
                :list="[
                    {
                        checked: filterTmp.service_level.none,
                        text: '-',
                        dataAlias: 'filter-service_level_none-check'
                    },
                    {
                        checked: filterTmp.service_level.member,
                        text: 'Member',
                        dataAlias: 'filter-service_level_member-check'
                    },
                    {
                        checked: filterTmp.service_level.exclusive,
                        text: 'Exclusive',
                        dataAlias: 'filter-service_level_exclusive-check'
                    },
                    {
                        checked: filterTmp.service_level.exclusive_prime,
                        text: 'Exclusive Prime',
                        dataAlias: 'filter-service_level_exclusive_prime-check'
                    },
                    {
                        checked: filterTmp.service_level.fpw,
                        text: 'FPW',
                        dataAlias: 'filter-service_level_fpw-check'
                    },
                    {
                        checked: filterTmp.service_level.fpw_plus,
                        text: 'FPW Plus',
                        dataAlias: 'filter-service_level_fpw_plus-check'
                    },
                    {
                        checked: filterTmp.service_level.fpb,
                        text: 'FPB',
                        dataAlias: 'filter-service_level_fpb-check'
                    },
                    {
                        checked: filterTmp.service_level.fpb_ex,
                        text: 'FPB Ex',
                        dataAlias: 'filter-service_level_fpb_ex-check'
                    },
                    {
                        checked: filterTmp.service_level.fpb_ultra,
                        text: 'FPB Ultra',
                        dataAlias: 'filter-service_level_fpb_ultra-check'
                    }
                ]"
                class="bottom__margin--bigger"
                section="service_level"
                :feature-service-level-rebranding="featureServiceLevelRebranding"
                @change="list => updateFilter('service_level', list)"
            />
            <line-checkbox
                title="Show Only"
                :list="[
                    {
                        checked: filterTmp.others.has_action_required,
                        text: 'Has Action Required',
                        dataAlias: 'filter-action-btn'
                    },
                    {
                        checked: filterTmp.others.has_dca_profiles,
                        text: 'Has DCA Profiles',
                        dataAlias: 'filter-dca-btn'
                    }
                ]"
                class="bottom__margin--bigger"
                @change="list => updateFilter('others', list)"
            />
        </div>
        <line-button
            prefix-alias="filter"
            data-fn-action-apply="filter_apply"
            :data-fn-params-apply="combinedDataParams"
            @click="buttonClicked"
        />
    </div>
</template>

<script>
import { defaultFilter, showValidate, updateValueFilter } from '../../../store/modules/customer/config'
import { filterValidator } from '../../../store/modules/customer/validator'
import { iconsConst, isNumber, type, validateMaxValue, validateMinValue } from '../../../store/utill'
import { Autocomplete } from '../../atoms'
import { LineButton, LineCheckbox, RangeInput, TitleModal } from '../../molecules'
import { mapGetters } from 'vuex'

export default {
    components: {
        LineButton,
        LineCheckbox,
        RangeInput,
        TitleModal,
        Autocomplete
    },
    props: {
        filterValues: {
            type: Object,
            required: true,
            validator: filterValidator
        },
        featureServiceLevelRebranding: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            defaultFilter,
            iconsConst,
            type,
            filterTmp: {},
            validation: {},
            completedData: false
        }
    },
    computed: {
        ...mapGetters('customer', ['getFunds', 'getPlanTypes']),
        funds() {
            return this.getFunds
        },
        planTypes() {
            return this.getPlanTypes
        },
        combinedDataParams() {
            return JSON.stringify({
                favorite: this.filterTmp.favorite_status.favorite,
                cost_from: this.filterTmp.cost.min,
                cost_to: this.filterTmp.cost.max,
                outstanding_from: this.filterTmp.outstanding.min,
                outstanding_to: this.filterTmp.outstanding.min,
                unrealized_from: this.filterTmp.unrealized_pl.min,
                unrealized_to: this.filterTmp.unrealized_pl.max,
                contain_fund: this.filterTmp.funds,
                contain_plan: this.filterTmp.plan_type,
                'account_status.ready_to_invest': this.filterTmp.investment_status.ready_to_invest,
                'account_status.invested': this.filterTmp.investment_status.invested,
                'account_status.closed': this.filterTmp.investment_status.closed,
                'account_status.oa_reviewing': this.filterTmp.investment_status.oa_reviewing,
                'account_status.oa_rejected': this.filterTmp.investment_status.oa_rejected,
                'account_status.ats': this.filterTmp.investment_status.ats,
                'account_status.kyc': this.filterTmp.investment_status.kyc,
                'show_only.has_action_required': this.filterTmp.others.has_action_required,
                'show_only.has_dca_profiles': this.filterTmp.others.has_dca_profiles
            })
        }
    },
    watch: {
        filterTmp: {
            handler(newVal) {
                this.filterTmp = newVal
            },
            deep: true
        }
    },
    async created() {
        document.body.style.overflowY = 'hidden'
        this.filterTmp = JSON.parse(JSON.stringify(this.filterValues))
        this.validation = {
            cost: false,
            outstanding: false,
            unrealized_pl: false
        }
        this.completedData = true
    },
    destroyed() {
        document.body.style.overflowY = 'auto'
    },
    methods: {
        isNumber,
        validateMinValue,
        validateMaxValue,
        showValidate,
        updateValueFilter,
        updateFilter(key, value) {
            this.updateValueFilter(key, value, this.filterTmp, this.validation, this.featureServiceLevelRebranding)
            this.$forceUpdate
        },
        close() {
            this.$emit('close')
        },
        buttonClicked(buttonName) {
            switch (buttonName) {
                case 'reset':
                    this.filterTmp = JSON.parse(JSON.stringify(this.defaultFilter))
                    this.validation = {
                        cost: false,
                        outstanding: false,
                        unrealized_pl: false
                    }
                    break
                case 'cancel':
                    this.close()
                    break
                case 'apply':
                    if (
                        !this.showValidate('cost', this.filterTmp, this.validation) &&
                        !this.showValidate('outstanding', this.filterTmp, this.validation) &&
                        !this.showValidate('unrealized_pl', this.filterTmp, this.validation)
                    ) {
                        this.$emit('filter', this.filterTmp, this.validation)
                        this.close()
                    }
                    break
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.filter__modal {
    min-width: 510px;
    margin: auto;
    @include responsive(mobile) {
        min-width: 300px;
        overflow-y: scroll;
    }
}
</style>
