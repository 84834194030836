<template>
    <article>
        <div class="background--full horizon__padding--normal">
            <div class="login__panel">
                <F4ALogo />
                <create-password class="vertical__margin--big" />
            </div>
        </div>
    </article>
</template>

<script>
import { F4ALogo } from '../../atoms'
import { CreatePassword } from '../../organisms'

export default {
    components: {
        F4ALogo,
        CreatePassword
    }
}
</script>

<style lang="scss" scoped>
.background--full {
    display: flex;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 30vh;
    min-height: 100vh;
}
.login__panel {
    max-width: 416px !important;
    min-width: 288px !important;
    width: 100%;
}
</style>
