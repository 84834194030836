<template>
    <div
        :class="
            status == 'Cancelled'
                ? 'normal__text--red'
                : status == 'Success'
                ? 'normal__text--green'
                : 'normal__text--secondary'
        "
    >
        <span> {{ status }} </span>
    </div>
</template>

<script>
import { iconsConst } from '../../../store/utill/config'
export default {
    props: {
        status: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            iconsConst
        }
    }
}
</script>
