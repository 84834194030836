<template>
    <v-container class="list__container round__block--primary">
        <title-box
            id="btn-more-investment"
            :title="'Investment'"
            :see-more-tab="seeMoreTab"
            :show-more="showMore"
            @seeMore="seeMore"
        />
        <div class="list__container bottom__margin--normal">
            <span class="topic__text--primary"> Total Return </span>
            <span
                :class="
                    getClassNameColorForProfit(investment.total_return, 2, {
                        profit: 'big__text--green',
                        at_par: 'big__text--greyPri',
                        loss: 'big__text--red'
                    })
                "
            >
                {{ getNumberFormat(investment.total_return, true, 2, '฿') }}
            </span>
        </div>
        <div class="row__container item__align--center bottom__margin--small wrap__content">
            <span class="topic__text--primary">Total Cost: </span
            ><span class="normal__text--primary font-bold">{{ investment.total_cost }}</span>
        </div>
        <div class="row__container item__align--center wrap__content">
            <span class="topic__text--primary">Total Outstanding: </span>
            <span class="normal__text--primary font-bold">{{ investment.total_outstanding }}</span>
        </div>
    </v-container>
</template>
<script>
import { getClassNameColorForProfit, getNumberFormat } from '../../../store/utill'
import { TitleBox } from '../Title'
import { investmentValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        investment: {
            type: Object,
            required: true,
            validator: investmentValidator
        }
    },
    methods: {
        getClassNameColorForProfit,
        getNumberFormat,
        seeMore(newVal) {
            this.$emit('seeMore', newVal)
        }
    }
}
</script>
