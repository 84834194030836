<template>
    <div>
        <slot v-if="enabled"></slot>
        <slot v-else name="fallback"></slot>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        feature: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            enabled: false
        }
    },
    computed: {
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSetting()
        }
    },
    created() {
        this.updateFeatureSetting()
    },
    methods: {
        ...mapActions('feature', ['isEnabled']),
        updateFeatureSetting() {
            // enable debug feature functionality for development build
            if (process.env.VUE_APP_ALLOW_DEBUG_TOOLS === 'true') {
                const debugFlag = this.$cookies.get(`Debug-Feature-${this.feature}`)
                switch (debugFlag) {
                    case 'true':
                        this.enabled = true
                        break
                    case 'false':
                        this.enabled = false
                        break
                    default:
                        this.isEnabled(this.feature).then(flag => {
                            this.enabled = flag
                        })
                        break
                }
            } else {
                this.isEnabled(this.feature).then(flag => {
                    this.enabled = flag
                })
            }
        }
    }
}
</script>
