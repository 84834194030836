<template>
    <div data-fn-location="customer-list">
        <div v-if="completedData" class="list__container content__page">
            <div
                id="custlist-quick_search-desktop"
                class="row__container bottom__margin--normal wrap__content display__phablet--none lessthan"
            >
                <advisor-dropdown
                    v-if="advisors.length > 1"
                    :items="advisors"
                    :filtered-items="filteredAdvisors"
                    :value="advisorFilterValue"
                    item-title="display_name"
                    item-value="identifier"
                    placeholder="Search"
                    all-selected-text="All Advisors"
                    style="margin-right: 1rem"
                    @change="updateAdvisorFilter"
                    @input="onAdvisorDropdownSearchChange"
                />
                <quick-search
                    :list="quickFilterList"
                    :value="quickFilterValue"
                    :use-data-fn="true"
                    @value="updateQuickFilter"
                />
                <search
                    class="bottom__margin--normal"
                    :value="searchValue"
                    @value="updateSearch"
                />
                <v-spacer />
                <grey-button
                    :icon="iconsConst.SORT"
                    :text="'Sort'"
                    class="right__margin--small bottom__margin--normal"
                    @click.native="showSortModal = true"
                />
                <grey-button
                    class="bottom__margin--normal"
                    :icon="iconsConst.FILTER"
                    :text="'Filter'"
                    @click.native="showFilterModal = true"
                />
            </div>
            <div
                id="custlist-quick_search-mobile"
                class="list__container bottom__margin--normal display__tablet--none morethan"
            >
                <quick-search
                    :list="quickFilterList"
                    :value="quickFilterValue"
                    :use-data-fn="true"
                    @value="updateQuickFilter"
                />
                <search
                    class="bottom__margin--normal"
                    :value="searchValue"
                    @value="updateSearch"
                />
                <div class="row__container">
                    <grey-button
                        :icon="iconsConst.SORT"
                        :text="'Sort'"
                        class="right__margin--small bottom__margin--normal"
                        @click.native="showSortModal = true"
                    />
                    <grey-button
                        class="bottom__margin--normal"
                        :icon="iconsConst.FILTER"
                        :text="'Filter'"
                        @click.native="showFilterModal = true"
                    />
                </div>
            </div>

            <div class="row__container bottom__margin--big block__margin wrap__content">
                <box-total-investment :see-more-tab="''" :investment="totalInvestBox" :show-more="false" />
                <box-total-plan :see-more-tab="''" :plan="totalPlanBox" :show-more="false" />
            </div>

            <blue-banner v-if="textKeyFilter != ''" :title="'Filter: '" :value="textKeyFilter" @clear="clearFilter" />

            <div v-if="showSortModal" class="modal__container" @click.self="showSortModal = false">
                <modal-sort
                    :sorting-list="headerCustList"
                    :sorting="sorting"
                    @sorting="updateSorting"
                    @close="showSortModal = false"
                />
            </div>
            <div v-if="showFilterModal" class="modal__container" @click.self="showFilterModal = false">
                <modal-filter
                    :filter-values="filterValues"
                    :feature-service-level-rebranding="featureServiceLevelRebranding"
                    @filter="updateFilter"
                    @close="showFilterModal = false"
                />
            </div>
            <div class="list__container item__align--center">
                <table-cust-list
                    class="full__width bottom__margin--normal"
                    :header-list="headerCustList"
                    :sorting="sorting"
                    :list="visibledCustList"
                    :feature-service-level-rebranding="featureServiceLevelRebranding"
                    @sorting="updateSorting"
                />
                <pagination :current-page="currentPage" :total-pages="totalPages" @currentPage="updateCurrentPage" />
            </div>
        </div>
        <div v-else class="vertical__margin--big horizon__align--center item__align--center">
            <spinner />
        </div>
    </div>
</template>

<script>
import { actionsConst, defaultFilter, headerCustList, quickFilterList } from '../../../store/modules/customer/config'
import { F4A_SERVICE_LEVEL_REBRANDING } from '../../../store/utill/config/constant'
import { custListValidator, filterValidator, sortingValidator } from '../../../store/modules/customer/validator'
import { iconsConst } from '../../../store/utill'
import { AdvisorDropdown, BlueBanner, GreyButton, QuickSearch, Search, Spinner } from '../../atoms'
import { ModalFilter, ModalSort } from '../Modal'
import { BoxTotalInvestment, BoxTotalPlan, Pagination, TableCustList } from '../../molecules'
import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        AdvisorDropdown,
        BlueBanner,
        BoxTotalInvestment,
        BoxTotalPlan,
        GreyButton,
        ModalFilter,
        ModalSort,
        Pagination,
        QuickSearch,
        Search,
        TableCustList,
        Spinner
    },
    props: {
        custList: {
            type: Array,
            required: true,
            validator: custListValidator
        },
        sorting: {
            type: Object,
            required: true,
            validator: sortingValidator
        },
        quickFilterValue: {
            type: String,
            required: false,
            default: 'all'
        },
        advisorFilterValue: {
            type: Array,
            required: false,
            default: () => []
        },
        searchValue: {
            type: String,
            required: false,
            default: ''
        },
        filterValues: {
            type: Object,
            required: true,
            validator: filterValidator
        }
    },
    data() {
        return {
            actionsConst,
            defaultFilter,
            iconsConst,
            quickFilterList,
            headerCustList,
            showFilterModal: false,
            showSortModal: false,
            amountContent: 20,
            currentPage: 1,
            completedData: false,
            advisorFilterText: '',
            featureServiceLevelRebranding: false,
            featureAneo: false
        }
    },
    computed: {
        ...mapGetters('customer', ['getAdvisorList']),
        ...mapGetters('feature', ['getTimestamp']),
        advisors() {
            return this.getAdvisorList
        },
        filteredAdvisors() {
            return this.advisors.filter(advisor => {
                const dNAme = advisor.display_name.toLowerCase()
                return dNAme.indexOf(this.advisorFilterText) > -1 || advisor.identifier === 'All Advisors'
            })
        },
        totalInvestBox() {
            let totalCost = 0
            let totalOutstanding = 0
            this.custList.forEach(cust => {
                totalCost += cust.cost
                totalOutstanding += cust.outstanding
            })
            return {
                total_cost: totalCost,
                total_outstanding: totalOutstanding,
                total_customer: this.custList.length
            }
        },
        totalPlanBox() {
            let custNeedPRA = 0

            this.custList.forEach(cust => {
                custNeedPRA += this.findPRA(cust.actions)
            })
            return {
                cust_need_pra: custNeedPRA
            }
        },
        totalPages() {
            return Math.ceil(this.custList.length / this.amountContent)
        },
        visibledCustList() {
            this.custList.forEach(customer => {
                customer.advisors.forEach(custAdvisor => {
                    const matchingAdvisor = this.advisors.find(advisor => advisor.identifier === custAdvisor.identifier)
                    if (matchingAdvisor) {
                        custAdvisor.profile_picture = matchingAdvisor.profile_picture
                    }
                })
            })
            return this.custList.slice(
                (this.currentPage - 1) * this.amountContent,
                this.currentPage * this.amountContent
            )
        },
        textKeyFilter() {
            let textList = ''
            /**
             * checkbox condition (checked:true / unchecked:false)
             * 1. favorite and regular not be checked both
             * 2. neither favorite nor regular not be checked
             */
            if (
                this.filterValues.favorite_status.favorite != undefined &&
                this.filterValues.favorite_status.regular != undefined &&
                !(this.filterValues.favorite_status.favorite && this.filterValues.favorite_status.regular) &&
                ((!this.filterValues.favorite_status.favorite && !this.filterValues.favorite_status.regular) ||
                    this.filterValues.favorite_status.favorite ||
                    this.filterValues.favorite_status.regular)
            ) {
                textList += textList == '' ? 'Favorite' : ', Favorite'
            }
            if (this.filterValues.cost.min != '' || this.filterValues.cost.max != '') {
                textList += textList == '' ? 'Cost' : ', Cost'
            }
            if (this.filterValues.outstanding.min != '' || this.filterValues.outstanding.max != '') {
                textList += textList == '' ? 'Outstanding' : ', Outstanding'
            }
            if (this.filterValues.unrealized_pl.min != '' || this.filterValues.unrealized_pl.max != '') {
                textList += textList == '' ? 'Unrealized P/L' : ', Unrealized P/L'
            }
            if (this.filterValues.funds.length > 0) {
                textList += textList == '' ? 'Contain Fund' : ', Contain Fund'
            }
            if (this.filterValues.plan_type.length > 0) {
                textList += textList == '' ? 'Contain Plan' : ', Contain Plan'
            }
            const investmentStatus = ['ats', 'closed', 'invested', 'oa_rejected', 'oa_reviewing', 'ready_to_invest']
            if (!this.featureAneo) {
                investmentStatus.push('kyc')
            }
            // if every account status is true -> not show text
            const isStatusTrue = status => this.filterValues.investment_status[status]
            const isAddAccountStatusText = statuses => !statuses.every(isStatusTrue)
            if (isAddAccountStatusText(investmentStatus)) {
                textList += textList == '' ? 'Acc. Status' : ', Acc. Status'
            }
            if (this.filterValues.others.has_action_required) {
                textList += textList == '' ? 'Has Action' : ', Has Action'
            }
            if (this.filterValues.others.has_dca_profiles) {
                textList += textList == '' ? 'Has DCA' : ', Has DCA'
            }
            let serviceLevels = [
                'member',
                'exclusive',
                'exclusive_prime',
                'fpw',
                'fpw_plus',
                'fpb',
                'fpb_ex',
                'fpb_ultra',
                'none'
            ]
            if (this.featureServiceLevelRebranding) {
                serviceLevels = [
                    'club',
                    'exclusive',
                    'exclusive_prime',
                    'private',
                    'private_plus',
                    'ultra',
                    'ultra_ex',
                    'ultra_ultra',
                    'none'
                ]
            }
            const hasSelectedOptions = serviceLevels.some(level => !this.filterValues.service_level[level])
            if (hasSelectedOptions) {
                textList += textList ? ', Service Level' : 'Service Level'
            }
            return textList
        }
    },
    watch: {
        getTimestamp() {
            this.updateServiceLevelRebranding()
        }
    },
    async created() {
        this.completedData = true
        this.updateServiceLevelRebranding()
    },
    methods: {
        ...mapActions('feature', ['isEnabled']),
        onAdvisorDropdownSearchChange(text) {
            this.advisorFilterText = text
        },
        findPRA(actionList) {
            for (let action of actionList) {
                if (action == actionsConst.PRA) {
                    return 1
                }
            }
            return 0
        },
        updateAdvisorFilter(item) {
            this.updateCurrentPage(1)
            this.$emit('advisorFilter', item)
        },
        updateQuickFilter(item) {
            this.updateCurrentPage(1)
            this.$emit('quickFilter', item)
        },
        updateFilter(newVal) {
            this.$emit('filter', newVal)
        },
        clearFilter() {
            this.$emit('filter', this.defaultFilter)
        },
        updateSearch(newVal) {
            if (newVal && newVal.length) {
                this.updateCurrentPage(1)
                this.$emit('search', newVal)
            } else {
                this.$emit('search', '')
            }
        },
        updateSorting(newVal) {
            this.updateCurrentPage(1)
            this.$emit('sorting', newVal)
        },
        async updateCurrentPage(newVal) {
            this.currentPage = newVal
        },
        updateServiceLevelRebranding() {
            this.isEnabled(F4A_SERVICE_LEVEL_REBRANDING).then(flag => {
                this.featureServiceLevelRebranding = flag
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.block__margin {
    margin-left: -16px;
}
</style>
