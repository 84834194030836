import dayjs from 'dayjs'
import { pagesConst, type } from '../utill'
import { orderType } from '../utill/config'
import { leadRequestStatus } from '../../store/modules/leadRequest/config'

const getAbbrName = fullName => {
    let splitted = fullName.trim().split(' ')
    splitted = splitted.filter(arr => {
        return arr != ''
    })
    switch (splitted.length) {
        case 2:
            // case name without title
            splitted[0] = excludeThaiVowelFromString(splitted[0])
            splitted[1] = excludeThaiVowelFromString(splitted[1])
            return `${splitted[0][0].toUpperCase()}${splitted[1][0].toUpperCase()}`
        case 3:
            // case name with title
            splitted[1] = excludeThaiVowelFromString(splitted[1])
            splitted[2] = excludeThaiVowelFromString(splitted[2])
            return `${splitted[1][0].toUpperCase()}${splitted[2][0].toUpperCase()}`
        default:
            return '--'
    }
}

const getAbbrThaiMonthText = month => {
    let abbrThaiMonth = [
        'ม.ค.',
        'ก.พ.',
        'มี.ค.',
        'เม.ย.',
        'พ.ค.',
        'มิ.ย.',
        'ก.ค.',
        'ส.ค.',
        'ก.ย.',
        'ต.ค.',
        'พ.ย.',
        'ธ.ค.'
    ]
    return month >= 0 && month <= 11 ? abbrThaiMonth[month] : '-'
}

const getFullThaiMonthText = month => {
    let fullThaiMonth = [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ]
    return month >= 0 && month <= 11 ? fullThaiMonth[month] : '-'
}

const getDateFormat = (value, inputFormat, outputFormat, isBE) => {
    const newInputFormat = inputFormat || 'DD/MM/YY'
    let date = dayjs(value, { format: newInputFormat })
    if (!date.isValid()) {
        return '-'
    }
    if (isBE) {
        date.add(543, 'years')
    }
    switch (outputFormat) {
        case 'dd/mm/yy':
            return date.format('DD/MM/YY')
        case 'd mmm yy | hh:mm:ss':
            return `${date.format('D MMM YY')} | ${date.format('HH:mm:ss')}`
        case 'dd/mm/yy - hh:mm':
            return `${date.format('DD/MM/YY')} - ${date.format('HH:mm')}`
        case 'd mmm yy - hh:mm':
            return `${date.format('D MMM YY')} - ${date.format('HH:mm')}`
        case 'dd m.m. yy - hh:mm':
            return `${date.date()} ${getAbbrThaiMonthText(date.month())} ${date
                .year()
                .toString()
                .substring(2, 4)} - ${date.format('HH:mm')}`
        case 'full-thai-abbr-month':
            return `${date.date()} ${getAbbrThaiMonthText(date.month())} ${date.year().toString()}` // Ex. 16 มิ.ย. 2566
        case 'full-thai':
            return `${date.date()} ${getFullThaiMonthText(date.month())} ${date.year().toString()}` // Ex. 16 มิถุนายน 2566
        default:
            return date.locale('en').format('D MMM YY') // Ex. 2 Nov 23
    }
}

const getPhoneFormat = phone => {
    switch (phone.length) {
        case 9:
            return `${phone.substring(0, 2)}-${phone.substring(2, 5)}-${phone.substring(5, 9)}`
        case 10:
            return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`
        default:
            return phone
    }
}

const getNumberFormat = (number = 0, showPrefix = false, decimal = 0, suffix = '') => {
    let newNumber = number == null || number == undefined ? 0 : number
    let roundedNumber = Math.round(newNumber * Math.pow(10, decimal)) / Math.pow(10, decimal)
    let prefix = showPrefix && newNumber > 0 ? '+' : ''
    let numberSplitted = roundedNumber.toString().split('.')
    if (roundedNumber == 0) {
        // INFO: handle case '-0'
        numberSplitted[0] =
            numberSplitted[0][0] == '-' ? numberSplitted[0].slice(1, numberSplitted[0].length) : numberSplitted[0]
    }
    if (decimal > 0) {
        // INFO: concat '0' after decimal
        let decimalNumber =
            numberSplitted.length > 1
                ? numberSplitted[1].concat('0'.repeat(decimal - numberSplitted[1].length))
                : '0'.repeat(decimal)
        return `${prefix}${numberSplitted[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}.${decimalNumber} ${suffix}`
    } else {
        return `${prefix}${numberSplitted[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} ${suffix}`
    }
}

const getTextColorClassByType = textType => {
    switch (textType) {
        case leadRequestStatus.APPROVED:
        case orderType.BUY:
            return 'normal__text--green'
        case leadRequestStatus.REJECTED:
        case orderType.SELL:
            return 'normal__text--red'
        case orderType.SWITCH:
        case orderType.SWITCH_CROSS_AMC:
            return 'normal__text--blue'
        case leadRequestStatus.PENDING:
        case orderType.TRANS_IN:
        case orderType.TRANS_OUT:
            return 'normal__text--secondary'
        default:
            return ''
    }
}

const getFirstUpperText = value => {
    return value[0].toLocaleUpperCase().concat(value.substring(1, value.length))
}

const getHaveOrNotText = value => {
    return value ? 'มี' : 'ไม่มี'
}

const getYesNoText = value => {
    return value ? 'Yes' : 'No'
}

const getRedirectByHTTPStatus = status => {
    switch (status) {
        case 401:
            return pagesConst.LOGIN
        case 403:
            return pagesConst.FORBIDDEN
        default:
            return null
    }
}

const getClassNameColorForProfit = (
    value,
    decimal = 2,
    className = {
        profit: 'normal__text--green',
        at_par: 'normal__text--primary',
        loss: 'normal__text--red'
    }
) => {
    if (typeof value == type.NUMBER) {
        let roundedNumber = Math.round(value * Math.pow(10, decimal))
        if (roundedNumber > 0) {
            return className.profit
        } else if (roundedNumber < 0) {
            return className.loss
        }
    }
    return className.at_par
}

const getTextByActionType = (actionType, lang = 'th') => {
    switch (actionType) {
        case orderType.BUY:
            return lang == 'th' ? 'ซื้อ' : 'Buy'
        case orderType.SELL:
            return lang == 'th' ? 'ขาย' : 'Sell'
        case orderType.SWITCH:
            return lang == 'th' ? 'สับเปลี่ยน' : 'Switch'
        case orderType.SWITCH_CROSS_AMC:
            return lang == 'th' ? 'สับเปลี่ยนข้าม บลจ.' : 'Cross AMC Sw.'
        case orderType.TRANS_IN:
            return lang == 'th' ? 'โอนเข้า' : 'Trans. In'
        case orderType.TRANS_OUT:
            return lang == 'th' ? 'โอนออก' : 'Trans. Out'
        default:
            return ''
    }
}

const isNumber = value => {
    let onlyNumber = value.match(/[0-9]/g)
    let numOfMinus = value.match(/-/g)
    let numOfDot = value.match(/\./g)
    if (value == '' || !onlyNumber || (numOfMinus && numOfMinus.length > 1) || (numOfDot && numOfDot.length > 1)) {
        return false
    }
    return true
}

const validateMinValue = (min, value) => {
    return value < min ? min : value
}

const validateMaxValue = (max, value) => {
    return value > max ? max : value
}

const getFundShortNameURI = fullName => {
    var fund = fullName
    if (fullName.includes('มูลค่าก่อนปิด')) {
        fund = fullName.split(' (')[0]
    }
    const preparedFundName = encodeURIComponent(fund)
    return preparedFundName
}

const excludeThaiVowelFromString = value => {
    const consonantPattern = /[ก-ฮ]/g
    const consonants = value.match(consonantPattern)
    if (consonants && consonants.length > 0) {
        return consonants ? consonants.join('') : ''
    }
    return value[0] || ''
}

const getServiceLevelImagePath = serviceLevel => {
    return `${process.env.VUE_APP_SCONTENT_PATH}/${process.env.VUE_APP_GCS_SERVICE_LEVEL_REBRANDING_IMAGE_PATH}/${serviceLevel}.png`
}

export {
    getAbbrName,
    getAbbrThaiMonthText,
    getClassNameColorForProfit,
    getFundShortNameURI,
    getTextColorClassByType,
    getFirstUpperText,
    getHaveOrNotText,
    getNumberFormat,
    getPhoneFormat,
    getRedirectByHTTPStatus,
    getTextByActionType,
    getDateFormat,
    getYesNoText,
    isNumber,
    validateMaxValue,
    validateMinValue,
    excludeThaiVowelFromString,
    getServiceLevelImagePath
}
