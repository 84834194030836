<template>
    <div class="loading"></div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.loading {
    background-color: #f2f2f2;
    background-size: 1000%;
    animation: sweep 2s ease-in-out infinite;
    width: 100% !important;
    height: calc(100vh - 250px);
    max-height: 600px;

    @media screen and (min-width: 1024px) {
        display: flex;
        margin: 0 auto;
        max-width: 1024px;
        max-height: 600px;
    }
}
@keyframes sweep {
    0% {
        background-color: #f2f2f2;
    }
    50% {
        background-color: #dae0e4;
    }
    100% {
        background-color: #f2f2f2;
    }
}
</style>
