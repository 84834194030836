<template>
    <div v-if="recievedProps" class="list__container">
        <div class="list__container bottom__margin--bigger">
            <span class="big__text--greyPri horizon__margin--normal">General</span>
            <div class="row__container wrap__content">
                <list-attribute
                    v-for="(attrList, idx) in general"
                    :key="`general-list-${idx}`"
                    :attr-list="attrList"
                    class="top__margin--normal"
                    @reload="reload"
                    @toggleKnowledgeAssessmentModal="toggleKnowledgeAssessmentModal"
                />
            </div>
        </div>
        <div>
            <div class="bottom__border--solid bottom__margin--bigger" />
        </div>
        <div class="list__container bottom__margin--bigger">
            <span class="big__text--greyPri horizon__margin--normal">Personal Info</span>
            <div class="row__container wrap__content">
                <list-attribute
                    v-for="(attrList, idx) in personal"
                    :key="`personal-list-${idx}`"
                    :attr-list="attrList"
                    class="top__margin--normal"
                    @reload="reload"
                />
            </div>
        </div>
        <div class="list__container bottom__margin--bigger">
            <span class="big__text--greyPri horizon__margin--normal">Spouse & Children</span>
            <div class="row__container wrap__content">
                <list-attribute :attr-list="family[0]" class="top__margin--normal" />
                <table-family v-if="family.length > 1" :family-list="family[1]" class="top__margin--normal" />
            </div>
        </div>
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { ListAttribute, TableFamily } from '../../molecules'
import { familyInfoValidator, generalInfoValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        ListAttribute,
        TableFamily,
        Spinner
    },
    props: {
        general: {
            type: Array,
            required: true,
            validator: generalInfoValidator
        },
        personal: {
            type: Array,
            required: true,
            validator: generalInfoValidator
        },
        family: {
            type: Array,
            required: true,
            validator: familyInfoValidator
        }
    },
    data() {
        return {
            recievedProps: false
        }
    },
    mounted() {
        this.recievedProps = true
    },
    methods: {
        reload(topic) {
            this.$emit('reload', topic)
        },
        toggleKnowledgeAssessmentModal() {
            this.$emit('toggleKnowledgeAssessmentModal')
        }
    }
}
</script>
