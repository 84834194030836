<template>
    <div>
        <div v-if="completedData">
            <bread-crumb :path="header.path" />
            <div class="background--normal__grey">
                <v-alert v-if="alert.show" :type="alert.type" text :icon="alert.icon" class="content__page">{{
                    alert.msg
                }}</v-alert>
                <port-basic-info
                    :port-info="basicInfo"
                    :advisors="{ see_more: '', advisors: advisor }"
                    class="wrap__content content__page"
                    @activeTab="updateActiveTab"
                />
            </div>
            <div class="background--normal__white content__page">
                <tab-bar
                    :tabs="tabs"
                    :active-tab="activeTab"
                    data-fn-location="port_tab"
                    :active-data-fn="true"
                    @activeTab="updateActiveTab"
                />
            </div>
            <div class="background--normal__white bottom__padding--footer content__page body">
                <port-outstanding
                    v-if="activeTab == tabs.OUTSTANDING && outstanding"
                    :investment-box="{ see_more: '', investment: outstanding.investment_box }"
                    :pl-box="{ see_more: '', pl: outstanding.pl_box }"
                    :plan-setting-box="{ see_more: '', plan_setting: outstanding.plan_setting_box }"
                    :tax-cost-details="totalTaxCost"
                    :assets="{ all_assets: outstanding.all_assets, asset_list: outstanding.asset_list }"
                    @activeTab="updateActiveTab"
                    @openFundTab="openFundTab"
                />
                <port-dca
                    v-else-if="activeTab == tabs.DCA && dca"
                    :dca-list="dca.dca_list"
                    @openFundTab="openFundTab"
                />
                <port-model
                    v-else-if="activeTab == tabs.MODEL && model"
                    :plan-type="model.plan_type"
                    :categories="model.categories"
                    :asset-model-list="model.assetModelList"
                    @openFundTab="openFundTab"
                />
                <port-pra
                    v-else-if="activeTab == tabs.PRA && pra"
                    :current="pra.current"
                    :upcoming="pra.upcoming"
                    :bcp="pra.bcp"
                    @openFundTab="openFundTab"
                />
                <port-history
                    v-else-if="activeTab == tabs.HISTORY && history"
                    :order-list="orderHistoryList"
                    :advisor="advisor"
                    :customer-info="basicInfo"
                    :quick-filter-value="quickFilterValue"
                    :search-value="searchValue"
                    @quickFilter="updateQuickFilter"
                    @search="updateSearch"
                    @openFundTab="openFundTab"
                />
                <port-distribution
                    v-else-if="activeTab == tabs.DISTRIBUTION && distribution"
                    :distribution-list="distributionList"
                    :search-value="searchValue"
                    :filter-values="filterValues"
                    @filter="updateFilter"
                    @search="updateSearch"
                    @openFundTab="openFundTab"
                />
                <template v-else>
                    <div v-if="reloadingTab" class="horizon__align--center item__align--center pt-16">
                        <spinner />
                    </div>
                    <error-refresh v-else :current-tab="activeTab" @refresh="refresh" />
                </template>
            </div>
        </div>
        <div v-else>
            <header-skeleton-object />
            <content-skeleton-object />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Spinner } from '../../atoms'
import { HeaderSkeletonObject, ContentSkeletonObject } from '../../molecules'

import {
    ErrorRefresh,
    PortBasicInfo,
    PortDca,
    PortHistory,
    PortDistribution,
    PortModel,
    PortOutstanding,
    PortPra,
    TabBar,
    BreadCrumb
} from '../../organisms'

import { url, getFundShortNameURI } from '../../../store/utill'
import { defaultFilter } from '../../../store/modules/port/config'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

import {
    httpStatus,
    pagesConst,
    FEATURE_CUSTOMER_INFO_V2,
    FEATURE_CUSTOMER_LIST_V2
} from '../../../store/utill/config/constant'

Vue.use(VueCookies)

export default {
    components: {
        ErrorRefresh,
        PortBasicInfo,
        PortDca,
        PortHistory,
        PortDistribution,
        PortModel,
        PortOutstanding,
        PortPra,
        TabBar,
        BreadCrumb,
        HeaderSkeletonObject,
        ContentSkeletonObject,
        Spinner
    },
    props: {
        tabs: {
            type: Object,
            required: true
        },
        portId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            header: {
                title: 'ADVISOR',
                path: [
                    {
                        // TODO: [Next Phase] link should be HOME
                        text: 'Home',
                        dataAlias: 'Home-link',
                        link: pagesConst.CUSTOMER_LIST,
                        external: false
                    },
                    {
                        text: 'Customers',
                        link: pagesConst.CUSTOMER_LIST,
                        external: false
                    }
                ]
            },
            alert: {},
            alertCustInfo: {},
            completedData: false,
            activeTab: this.tabs.OUTSTANDING,
            reloadingTab: false,
            basicInfo: {},
            advisor: [],
            defaultFilter,
            filterValues: {},
            outstanding: {},
            dca: {},
            history: {},
            distribution: {},
            model: {},
            pra: {},
            quickFilterValue: 'all',
            searchValue: '',
            orderHistoryList: [],
            distributionList: [],
            url,
            totalTaxCost: {},
            profile: {},
            featureCustomerInfoV2Enabled: false,
            featureCustomerListV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('port', [
            'getAdvisor',
            'getBasicInfo',
            'getHistory',
            'getDistribution',
            'getOutstanding',
            'getDca',
            'getModel',
            'getPra'
        ]),
        ...mapGetters('customer', ['getAdvisors']),
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        basicInfo() {
            this.updateLinkBreadcrumb()
        },
        featureCustomerInfoV2Enabled() {
            this.updateLinkBreadcrumb()
        },
        featureCustomerListV2Enabled(val) {
            this.$set(this.header.path, 0, {
                text: 'Home',
                dataAlias: 'Home-link',
                link: val ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list` : pagesConst.CUSTOMER_LIST,
                external: val
            })
            this.$set(this.header.path, 1, {
                text: 'Customers',
                link: val ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list` : pagesConst.CUSTOMER_LIST,
                external: val
            })
        },
        getTimestamp() {
            this.updateFeatureSettings()
        }
    },
    mounted() {
        this.updateFeatureSettings()
    },
    async created() {
        if (this.$route.query.tab && this.tabs[this.$route.query.tab.toUpperCase()]) {
            this.activeTab = this.tabs[this.$route.query.tab.toUpperCase()]
        }
        await this.getAMC(this.$cookies.get('uuid'))
        await this.getPort('general,outstanding,dca,history,model,pra,distribution')
        this.basicInfo = await this.getBasicInfo
        this.alertCustInfo = await this.getCustInfo({
            uuid: this.$cookies.get('uuid'),
            custCode: this.basicInfo.general_info.customer_code,
            scope: 'overview'
        })
        this.advisor = this.alert.type == 'success' ? await this.getAdvisors : await this.getAdvisor
        this.history = await this.getHistory
        this.distribution = await this.getDistribution
        this.filterValues = JSON.parse(JSON.stringify(this.defaultFilter))
        await this.updateVisibledHistoryList()
        await this.updateVisibledDistributionList()
        this.outstanding = await this.getOutstanding
        await this.updateAdjustedTotalTaxCost()
        this.dca = await this.getDca
        this.model = await this.getModel
        this.pra = await this.getPra
        this.$emit('customerName', this.basicInfo.general_info.customer_name)
        this.completedData = true
        this.updateFeatureSettings()
    },
    methods: {
        ...mapActions('port', [
            'getAdjustedHistory',
            'getAdjustedTotalTaxCost',
            'getPortInfo',
            'getAdjustedDistribution',
            'getAMC'
        ]),
        ...mapActions('customer', ['getCustInfo']),
        ...mapActions('feature', ['isEnabled']),
        async getPort(scope) {
            this.alert = await this.getPortInfo({
                uuid: this.$cookies.get('uuid'),
                portId: this.$route.params.portId,
                scope
            })
            if (
                this.alert &&
                this.alert.err &&
                this.alert.err.status &&
                this.alert.err.status === httpStatus.NOT_FOUND
            ) {
                this.redirect('/notfound')
            }

            if (this.alert.redirect != null) {
                this.redirect(this.alert.redirect)
            }
        },
        getTabKeyByValue(val) {
            return Object.keys(this.tabs).find(key => this.tabs[key] === val)
        },
        async updateActiveTab(newVal) {
            this.activeTab = newVal
            let keyTab = this.getTabKeyByValue(newVal)
            if (keyTab) {
                this.$router.replace({ query: { tab: keyTab.toLowerCase() } })
            }
        },
        async updateFilter(newVal) {
            this.filterValues = newVal
            await this.updateVisibledDistributionList()
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        },
        async refresh(tab) {
            this.reloadingTab = true
            switch (tab) {
                case this.tabs.OUTSTANDING:
                    await this.getPort('outstanding')
                    this.outstanding = this.alert.type == 'success' ? await this.getOutstanding : null
                    break
                case this.tabs.DCA:
                    await this.getPort('dca')
                    this.dca = this.alert.type == 'success' ? await this.getDca : null
                    break
                case this.tabs.MODEL:
                    await this.getPort('model')
                    this.model = this.alert.type == 'success' ? await this.getModel : null
                    break
                case this.tabs.PRA:
                    await this.getPort('pra')
                    this.pra = this.alert.type == 'success' ? await this.getPra : null
                    break
                case this.tabs.HISTORY:
                    await this.getPort('history')
                    this.history = this.alert.type == 'success' ? await this.getHistory : null
                    break
                case this.tabs.DISTRIBUTION:
                    await this.getPort('distribution')
                    this.distribution = this.alert.type == 'success' ? await this.getDistribution : null
                    break
                default:
                    await this.getPort('outstanding,dca,model,pra,history,distribution')
                    if (this.alert.type == 'success') {
                        this.outstanding = await this.getOutstanding
                        this.dca = await this.getDca
                        this.model = await this.getModel
                        this.pra = await this.getPra
                        this.history = await this.getHistory
                        this.distribution = await this.getDistribution
                    }
            }
            this.reloadingTab = false
            clearInterval(this.loadingTab.interval)
        },
        async updateQuickFilter(item) {
            this.quickFilterValue = item
            await this.updateVisibledHistoryList()
        },
        async updateSearch(newVal) {
            this.searchValue = newVal
            await this.updateVisibledHistoryList()
            await this.updateVisibledDistributionList()
        },
        async updateVisibledHistoryList() {
            this.orderHistoryList = await this.getAdjustedHistory({
                quickFilter: this.quickFilterValue,
                searchWord: this.searchValue
            })
        },
        async updateVisibledDistributionList() {
            this.distributionList = await this.getAdjustedDistribution({
                searchWord: this.searchValue,
                filterList: this.filterValues
            })
        },
        async openFundTab(fund) {
            const preparedFundName = getFundShortNameURI(fund)
            window.open(`${this.url.FINNO_FUND}${preparedFundName}`)
        },
        async updateAdjustedTotalTaxCost() {
            this.totalTaxCost = await this.getAdjustedTotalTaxCost()
        },
        updateLinkBreadcrumb() {
            this.$set(this.header.path, 2, {
                text: this.basicInfo.general_info.customer_name,
                link: this.getCustomerUrl(this.basicInfo.general_info.customer_code),
                external: this.featureCustomerInfoV2Enabled
            })
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_INFO_V2).then(flag => {
                this.featureCustomerInfoV2Enabled = flag
            })
            this.isEnabled(FEATURE_CUSTOMER_LIST_V2).then(flag => {
                this.featureCustomerListV2Enabled = flag
            })
        },
        getCustomerUrl(customerCode) {
            return this.featureCustomerInfoV2Enabled
                ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-info/${customerCode}`
                : `${pagesConst.CUSTOMER_INFO}/${customerCode}`
        }
    }
}
</script>
