import { type, keyValidator, typeValidator } from '../../../utill'

const userHeaderValidator = user => {
    if (!keyValidator(user, ['username', 'email', 'abbr', 'full', 'profile_picture', 'display_circle'])) {
        return false
    }
    if (
        !typeValidator([
            { value: user.username, type: type.STRING },
            { value: user.email, type: type.STRING },
            { value: user.abbr, type: type.STRING },
            { value: user.full, type: type.STRING },
            { value: user.profile_picture, type: type.OBJECT },
            { value: user.display_circle, type: type.BOOLEAN }
        ])
    ) {
        return false
    }
    return true
}

const userProfileValidator = user => {
    if (!keyValidator(user, ['display_circle', 'profile_picture', 'abbr', 'full'])) {
        return false
    }
    if (
        !typeValidator([
            { value: user.display_circle, type: type.BOOLEAN },
            { value: user.abbr, type: type.STRING },
            { value: user.full, type: type.STRING },
            { value: user.profile_picture, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return true
}

const permissionsValidator = permissions => {
    if (!keyValidator(permissions, ['role', 'permissions'])) {
        return false
    }
    if (
        !typeValidator([
            { value: permissions.role, type: type.STRING },
            { value: permissions.permissions, type: type.ARRAY }
        ])
    ) {
        return false
    }
    for (let permission of permissions.permissions) {
        if (!typeValidator([{ value: permission, type: type.NUMBER }])) {
            return false
        }
    }
    return true
}

export { permissionsValidator, userHeaderValidator, userProfileValidator }
