var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table__secondary--normal"},[_c('thead',{staticClass:"head__table--primary bottom__border--solid"},[_c('tr',[_vm._l((_vm.headerList),function(head,idx){return [_c('th',{key:("column-" + idx),staticClass:"pointer__cursor",class:("horizon__align--" + (head.align) + " " + (_vm.getHeaderClass(head.value))),on:{"click":function($event){return _vm.updateSorting(head.value)}}},[_vm._v(" "+_vm._s(head.text)+" "),(_vm.sorting.by == head.value)?_c('i',{staticClass:"normal__text--primary",class:_vm.sorting.order == _vm.orderByConst.ASC
                                ? _vm.iconsConst.FULL_ARROW_DOWN
                                : _vm.iconsConst.FULL_ARROW_UP}):_vm._e()])]})],2)]),(_vm.list.length > 0)?_c('tbody',[_vm._l((_vm.list),function(cust,idx){return [_c('tr',{key:("cust-row-" + idx),staticClass:"bottom__border--solid border__tablet--none lessthan",attrs:{"id":"cust-row"}},[_c('td',{staticClass:"horizon__align--left"},[_c('div',{staticClass:"row__container item__align--center"},[_c('link-anchor',{attrs:{"to":((_vm.getCustomerUrl(cust)) + "?tab=overview"),"class-name":"btn__secondary--normal font-bold link","external":_vm.featureCustomerInfoV2Enabled}},[_vm._v(" "+_vm._s(cust.title_th)+" "+_vm._s(cust.name_th)+" ")]),(cust.is_favorite)?_c('favorite-display',{staticClass:"bottom__margin--small"}):_vm._e()],1)]),_c('td',{staticClass:"horizon__align--left service-level--container"},[_c('service-level',{attrs:{"level":cust.service_level,"feature-service-level-rebranding":_vm.featureServiceLevelRebranding}})],1),_c('td',{staticClass:"horizon__align--right  display__phablet--none lessthan"},[_c('span',{staticClass:"normal__text--primary"},[_vm._v(_vm._s(_vm.getNumberFormat(cust.cost, false, 2, '')))])]),_c('td',{staticClass:"horizon__align--right  display__phablet--none lessthan"},[_c('span',{staticClass:"normal__text--primary"},[_vm._v(_vm._s(_vm.getNumberFormat(cust.outstanding, false, 2, '')))])]),_c('td',{staticClass:"horizon__align--right  display__phablet--none lessthan"},[_c('div',{staticClass:"list__container"},[_c('span',{class:_vm.getClassNameColorForProfit(cust.unrealized_pl.percent, 2)},[_vm._v(" "+_vm._s(_vm.getNumberFormat(cust.unrealized_pl.value, true, 2))+" ")]),_c('span',{class:_vm.getClassNameColorForProfit(cust.unrealized_pl.percent, 2)},[_vm._v(" "+_vm._s(_vm.getNumberFormat(cust.unrealized_pl.percent, true, 2, '%'))+" ")])])]),_c('td',{staticClass:"horizon__align--left display__tablet--none lessthan"},[(
                            (cust.investment_status === 'Invested' ||
                                cust.investment_status === 'Ready to Invest') &&
                                _vm.checkOAStatus(cust.pending_detail)
                        )?_c('span',{staticClass:"normal__text--primary"},[_vm._v(" "+_vm._s(cust.investment_status)+" ")]):_c('pending-detail',{attrs:{"pending-detail":cust.pending_detail}})],1),_c('td',{staticClass:"horizon__align--left display__tablet--none lessthan"},[_c('table-advisor',{attrs:{"advisor-list":cust.advisors}})],1),_c('td',{staticClass:"horizon__align--left display__mobile--none lessthan"},[_c('table-port-total-action',{attrs:{"total-action":cust.port_total_action,"link":((_vm.getCustomerUrl(cust)) + "?tab=invest"),"is-customer-v2":_vm.featureCustomerInfoV2Enabled}})],1)]),_c('tr',{key:("cust-row-responsive-" + idx),staticClass:"display__desktop--none bottom__border--solid",attrs:{"id":"cust-row-responsive"}},[_c('td',{staticClass:"horizon__align--left",attrs:{"colspan":_vm.headerList.length}},[_c('div',{staticClass:"row__container"},[_c('div',{staticClass:"left__col--responsive list__container"},[_c('table-responsive-display',{staticClass:"bottom__margin--small display__tablet--none",attrs:{"title":'Cost:',"value":_vm.getNumberFormat(cust.cost, false, 2, '')}}),_c('table-responsive-display',{staticClass:"bottom__margin--small display__tablet--none",attrs:{"title":'Outstanding:',"value":_vm.getNumberFormat(cust.outstanding, false, 2, '')}}),_c('table-responsive-display',{staticClass:"bottom__margin--small display__tablet--none",attrs:{"title":'Unrealized P/L:',"value":_vm.getUnrealizedPL(cust),"value-color":_vm.getClassNameColorForProfit(cust.unrealized_pl.percent, 2)}}),(
                                    (cust.investment_status === 'Invested' ||
                                        cust.investment_status === 'Ready to Invest') &&
                                        _vm.checkOAStatus(cust.pending_detail)
                                )?_c('table-responsive-display',{staticClass:"bottom__margin--small display__phablet--none",attrs:{"title":'Acc. Status:',"value":cust.investment_status}}):_c('table-pending-detail-responsive-display',{staticClass:"bottom__margin--small display__phablet--none",attrs:{"title":'Acc. Status:',"pending-detail":cust.pending_detail}}),_c('div',{staticClass:"row__container item__align--center bottom__margin--small display__tablet--none display__phablet--none"},[_c('span',{staticClass:"font-bold topic__text--primary right__margin--small"},[_vm._v("Advisors:")]),_c('table-advisor',{attrs:{"advisor-list":cust.advisors}})],1),_c('div',{staticClass:"row__container item__align--center bottom__margin--small display__tablet--none display__phablet--none"},[_c('span',{staticClass:"font-bold topic__text--primary right__margin--small",staticStyle:{"margin-bottom":"0"}},[_vm._v("Action:")]),_c('table-port-total-action',{attrs:{"total-action":cust.port_total_action,"link":((_vm.getCustomerUrl(cust)) + "?tab=invest"),"is-customer-v2":_vm.featureCustomerInfoV2Enabled}})],1)],1),_c('div',{staticClass:"right__col--responsive list__container"},[(
                                    (cust.investment_status === 'Invested' ||
                                        cust.investment_status === 'Ready to Invest') &&
                                        _vm.checkOAStatus(cust.pending_detail)
                                )?_c('table-responsive-display',{staticClass:"wrap__content bottom__margin--small display__tablet--none display__mobile--none",attrs:{"title":'Acc. Status:',"value":cust.investment_status}}):_c('table-pending-detail-responsive-display',{staticClass:"wrap__content bottom__margin--small display__tablet--none display__mobile--none",attrs:{"title":'Acc. Status:',"pending-detail":cust.pending_detail}}),_c('div',{staticClass:"row__container item__align--center display__mobile--none"},[_c('span',{staticClass:"font-bold topic__text--primary right__margin--small"},[_vm._v("Advisors:")]),_c('table-advisor',{attrs:{"advisor-list":cust.advisors}})],1)],1)])])])]})],2):_c('tbody',[_c('tr',{staticClass:"bottom__border--solid"},[_c('td',{staticClass:"no__result",attrs:{"colspan":_vm.headerList.length}},[_vm._v("No Result")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }