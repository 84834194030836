<template>
    <div>
        <div v-if="this.featureLogoRebranding">
            <img :src="require('../../../../public/LogoForAdvisors.svg')" height="56" />
        </div>
        <div v-else class="list__container">
            <div class="row__container item__align--center">
                <img :src="require('../../../../public/LOGO-FINNO.png')" width="30" />
                <span class="logo__text--small spacing__logo">FINNOMENA</span>
            </div>
            <span v-if="showFull" class="logo__text--big spacing__logo left__margin--small top__margin--negative">
                FOR ADVISORS
            </span>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { F4A_LOGO_REBRANDING } from '../../../store/utill/config/constant'
export default {
    props: {
        showFull: {
            type: Boolean,
            required: false,
            default() {
                return true
            }
        }
    },
    data() {
        return {
            featureLogoRebranding: false
        }
    },
    computed: {
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.isEnabled(F4A_LOGO_REBRANDING).then(flag => {
                this.featureLogoRebranding = flag
            })
        }
    },
    created() {
        this.isEnabled(F4A_LOGO_REBRANDING).then(flag => {
            this.featureLogoRebranding = flag
        })
    },
    methods: {
        ...mapActions('feature', ['isEnabled'])
    }
}
</script>
<style lang="scss" scoped>
.spacing__logo {
    letter-spacing: 2px;
}

.top__margin--negative {
    margin-top: -7px;
}
</style>
