<template>
    <div>
        <table ref="tableOrderHistory" class="table__secondary--normal bottom__margin--normal">
            <thead class="head__table--primary bottom__border--solid">
                <tr>
                    <th class="first__column first__column--fix"></th>
                    <th class="horizon__align--left width__container--fifty">Order</th>
                    <th class="horizon__align--left status__column--fix display__phablet--none lessthan">Status</th>
                    <th class="horizon__align--right date__column--fix display__phablet--none lessthan">
                        Date Created
                    </th>
                    <th class="horizon__align--right last__column--fix">Value</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(order, idx) in orderList">
                    <tr :key="`order-row-${idx}`">
                        <td
                            class="pointer__cursor first__column--fix"
                            :class="order.order_type == orderType.SWITCH ? 'top__padding--normal item__align--top' : ''"
                            :data-idx="idx"
                            @click="arrowClicked(idx)"
                        >
                            <i :class="expandList[idx] ? iconsConst.ARROW_DOWN : iconsConst.ARROW_RIGHT" />
                        </td>
                        <td class="horizon__align--left right__padding--zero">
                            <div class="list__container">
                                <div v-if="order.xwt_ref_no" class="list__container">
                                    <div class="order__container">
                                        <action-type
                                            :type="order.order_type"
                                            lang="en"
                                        />
                                        <div class="cross__amc-order-badge">
                                            <order-badge
                                                text="Cross AMC Sw."
                                                color="#003fff"
                                                background-color="#f4f7f9"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="normal__text--primary font-bold top__margin--smallest bottom__margin--small"
                                    >
                                        <external-link
                                            type="underline"
                                            :text="order.fund_name"
                                            @click.native="$emit('open-fund-tab', order.fund_name)"
                                        />
                                    </div>
                                </div>
                                <div v-else-if="order.proposal_id" class="list__container">
                                    <div class="row__container item__align--center">
                                        <action-type
                                            :type="order.order_type"
                                            lang="en"
                                        />
                                        <div
                                            class="font-bold topic__text--primary right__margin--small"
                                        >
                                            <i :class="iconsConst.COMMENT_LINES"></i>
                                        </div>
                                    </div>
                                    <div
                                        class="normal__text--primary font-bold"
                                    >
                                        <external-link
                                            type="underline"
                                            :text="order.fund_name"
                                            @click.native="$emit('open-fund-tab', order.fund_name)"
                                        />
                                    </div>
                                </div>
                                <div v-else class="row__container wrap__content item__align--center">
                                    <action-type
                                        :type="order.order_type"
                                        lang="en"
                                    />
                                    <div
                                        class="normal__text--primary font-bold"
                                    >
                                        <external-link
                                            type="underline"
                                            :text="order.fund_name"
                                            @click.native="$emit('open-fund-tab', order.fund_name)"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-if="order.order_type == orderType.SWITCH"
                                    class="row__container wrap__content item__align--center top__margin--small"
                                >
                                    <i
                                        class="normal__text--blue right__margin--small"
                                        :class="iconsConst.LIGHT__ARROW__RIGHT"
                                    />
                                    <div
                                        class="normal__text--primary font-bold"
                                    >
                                        {{ order.sw_to_fund }}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="horizon__align--left display__phablet--none lessthan">
                            <order-status :status="order.status" />
                        </td>
                        <td class="horizon__align--right display__phablet--none lessthan">
                            <div class="normal__text--primary">
                                {{ getDateFormat(order.transaction_date) }}
                            </div>
                        </td>
                        <td class="horizon__align--right left__padding--zero">
                            <div>
                                <div class="normal__text--primary">{{ order.amount }}</div>
                                <div class="normal__text--primary top__margin--smallest">{{ order.unit }}</div>
                            </div>
                            <div v-if="order.order_type == orderType.SWITCH" class="top__margin--small">
                                <div class="normal__text--primary">{{ order.sw_in_amount }}</div>
                                <div class="normal__text--primary">{{ order.sw_in_unit }}</div>
                            </div>
                        </td>
                    </tr>
                    <tr
                        :key="`order-date-created-${idx}`"
                        :class="
                            expandList[idx]
                                ? 'responsive__date-created'
                                : 'responsive__date-created bottom__border--solid'
                        "
                    >
                        <td colspan="3">
                            <table-responsive-display
                                class="display__tablet--none morethan"
                                title="Date Created:"
                                :value="getDateFormat(order.transaction_date)"
                            />
                        </td>
                    </tr>
                    <tr
                        v-if="expandList[idx]"
                        :key="`order-row-expand-${idx}`"
                        class="bottom__border--solid background--grey"
                    >
                        <td></td>
                        <td :colspan="colspan">
                            <div
                                v-if="order.proposal_id"
                                class="list__container bottom__border--solid bottom__margin--normal bottom__padding--normal"
                            >
                                <div>
                                    <span class="font-bold topic__text--primary right__margin--small">
                                        <i :class="iconsConst.COMMENT_LINES"></i> Proposal Order:
                                    </span>
                                    <span class="font-bold normal__text--primary">{{ order.proposal_id }}</span>
                                </div>
                            </div>
                            <div v-if="order.xwt_ref_no" class="list__container">
                                <div class="cross__amc--detail">
                                    <span class="normal__text--blue-primary font-bold right__margin--small"
                                        >Cross AMC Switch</span
                                    >
                                    <span
                                        :class="
                                            `right__margin--small normal__text--primary ${order.order_type ==
                                                orderType.SELL && 'font-bold'} responsive__margin--top`
                                        "
                                        >{{
                                            order.order_type == orderType.SELL
                                                ? findOrderNameByXWTRefNo(
                                                      order.fund_name,
                                                      orderType.SELL,
                                                      order.xwt_ref_no
                                                  )
                                                : findOrderNameByXWTRefNo(
                                                      order.xwt_fund,
                                                      orderType.SELL,
                                                      order.xwt_ref_no
                                                  )
                                        }}</span
                                    >
                                    <div class="row__container item__align--center responsive__margin--top">
                                        <i :class="iconsConst.LONG_ARROW_RIGHT"></i>
                                        <span
                                            :class="
                                                `left__margin--small normal__text--primary ${order.order_type ==
                                                    orderType.BUY && 'font-bold'}`
                                            "
                                            >{{
                                                order.order_type == orderType.SELL
                                                    ? findOrderNameByXWTRefNo(
                                                          order.xwt_fund,
                                                          orderType.BUY,
                                                          order.xwt_ref_no
                                                      ) || '-'
                                                    : findOrderNameByXWTRefNo(
                                                          order.fund_name,
                                                          orderType.BUY,
                                                          order.xwt_ref_no
                                                      )
                                            }}</span
                                        >
                                    </div>
                                </div>
                                <button
                                    class="normal__text--highlight-primary font-bold top__margin--small bottom__margin--normal dashed__underline-yellow"
                                    @click="
                                        toggleCrossAMCModal([
                                            findOrderByXWTRefNo(order.xwt_ref_no)
                                                ? findOrderByXWTRefNo(order.xwt_ref_no).sell
                                                : order,
                                            findOrderByXWTRefNo(order.xwt_ref_no)
                                                ? findOrderByXWTRefNo(order.xwt_ref_no).buy
                                                : null
                                        ])
                                    "
                                >
                                    Full Detail
                                </button>
                                <span class="dashed__line-grey bottom__margin--small"></span>
                            </div>
                            <div class="row__container top__margin--small">
                                <div class="expand__row--responsive phablet list__container">
                                    <div class="row__container display__tablet--none morethan bottom__margin--smallest">
                                        <span class="font-bold topic__text--primary right__margin--small"
                                            >Status:
                                        </span>
                                        <order-status :status="order.status" />
                                    </div>
                                    <table-responsive-display
                                        title="Detailed Status:"
                                        :value="order.status_detail"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        v-if="
                                            order.order_type === orderType.SELL || order.order_type === orderType.SWITCH
                                        "
                                        title="Profit/Loss:"
                                        :value="
                                            order.realized_gl != null
                                                ? getNumberFormat(order.realized_gl, true, 2, '฿')
                                                : '-'
                                        "
                                        :value-color="getClassNameColorForProfit(order.realized_gl, 2)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Order ID:"
                                        :value="order.order_ref_no"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        v-if="order.order_type != orderType.SWITCH"
                                        :title="`NAV (${getDateFormat(order.allotment_date)}):`"
                                        :value="order.nav"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        v-if="order.order_type == orderType.SWITCH"
                                        :title="`NAV Out/In (${getDateFormat(order.sw_in_allotment_date)}):`"
                                        :value="`${order.nav} / ${order.sw_in_nav}`"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Order Source:"
                                        :value="order.order_source"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Transaction Date:"
                                        :value="getDateFormat(order.transaction_date, '', 'd mmm yy - hh:mm')"
                                        class="bottom__margin--smallest"
                                    />
                                    <div class="display__tablet--none morethan">
                                        <table-responsive-display
                                            title="Trade Date:"
                                            :value="getDateFormat(order.order_date)"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            title="Allotment Date:"
                                            :value="getDateFormat(order.allotment_date)"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            v-if="[orderType.BUY, orderType.SELL].includes(order.order_type)"
                                            title="Payment Date:"
                                            :value="getDateFormat(order.payment_date)"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            v-if="[orderType.BUY, orderType.SELL].includes(order.order_type)"
                                            title="Payment Method:"
                                            :value="order.payment_method"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            v-if="[orderType.BUY, orderType.SELL].includes(order.order_type)"
                                            title="Bank Account:"
                                            :value="order.bank_account"
                                            class="bottom__margin--smallest"
                                        />
                                    </div>
                                </div>
                                <div class="expand__row--responsive list__container display__phablet--none lessthan">
                                    <table-responsive-display
                                        title="Trade Date:"
                                        :value="getDateFormat(order.order_date)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Allotment Date:"
                                        :value="getDateFormat(order.allotment_date)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        v-if="[orderType.BUY, orderType.SELL].includes(order.order_type)"
                                        title="Payment Date:"
                                        :value="getDateFormat(order.payment_date)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        v-if="[orderType.BUY, orderType.SELL].includes(order.order_type)"
                                        title="Payment Method:"
                                        :value="order.payment_method"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        v-if="[orderType.BUY, orderType.SELL].includes(order.order_type)"
                                        title="Bank Account:"
                                        :value="order.bank_account"
                                        class="bottom__margin--smallest"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { orderListValidator } from '../../../store/modules/port/validator'
import { getClassNameColorForProfit, getNumberFormat } from '../../../store/utill'
import { getDateFormat } from '../../../store/utill'
import { iconsConst, orderType } from '../../../store/utill/config'
import { ActionType, ExternalLink, OrderStatus, TableResponsiveDisplay, OrderBadge } from '../../atoms'
export default {
    components: {
        ActionType,
        ExternalLink,
        OrderStatus,
        TableResponsiveDisplay,
        OrderBadge
    },
    props: {
        orderList: {
            type: Array,
            required: true,
            validator: orderListValidator
        },
        fullOrderList: {
            type: Array,
            required: true,
            validator: orderListValidator
        }
    },
    data() {
        return {
            colspan: 1,
            expandList: [],
            matchingOrders: [],
            iconsConst,
            orderType
        }
    },
    watch: {
        orderList: {
            async handler() {
                this.expandList = await this.getAllExpand(false)
                this.matchingOrders = this.getMatchingOrders()
            }
        }
    },
    async mounted() {
        this.expandList = await this.getAllExpand(false)
        this.matchingOrders = this.getMatchingOrders()
    },
    methods: {
        getDateFormat,
        getNumberFormat,
        getClassNameColorForProfit,
        arrowClicked(idx) {
            this.colspan = this.$refs.tableOrderHistory.rows[0].cells.length
            this.expandList = this.expandList
                .slice(0, idx)
                .concat(!this.expandList[idx])
                .concat(this.expandList.slice(idx + 1, this.expandList.length))
        },
        async getAllExpand(value) {
            let list = []
            for (let idx in this.orderList) {
                list[idx] = value
            }
            return list
        },
        getMatchingOrders() {
            const matchingOrders = []
            const buyOrders = this.fullOrderList.filter(order => order.order_type === orderType.BUY)
            const sellOrders = this.fullOrderList.filter(order => order.order_type === orderType.SELL)

            // find matching buy and sell orders with xwt_ref_no
            sellOrders.forEach(sellOrder => {
                if (!sellOrder.xwt_ref_no) {
                    return
                }
                const matchingBuyOrder = buyOrders.find(buyOrder => {
                    if (!buyOrder.xwt_ref_no) {
                        return false
                    }
                    return buyOrder.xwt_ref_no === sellOrder.xwt_ref_no
                })
                matchingOrders.push({
                    xwt_ref_no: sellOrder.xwt_ref_no,
                    buy: matchingBuyOrder ? matchingBuyOrder : null,
                    sell: sellOrder
                })
            })
            return matchingOrders
        },
        findOrderByXWTRefNo(xwtRefNo) {
            return this.matchingOrders.find(order => order.xwt_ref_no == xwtRefNo)
        },
        findOrderNameByXWTRefNo(oldName, type, xwtRefNo) {
            const order = this.findOrderByXWTRefNo(xwtRefNo)
            if (!order) {
                return oldName
            }
            if (type === orderType.BUY && order.buy) {
                return order.buy.fund_name
            } else if (type === orderType.SELL && order.sell) {
                return order.sell.fund_name
            }
            return oldName
        },
        toggleCrossAMCModal(orders) {
            this.$emit('toggleCrossAMCModal', orders)
        }
    }
}
</script>

<style lang="scss" scoped>
.first__column--fix {
    width: 45px !important;
}
.status__column--fix {
    min-width: 120px;
}
.date__column--fix {
    min-width: 100px;
}
.last__column--fix {
    min-width: 170px;
    @include responsive(phablet) {
        min-width: 130px;
    }
    @include responsive(mobile) {
        min-width: 120px;
    }
}
.table__padding--zero {
    padding: 0%;
}
.cross__amc--detail {
    display: flex;
    align-items: center;

    @include responsive(mobile) {
        flex-direction: column;
        align-items: flex-start;
    }

    @include responsive(phablet) {
        flex-direction: column;
        align-items: flex-start;
    }
}
.responsive__margin--top {
    @include responsive(mobile) {
        margin-top: 4px;
    }
    @include responsive(phablet) {
        margin-top: 4px;
    }
}
.dashed__underline-yellow {
    align-self: flex-start;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-color: #f7a501;
    text-decoration-style: dashed;
}
.dashed__line-grey {
    display: block;
    height: 1px;
    border-top: 1px solid #e4e8ea;
    border-spacing: 20px;
}
.order__container {
    display: flex;
    @media only screen and (max-width: 360px) {
        flex-direction: column;
        padding-bottom: 4px;
    }
}
.cross__amc-order-badge {
    @media only screen and (max-width: 360px) {
        padding-top: 4px;
    }
}
.responsive__date-created td {
    padding: 0;
    @include responsive(mobile) {
        padding: 0 0 12px 60px;
    }
    @include responsive(phablet) {
        padding: 0 0 12px 60px;
    }
}
.left__padding--zero {
    padding-left: 0 !important;
}
.right__padding--zero {
    padding-right: 0 !important;
}
</style>
