<template>
    <div v-if="activeDataFn" class="tab__bar">
        <div
            v-for="(tab, idx) in tabs"
            :id="`btn-tab-${idx + 1}`"
            :key="`tab-${idx + 1}`"
            class="normal__text--primary font-bold right__margin--big"
            :class="tab == activeTab ? 'btn__primary--active' : 'btn__primary--normal'"
            data-fn-action="tab_change"
            :data-fn-params="getDataFnLocationAndAction(tab)"
            @click="selectTab(tab)"
        >
            {{ tab }}
        </div>
    </div>
    <div v-else class="tab__bar">
        <div
            v-for="(tab, idx) in tabs"
            :id="`btn-tab-${idx + 1}`"
            :key="`tab-${idx + 1}`"
            class="normal__text--primary font-bold right__margin--big"
            :class="tab == activeTab ? 'btn__primary--active' : 'btn__primary--normal'"
            @click="selectTab(tab)"
        >
            {{ tab }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Object,
            required: true
        },
        activeTab: {
            type: String,
            required: true
        },
        activeDataFn: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            dataFnLocation: '',
            dataFnAction: ''
        }
    },
    methods: {
        selectTab(tabIdx) {
            this.$emit('activeTab', tabIdx)
        },

        getDataFnLocationAndAction(tab) {
            switch (tab) {
                case this.tabs.OUTSTANDING:
                    return 'port-outstanding'
                case this.tabs.DCA:
                    return 'port-dca'
                case this.tabs.MODEL:
                    return 'port-model'
                case this.tabs.PRA:
                    return 'port-pra'
                case this.tabs.HISTORY:
                    return 'port-history'
                case this.tabs.DISTRIBUTION:
                    return 'port-div-ar'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tab__bar {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    overflow-x: auto;
    overflow-y: hidden;
}
</style>
