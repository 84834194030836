import { advisorsValidator } from './advisorValidator'
import { type, keyValidator, typeValidator } from '../../../utill'
import { unrealizedPlValidator } from './investValidator'

const sortingValidator = sorting => {
    if (!keyValidator(sorting, ['by', 'order'])) {
        return false
    }
    if (
        !typeValidator([
            { value: sorting.by, type: type.STRING },
            { value: sorting.order, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

const filterValidator = filter => {
    if (!keyValidator(filter, ['cost', 'outstanding', 'unrealized_pl', 'investment_status', 'service_level'])) {
        return false
    }
    if (
        !typeValidator([
            { value: filter.cost, type: type.OBJECT },
            { value: filter.outstanding, type: type.OBJECT },
            { value: filter.unrealized_pl, type: type.OBJECT },
            { value: filter.investment_status, type: type.OBJECT },
            { value: filter.service_level, type: type.OBJECT }
        ])
    ) {
        return false
    }
    if (!keyValidator(filter.cost, ['min', 'max'])) {
        return false
    }
    if (!keyValidator(filter.outstanding, ['min', 'max'])) {
        return false
    }
    if (!keyValidator(filter.unrealized_pl, ['min', 'max'])) {
        return false
    }
    if (
        !keyValidator(filter.investment_status, [
            'ats',
            'closed',
            'invested',
            'kyc',
            'oa_rejected',
            'oa_reviewing',
            'ready_to_invest'
        ])
    ) {
        return false
    }
    if (
        !keyValidator(filter.service_level, [
            'none',
            'member',
            'exclusive',
            'exclusive_prime',
            'fpw',
            'fpw_plus',
            'fpb',
            'fpb_ex',
            'fpb_ultra',
            'club',
            'private',
            'private_plus',
            'ultra',
            'ultra_ex',
            'ultra_ultra'
        ])
    ) {
        return false
    }
    return true
}

const headerCustListValidator = headerList => {
    for (let header of headerList) {
        if (!typeValidator([{ value: header, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(header, ['value', 'text', 'align', 'dataAlias'])) {
            return false
        }
        if (
            !typeValidator([
                { value: header.value, type: type.STRING },
                { value: header.text, type: type.STRING },
                { value: header.align, type: type.STRING },
                { value: header.dataAlias, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

const custListValidator = customerList => {
    for (let customer of customerList) {
        if (!typeValidator([{ value: customer, type: type.OBJECT }])) {
            return false
        }
        if (
            !keyValidator(customer, [
                'customer_code',
                'title_th',
                'name_th',
                'service_level',
                'cost',
                'outstanding',
                'unrealized_pl',
                'account_status',
                'investment_status',
                'advisors',
                'actions',
                'is_favorite',
                'email',
                'tel',
                'pending_detail'
            ])
        ) {
            return false
        }
        if (
            !typeValidator([
                { value: customer.customer_code, type: type.STRING },
                { value: customer.title_th, type: type.STRING },
                { value: customer.name_th, type: type.STRING },
                { value: customer.service_level, type: type.STRING },
                { value: customer.cost, type: type.NUMBER },
                { value: customer.outstanding, type: type.NUMBER },
                { value: customer.unrealized_pl, type: type.OBJECT },
                { value: customer.account_status, type: type.STRING },
                { value: customer.investment_status, type: type.STRING },
                { value: customer.advisors, type: type.ARRAY },
                { value: customer.actions, type: type.ARRAY },
                { value: customer.is_favorite, type: type.BOOLEAN },
                { value: customer.email, type: type.STRING },
                { value: customer.tel, type: type.STRING },
                { value: customer.pending_detail, type: type.OBJECT }
            ])
        ) {
            return false
        }
        if (!unrealizedPlValidator(customer.unrealized_pl) || !advisorsValidator(customer.advisors)) {
            return false
        }
    }
    return true
}

const pendingDetailValidator = pendingDetail => {
    if (!keyValidator(pendingDetail, ['oa_status', 'required'])) {
        return false
    }
    if (
        !typeValidator([
            { value: pendingDetail.oa_status, type: type.STRING },
            { value: pendingDetail.required, type: type.ARRAY }
        ])
    ) {
        return false
    }

    return true
}

export { custListValidator, filterValidator, headerCustListValidator, sortingValidator, pendingDetailValidator }
