<template>
    <div v-if="recievedProps" class="list__container">
        <div class="row__container wrap__content">
            <box-investment
                :show-more="false"
                :see-more-tab="investmentBox.see_more"
                :investment="investmentBox.investment"
                @seeMore="updateActiveTab"
            />
            <box-pl :see-more-tab="plBox.see_more" :show-more="false" :pl="plBox.pl" />
            <box-plan-setting
                :show-more="false"
                :see-more-tab="planSettingBox.see_more"
                :plan-setting="planSettingBox.plan_setting"
            />
            <box-tax-cost v-if="Object.keys(taxCostDetails).length" :tax-cost-details="taxCostDetails" />
        </div>
        <table-asset :all-assets="assets.all_assets" :asset-list="assets.asset_list" @open-fund-tab="openFundTab" />
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { BoxInvestment, BoxPl, BoxPlanSetting, BoxTaxCost, TableAsset } from '../../molecules'
import { investBoxValidator, plBoxValidator } from '../../../store/modules/customer/validator'
import { assetsValidator, planSettingBoxValidator } from '../../../store/modules/port/validator'

export default {
    components: {
        BoxInvestment,
        BoxPl,
        BoxPlanSetting,
        BoxTaxCost,
        TableAsset,
        Spinner
    },
    props: {
        investmentBox: {
            type: Object,
            required: true,
            validator: investBoxValidator
        },
        plBox: {
            type: Object,
            required: true,
            validator: plBoxValidator
        },
        planSettingBox: {
            type: Object,
            required: true,
            validator: planSettingBoxValidator
        },
        taxCostDetails: {
            type: Object,
            required: true
        },
        assets: {
            type: Object,
            required: true,
            validator: assetsValidator
        }
    },
    data() {
        return {
            recievedProps: false,
            loading: {
                interval: {},
                value: 0
            }
        }
    },
    mounted() {
        this.recievedProps = true
    },
    methods: {
        async updateActiveTab(newVal) {
            this.$emit('activeTab', newVal)
        },
        async openFundTab(newVal) {
            this.$emit('openFundTab', newVal)
        }
    }
}
</script>
