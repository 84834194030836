var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"top__info"},[_c('profile-name',{staticClass:"info__blog",attrs:{"profile":{
                title: 'Customer',
                display_circle: true,
                profile_picture: _vm.customer.profile_picture,
                abbr: _vm.customer.abbr,
                full: _vm.customer.full
            }}}),_c('status-display',{attrs:{"title":'Investment Status',"status":_vm.customer.investment_status}})],1),(_vm.showFull)?_c('div',{staticClass:"bottom__info"},[_c('personal-info',{staticClass:"info__blog",attrs:{"info":{
                service_level: _vm.customer.service_level,
                customer_code: _vm.customer.customer_code,
                phone: _vm.customer.phone,
                email: _vm.customer.email
            },"feature-service-level-rebranding":_vm.featureServiceLevelRebranding}}),_c('advisors-info',{attrs:{"see-more-tab":_vm.advisors.see_more,"info":_vm.advisors.advisors},on:{"seeMore":_vm.updateActiveTab}})],1):_vm._e(),_c('div',{staticClass:"absolute__btn--primary",attrs:{"id":"btn-up-info"},on:{"click":function($event){_vm.showFull = !_vm.showFull}}},[_c('i',{class:_vm.showFull ? _vm.iconsConst.ARROW_UP : _vm.iconsConst.ARROW_DOWN})])])}
var staticRenderFns = []

export { render, staticRenderFns }