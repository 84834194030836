<template>
    <base-modal
        title="Approve?"
        :visible="visible"
        :close="close"
        :reset="() => {}"
        :apply="() => {}"
        prefix-alias="approval_approve"
    >
        <div class="general__modal--content">
            Approve the following request<br />
            <span class="general__modal--label">Advisor:</span
            ><span> {{ iaName }}</span> <br />
            <span class="general__modal--label">Cust. Name</span
            ><span> {{ customerName }}</span>
        </div>
        <div class="row__container sortby__content top__border--solid vertical__padding--normal horizon__padding--big">
            <v-spacer />
            <button
                class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small"
                @click="close"
            >
                Cancel
            </button>
            <button
                class="yellow__btn--normal vertical__padding--small horizon_padding--normal font-bold"
                @click="$emit('approve', requestID)"
            >
                Approve
            </button>
        </div>
    </base-modal>
</template>

<script>
import { BaseModal } from './index'
export default {
    components: {
        BaseModal
    },
    props: {
        visible: Boolean,
        close: {
            type: Function,
            required: true
        },
        request: {
            type: Object,
            default: () => {
                return {
                    ia_name: '',
                    customer_name: '',
                    request_id: -1
                }
            }
        }
    },
    computed: {
        iaName() {
            return this.request && this.request.ia_name
        },
        customerName() {
            return this.request && this.request.customer_name
        },
        requestID() {
            return this.request && this.request.request_id
        }
    }
}
</script>

<style lang="scss" scoped>
.general__modal {
    width: 416px;
    height: max-content;
    border-radius: 8px;
    border: 1px solid $greyPrimary;
    background: $white;
    @include responsive(mobile) {
        width: 100%;
        max-width: 320px;
    }
    .general__modal--label {
        color: $textSecondary;
        font-size: 16px;
    }
    .general__modal--content {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 59px;
    }
}
</style>
