import { getDateFormat } from '../../../utill'

const getDcaList = dcaList => {
    let list = []
    for (let dca of dcaList) {
        list.push({
            fund_name: dca.fund_name || '-',
            update_date: dca.update_date ? getDateFormat(dca.update_date) : '-',
            bank_account: dca.bank_account || '-',
            dca_start_date: dca.dca_start_date ? getDateFormat(dca.dca_start_date) : 'Forever',
            dca_end_date: dca.dca_end_date ? getDateFormat(dca.dca_end_date) : 'Forever',
            occurrence: dca.occurrence || '-',
            value: dca.value || 0
        })
    }
    return list
}

export { getDcaList }
