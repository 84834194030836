<template>
    <div v-if="recievedProps" class="list__container item__align--center">
        <table-cust-log :log-list="visibledCustLog" />
        <br />
        <pagination :current-page="currentPage" :total-pages="totalPages" @currentPage="updateCurrentPage" />
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { TableCustLog, Pagination } from '../../molecules'

// import { investBoxValidator, planBoxValidator, plBoxValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        TableCustLog,
        Pagination,
        Spinner
    },
    props: {
        logList: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            recievedProps: false,
            amountContent: 20,
            currentPage: 1
        }
    },
    computed: {
        visibledCustLog() {
            return this.logList.slice(
                (this.currentPage - 1) * this.amountContent,
                this.currentPage * this.amountContent
            )
        },
        totalPages() {
            return Math.ceil(this.logList.length / this.amountContent)
        }
    },
    created() {
        this.logList = this.logList.sort(function(a, b) {
            return new Date(b.date) - new Date(a.date)
        })
    },
    mounted() {
        this.recievedProps = true
    },
    methods: {
        async updateCurrentPage(newVal) {
            this.currentPage = newVal
        }
    }
}
</script>
