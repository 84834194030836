<template>
    <v-container class="row__container item__align--center wrap__content content__page">
        <img v-if="content.type == 'image'" :src="content.text" width="100%" />
        <span v-if="content.type == 'text'" class="middle__text"> {{ content.text }} </span>
    </v-container>
</template>

<script>
import { contentValidator } from '../../../store/modules/general/validator'
export default {
    props: {
        content: {
            type: Object,
            required: true,
            validator: contentValidator
        }
    }
}
</script>
<style lang="scss" scoped>
.middle__text {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding: 150px 0px;
}
</style>
