<template>
    <v-container class="list__container round__block--primary">
        <title-box
            id="title-investment"
            :title="'Investment'"
            :see-more-tab="seeMoreTab"
            :show-more="showMore"
            @seeMore="seeMore"
        />
        <div class="row__container item__align--center top__margin--normal bottom__margin--big">
            <span class="topic__text--primary"> Total Customer: </span>
            <span class="normal__text--primary font-bold">{{
                getNumberFormat(investment.total_customer, false, 0, '')
            }}</span>
        </div>
        <div class="row__container item__align--center bottom__margin--small wrap__content">
            <span class="topic__text--primary"> Total Cost: </span>
            <span class="normal__text--primary font-bold">
                {{ getNumberFormat(investment.total_cost, false, 2, '฿') }}
            </span>
        </div>
        <div class="row__container item__align--center wrap__content">
            <span class="topic__text--primary"> Total Outstanding: </span>
            <span class="normal__text--primary font-bold">
                {{ getNumberFormat(investment.total_outstanding, false, 2, '฿') }}
            </span>
        </div>
    </v-container>
</template>
<script>
import { getNumberFormat } from '../../../store/utill'
import { TitleBox } from '../Title'
import { totalInvestmentValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        investment: {
            type: Object,
            required: true,
            validator: totalInvestmentValidator
        }
    },
    methods: {
        getNumberFormat,
        seeMore(newVal) {
            this.$emit('seeMore', newVal)
        }
    }
}
</script>
