<template>
    <div class="list__container">
        <div class="row__container item__align--center bottom__margin--small">
            <span class="topic__text--primary">Created: </span>
            <span class="normal__text--primary font-bold">{{ info.created_date }}</span>
        </div>
        <div class="row__container item__align--center bottom__margin--small">
            <span class="topic__text--primary">Last Edit: </span
            ><span class="normal__text--primary font-bold">{{ info.last_edit_date }}</span>
        </div>
        <div class="row__container item__align--center bottom__margin--small">
            <span class="topic__text--primary">First Invest: </span
            ><span class="normal__text--primary font-bold">{{ info.first_invest_date }}</span>
        </div>
        <div class="row__container item__align--center">
            <span class="topic__text--primary">Last Trans.: </span
            ><span class="normal__text--primary font-bold">{{ info.last_trans_date }}</span>
        </div>
    </div>
</template>

<script>
import { dateInfoValidator } from '../../../store/modules/port/validator'
export default {
    props: {
        info: {
            type: Object,
            required: true,
            validator: dateInfoValidator
        }
    }
}
</script>
