<template>
    <div class="loading" :style="{ width: width + 'px', height: height + 'px' }"></div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            required: false,
            default: () => 128
        },
        height: {
            type: Number,
            required: false,
            default: () => 32
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.loading {
    background-color: #f2f2f2;
    background-size: 1000%;
    animation: sweep 2s ease-in-out infinite;
}
@keyframes sweep {
    0% {
        background-color: #f2f2f2;
    }
    50% {
        background-color: #dae0e4;
    }
    100% {
        background-color: #f2f2f2;
    }
}
</style>
