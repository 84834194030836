<template>
    <div>
        <template v-if="totalAction > 0">
            <link-anchor
                :to="link"
                class-name="btn__secondary--normal font-bold link"
                :external="isCustomerV2"
            >
                {{ `${totalAction} ${getWordingPort(totalAction)}` }}
            </link-anchor>
        </template>
        <template v-else>
            -
        </template>
    </div>
</template>

<script>
import LinkAnchor from '../../atoms/LinkAnchor'

export default {
    components: {
        LinkAnchor
    },
    props: {
        totalAction: {
            type: Number,
            required: true
        },
        link: {
            type: String,
            default: ''
        },
        dataAlias: {
            type: String,
            default: null
        },
        isCustomerV2: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getWordingPort(totalAction) {
            if (totalAction > 1) {
                return 'Ports'
            } else {
                return 'Port'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.link {
    text-decoration: none;
}
</style>
