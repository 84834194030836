<template>
    <div>
        <i class="small__text--primary left__margin--small" :class="iconsConst.FULL_STAR" />
    </div>
</template>

<script>
import { iconsConst } from '../../../store/utill'

export default {
    data() {
        return {
            iconsConst
        }
    }
}
</script>
