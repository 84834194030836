<template>
    <div class="full__width row__container radius__border--normal all__padding--normal horizon__align--center">
        <span class="big__text--primary font-bold">{{ text }}</span>
    </div>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        }
    }
}
</script>
