<template>
    <div class="background--normal__grey breadcrumb vertical__padding--normal">
        <div class="wrap__content row__container">
            <span v-for="(step, idx) in path" :id="`btn-link-${idx + 1}-breadcrumb`" :key="`step-${idx + 1}`">
                <link-anchor
                    :to="step.link"
                    class-name="hyperlink--primary"
                    :external="step.external || step.external === undefined"
                >
                    {{ step.text }}
                </link-anchor>
                <span class="space__delimiter">{{ idx == path.length - 1 ? '' : '/' }}</span>
            </span>
            <v-spacer />
            <favorite-button
                v-if="showFavoriteIcon"
                class="favorite__btn--absolute item__align--center"
                :favorite-status="favoriteStatus"
                @updateFavoriteStatus="updateFavoriteStatus"
            />
        </div>
    </div>
</template>
<script>
import { pathValidator } from '../../../store/modules/general/validator'
import { FavoriteButton } from '../../atoms'
import { mapActions, mapState } from 'vuex'
import LinkAnchor from '../../atoms/LinkAnchor'

export default {
    components: {
        FavoriteButton,
        LinkAnchor
    },
    props: {
        identifier: {
            type: String,
            default: ''
        },
        showFavoriteIcon: {
            type: Boolean,
            default: false
        },
        path: {
            type: Array,
            required: true,
            validator: pathValidator
        }
    },
    computed: {
        ...mapState('customer', ['favoriteStatus'])
    },
    methods: {
        ...mapActions('customer', ['updateFavorite', 'getCustInfo']),
        async updateFavoriteStatus() {
            await this.updateFavorite({
                uuid: this.$cookies.get('uuid'),
                id: this.identifier,
                action: this.favoriteStatus ? 'remove' : 'add',
                updatedStatus: !this.favoriteStatus
            })
            await this.getCustInfo({
                uuid: this.$cookies.get('uuid'),
                custCode: this.identifier,
                scope: 'favorite'
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
    margin-left: 64px;
    margin-right: 64px;
    @include responsive(mobile) {
        flex-wrap: wrap;
        margin-left: 16px;
        margin-right: 16px;
    }
}
.space__delimiter {
    margin: 0px 4px;
}
</style>
