<template>
    <v-container class="list__container round__block--primary">
        <title-box
            id="title-customer"
            :title="'Customer'"
            :see-more-tab="seeMoreTab"
            :show-more="showMore"
            @seeMore="seeMore"
        />
        <div class="list__container bottom__margin--normal">
            <span class="topic__text--primary">Customers need PRA</span>
            <span class="big__text--red">{{ plan.cust_need_pra }}</span>
        </div>
    </v-container>
</template>
<script>
import { TitleBox } from '../Title'
import { totalPlanValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        plan: {
            type: Object,
            required: true,
            validator: totalPlanValidator
        }
    },
    methods: {
        seeMore(newVal) {
            this.$emit('seeMore', newVal)
        }
    }
}
</script>
