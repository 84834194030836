<template>
    <div v-if="receivedProps" class="row__container item__align--center wrap__content">
        <box-investment
            :see-more-tab="investmentBox.see_more"
            :investment="investmentBox.investment"
            @seeMore="updateActiveTab"
        />
        <box-plan :see-more-tab="planBox.see_more" :plan="planBox.plan" @seeMore="updateActiveTab" />
        <box-advisors :see-more-tab="advisorBox.see_more" :advisors="advisorBox.advisors" @seeMore="updateActiveTab" />
        <box-bank :see-more-tab="bankBox.see_more" :bank-account="bankBox.bank_account" @seeMore="updateActiveTab" />
        <box-note
            :see-more-tab="noteBox.see_more"
            :note-detail="noteBox.note"
            :customer-code="noteBox.customer_code"
            @showModalNoteDetail="toggleNoteModal"
        />
        <customer-note-modal
            :visible="noteModalVisible"
            :close="toggleNoteModal"
            :note="noteBox.note"
            :advisor="noteBox.advisor"
        />
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { BoxAdvisors, BoxBank, BoxInvestment, BoxPlan, BoxNote } from '../../molecules'
import { CustomerNoteModal } from '../Modal'
import {
    advisorBoxValidator,
    bankBoxValidator,
    investBoxValidator,
    planBoxValidator,
    noteBoxValidator
} from '../../../store/modules/customer/validator'

export default {
    components: {
        BoxAdvisors,
        BoxBank,
        BoxInvestment,
        BoxPlan,
        BoxNote,
        Spinner,
        CustomerNoteModal
    },
    props: {
        investmentBox: {
            type: Object,
            required: true,
            validator: investBoxValidator
        },
        planBox: {
            type: Object,
            required: true,
            validator: planBoxValidator
        },
        advisorBox: {
            type: Object,
            required: true,
            validator: advisorBoxValidator
        },
        bankBox: {
            type: Object,
            required: true,
            validator: bankBoxValidator
        },
        noteBox: {
            type: Object,
            required: true,
            validator: noteBoxValidator
        }
    },
    data() {
        return {
            receivedProps: false,
            noteModalVisible: false
        }
    },
    mounted() {
        this.receivedProps = true
    },
    methods: {
        async updateActiveTab(newVal) {
            this.$emit('activeTab', newVal)
        },
        toggleNoteModal() {
            this.noteModalVisible = !this.noteModalVisible
        }
    }
}
</script>
