<template>
    <div class="daterange-picker row__container item__align--center">
        <v-menu
            ref="startMenu"
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="daterange-picker__input"
        >
            <template v-slot:activator="{ on, attrs }">
                <div class="daterange-picker__input">
                    <input
                        v-model="formattedStartDate"
                        :class="[{ 'input__primary--disable': startChecked }]"
                        class="full__width input__primary--normal"
                        :placeholder="DATE_PICKER_START"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                    <span class="daterange-picker__input-icon">
                        <i :class="iconsConst.CALENDAR" />
                    </span>
                    <checkbox
                        class="daterange-picker__checkbox"
                        :label="DATE_PICKER_START"
                        :show-label="true"
                        :can-edit="true"
                        :item="{
                            text: DATE_PICKER_START,
                            checked: startChecked
                        }"
                        @change="toggleStartCheckbox"
                    />
                </div>
            </template>
            <div class="daterange-picker__picker">
                <v-date-picker :value="dateRange.startDate" :max="maxPickerDate" @change="onStartDateChange" />
            </div>
        </v-menu>
        <span class="horizon__margin--normal">
            To
        </span>
        <v-menu
            ref="endMenu"
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="daterange-picker__input"
        >
            <template v-slot:activator="{ on, attrs }">
                <div class="daterange-picker__input">
                    <input
                        v-model="formattedEndDate"
                        :class="[{ 'input__primary--disable': endChecked }]"
                        class="full__width input__primary--normal"
                        :placeholder="DATE_PICKER_END"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    />
                    <span class="daterange-picker__input-icon">
                        <i :class="iconsConst.CALENDAR" />
                    </span>
                    <checkbox
                        class="daterange-picker__checkbox"
                        :label="DATE_PICKER_END"
                        :show-label="true"
                        :can-edit="true"
                        :item="{
                            text: DATE_PICKER_END,
                            checked: endChecked
                        }"
                        @change="toggleEndCheckbox"
                    />
                </div>
            </template>
            <div class="daterange-picker__picker">
                <v-date-picker :value="dateRange.endDate" :min="minPickerDate" @change="onEndDateChange" />
            </div>
        </v-menu>
    </div>
</template>

<script>
import { Checkbox } from '../../atoms'
import { iconsConst, DATE_PICKER_START, DATE_PICKER_END, getDateFormat } from '../../../store/utill'

export default {
    components: {
        Checkbox
    },
    props: {
        dateRange: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            startDatePickerVisible: false,
            endDatePickerVisible: false,
            DATE_PICKER_START,
            DATE_PICKER_END,
            startMenu: null,
            endMenu: null
        }
    },
    computed: {
        startChecked() {
            return this.dateRange.startDate === DATE_PICKER_START
        },
        endChecked() {
            return this.dateRange.endDate === DATE_PICKER_END
        },
        checkboxStartClass() {
            if (this.startChecked) {
                return 'daterange-picker__disabled'
            }
            return ''
        },
        checkboxEndClass() {
            if (this.endChecked) {
                return 'daterange-picker__disabled'
            }
            return ''
        },
        formattedStartDate() {
            if (this.dateRange.startDate) {
                return getDateFormat(this.dateRange.startDate)
            }
            return ''
        },
        formattedEndDate() {
            if (this.dateRange.endDate) {
                return getDateFormat(this.dateRange.endDate)
            }
            return ''
        },
        startDate() {
            if (this.dateRange.startDate) {
                return this.dateRange.startDate
            }
            return ''
        },
        endDate() {
            if (this.dateRange.endDate) {
                return this.dateRange.endDate
            }
            return ''
        },
        minPickerDate() {
            if (this.startDate === DATE_PICKER_START) {
                return ''
            }
            return this.startDate
        },
        maxPickerDate() {
            if (this.endDate === DATE_PICKER_END) {
                return ''
            }
            return this.endDate
        }
    },
    methods: {
        getDateFormat,
        toggleStartCheckbox(val) {
            let dateRange = this.dateRange
            if (val) {
                dateRange.startDate = DATE_PICKER_START
            } else {
                dateRange.startDate = ''
            }
            this.$emit('input', dateRange)
        },
        toggleEndCheckbox(val) {
            let dateRange = this.dateRange
            if (val) {
                dateRange.endDate = DATE_PICKER_END
            } else {
                dateRange.endDate = ''
            }
            this.$emit('input', dateRange)
        },
        onStartDateChange(val) {
            let dateRange = this.dateRange
            dateRange.startDate = val
            this.$refs.startMenu.save(val)
            this.$emit('input', dateRange)
        },
        onEndDateChange(val) {
            let dateRange = this.dateRange
            dateRange.endDate = val
            this.$refs.endMenu.save(val)
            this.$emit('input', dateRange)
        }
    }
}
</script>

<style lang="scss" scoped>
.daterange-picker__input {
    position: relative;

    .daterange-picker__input-icon {
        position: absolute;
        right: 10px;
        top: 12px;
    }
    .daterange-picker__checkbox {
        position: absolute;
    }
    .daterange-picker__picker {
        position: absolute;
        z-index: 3;
    }
}
</style>
