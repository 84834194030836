<template>
    <div class="horizon__padding--normal">
        <table class="table__secondary--normal">
            <thead>
                <tr class="head__table--primary bottom__border--solid">
                    <th class="horizon__align--left first__column--fix"></th>
                    <th class="horizon__align--left">Fund</th>
                    <th class="horizon__align--right display__mobile--none">First Invest</th>
                    <th
                        v-if="[planTypesConst.GOAL, planTypesConst.FIRST_MILLION].includes(planType)"
                        class="horizon__align--right display__mobile--none"
                    >
                        Monthly
                    </th>
                    <th class="horizon__align--right">%</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(model, idx) in modelList">
                    <tr :key="`row-${idx}`" class="bottom__border--solid border__mobile--none">
                        <td class="horizon__align--left first__column--fix">
                            <i
                                v-if="model.category_color != ''"
                                :class="iconsConst.CIRCLE"
                                :style="`color: ${model.category_color}`"
                            />
                        </td>
                        <td class="horizon__align--left">
                            <span class="normal__text--primary font-bold">
                                {{ model.fund }}
                                <external-link
                                    v-if="model.category_color != ''"
                                    @click.native="$emit('open-fund-tab', model.fund)"
                                />
                            </span>
                        </td>
                        <td class="horizon__align--right display__mobile--none">
                            <span class="normal__text--primary">
                                {{ getNumberFormat(model.first_invest, false, 0, '') }}
                            </span>
                        </td>
                        <td
                            v-if="[planTypesConst.GOAL, planTypesConst.FIRST_MILLION].includes(planType)"
                            class="horizon__align--right display__mobile--none"
                        >
                            <span class="normal__text--primary">
                                {{ model.monthly ? getNumberFormat(model.monthly, false, 0, '') : '0' }}
                            </span>
                        </td>
                        <td class="horizon__align--right">
                            <span v-if="model.percent > -1" class="normal__text--primary">
                                {{ getNumberFormat(model.percent, false, 0, '%') }}
                            </span>
                        </td>
                    </tr>
                    <tr :key="`row-responsive-${idx}`" class="bottom__border--solid display__phablet--none morethan">
                        <td class="horizon__align--left first__column--fix"></td>
                        <td colspan="2">
                            <table-responsive-display
                                :title="'First Invest:'"
                                :value="getNumberFormat(model.first_invest, false, 0, '')"
                            />
                            <table-responsive-display
                                v-if="[planTypesConst.GOAL, planTypesConst.FIRST_MILLION].includes(planType)"
                                :title="'Monthly:'"
                                :value="model.monthly ? getNumberFormat(model.monthly, false, 0, '') : '0'"
                            />
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { getNumberFormat, iconsConst, planTypesConst } from '../../../store/utill'
import { modelListValidator } from '../../../store/modules/port/validator'
import { ExternalLink, TableResponsiveDisplay } from '../../atoms'

export default {
    components: {
        ExternalLink,
        TableResponsiveDisplay
    },
    props: {
        planType: {
            type: String,
            required: true
        },
        modelList: {
            type: Array,
            required: true,
            validator: modelListValidator
        }
    },
    data() {
        return {
            iconsConst,
            planTypesConst
        }
    },
    methods: {
        getNumberFormat
    }
}
</script>
<style lang="scss" scoped>
.first__column--fix {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 10px !important;
}
.border__mobile--none {
    @include multiple-responsive(mobile) {
        border-bottom: none !important;
    }
}
</style>
