<template>
    <div>
        <i
            :class="
                favoriteStatus
                    ? [iconsConst.FULL_STAR, 'icon__btn--secondary']
                    : [iconsConst.EMPTY_STAR, 'icon__btn--primary']
            "
            @click="updateFavoriteStatus"
        />
    </div>
</template>

<script>
import { iconsConst } from '../../../store/utill'

export default {
    props: {
        favoriteStatus: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            iconsConst
        }
    },
    methods: {
        async updateFavoriteStatus() {
            this.$emit('updateFavoriteStatus')
        }
    }
}
</script>
