import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './modules/authentication'
import customer from './modules/customer'
import leadRequest from './modules/leadRequest'
import port from './modules/port'
import feature from './modules/feature'

Vue.use(Vuex)

export default () =>
    new Vuex.Store({
        modules: {
            authentication,
            customer,
            leadRequest,
            port,
            feature
        }
    })
