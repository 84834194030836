<template>
    <div class="row__container item__align--center bottom__margin--big">
        <span class="normal__text--primary font-bold">
            {{ title }}
        </span>
        <v-spacer></v-spacer>
        <button
            :id="`btn-edit-${prefix}`"
            class="right__margin--normal"
            :class="canEdit ? 'btn__secondary--disable' : 'btn__secondary--normal'"
            :disabled="canEdit"
            @click="editClicked()"
        >
            Edit
        </button>
        <button
            :id="`btn-save-${prefix}`"
            :class="canEdit ? 'btn__secondary--normal' : 'btn__secondary--disable'"
            :disabled="!canEdit"
            @click="saveClicked()"
        >
            Save
        </button>
    </div>
</template>
<script>
export default {
    props: {
        prefix: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        editClicked() {
            this.$emit('canEdit', true)
        },
        saveClicked() {
            this.$emit('canEdit', false)
        }
    }
}
</script>
