import { HTTP_REQUEST, API_PREFIX, API_PRIVATE_V1_PREFIX } from './apiConfig'

export const customerInfoAPI = {
    // Customer Info
    customerOverview: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API}/customer-overview` },
    customer: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API}/customer` },

    // Customer List
    customerList: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API}/customers` },

    // PDPA
    pdpa: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API}/pdpa` },

    // CRS
    crs: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API}/crs` },

    //ANEO
    disability: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API}/disability` },

    // Advisor
    advisors: { method: HTTP_REQUEST.GET, url: `${API_PRIVATE_V1_PREFIX.API}/advisors` }
}
