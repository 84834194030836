<template>
    <div class="background--normal__white bottom__padding--footer h-calc-full loading-container">
        <div class="flex items-container">
            <skeleton :width="100" :height="46" class="mr-4" />
            <skeleton :width="100" :height="46" class="mr-4" />
            <skeleton :width="100" :height="46" class="mr-4" />
            <skeleton :width="100" :height="46" class="mr-4" />
        </div>
        <div>
            <responsive-skeleton />
        </div>
    </div>
</template>

<script>
import { ResponsiveSkeleton, Skeleton } from '../../atoms'

export default {
    components: { ResponsiveSkeleton, Skeleton },
    props: {},
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
}
.loading-container {
    padding: 64px;
    padding-top: 32px;

    @media screen and (max-width: 480px) {
        padding: 32px 16px;
    }
}
.items-container {
    padding-bottom: 32px;

    @media screen and (min-width: 480px) {
        padding-bottom: 64px;
    }
}
.h-calc-full {
    height: calc(100vh - 100px);

    @media screen and (min-width: 480px) {
        height: calc(100vh - 480px);
    }
}
</style>
