<template>
    <div class="general__modal">
        <title-modal :title="'Sort'" prefix-alias="sort" @close="close" />
        <div class="list__container sortby__content">
            <span class="size__font--16px normal__text--primary bottom__margin--small">Sort By</span>
            <div class="dropdown__container">
                <v-select
                    :items="sortingList"
                    :value="sortByTmp"
                    dense
                    outlined
                    @input="updateSortBy"
                ></v-select>
            </div>
        </div>
        <div class="row__container orderby__content">
            <div class="row__container pointer__cursor item__align--center right__margin--normal" @click="ascClicked">
                <input-custom
                    :type="'radio'"
                    :checked="orderByTmp"
                    :radio-label="orderByConst.ASC"
                    class="mr-3"
                />
                <span class="row_container">
                    <i :class="iconsConst.ASC_SORT" />
                    <span>
                        Ascending
                    </span>
                </span>
            </div>
            <div class="row__container pointer__cursor item__align--center" @click="dscClicked">
                <input-custom
                    :type="'radio'"
                    :checked="orderByTmp"
                    :radio-label="orderByConst.DESC"
                    class="mr-3"
                />
                <span class="row_container">
                    <i :class="iconsConst.DESC_SORT" />
                    <span>
                        Descending
                    </span>
                </span>
            </div>
        </div>
        <line-button prefix-alias="sort" @click="buttonClicked" />
    </div>
</template>

<script>
import { InputCustom } from '../../atoms'
import { headerCustListValidator, sortingValidator } from '../../../store/modules/customer/validator'
import { iconsConst, orderByConst } from '../../../store/utill'
import { LineButton, TitleModal } from '../../molecules'

export default {
    components: {
        InputCustom,
        LineButton,
        TitleModal
    },
    props: {
        sortingList: {
            type: Array,
            required: true,
            validator: headerCustListValidator
        },
        sorting: {
            type: Object,
            required: true,
            validator: sortingValidator
        }
    },
    data() {
        return {
            iconsConst,
            orderByConst,
            orderByTmp: '',
            sortByTmp: {}
        }
    },
    destroyed() {
        document.body.style.overflowY = 'auto'
    },
    mounted() {
        document.body.style.overflowY = 'hidden'
        this.sortByTmp = this.sorting.by
        this.orderByTmp = this.sorting.order
    },
    methods: {
        updateSortBy(item) {
            this.sortByTmp = item
        },
        ascClicked() {
            this.orderByTmp = this.orderByConst.ASC
        },
        dscClicked() {
            this.orderByTmp = this.orderByConst.DESC
        },
        close() {
            this.$emit('close')
        },
        buttonClicked(buttonName) {
            switch (buttonName) {
                case 'reset':
                    this.sortByTmp = 'name_th'
                    this.orderByTmp = this.orderByConst.ASC
                    break
                case 'cancel':
                    this.close()
                    break
                case 'apply':
                    this.$emit('sorting', { by: this.sortByTmp, order: this.orderByTmp })
                    this.close()
                    break
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sortby__content {
    padding: 16px 32px;
    @include responsive(mobile) {
        padding: 16px;
    }
}
.orderby__content {
    padding: 0px 32px 16px 32px;
    @include responsive(mobile) {
        padding: 0px 16px 16px 16px;
    }
}
.dropdown__container {
    margin-bottom: -10px;
    width: 350px;
    font-family: DBHeaventRez !important;
    font-size: 16px !important;
    @include responsive(mobile) {
        width: 100%;
        max-width: 245px;
    }
}
</style>
