<template>
    <div class="display__status-container">
        <div class="display__status-container header font-bold radius__border--normal">
            <i class="fas fa-exclamation-circle mr-2 normal__text--red size__font--16px"></i>
            บัญชีถูกอายัด
        </div>
        <div v-if="isPartner" class="display__status-container body radius__border--normal">
            หากมีข้อสงสัย กรุณาติดต่อ Kept help center ได้ที่ <b>02-296-6299</b> หรือ Live chat ผ่านแอบ Kept
        </div>
        <div v-else class="display__status-container body radius__border--normal">
            หากมีข้อสงสัย กรุณาติดต่อ <b>02-026-5100</b> หรือ LINE <b>@FINNOMENAPORT</b>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('customer', ['getAdvisors']),
        isPartner() {
            return this.getAdvisors.length ? this.getAdvisors[0].isPartner : false
        }
    }
}
</script>

<style lang="scss" scoped>
.display__status-container {
    background-color: #fff;

    box-sizing: border-box;

    &.header {
        border-radius: 8px 8px 0 0;

        padding: 20px;

        background-color: rgba(224, 61, 59, 0.1);
    }

    &.body {
        border-radius: 0 0 8px 8px;
        border-top: none;

        padding: 32px 48px;

        background-color: rgba(224, 61, 59, 0.1);
    }
}
</style>
