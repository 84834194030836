<template>
    <div v-if="completedData" class="list__container">
        <span class="size__font--16px normal__text--primary">{{ title }} </span
        ><span v-if="validation.show" class="normal__text--red">{{ validation.msg }}</span>
        <div class="row__container item__align--center">
            <input-custom
                type="text"
                :value="min"
                :icon="icons[0]"
                :validation="{ show: validation.show, msg: '' }"
                :number-format="true"
                :allow-negative-number="allowNegativeNumber"
                @value="value => updateValue(value, max)"
            />
            <span class="horizon__margin--normal"> to </span>
            <input-custom
                type="text"
                :value="max"
                :icon="icons[1]"
                :validation="{ show: validation.show, msg: '' }"
                :number-format="true"
                :allow-negative-number="allowNegativeNumber"
                @value="value => updateValue(min, value)"
            />
        </div>
    </div>
</template>

<script>
import { iconListValidatior, validationValidator } from '../../../store/modules/general/validator'
import { InputCustom } from '../../atoms'

export default {
    components: {
        InputCustom
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        min: {
            type: String,
            required: true
        },
        max: {
            type: String,
            required: true
        },
        icons: {
            type: Array,
            required: false,
            default() {
                return [
                    { isIcon: true, icon: '' },
                    { isIcon: true, icon: '' }
                ]
            },
            validator: iconListValidatior
        },
        validation: {
            type: Object,
            required: false,
            default() {
                return {
                    show: false,
                    msg: ''
                }
            },
            validator: validationValidator
        },
        allowNegativeNumber: {
            type: Boolean,
            required: false,
            default: false
        },
        dataAlias: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            completedData: false
        }
    },
    mounted() {
        this.completedData = true
    },
    methods: {
        updateValue(min, max) {
            this.$emit('range', [min, max])
        }
    }
}
</script>
