<template>
    <div class="general__modal">
        <title-modal :class="{ 'element--disable': loading }" :title="'New Lead Request'" @close="close" />
        <div
            :class="[
                'horizon__padding--big',
                'top__padding--big',
                'position--relative',
                { 'element--disable': loading }
            ]"
        >
            <span v-if="leadStatus.status === false" id="error-msg-lead-request" class="normal__text--red">
                <i :class="iconsConst.EXCLAM_TRI"></i>
                {{ leadStatus.msg }}
            </span>
            <div class="list__container bottom__margin--big">
                <span>
                    Port ID to Request
                    <spinner v-if="searchingCustomerInfo" />
                </span>
                <div class="search--primary">
                    <cleave
                        v-model="agentAccountID"
                        class="form__leadrequest input__primary--normal"
                        :disabled="searchingCustomerInfo"
                        :options="patternAgentAccountID"
                    />
                </div>
            </div>
            <input-custom
                class="bottom__margin--big form__leadrequest"
                type="text"
                title="Customer Name"
                :value="customerInfo.customer_name"
                :disable="true"
                :validation="warningMsg"
            />
            <text-area-custom
                class="bottom__margin--big"
                title="Note"
                :resize="false"
                rows="3"
                maxlength="500"
                @value="val => (note = val)"
            />
        </div>
        <div
            class="row__container top__border--solid item__align--center vertical__padding--normal horizon__padding--big"
        >
            <v-spacer />
            <spinner v-if="loading" />
            <div :class="{ 'element--disable': loading }">
                <button
                    class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small"
                    @click="close"
                >
                    Cancel
                </button>
                <button
                    :class="[
                        'yellow__btn--normal',
                        'vertical__padding--small',
                        'horizon_padding--normal',
                        'font-bold',
                        { 'element--disable': !checkCanCreateLeadRequest }
                    ]"
                    @click="saveLeadRequest"
                >
                    Create
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { leadRequestMessages } from '../../../store/modules/leadRequest/config'
import { TitleModal } from '../../molecules'
import { InputCustom, TextAreaCustom, Spinner } from '../../atoms'
import { iconsConst } from '../../../store/utill'
import Cleave from 'vue-cleave-component'
export default {
    components: {
        Cleave,
        InputCustom,
        TextAreaCustom,
        TitleModal,
        Spinner
    },
    props: {
        customerInfo: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },
        leadStatus: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            leadRequestMessages,
            iconsConst,
            patternAgentAccountID: {
                blocks: [12],
                numericOnly: true
            },
            agentAccountID: '',
            latestAgentAccountIDSearch: '',
            note: '',
            timeout: null,
            delayTime: 500,
            searchingCustomerInfo: false,
            loading: false,
            action: {
                SEARCH: 'search',
                CREATE: 'create'
            }
        }
    },
    computed: {
        warningMsg() {
            let warning = {
                show: false,
                msg: ''
            }
            if (this.customerInfo.warning_message) {
                warning.show = true
                warning.msg = this.customerInfo.warning_message
            }
            return warning
        },
        checkCanCreateLeadRequest() {
            if (
                this.customerInfo &&
                this.customerInfo.customer_name &&
                this.customerInfo.customer_code &&
                !this.customerInfo.warning_message
            ) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        agentAccountID(val) {
            this.delayTimeBeforeSearch(val)
            if (this.customerInfo.customer_name) {
                this.clearCustomerInfo()
            }
        },
        leadStatus(val) {
            this.actionLeadStatusResponse(val)
        }
    },
    destroyed() {
        document.body.style.overflowY = 'auto'
    },
    mounted() {
        document.body.style.overflowY = 'hidden'
    },
    methods: {
        close() {
            this.$emit('close')
        },
        clearCustomerInfo() {
            this.$emit('clearCustomerInfo')
        },
        delayTimeBeforeSearch(val) {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            if (val.length == 12) {
                this.timeout = setTimeout(async () => {
                    this.searchingCustomerInfo = true
                    await this.searchCustomerByAgentAccountID(val)
                    this.latestAgentAccountIDSearch = val
                }, this.delayTime)
            }
        },
        searchCustomerByAgentAccountID(portId) {
            this.$emit('searchLeadRequest', portId)
        },
        saveLeadRequest() {
            this.loading = true
            if (this.checkCanCreateLeadRequest) {
                let reqBody = {
                    customer_code: this.customerInfo.customer_code,
                    agent_account_id: this.latestAgentAccountIDSearch,
                    note: this.note
                }
                this.$emit('saveLeadRequest', reqBody)
            } else {
                this.loading = false
                alert(this.leadRequestMessages.CREATE_FAILED)
            }
        },
        actionLeadStatusResponse(val) {
            if (typeof val === 'object') {
                switch (val.action) {
                    case this.action.CREATE:
                        this.loading = false
                        if (val.status === true) {
                            this.close()
                        }
                        break
                    case this.action.SEARCH:
                        this.searchingCustomerInfo = false
                        break
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#error-msg-lead-request {
    position: absolute;
    left: 32px;
    top: 10px;
}
.form__leadrequest {
    width: 240px;
    @include responsive(mobile) {
        width: 100%;
    }
}
</style>
