<template>
    <div>
        <table class="table__secondary--normal">
            <thead class="head__table--primary bottom__border--solid">
                <tr>
                    <th class="first__column"></th>
                    <th class="horizon__align--left">Bank</th>
                    <th class="horizon__align--left">Branch</th>
                    <th class="horizon__align--left display__mobile--none">Account No.</th>
                    <!-- LUMN-123 | hide primary column on bank table -->
                    <!-- <th class="horizon__align--left display__mobile--none">Primary</th> -->
                </tr>
            </thead>
            <tbody>
                <template v-for="(row, idx) in bankList">
                    <tr :key="`bank-row-${idx}`" class="bottom__border--solid border__mobile--none">
                        <td class="first__column">
                            <span class="normal__text--primary">{{ idx + 1 }}</span>
                        </td>
                        <td class="horizon__align--left">
                            <span class="normal__text--primary">{{ row.bank }}</span>
                        </td>
                        <td class="horizon__align--left">
                            <span class="normal__text--primary">{{ row.branch }}</span>
                        </td>
                        <td class="horizon__align--left display__mobile--none">
                            <span class="normal__text--primary">{{ row.account_no }}</span>
                        </td>
                        <!-- LUMN-123 | hide primary column on bank table -->
                        <!-- <td class="horizon__align--left display__mobile--none">
                            <span v-if="row.primary" class="normal__text--primary font-bold">Primary</span>
                            <span v-else class="normal__text--secondary">No</span>
                        </td> -->
                    </tr>
                    <tr
                        :key="`bank-row-responsive-${idx}`"
                        class="display__phablet--none morethan bottom__border--solid"
                    >
                        <td :colspan="5">
                            <div class="list__container">
                                <table-responsive-display :title="'Account No.:'" :value="row.account_no" />
                                <!-- LUMN-123 | hide primary column on bank table -->
                                <!-- <div>
                                    <span class="font-bold topic__text--primary right__margin--small">Primary:</span>
                                    <span v-if="row.primary" class="normal__text--primary font-bold">Primary</span>
                                    <span v-else class="normal__text--secondary">No</span>
                                </div> -->
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { bankListValidator } from '../../../store/modules/customer/validator'
import { TableResponsiveDisplay } from '../../atoms'

export default {
    components: {
        TableResponsiveDisplay
    },
    props: {
        bankList: {
            type: Array,
            required: true,
            validator: bankListValidator
        }
    }
}
</script>

<style lang="scss" scoped>
.border__mobile--none {
    @include responsive(mobile) {
        border-bottom: none !important;
    }
}
</style>
