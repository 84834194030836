<template>
    <div class="pra__status--wrapper">
        <no-adjustment
            v-if="
                praDisplayStatus == 'OPTIONAL' ||
                    praDisplayStatus == 'OPTIONAL_AND_TOPUP' ||
                    praDisplayStatus == 'OPTIONAL_AND_INVALID_MIN'
            "
        />
        <ready-to-invest v-if="praDisplayStatus == 'READY_TO_INVEST'" />
        <match v-if="praDisplayStatus == 'MATCH'" />
        <need-adjustment
            v-if="praDisplayStatus == 'NEED_ADJUSTMENT'"
            :pra-list="getPraList()"
            :total-amount="getTotalAmount()"
            :time-stamp="getTotalAmountTimeStamp()"
        />
        <need-adjustment-and-top-up
            v-if="praDisplayStatus == 'NEED_ADJUSTMENT_AND_TOPUP'"
            :pra-list="getPraList()"
            :total-amount="getTotalAmount()"
            :time-stamp="getTotalAmountTimeStamp()"
            :bank-account="getBankAccount()"
            :top-up-amount="getTopUpAmount()"
        />
        <on-process v-if="praDisplayStatus == 'ONPROCESS'" />
        <need-adjustment-and-invalid-min v-if="praDisplayStatus == 'NEED_ADJUSTMENT_AND_INVALID_MIN'" />
        <need-top-up v-if="praDisplayStatus == 'NEED_TOPUP'" :min-invest="getMinInvest()" />
        <need-checkup v-if="praDisplayStatus == 'NEED_CHECKUP'" />
    </div>
</template>
<script>
import {
    ReadyToInvest,
    Match,
    NeedAdjustment,
    NeedAdjustmentAndTopUp,
    NeedAdjustmentAndInvalidMin,
    OnProcess,
    NeedCheckup,
    NeedTopUp,
    NoAdjustment
} from './PraStatus/index'

import { praCurrentValidator, praUpcomingValidator } from '../../../../store/modules/port/validator'
import { actionStatusKey } from '../../../../store/utill/config/constant'

export default {
    components: {
        ReadyToInvest,
        Match,
        NeedAdjustment,
        NeedAdjustmentAndTopUp,
        NeedAdjustmentAndInvalidMin,
        OnProcess,
        NeedCheckup,
        NeedTopUp,
        NoAdjustment
    },
    props: {
        current: {
            type: Object,
            required: true,
            validator: praCurrentValidator
        },
        upcoming: {
            type: Object,
            default: () => {},
            validator: praUpcomingValidator
        },
        activeTab: {
            type: String,
            required: true
        },
        tabs: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            actionStatus: actionStatusKey
        }
    },
    computed: {
        praDisplayStatus() {
            let praStatus = ''
            let displayStatus = ''
            if (this.activeTab == this.tabs.UPCOMING) {
                praStatus = this.upcoming.pra.status
                displayStatus = this.upcoming.pra.display_status
            } else if (this.activeTab == this.tabs.CURRENT) {
                praStatus = this.current.pra.status
                displayStatus = this.current.pra.display_status
            }

            if (praStatus == this.actionStatus.OPTIONAL_ADJUSTMENT && displayStatus == 'NEED_TOPUP') {
                displayStatus = 'OPTIONAL_AND_TOPUP'
            }

            return displayStatus
        }
    },
    mounted() {},
    methods: {
        getPraList() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.pra_list || []
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.pra_list || []
                    }
                    default: {
                        return []
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return []
            }
        },
        getTotalAmount() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.total_amount || 0
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.total_amount || 0
                    }
                    default: {
                        return 0
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return 0
            }
        },
        getTotalAmountTimeStamp() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.time_stamp || new Date()
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.time_stamp || new Date()
                    }
                    default: {
                        return new Date()
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return new Date()
            }
        },
        getApprovedDate() {
            try {
                if (this.upcoming && this.upcoming.investment_view && this.upcoming.investment_view.approved_at) {
                    return this.upcoming.investment_view.approved_at
                }
                return new Date()
            } catch (error) {
                console.error(new Date(), error)
                return new Date()
            }
        },
        getNoticeText() {
            switch (this.activeTab) {
                case this.tabs.UPCOMING: {
                    return "This is what the customer will see. Buttons are clickable on customer's site/app."
                }
                case this.tabs.CURRENT: {
                    return "This is what the customer is seeing. Buttons are clickable on customer's site/app."
                }
                default: {
                    return ''
                }
            }
        },
        getBankAccount() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.bank_account || { no: '', name: '' }
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.bank_account || { no: '', name: '' }
                    }
                    default: {
                        return { no: '', name: '' }
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return { no: '', name: '' }
            }
        },
        getTopUpAmount() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.topup_amount || 0
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.topup_amount || 0
                    }
                    default: {
                        return 0
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return 0
            }
        },
        getMinInvest() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.pra.order.min_invest || 0
                    }
                    case this.tabs.CURRENT: {
                        return this.current.pra.order.min_invest || 0
                    }
                    default: {
                        return 0
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return 0
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pra__status--wrapper {
    background-color: #fff;
}
</style>
