<template>
    <base-modal
        title="Approve?"
        :visible="visible"
        :close="closeModal"
        :reset="() => {}"
        :apply="() => {}"
        prefix-alias="approval_approve_all"
    >
        <div class="general__modal--content">
            Do you want to approve <b>{{ requestCount }}</b
            >?
        </div>
        <div class="row__container sortby__content top__border--solid vertical__padding--normal horizon__padding--big">
            <v-spacer />
            <button
                class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="yellow__btn--normal vertical__padding--small horizon_padding--normal font-bold"
                @click="$emit('approve')"
            >
                Approve All
            </button>
        </div>
    </base-modal>
</template>

<script>
import { BaseModal } from './index'
export default {
    components: {
        BaseModal
    },
    props: {
        visible: Boolean,
        requests: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    computed: {
        requestCount() {
            return `${this.requests.length} ${this.unitLabel}`
        },
        unitLabel() {
            return this.requests.length > 1 ? 'requests' : 'request'
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.general__modal {
    width: 416px;
    height: max-content;
    border-radius: 8px;
    border: 1px solid $greyPrimary;
    background: $white;
    @include responsive(mobile) {
        width: 100%;
        max-width: 320px;
    }
    .general__modal--content {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 59px;
        color: $textPrimary;
        font-size: 16px;
        min-height: 6rem;
    }
}
</style>
