import { orderByConst } from '../../../utill'

const buildRequestList = leadRequestList => {
    let list = []
    for (let request of leadRequestList) {
        list.push({
            customer_code: request.customer_code ? request.customer_code : '-',
            customer_name: request.customer_name ? request.customer_name : '-',
            agent_account_id: request.agent_account_id ? request.agent_account_id : '-',
            request_date: request.created_at,
            note: request.note ? request.note : '-',
            status: request.status,
            updated_at: request.updated_at ? request.updated_at : '-'
        })
    }
    return list
}

const getSortValueByKey = (a, b, key) => {
    switch (key) {
        case 'request_date':
            return { firstValue: a.request_date, secondValue: b.request_date }
        default:
            return { firstValue: a[key], secondValue: b[key] }
    }
}

const getVisibledLeadRequestList = (leadRequestList, { sorting, searchWord }) => {
    return leadRequestList.filter(current => searchFunc(current, searchWord)).sort((a, b) => sortFunc(a, b, sorting))
}

const searchFunc = (current, searchWord) => {
    return (
        current.customer_name.toUpperCase().search(searchWord.toUpperCase()) >= 0 &&
        current.agent_account_id.search(searchWord) >= 0
    )
}

const sortFunc = (a, b, sorting) => {
    let { firstValue, secondValue } = getSortValueByKey(a, b, sorting.by)
    if (firstValue < secondValue) {
        return sorting.order == orderByConst.ASC ? -1 : 1
    } else if (firstValue > secondValue) {
        return sorting.order == orderByConst.ASC ? 1 : -1
    }
}

export { buildRequestList, getVisibledLeadRequestList }
