<template>
    <v-layout text-center wrap class="list__container">
        <v-simple-table class="table__primary--normal wrap__content">
            <template>
                <thead>
                    <tr>
                        <th class="first__column"></th>
                        <th v-for="column in columns" :key="`column-${column.id}`">{{ column.text }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, rowIdx) in rows" :key="`row-${row.id}`">
                        <td class="first__column">{{ row.text }}</td>
                        <td
                            v-for="(column, columnIdx) in columns"
                            :key="`column-${column.id}`"
                            class="item__align--center"
                        >
                            <checkbox
                                :id="`checkbox-${prefix}-${rowIdx + 1}-${columnIdx + 1}`"
                                :show-label="false"
                                :can-edit="canEdit"
                                :item="{ checked: row.checked[columnIdx], text: '' }"
                                @change="boolean => changeSelected(boolean, row.id, columnIdx)"
                            />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-layout>
</template>

<script>
import { Checkbox } from '../../atoms'
import { checkboxTableColumnsValidator, checkboxTableRowsValidator } from '../../../store/modules/general/validator'

export default {
    components: {
        Checkbox
    },
    props: {
        prefix: {
            type: String,
            required: true
        },
        rows: {
            type: Array,
            required: true,
            validator: checkboxTableRowsValidator
        },
        columns: {
            type: Array,
            required: true,
            validator: checkboxTableColumnsValidator
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        changeSelected(boolean, rowId, columnIdx) {
            this.$emit('change', { boolean, rowId, columnIdx })
        }
    }
}
</script>
