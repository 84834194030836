<template>
    <v-container class="list__container round__block--primary">
        <title-box
            id="btn-more-advisor"
            :title="'Advisor'"
            :see-more-tab="seeMoreTab"
            :show-more="true"
            @seeMore="seeMore"
        />
        <div class="list__container">
            <span v-if="advisors.length == 0">0 advisor</span>
            <profile-display
                v-for="(advisor, idx) in advisors.slice(0, 2)"
                :key="`advisor-${idx + 1}`"
                :profile="advisor"
                :show-name="true"
                :show-tooltip="false"
                class="bottom__margin--small"
            />
            <div v-if="advisors.length > 2">
                <button class="topic__text--primary left__margin--big" @click="seeMore(seeMoreTab)">
                    {{ `+ ${advisors.length - 2} advisor(s)` }}
                </button>
            </div>
        </div>
    </v-container>
</template>
<script>
import { advisorsValidator } from '../../../store/modules/customer/validator'
import { ProfileDisplay } from '../../atoms'
import { TitleBox } from '../Title'

export default {
    components: {
        ProfileDisplay,
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        advisors: {
            type: Array,
            required: true,
            validator: advisorsValidator
        }
    },
    methods: {
        seeMore(tab) {
            this.$emit('seeMore', tab)
        }
    }
}
</script>
