<template>
    <article>
        <div class="background--full horizon__padding--normal">
            <div class="login__panel">
                <F4ALogo />
                <login class="vertical__margin--big" />
                <FARegisterNote />
            </div>
        </div>
    </article>
</template>

<script>
import { F4ALogo, FARegisterNote } from '../../atoms'
import { Login } from '../../organisms'

export default {
    components: {
        F4ALogo,
        FARegisterNote,
        Login
    }
}
</script>

<style lang="scss" scoped>
.background--full {
    display: flex;
    justify-content: center;
    padding-top: 10vh;
    padding-bottom: 30vh;
}
.login__panel {
    max-width: 416px !important;
    min-width: 288px !important;
    width: 100%;
}
</style>
