export const getters = {
    getCSRF(state) {
        return state.csrf
    },
    getUUID(state) {
        return state.uuid
    },
    getUserData(state) {
        return state.userData
    },
    getPermissions(state) {
        return state.permissions
    },
    getPermissionsKey(state) {
        return state.permissionsKey
    }
}
