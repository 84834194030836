export const getters = {
    getCustList(state) {
        return state.custList
    },
    getBasicInfo(state) {
        return state.basicInfo
    },
    getAdvisors(state) {
        return state.advisors
    },
    getInfo(state) {
        return state.info
    },
    getInvest(state) {
        return state.invest
    },
    getBank(state) {
        return state.bank
    },
    getFavorite(state) {
        return state.favoriteStatus
    },
    getFunds(state) {
        return state.funds
    },
    getPlanTypes(state) {
        return state.planTypes
    },
    getLog(state) {
        return state.log
    },
    getPDPAStatus(state) {
        return state.pdpaStatus
    },
    getCRSStatus(state) {
        return state.CRSStatus
    },
    getCustDisability(state) {
        return state.custDisability
    },
    getAdvisorList(state) {
        return state.advisorList
    },
    getNote(state) {
        return state.note
    }
}
