import { dataValidator, keyValidator, quantification, type, typeValidator } from '../../../utill'

const contentValidator = content => {
    if (!keyValidator(content, ['type', 'text'])) {
        return false
    }
    if (
        !typeValidator([
            { value: content.type, type: type.STRING },
            { value: content.text, type: type.STRING }
        ])
    ) {
        return false
    }
    if (
        !dataValidator(quantification.SOME, [
            { key: content.type, value: 'image' },
            { key: content.type, value: 'text' }
        ])
    ) {
        return false
    }
    return true
}

export { contentValidator }
