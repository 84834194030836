import { getAbbrName } from '../../../utill'

const getAdvisors = advisors => {
    let advisorList = []
    for (let advisor of advisors) {
        advisorList.push({
            abbr: getAbbrName(`${advisor.first_name_th} ${advisor.last_name_th}`),
            full: `${advisor.first_name_th} ${advisor.last_name_th}`,
            profile_picture: advisor.profile_picture ? advisor.profile_picture : {},
            display_circle: true,
            isPartner: advisor.partner ? true : false,
            identifier: advisor.identifier
        })
    }
    return advisorList
}

export { getAdvisors }
