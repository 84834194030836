<template>
    <div class="width__table">
        <table class="table__secondary--normal">
            <thead>
                <tr class="head__table--primary bottom__border--solid">
                    <th></th>
                    <th class="horizon__align--left display__mobile--none">ชื่อ-นามสกุล</th>
                    <th class="horizon__align--left display__mobile--none">เลขบัตรปชช./Passport</th>
                    <th class="horizon__align--left display__phablet--none lessthan">ประเทศ</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(row, idx) in familyList">
                    <tr :key="`row-${idx}`" class="bottom__border--solid border__phablet--none">
                        <td>
                            <span class="normal__text--primary font-bold">{{ row.relation }}</span>
                        </td>
                        <td class="horizon__align--left display__mobile--none">
                            <span class="normal__text--primary">{{ row.full_name }}</span>
                        </td>
                        <td class="horizon__align--left display__mobile--none">
                            <span class="normal__text--primary">{{ row.personal_id }}</span>
                        </td>
                        <td class="horizon__align--left display__phablet--none lessthan">
                            <span class="normal__text--primary">{{ row.country }}</span>
                        </td>
                    </tr>
                    <!-- Row for responsive -->
                    <tr :key="`row-responsive-${idx}`" class="bottom__border--solid display__tablet--none morethan">
                        <td colspan="3">
                            <table-responsive-display
                                class="display__phablet--none morethan"
                                :title="'ชื่อ-นามสกุล:'"
                                :value="row.full_name"
                            />
                            <table-responsive-display
                                class="display__phablet--none morethan"
                                :title="'เลขบัตรปชช./Passport: '"
                                :value="row.personal_id"
                            />
                            <table-responsive-display :title="'ประเทศ: '" :value="row.country" />
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { familyListValidator } from '../../../store/modules/customer/validator'
import { TableResponsiveDisplay } from '../../atoms'

export default {
    components: {
        TableResponsiveDisplay
    },
    props: {
        familyList: {
            type: Array,
            required: true,
            validator: familyListValidator
        }
    }
}
</script>

<style lang="scss" scoped>
.width__table {
    width: 100%;
    max-width: 710px;
    margin: 0;
    padding: 0;
}
.border__phablet--none {
    @include multiple-responsive(mobile, phablet) {
        border-bottom: none !important;
    }
}
</style>
