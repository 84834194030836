import { UnleashClient } from 'unleash-proxy-client'

const state = {
    unleashClient: undefined,
    updateTimestamp: new Date().valueOf()
}

export default {
    namespaced: true,
    state,
    getters: {
        getClient(state) {
            return state.unleashClient
        },
        getTimestamp(state) {
            return state.updateTimestamp
        }
    },
    actions: {
        async initClient({ commit }, config) {
            const unleash = new UnleashClient(config)
            unleash.start()
            unleash.on('update', () => {
                commit('setTimestamp', new Date().valueOf())
            })
            await commit('setClient', unleash)
        },
        async stopClient({ state }) {
            state.unleashClient?.stop()
        },
        isEnabled({ state }, feature) {
            return state.unleashClient?.isEnabled(feature)
        },
        getVariant({ state }, feature) {
            return state.unleashClient?.getVariant(feature)
        },
        async triggerUpdate({ commit }) {
            await commit('setTimestamp', new Date().valueOf())
        }
    },
    mutations: {
        setClient: (state, data) => {
            state.unleashClient = data
        },
        setTimestamp: (state, timestamp) => {
            state.updateTimestamp = timestamp
        }
    }
}
