<template>
    <v-container class="list__container round__block--primary">
        <title-box id="btn-more-investment" :title="'Plan Settings'" :see-more-tab="seeMoreTab" :show-more="showMore" />
        <div
            v-if="planSetting.target > -1"
            class="row__container item__align--center bottom__margin--small wrap__content"
        >
            <span class="topic__text--primary">Target: </span
            ><span class="normal__text--primary font-bold">
                {{ getNumberFormat(planSetting.target, false, 0, '฿') }}
            </span>
        </div>
        <div
            v-if="planSetting.target_duration > -1"
            class="row__container item__align--center bottom__margin--small wrap__content"
        >
            <span class="topic__text--primary">Target Duration: </span>
            <span class="normal__text--primary font-bold">
                {{
                    getNumberFormat(
                        planSetting.target_duration,
                        false,
                        0,
                        planSetting.target_duration > 1 ? 'Years' : 'Year'
                    )
                }}
            </span>
        </div>
        <div class="row__container item__align--center bottom__margin--small wrap__content">
            <span class="topic__text--primary">Planned First Invest: </span>
            <span class="normal__text--primary font-bold">
                {{
                    planSetting.planned_first_invest > -1
                        ? getNumberFormat(planSetting.planned_first_invest, false, 0, '฿')
                        : '-'
                }}
            </span>
        </div>
        <div
            v-if="planSetting.planned_monthly_dca > -1"
            class="row__container item__align--center bottom__margin--small wrap__content"
        >
            <span class="topic__text--primary">Planned Monthly DCA: </span>
            <span class="normal__text--primary font-bold">
                {{ getNumberFormat(planSetting.planned_monthly_dca, false, 0, '฿') }}
            </span>
        </div>
        <div
            v-if="planSetting.risk_level > -1"
            class="row__container item__align--center bottom__margin--small wrap__content"
        >
            <span class="topic__text--primary">Risk Level: </span>
            <span class="normal__text--primary font-bold">{{ planSetting.risk_level }}</span>
        </div>
    </v-container>
</template>
<script>
import { getNumberFormat } from '../../../store/utill'
import { planSettingValidator } from '../../../store/modules/port/validator'
import { TitleBox } from '../Title'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        planSetting: {
            type: Object,
            required: true,
            validator: planSettingValidator
        }
    },
    methods: {
        getNumberFormat
    }
}
</script>
