<template>
    <div :class="`fancy__button-container ${className}`">
        <button
            class="fancy__button button-token-properties"
            :disabled="disabled"
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
            @click="click"
        >
            <slot name="prefixIcon" :isHover="isHover" />
            <span>
                <slot :isHover="isHover" />
            </span>
            <slot name="suffixIcon" :isHover="isHover" />
        </button>
    </div>
</template>

<script>
export default {
    props: {
        className: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            isHover: false
        }
    },
    methods: {
        mouseEnter() {
            this.isHover = true
        },
        mouseLeave() {
            this.isHover = false
        },
        click() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
.button-token-properties {
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    height: 2.5rem;
    /* antd transition */
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;

    & > i {
        /* temporary fix for icon size */
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;

        /* compensate for span animtion stagging */
        transition-delay: -0.3s;
    }

    & > span {
        margin: 0 0.25rem;
        display: inline-block;
    }
}

.button-token-properties:not(:has(span)) {
    padding: 0.5rem;
    height: calc(0.5rem + 0.5rem + 1.5em);
}

.fancy__button,
.fancy__button:focus {
    color: #f7a501;
    background-color: #fff;
    box-shadow: none;
}

.fancy__button:hover {
    color: #f7a501;
    background-color: #fdf5df;
}

.fancy__button:active {
    color: #c98502;
    background-color: #fceab7;
}

.fancy__button:disabled,
.fancy__button:disabled:hover {
    color: #f9cf9a;
    background-color: #fff;
}

.fancy__button-container {
    background: linear-gradient(
        45deg,
        #c88500,
        #f7a501,
        #fdbb5e,
        #bddbfb,
        #55a9ff,
        #55a9ff,
        #bddbfb,
        #fdbb5e,
        #f7a501,
        #c88500
    );
    padding: 1px;
    animation: glowing 4s linear infinite;
    background-size: 400%;
    border-radius: calc(0.5rem + 1px);
    box-shadow: 2px 4px 16px 0 rgba(0 0 0 /10%);
}

.fancy__button-container:has(*:disabled) {
    animation-play-state: paused;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}
</style>
