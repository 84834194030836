import { type, keyValidator, typeValidator } from '../../../utill'

const distributionListValidator = distributionList => {
    for (let distribution of distributionList) {
        if (!typeValidator([{ value: distribution, type: type.OBJECT }])) {
            return false
        }
        if (
            !keyValidator(distribution, [
                'fund_name',
                'order_id',
                'order_type',
                'amount',
                'unit',
                'nav',
                'trade_date',
                'payment_date',
                'book_closing_date',
                'payment_method',
                'bank_account'
            ])
        ) {
            return false
        }
        if (
            !typeValidator([
                { value: distribution.fund_name, type: type.STRING },
                { value: distribution.order_id, type: type.STRING },
                { value: distribution.order_type, type: type.STRING },
                { value: distribution.amount, type: type.STRING },
                { value: distribution.unit, type: type.STRING },
                { value: distribution.nav, type: type.STRING },
                { value: distribution.trade_date, type: type.STRING },
                { value: distribution.payment_date, type: type.STRING },
                { value: distribution.book_closing_date, type: type.STRING },
                { value: distribution.payment_method, type: type.STRING },
                { value: distribution.bank_account, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

export { distributionListValidator }
