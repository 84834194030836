<template>
    <div
        class="background--normal__grey border--blue horizon__padding--normal vertical__padding--small position--relative row__container item__align--center bottom__margin--normal wrap__content normal--text__primary"
    >
        <span class="font-bold right__margin--small">{{ title }}</span>
        <span> {{ value }}</span>
        <i
            :class="iconsConst.CROSS"
            class="big__text--blue clear__button--absolute pointer__cursor"
            @click="clear"
        />
    </div>
</template>

<script>
import { iconsConst } from '../../../store/utill'

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        value: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            iconsConst
        }
    },
    methods: {
        clear() {
            this.$emit('clear')
        }
    }
}
</script>

<style lang="scss" scoped>
.clear__button--absolute {
    position: absolute;
    right: 16px;
}
</style>
