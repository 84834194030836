import { type, keyValidator, typeValidator } from '../../../utill'

const categoriesValidator = categories => {
    for (let category of categories) {
        if (!typeValidator([{ value: category, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(category, ['id', 'name', 'percent'])) {
            return false
        }
        if (
            !typeValidator([
                { value: category.id, type: type.NUMBER },
                { value: category.name, type: type.STRING },
                { value: category.percent, type: type.NUMBER }
            ])
        ) {
            return false
        }
    }
    return true
}

const modelListValidator = modelList => {
    for (let model of modelList) {
        if (!typeValidator([{ value: model, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(model, ['category_color', 'fund', 'first_invest', 'monthly', 'percent'])) {
            return false
        }
        if (
            !typeValidator([
                { value: model.category_color, type: type.STRING },
                { value: model.fund, type: type.STRING },
                { value: model.first_invest, type: type.NUMBER },
                { value: model.percent, type: type.NUMBER }
            ])
        ) {
            return false
        }
    }
    return true
}

export { categoriesValidator, modelListValidator }
