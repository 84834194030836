<template>
    <div class="list__container width__list horizon__margin--normal">
        <div
            v-for="(attr, idx) in attrList"
            :key="`attr-${idx}`"
            class="row__container attr__list"
            :class="{ 'bottom__border--solid': attr.topic != 'empty' && attr.value }"
        >
            <div
                v-if="(attr.value == 'RetryANEO' || attr.value == 'ยังไม่เคยทำ') && hasANEO() == 0"
                class="attr__topic normal__text--secondary whitespace-pre"
            >
                {{ attr.topic }}
            </div>
            <span
                v-else
                class="attr__topic"
                :class="attr.value || attr.value == 0 ? 'normal__text--secondary' : 'normal__text--primary font-bold'"
            >
                {{ attr.topic }}
            </span>
            <v-spacer />
            <span class="normal__text--primary horizon__align--right items-end">
                <v-tooltip top color="#333" max-width="320px" content-class="tooltip-top">
                    <template v-slot:activator="{ on }">
                        <span
                            v-if="attr.value == 'Allow' || getPDPAValueFromStore(attr.topic, attr.value) == 'Allow'"
                            class="btn__primary link"
                            v-on="on"
                        >
                            {{ getPDPAValueFromStore(attr.topic, attr.value) }}
                        </span>
                        <span
                            v-else-if="
                                attr.value == 'Not Allow' ||
                                    getPDPAValueFromStore(attr.topic, attr.value) == 'Not Allow'
                            "
                            class="btn__red--normal link"
                            v-on="on"
                        >
                            {{ getPDPAValueFromStore(attr.topic, attr.value) }}
                        </span>
                        <span v-else-if="attr.value == 'Retry'" class="normal__text--secondary">
                            <i :class="iconsConst.SOLID_EXCLAM_CIRCLE"></i>
                            <span>Error </span>
                            <span class="btn__grey--normal" @click="retry('PDPA')">(Retry)</span>
                        </span>
                        <span v-else-if="attr.value == 'RetryCRS'">
                            <div v-if="!hasCRS()" class="normal__text--secondary">
                                <i :class="iconsConst.SOLID_EXCLAM_CIRCLE"></i>
                                <span>Error </span>
                                <span class="btn__grey--normal" @click="retry('CRS')">(Retry)</span>
                            </div>
                            <div v-else>
                                {{ getCRSValueFromStore(attr.topic) }}
                            </div>
                        </span>
                        <span v-else-if="attr.value == 'RetryANEO'" class="normal__text--secondary">
                            <div v-if="!hasANEO()" class="normal__text--secondary">
                                <i :class="iconsConst.SOLID_EXCLAM_CIRCLE"></i>
                                <span>Error </span>
                                <span class="btn__grey--normal" @click="retry('ANEO')">(Retry)</span>
                            </div>
                            <div v-else>{{ getANEOValueFromStore(attr.topic) }}</div>
                        </span>
                        <span v-else-if="attr.topic == 'Knowledge Assessment'" class="normal__text--secondary">
                            <span
                                v-if="getKnowledgeAssessmentValue() == 'View'"
                                class="btn__primary link"
                                @click="toggleKnowledgeAssessmentModal"
                            >
                                View
                            </span>
                            <span v-else>{{ getKnowledgeAssessmentValue() }}</span>
                        </span>
                        <span v-else> {{ attr.value }} </span>
                    </template>
                    <span class="tooltiptext">{{ getTooltipMsg(attr.topic, attr.value) }}</span>
                </v-tooltip>
            </span>
        </div>
    </div>
</template>
<script>
import { attributeListValidator } from '../../../store/modules/general/validator'
import { iconsConst } from '../../../store/utill'
import { mapGetters } from 'vuex'
import { getInfoText } from '../../../store/modules/customer/config'
import { RetryError } from '../../../store/modules/customer/config/constant'

export default {
    props: {
        attrList: {
            type: Array,
            required: true,
            validator: attributeListValidator
        }
    },
    data() {
        return {
            showTooltip: false,
            isReloadPDPAStatus: false,
            iconsConst
        }
    },
    computed: {
        ...mapGetters('customer', ['getPDPAStatus', 'getInfo', 'getCRSStatus', 'getCustDisability'])
    },
    methods: {
        getTooltipMsg(topic, value) {
            switch (topic) {
                case 'Contact (phone)':
                    return value == 'Allow'
                        ? 'The investor ALLOW FINNOMENA to contact them via a phone call regarding marketing offer.'
                        : 'The investor does NOT allow FINNOMENA to contact them via a phone call regarding marketing offer.'
                case 'Contact (email & notification)':
                    return value == 'Allow'
                        ? 'The investor ALLOW FINNOMENA to contact them via an email or notification regarding marketing offer.'
                        : 'The investor does NOT allow FINNOMENA to contact them via an email or notification regarding marketing offer.'
                case 'Data Collection':
                    return value == 'Allow'
                        ? 'The investor does ALLOW FINNOMENA to collect and process their personal data.'
                        : 'The investor does NOT allow FINNOMENA to collect and process their personal data.'
                default:
                    return ''
            }
        },
        getPDPAValueFromStore(topic, oldValue) {
            if (this.isReloadPDPAStatus) {
                switch (topic) {
                    case 'Contact (phone)':
                        return getInfoText('pdpa_call_status', this.getPDPAStatus.pdpa_call_status)
                    case 'Contact (email & notification)':
                        return getInfoText('pdpa_promotion_status', this.getPDPAStatus.pdpa_promotion_status)
                    case 'Data Collection':
                        return getInfoText('pdpa_analytic_status', this.getPDPAStatus.pdpa_analytic_status)
                    default:
                        return ''
                }
            }
            return oldValue
        },
        getCRSValueFromStore(topic) {
            switch (topic) {
                case 'สถานะ CRS':
                    return getInfoText('crs_status', this.getCRSStatus.crs_status)
                case 'วันหมดอายุ':
                    return getInfoText('crs_expiry_date', this.getCRSStatus.crs_expiry_date)
                default:
                    return '-'
            }
        },
        hasCRS() {
            return Object.keys(this.getCRSStatus).length
        },
        getANEOValueFromStore(topic) {
            switch (topic) {
                case 'มีความรู้ทางการเงินหรือ \nมีประสบการณ์การลงทุน':
                    return getInfoText('is_ka_accept_result', this.getCustDisability.is_ka_accept_result)
                case 'ผู้พิการทางสายตา':
                    return getInfoText('is_visually_impaired', this.getCustDisability.is_visually_impaired)
                case 'ผู้พิการทางการได้ยิน':
                    return getInfoText('is_hearing_impaired', this.getCustDisability.is_hearing_impaired)
                case 'อายุตั้งแต่ 60 ปีขึ้นไป':
                    return getInfoText('is_age_over_60', this.getCustDisability.is_age_over_60)
                default:
                    return '-'
            }
        },
        hasANEO() {
            return Object.keys(this.getCustDisability).length
        },
        async retry(topic) {
            switch (topic) {
                case RetryError.PDPA:
                    this.isReloadPDPAStatus = true
                    return this.$emit('reload', topic)
                case RetryError.CRS:
                    return this.$emit('reload', topic)
                case RetryError.ANEO:
                    return this.$emit('reload', topic)
            }
        },
        toggleKnowledgeAssessmentModal() {
            this.$emit('toggleKnowledgeAssessmentModal')
        },
        getKnowledgeAssessmentValue() {
            return getInfoText('knowledge_assessment', this.getInfo?.knowledge_assessment_v2?.knowledge_choices)
        }
    }
}
</script>
<style lang="scss" scoped>
.width__list {
    width: 340px;
    @include responsive(mobile) {
        width: 100%;
        max-width: 470px;
    }
    @include responsive(phablet) {
        width: 100%;
        max-width: 760px;
    }
    @include responsive(tablet) {
        width: 100%;
        max-width: 330px;
    }
}
.attr__list {
    min-height: 37px;
    height: auto;
    padding: 10px 0px 5px 0px;
}
.attr__topic {
    width: auto;
}

.whitespace-pre {
    white-space: pre;
}
</style>
