<template>
    <base-modal
        class=""
        title="Action Information"
        :visible="visible"
        :close="closeModal"
        :clear-padding-content="true"
        :width="480"
    >
        <div class="general__modal--content horizon__padding--big top__padding--big">
            <p class="normal__text--primary mb-5">
                Action คือ การแสดงสถานะและคำแนะนำเกี่ยวกับพอร์ต สำหรับที่ปรึกษาการลงทุนและผู้ลงทุนนำไปดำเนินการต่อ
            </p>
            <template v-for="(data, index) in actionList">
                <div :key="`action-info-${index}`" class="row__container mb-5 normal__text--primary">
                    <div class="action-status">
                        <pra-action :pra-status="data.action" />
                    </div>
                    <div>
                        <p class="font-bold mb-0">{{ data.title }}</p>
                        <p class="mb-0">{{ data.description }}</p>
                    </div>
                </div>
            </template>
        </div>
        <div class="row__container sortby__content top__border--solid vertical__padding--normal horizon__padding--big">
            <button
                class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold full__width"
                @click="closeModal"
            >
                Close
            </button>
        </div>
    </base-modal>
</template>

<script>
import { BaseModal } from './index'
import { PraAction } from '../../atoms'
import { actionStatusKey } from '../../../store/utill'
export default {
    components: {
        BaseModal,
        PraAction
    },
    props: {
        visible: Boolean
    },
    data() {
        return {
            actionList: [
                {
                    action: actionStatusKey.NEED_ADJUSTMENT,
                    title: 'Need PRA',
                    description: 'มีรายการปรับพอร์ต'
                },
                // {
                //     action: actionStatusKey.NEED_TOPUP_MIN_INVEST,
                //     title: 'Need Top-up',
                //     description: 'มีรายการปรับพอร์ต และต้องเพิ่มเงินลงทุน'
                // },
                {
                    action: actionStatusKey.NEED_CHECKUP,
                    title: 'Need Checkup',
                    description: 'ที่ปรึกษาเช็คพอร์ต GNAVI ให้ตรงเป้าหมาย'
                },
                // {
                //     action: actionStatusKey.PENDING_ORDER,
                //     title: 'Await Pending Orders',
                //     description: 'มีคำสั่งซื้อขายอยู่ระหว่างดำเนินการ อาจมีรายการปรับพอร์ตตามมา'
                // },
                {
                    action: '',
                    title: 'No Action Needed',
                    description: 'ยังไม่มีคำแนะนำสำหรับพอร์ตนี้'
                }
            ]
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style lang="scss" scoped>
.action-status {
    width: 100px;
    flex-shrink: 0;
}
</style>
