<template>
    <div v-if="visible" class="modal__container">
        <div class="backdrop" @click="close"></div>
        <div class="cross__amc-modal">
            <section class="row__container bottom__border--solid horizon__padding--medium vertical__padding--normal">
                <span class="big__text--greyPri"> {{ title }} </span>
                <v-spacer />
                <button class="big__text--greySec" @click="close">
                    <i :class="iconsConst.CROSS" />
                </button>
            </section>
            <div class="scroll__area">
                <section
                    class="grid__container--detail item__justify--between horizon__padding--medium vertical__padding--normal"
                >
                    <div class="list__container">
                        <div class="list__container">
                            <span class="normal__text--secondary" data-alias="cross_amc_modal-customer_name-title"
                                >Name</span
                            >
                            <link-anchor
                                :to="getCustomerUrl(customerInfo.customer_code)"
                                class-name="top__margin--small normal__text--highlight-primary font-bold dashed__underline-yellow"
                                :external="featureCustomerInfoV2Enabled"
                            >
                                {{ customerInfo.customer_name }}
                            </link-anchor>
                        </div>
                        <div class="list__container top__margin--normal">
                            <span class="normal__text--secondary" data-alias="cross_amc_modal-date_created-title"
                                >Date Created</span
                            >
                            <span class="top__margin--small">{{
                                getDateFormat(orders[0].transaction_date)
                            }}</span>
                        </div>
                        <div class="list__container top__margin--normal">
                            <span
                                class="normal__text--secondary bottom__margin--small"
                                >Order Type</span
                            >
                            <span class="item__align--start">
                                <order-badge
                                    text="Cross AMC Switch"
                                    color="#003fff"
                                    background-color="#f4f7f9"
                                />
                            </span>
                        </div>
                    </div>
                    <div class="list__container">
                        <div class="list__container">
                            <span class="normal__text--secondary">Port ID</span>
                            <link-anchor
                                :to="getPortUrl(customerInfo.port_id)"
                                class-name="top__margin--small normal__text--highlight-primary font-bold dashed__underline-yellow"
                                :external="false"
                            >
                                {{ customerInfo.port_id }}
                            </link-anchor>
                        </div>
                        <div class="list__container top__margin--normal">
                            <span class="normal__text--secondary" data-alias="cross_amc_modal-advisor-title"
                                >Advisor</span
                            >
                            <div class="row__container item__align--center top__margin--small">
                                <profile-display
                                    :profile="advisor[0]"
                                    :show-name="false"
                                    :show-tooltip="false"
                                    :is-icon="true"
                                    class="right__margin--small"
                                />
                                <span>{{
                                    advisor[0] ? advisor[0].full : '-'
                                }}</span>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <template v-for="(order, idx) in orders">
                        <div
                            :key="`order-${idx}`"
                            :class="
                                `list__container horizon__padding--medium ${order.order_type == orderType.SELL &&
                                    'top__padding--smaller'} bottom__padding--medium`
                            "
                        >
                            <div
                                :class="
                                    `row__container grid__container--order ${order.order_type == orderType.BUY &&
                                        'top__border--solid top__padding--normal'}`
                                "
                            >
                                <div class="list__container">
                                    <action-type
                                        :type="order.order_type"
                                        lang="en"
                                    />
                                    <span class="bottom__margin--smallest"></span>
                                    <external-link
                                        type="underline"
                                        :text="order.fund_name"
                                        @click.native="$emit('open-fund-tab', order.fund_name)"
                                    />
                                </div>
                                <div class="list__container display__mobile--none horizon__align--right">
                                    <span class="bottom__margin--smallest">{{ order.amount }}</span>
                                    <span>{{ order.unit }}</span>
                                </div>
                                <div class="row__container item__align--end">
                                    <order-badge
                                        v-if="order.status == 'Success'"
                                        :text="order.status"
                                        type="modal"
                                        color="#fff"
                                        background-color="#17c161"
                                    />
                                    <order-badge
                                        v-else-if="order.status == 'On Process' || order.status == 'Pending'"
                                        color="#999"
                                        type="modal"
                                        :text="order.status"
                                        background-color="#e6e6e6"
                                    />
                                    <order-badge
                                        v-else-if="order.status == 'Failed' || order.status == 'Cancelled'"
                                        color="#fff"
                                        type="modal"
                                        :text="order.status"
                                        background-color="#e03d3b"
                                    />
                                </div>
                            </div>
                            <div
                                class="row__container item__justify--between display__phablet--none morethan top__margin--small"
                            >
                                <div class="normal__text--secondary">Amount</div>
                                <div class="list__container horizon__align--right">
                                    <span class="bottom__margin--smallest">{{ order.amount }}</span>
                                    <span>{{ order.unit }}</span>
                                </div>
                            </div>
                            <div class="row__container top__margin--normal grey__background">
                                <div class="expand__row--responsive phablet list__container">
                                    <table-responsive-display
                                        title="Detailed Status:"
                                        :value="
                                            order.order_type == orderType.BUY
                                                ? buyOrderStatus.status_detail
                                                : order.status_detail
                                        "
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        v-if="order.order_type === orderType.SELL"
                                        title="Profit/Loss:"
                                        :value="
                                            order.realized_gl != null
                                                ? getNumberFormat(order.realized_gl, true, 2, '฿')
                                                : '-'
                                        "
                                        :value-color="getClassNameColorForProfit(order.realized_gl, 2)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Order ID:"
                                        :value="order.order_ref_no"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        :title="`NAV (${getDateFormat(order.allotment_date)}):`"
                                        :value="order.nav"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Order Source:"
                                        :value="order.order_source"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Transaction Date:"
                                        :value="getDateFormat(order.transaction_date, '', 'd mmm yy - hh:mm')"
                                        class="bottom__margin--smallest"
                                    />
                                    <div class="display__tablet--none morethan">
                                        <table-responsive-display
                                            title="Trade Date:"
                                            :value="getDateFormat(order.order_date)"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            title="Allotment Date:"
                                            :value="getDateFormat(order.allotment_date)"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            title="Payment Date:"
                                            :value="getDateFormat(order.payment_date)"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            title="Payment Method:"
                                            :value="order.payment_method"
                                            class="bottom__margin--smallest"
                                        />
                                        <table-responsive-display
                                            title="Bank Account:"
                                            :value="order.bank_account"
                                            class="bottom__margin--smallest"
                                        />
                                    </div>
                                </div>
                                <div class="expand__row--responsive list__container display__phablet--none lessthan">
                                    <table-responsive-display
                                        title="Trade Date:"
                                        :value="getDateFormat(order.order_date)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Allotment Date:"
                                        :value="getDateFormat(order.allotment_date)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Payment Date:"
                                        :value="getDateFormat(order.payment_date)"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Payment Method:"
                                        :value="order.payment_method"
                                        class="bottom__margin--smallest"
                                    />
                                    <table-responsive-display
                                        title="Bank Account:"
                                        :value="order.bank_account"
                                        class="bottom__margin--smallest"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="order.buy_status == 'Pending' || order.buy_status == 'Failed'"
                            :key="`order-buy-${idx}`"
                            class="horizon__margin--medium top__border--solid top__padding--normal"
                        >
                            <div
                                :class="
                                    order.buy_status == 'Failed'
                                        ? 'row__container item__justify--between'
                                        : 'grid__container--order'
                                "
                            >
                                <div class="list__container">
                                    <action-type
                                        :type="orderType.BUY"
                                        lang="en"
                                    />
                                    <span class="bottom__margin--smallest"></span>
                                    <div v-if="order.xwt_fund">
                                        <external-link
                                            type="underline"
                                            :text="order.xwt_fund"
                                            @click.native="$emit('open-fund-tab', order.xwt_fund)"
                                        />
                                    </div>
                                    <div v-else class="font-bold">-</div>
                                </div>
                                <div
                                    v-if="order.buy_status == 'Pending'"
                                    class="list__container display__mobile--none horizon__align--right badge__margin--right"
                                >
                                    <span class="bottom__margin--smallest">0 ฿</span>
                                    <span>- Unit</span>
                                </div>
                                <div class="row__container item__align--end">
                                    <order-badge
                                        v-if="order.buy_status == 'Success'"
                                        :text="order.buy_status"
                                        type="modal"
                                        color="#fff"
                                        background-color="#17c161"
                                    />
                                    <order-badge
                                        v-else-if="order.buy_status == 'Pending'"
                                        color="#999"
                                        type="modal"
                                        :text="order.buy_status"
                                        background-color="#e6e6e6"
                                    />
                                    <order-badge
                                        v-else-if="order.buy_status == 'Failed'"
                                        color="#fff"
                                        type="modal"
                                        :text="order.buy_status"
                                        background-color="#e03d3b"
                                    />
                                </div>
                            </div>
                            <div class="bottom__margin--big display__mobile--none"></div>
                            <div class="top__margin--small bottom__margin--big"></div>
                            <div
                                v-if="order.buy_status == 'Pending'"
                                class="row__container item__justify--between display__phablet--none morethan top__margin--small bottom__margin--big"
                            >
                                <div class="normal__text--secondary">Amount</div>
                                <div class="list__container horizon__align--right">
                                    <span class="bottom__margin--smallest">0 ฿</span>
                                    <span>- Unit</span>
                                </div>
                            </div>
                            <div
                                class="list__container horizon__padding--medium top__padding--smaller bottom__margin--big grey__background"
                            >
                                <table-responsive-display
                                    title="Detailed Status:"
                                    :value="order.buy_status_detail"
                                    class="bottom__margin--smallest"
                                />
                                <table-responsive-display
                                    v-if="order.xamc_error_desc"
                                    title="Reason:"
                                    :value="order.xamc_error_desc"
                                    class="bottom__margin--smallest"
                                />
                            </div>
                        </div>
                    </template>
                    <div class="bottom__margin--normal display__phablet--none morethan"></div>
                </section>
            </div>
            <section class="cross__amc-footer--container">
                <div
                    class="cross__amc-footer top__border--solid vertical__padding--normal horizon__padding--big horizon__align--right"
                >
                    <button
                        class="modal__close-btn grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small"
                        @click="close"
                    >
                        Close
                    </button>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TableResponsiveDisplay, OrderBadge, ActionType, ProfileDisplay, ExternalLink } from '../../atoms'
import { iconsConst, orderType, pagesConst } from '../../../store/utill/config'
import { getClassNameColorForProfit, getNumberFormat, getDateFormat } from '../../../store/utill'
import { advisorsValidator } from '../../../store/modules/customer/validator'
import LinkAnchor from '../../atoms/LinkAnchor'
import { FEATURE_CUSTOMER_INFO_V2 } from '../../../store/utill/config/constant'

export default {
    components: {
        ProfileDisplay,
        ActionType,
        ExternalLink,
        TableResponsiveDisplay,
        OrderBadge,
        LinkAnchor
    },
    props: {
        orders: {
            type: Array,
            required: true
        },
        customerInfo: {
            type: Object,
            required: true
        },
        advisor: {
            type: Array,
            required: true,
            validator: advisorsValidator
        },
        visible: Boolean,
        close: {
            type: Function,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            orderType,
            buyOrderStatus: {
                status: '',
                status_detail: ''
            },
            featureCustomerInfoV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        orders: {
            handler() {
                this.buyOrderStatus = {
                    status: this.orders[0].buy_status,
                    status_detail: this.orders[0].buy_status_detail
                }
            },
            deep: true
        },
        getTimestamp() {
            this.updateFeatureSetting()
        }
    },
    created() {
        this.updateFeatureSettings()
    },
    methods: {
        ...mapActions('feature', ['isEnabled']),
        getDateFormat,
        getNumberFormat,
        getClassNameColorForProfit,
        getPortUrl(port) {
            return `${pagesConst.PORT_INFO}/${port}`
        },
        getCustomerUrl(customerCode) {
            return this.featureCustomerInfoV2Enabled
                ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-info/${customerCode}`
                : `${pagesConst.CUSTOMER_INFO}/${customerCode}`
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_INFO_V2).then(flag => {
                this.featureCustomerInfoV2Enabled = flag
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.modal__container {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 5;
}
.cross__amc-modal {
    position: fixed;
    width: 900px;
    height: 620px;
    border-radius: 8px;
    font-size: 14px;
    border: 1px solid $greyPrimary;
    background: $white;
    z-index: 10;

    @include responsive(mobile) {
        bottom: 0;
        width: 100%;
        height: 90%;
    }
    @include responsive(phablet) {
        width: 90%;
    }
    @include responsive(tablet) {
        width: 90%;
    }
    @media only screen and (max-height: 640px) {
        height: 450px;
    }
    @media only screen and (max-height: 480px) {
        height: 300px;
    }

    .horizon__align--right {
        display: flex;
        text-align: right;
        justify-content: flex-end;
    }
    .dashed__underline-yellow {
        align-self: flex-start;
        text-decoration: underline;
        text-underline-offset: 6px;
        text-decoration-color: #f7a501;
        text-decoration-style: dashed;
        cursor: pointer;
    }
    .underline {
        text-decoration: underline;
        text-underline-offset: 6px;
    }
    .scroll__area {
        height: 493px;
        overflow-y: scroll;
        @include responsive(mobile) {
            height: 83%;
        }
        @media only screen and (max-height: 640px) {
            height: 72%;
        }
        @media only screen and (max-height: 480px) {
            height: 58%;
        }
    }
    .modal__close-btn {
        width: 132px;
        height: 40px;
        @include responsive(mobile) {
            width: 100%;
        }
    }
    .advisor__picture {
        width: 24px;
        height: 24px;
    }
    .status__badge {
        display: flex;
        color: #fff;
        background-color: #17c161;
        font-size: 14px;
        padding: 4px 8px;
        border-radius: 1.5rem;
        max-width: fit-content;
    }
    .grey__background {
        background-color: #f4f7f9;
        padding: 16px;
    }
    .cross__amc-footer--container {
        @include responsive(mobile) {
            background-color: #fff;
            height: 73px;
        }
    }
    .cross__amc-footer {
        @include responsive(mobile) {
            position: fixed;
            background-color: #fff;
            width: 100%;
            bottom: 0;
            height: 73px;
        }
    }
}
.grid__container--detail {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.grid__container--order {
    display: grid;
    grid-template-columns: 6fr 4fr 2fr;
    @include responsive(mobile) {
        grid-template-columns: 1fr 1fr;
    }
    @include responsive(phablet) {
        grid-template-columns: 6fr 3fr 4fr;
    }
}
</style>
