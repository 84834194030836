<template>
    <div v-if="recievedProps" class="list__container">
        <box-status v-if="planType == planTypesConst.DIY" class="item__align--center" :text="diyMsg" />
        <template v-else>
            <box-model-port :categories="categories" />
            <table-model
                :plan-type="planType"
                :model-list="assetModelList"
                class="full__width top__margin--big"
                @open-fund-tab="openFundTab"
            />
        </template>
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { categoriesValidator, modelListValidator } from '../../../store/modules/port/validator'
import { BoxModelPort, BoxStatus, TableModel } from '../../molecules'
import { planTypesConst } from '../../../store/utill/config'

export default {
    components: {
        BoxModelPort,
        BoxStatus,
        TableModel,
        Spinner
    },
    props: {
        planType: {
            type: String,
            required: true
        },
        categories: {
            type: Array,
            required: true,
            validator: categoriesValidator
        },
        assetModelList: {
            type: Array,
            required: true,
            validator: modelListValidator
        }
    },
    data() {
        return {
            diyMsg: '',
            planTypesConst,
            recievedProps: false
        }
    },
    mounted() {
        this.diyMsg = 'แผนประเภท DIY ไม่มีโมเดลพอร์ต'
        this.recievedProps = true
    },
    methods: {
        async openFundTab(newVal) {
            this.$emit('openFundTab', newVal)
        }
    }
}
</script>
