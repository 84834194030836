const getBankList = bankList => {
    let list = []
    for (let bank of bankList) {
        list.push({
            bank: bank.bank_name,
            branch: bank.bank_branch_name,
            account_no: bank.bank_account_no,
            primary: bank.main
        })
    }
    return list
}

export { getBankList }
