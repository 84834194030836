import { HTTP_REQUEST, API_PREFIX } from './apiConfig'

export const authAPI = {
    // CSRF Token
    CSRFToken: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API_AUTH}/consent-request` },

    // login
    login: { method: HTTP_REQUEST.POST, url: `${API_PREFIX.API_AUTH}/login` },
    verify: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API_AUTH}/verify` },
    logout: { method: HTTP_REQUEST.POST, url: `${API_PREFIX.API_AUTH}/logout` },
    createPassword: { method: HTTP_REQUEST.POST, url: `${API_PREFIX.API_AUTH}/reset-password` },
    forgotPassword: { method: HTTP_REQUEST.POST, url: `${API_PREFIX.API_AUTH}/forgot-password` }
}
