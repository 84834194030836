<template>
    <div v-if="completedData" class="top__padding--normal">
        <div class="row__container bottom__margin--normal wrap__content display__phablet--none lessthan">
            <search class="bottom__margin--normal" :value="searchValue" @value="updateSearch" />
            <v-spacer />
            <grey-button
                class="bottom__margin--normal"
                :icon="iconsConst.FILTER"
                :text="'Filter'"
                @click.native="showFilterModal = true"
            />
        </div>
        <div class="list__container bottom__margin--normal display__tablet--none morethan">
            <search class="bottom__margin--normal" :value="searchValue" @value="updateSearch" />
            <div class="row__container">
                <grey-button
                    class="bottom__margin--normal"
                    :icon="iconsConst.FILTER"
                    :text="'Filter'"
                    @click.native="showFilterModal = true"
                />
            </div>
        </div>
        <blue-banner v-if="textKeyFilter" :title="'Filter: '" :value="textKeyFilter" @clear="clearFilter" />
        <div v-if="showFilterModal" class="modal__container" @click.self="showFilterModal = false">
            <filter-distribution-modal
                :filter-values="filterValues"
                :toggle-modal="toggleFilterModal"
                :visible="showFilterModal"
                @filter="updateFilter"
                @close="showFilterModal = false"
            />
        </div>

        <div class="list__container item__align--center">
            <table-distribution
                :distribution-list="visibledDistributionList"
                class="full__width bottom__margin--normal"
                @open-fund-tab="openFundTab"
            />
            <pagination :current-page="currentPage" :total-pages="totalPages" @currentPage="updateCurrentPage" />
        </div>
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>

<script>
import { Pagination, TableDistribution } from '../../molecules'
import { distributionListValidator } from '../../../store/modules/port/validator'
import { BlueBanner, GreyButton, Search, Spinner } from '../../atoms'
import { loadingConst, iconsConst, DATE_PICKER_START, DATE_PICKER_END } from '../../../store/utill'
import { defaultFilter } from '../../../store/modules/port/config'
import { FilterDistributionModal } from '../Modal'

export default {
    components: {
        BlueBanner,
        GreyButton,
        FilterDistributionModal,
        Pagination,
        Search,
        TableDistribution,
        Spinner
    },
    props: {
        distributionList: {
            type: Array,
            required: true,
            validator: distributionListValidator
        },
        searchValue: {
            type: String,
            required: false,
            default: ''
        },
        filterValues: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            amountContent: 20,
            currentPage: 1,
            defaultFilter,
            iconsConst,
            totalPages: 0,
            visibledDistributionList: [],
            completedData: false,
            loadingConst,
            showFilterModal: false
        }
    },
    computed: {
        textKeyFilter() {
            let textList = ''
            if (!(this.filterValues.orderType.ar && this.filterValues.orderType.dividend)) {
                textList += textList == '' ? 'Type' : ', Type'
            }
            if (
                !(
                    this.filterValues.paymentDate.startDate === DATE_PICKER_START &&
                    this.filterValues.paymentDate.endDate === DATE_PICKER_END
                )
            ) {
                textList += textList == '' ? 'Payment Date' : ', Payment Date'
            }
            return this.filterValues.isFilter ? textList : ''
        }
    },
    watch: {
        distributionList: {
            async handler(newVal) {
                this.distributionList = newVal
                await this.updateTotalPage()
                await this.updateCurrentPage(1)
            },
            deep: true
        }
    },
    async mounted() {
        this.completedData = true
        await this.updateTotalPage()
        await this.updateCurrentPage(1)
    },
    methods: {
        async updateTotalPage() {
            this.totalPages = Math.ceil(this.distributionList.length / this.amountContent)
        },
        async updateCurrentPage(newVal) {
            this.currentPage = newVal
            this.visibledDistributionList = this.distributionList.slice(
                (this.currentPage - 1) * this.amountContent,
                this.currentPage * this.amountContent
            )
        },
        updateSearch(newVal) {
            this.$emit('search', newVal)
        },
        updateFilter(newVal) {
            this.$emit('filter', newVal)
        },
        toggleFilterModal() {
            this.showFilterModal = !this.showFilterModal
        },
        clearFilter() {
            this.$emit('filter', this.defaultFilter)
        },
        async openFundTab(newVal) {
            this.$emit('openFundTab', newVal)
        }
    }
}
</script>
