<template>
    <v-container class="list__container round__block--primary position__relative">
        <title-box
            id="btn-more-investment"
            :title="'Notes'"
            :see-more-tab="seeMoreTab"
            :show-more="showMore"
            :see-more-link="link"
            :is-external-link="true"
        />
        <div v-if="noteDetail.id > 0" class="list__container note__container" @click="showModalNoteDetail">
            <span
                class="big__text--primary size__font--18px bottom__margin--smallest note-title__text-ellipsis"
                >{{ getDecodedText(noteDetail.title) }}</span
            >
            <span v-if="noteDetail.title == ''" class="bottom__margin--big"></span>
            <pre class="normal__text--primary note-body__text-ellipsis">{{
                getDecodedText(noteDetail.note)
            }}</pre>
        </div>
        <span
            :class="noteDetail.id > 0 ? 'new-note__container' : 'new-note__container--no-note'"
            class="row__container plain__btn--primary"
            @click="redirectToCreateNoteModal"
        >
            <i :class="iconsConst.PLUS" class="size__font--16px right__margin--small top__margin" />
            <p>New Note</p>
        </span>
    </v-container>
</template>
<script>
import { getClassNameColorForProfit, getNumberFormat } from '../../../store/utill'
import { TitleBox } from '../Title'
import { iconsConst } from '../../../store/utill/config'
import { noteDetailValidator } from '../../../store/modules/customer/validator'
import { decode } from 'html-entities'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        noteDetail: {
            type: Object,
            required: true,
            validator: noteDetailValidator
        },
        customerCode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            link: ''
        }
    },
    mounted() {
        if (this.noteDetail.id == 0) {
            this.showMore = false
        }
        this.link = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-info/${this.customerCode}?tab=notes`
    },
    methods: {
        getClassNameColorForProfit,
        getNumberFormat,
        redirectToCreateNoteModal() {
            window.location.href = `${this.link}&create=true`
        },
        showModalNoteDetail() {
            this.$emit('showModalNoteDetail', true)
        },
        getDecodedText(text) {
            const decodedText = decode(text)
            return decodedText
        }
    }
}
</script>

<style lang="scss" scoped>
.note__container {
    width: 294px;
    height: 110px;
    margin: -10px 0 10px -10px;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: #f2f3f4;
    }
    @include responsive(mobile) {
        width: 200px;
        padding: 8px 8px;
        margin: -10px 0 -10px -10px;
    }
}
.note-title__text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include responsive(mobile) {
        width: 230px;
    }
    @media only screen and (max-width: 340px) {
        width: 200px;
    }
}
.note-body__text-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: DBHeaventRez !important;
    line-height: 1.5;
    white-space: pre-line;
}
.new-note__container {
    position: absolute;
    bottom: 20px;
    width: 90px;
    height: 20px;
}
.new-note__container--no-note {
    bottom: 20px;
    width: 90px;
    height: 20px;
}
.top__margin {
    margin-top: 2px;
}
.position__relative {
    position: relative;
}
</style>
