import { type, keyValidator, typeValidator } from '../../../utill'

const orderListValidator = historyList => {
    for (let history of historyList) {
        if (!typeValidator([{ value: history, type: type.OBJECT }])) {
            return false
        }
        if (
            !keyValidator(history, [
                'fund_name',
                'order_ref_no',
                'order_type',
                'order_source',
                'amount',
                'unit',
                'nav',
                'status',
                'status_detail',
                'buy_status',
                'buy_status_no',
                'buy_status_detail',
                'transaction_date',
                'order_date',
                'allotment_date',
                'payment_date',
                'payment_method',
                'bank_account',
                'sw_to_fund',
                'sw_in_allotment_date',
                'sw_in_amount',
                'sw_in_unit',
                'sw_in_nav',
                'xwt_fund',
                'xwt_ref_no',
                'proposal_id'
            ])
        ) {
            return false
        }
        if (
            !typeValidator([
                { value: history.fund_name, type: type.STRING },
                { value: history.order_ref_no, type: type.STRING },
                { value: history.order_type, type: type.STRING },
                { value: history.order_source, type: type.STRING },
                { value: history.amount, type: type.STRING },
                { value: history.unit, type: type.STRING },
                { value: history.nav, type: type.STRING },
                { value: history.status, type: type.STRING },
                { value: history.status_detail, type: type.STRING },
                { value: history.buy_status, type: type.STRING },
                { value: history.buy_status_no, type: type.STRING },
                { value: history.buy_status_detail, type: type.STRING },
                { value: history.transaction_date, type: type.STRING },
                { value: history.order_date, type: type.STRING },
                { value: history.allotment_date, type: type.STRING },
                { value: history.payment_date, type: type.STRING },
                { value: history.payment_method, type: type.STRING },
                { value: history.bank_account, type: type.STRING },
                { value: history.sw_to_fund, type: type.STRING },
                { value: history.sw_in_allotment_date, type: type.STRING },
                { value: history.sw_in_amount, type: type.STRING },
                { value: history.sw_in_unit, type: type.STRING },
                { value: history.sw_in_nav, type: type.STRING },
                { value: history.xwt_fund, type: type.STRING },
                { value: history.xwt_ref_no, type: type.STRING },
                { value: history.proposal_id, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

export { orderListValidator }
