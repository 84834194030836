<template>
    <div class="row__container bottom__border--solid title__modal">
        <span class="big__text--greyPri" :class="colorClass"> <i :class="icon" /> {{ title }} </span>
        <v-spacer />
        <button class="big__text--greySec" @click="close">
            <i :class="iconsConst.CROSS" />
        </button>
    </div>
</template>

<script>
import { iconsConst } from '../../../store/utill'

export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        color: {
            type: String,
            required: false,
            default: ''
        },
        prefixAlias: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            iconsConst
        }
    },
    computed: {
        colorClass() {
            if (this.color) {
                return `title--${this.color}`
            }
            return ''
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.title__modal {
    padding: 16px 32px;
    @include responsive(mobile) {
        padding: 16px;
    }
    .title--red {
        color: $redPrimary !important;
    }
}
</style>
