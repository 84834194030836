import { type, keyValidator, sortingValidator, typeValidator } from '../../../utill'

const leadRequestListValidator = leadRequestList => {
    for (let request of leadRequestList) {
        if (!typeValidator([{ value: request, type: type.OBJECT }])) {
            return false
        }
        if (
            !keyValidator(request, [
                'customer_code',
                'customer_name',
                'agent_account_id',
                'request_date',
                'updated_at',
                'note',
                'status'
            ])
        ) {
            return false
        }
        if (
            !typeValidator([
                { value: request.customer_code, type: type.STRING },
                { value: request.customer_name, type: type.STRING },
                { value: request.agent_account_id, type: type.STRING },
                { value: request.request_date, type: type.STRING },
                { value: request.updated_at, type: type.STRING },
                { value: request.note, type: type.STRING },
                { value: request.status, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

export { leadRequestListValidator, sortingValidator }
