<template>
    <v-container class="background--normal__white border--normal horizon__padding--big">
        <div v-if="alert.type !== 'success'" class="vertical__margin--big">
            <v-alert v-if="alert.show" :type="alert.type" text :icon="alert.icon">
                <span>{{ alert.msg }}</span>
            </v-alert>
            <div class="big__text--greyPri vertical__margin--big">Forget Your Password?</div>
            <div class="vertical__margin--big">We’ll send you a link to change your password.</div>
            <form @submit.prevent="validate">
                <input-custom
                    title="Email"
                    autocomplete="email"
                    :value="email.value"
                    :validation="email.validation"
                    class="mb-8"
                    @value="value => updateValue('email', value)"
                />
                <div class="row__container mb-8 item__align--center button-wrap">
                    <button
                        type="submit"
                        class="forgot-password__btn--normal font-bold"
                    >
                        Send
                    </button>
                    <v-spacer />
                    <div class="flex cancel-button">
                        <button
                            type="button"
                            class="icon__btn--secondary font-bold forgot-password__font-size"
                            @click="redirect(`${pagesConst.LOGIN}`)"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="alert.type === 'success'" class="vertical__margin--big">
            <div class="big__text--greyPri vertical__margin--big">Check Your Email</div>
            <div class="vertical__margin--big">
                We’ve sent instructions on how to change your password to {{ email.value }}. Please set the new password
                within 24 hours.
            </div>
            <div class="row__container mb-8 item__align--center">
                <button
                    type="button"
                    class="forgot-password__btn--normal font-bold"
                    @click="redirect(`${pagesConst.LOGIN}`)"
                >
                    Continue
                </button>
            </div>
        </div>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

import { pagesConst } from '../../../store/utill'
import { InputCustom } from '../../atoms'

Vue.use(VueCookies)

export default {
    components: {
        InputCustom
    },
    props: {
        type: {
            type: String,
            default: 'forgot-password'
        }
    },
    data() {
        return {
            pagesConst,
            csrf: '',
            email: {
                value: '',
                validation: {
                    show: false,
                    msg: '',
                    dataAlias: 'forgot-password_validate-email'
                }
            },
            alert: {}
        }
    },
    computed: {
        ...mapGetters('authentication', ['getCSRF'])
    },
    methods: {
        ...mapActions('authentication', ['forgotPassword', 'requestCSRFToken']),
        async requestCSRF() {
            this.alert = await this.requestCSRFToken()
            this.csrf = await this.getCSRF
        },
        updateValue(variable, value) {
            this[variable].value = value
        },
        validEmail(email) {
            let regEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            return regEx.test(email)
        },
        async validate() {
            let validData = true
            this.email.validation = {
                show: false,
                msg: '',
                dataAlias: this.email.validation.dataAlias
            }
            if (this.email.value == '') {
                this.email.validation = {
                    show: true,
                    msg: 'Please fill in the email',
                    dataAlias: this.email.validation.dataAlias
                }
                validData = false
            }
            if (this.email.value !== '' && !this.validEmail(this.email.value)) {
                this.email.validation = {
                    show: true,
                    msg: 'Invalid email format',
                    dataAlias: this.email.validation.dataAlias
                }
                validData = false
            }
            if (validData) {
                await this.sendForgotPassword()
            }
        },
        async sendForgotPassword() {
            await this.requestCSRF()
            this.alert = await this.forgotPassword({
                csrf: this.csrf,
                email: this.email.value
            })
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        }
    }
}
</script>
<style lang="scss" scoped>
.button-wrap {
    .forgot-password__btn--normal {
        width: 120px;

        @media screen and (min-width: 480px) {
            width: 100%;
        }
    }

    .cancel-button {
        width: 100%;
        text-align: center;
    }
}
.forgot-password__font-size {
    font-size: 14px;
}
</style>
