import axios from 'axios'

import { authAPI } from './authAPI'
import { amcAPI } from './amcAPI'
import { customerInfoAPI } from './customerInfoAPI'
import { favoriteAPI } from './favoriteAPI'
import { fundAPI } from './fundAPI'
import { leadRequestAPI } from './leadRequestAPI'
import { permissionConfigAPI } from './permissionConfigAPI'
import { portInfoAPI } from './portInfoAPI'
import { planAPI } from './planAPI'

export const api = {
    authAPI,
    amcAPI,
    customerInfoAPI,
    favoriteAPI,
    fundAPI,
    leadRequestAPI,
    permissionConfigAPI,
    portInfoAPI,
    planAPI
}

export const requestApi = async (apiName, option = {}) => {
    const unleashSessionID = localStorage.getItem('unleash:repository:sessionId')
    if (unleashSessionID) {
        option.headers = {
            ...(option.headers || {}),
            'X-Unleash-Session-Id': unleashSessionID
        }
    }
    // debug feature overrides
    if (process.env.VUE_APP_ALLOW_DEBUG_TOOLS === 'true') {
        for (const cookie of document.cookie.split(';')) {
            const [key, value] = cookie.split('=').map(t => t.trim())
            if (key.startsWith('Debug-Feature-') && !!value) {
                option.headers = { ...(option.headers || {}), [`X-${key}`]: value }
            }
            if (key.startsWith('Debug-Variant-') && !!value) {
                option.headers = { ...(option.headers || {}), [`X-${key}`]: value }
            }
        }
    }

    let response = await axios({
        method: apiName.method,
        headers: option.headers,
        url: `${apiName.url}${option.variable ? `/${option.variable}` : ''}${option.query ? `?${option.query}` : ''}`,
        params: option.params,
        data: option.data
    })
    return { status: response.status, response: response.data }
}
