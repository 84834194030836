import { getters } from './getters'
import { actions } from './actions'

const state = {
    advisor: [],
    basicInfo: {},
    outstanding: {},
    dca: {},
    history: {},
    distribution: {},
    favoriteStatus: false,
    model: {},
    pra: {},
    amcs: {}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations: {
        setState: (state, data) => {
            state[data.type] = data.data
        }
    }
}
