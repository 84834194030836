<template>
    <div class="background--normal__white">
        <tab-bar :tabs="tabs" :active-tab="activeTab" class="horizon__margin--big" @activeTab="updateActiveTab" />
        <v-container
            v-if="activeTab == tabs.ROLE_PERMISSION"
            class="row__container item__align--center horizon__margin--big wrap__content"
        >
            <role-config />
        </v-container>
        <v-container
            v-else-if="activeTab == tabs.LICENSE_PERMISSION"
            class="row__container item__align--center horizon__margin--big wrap__content"
        >
            <license-config />
        </v-container>
    </div>
</template>

<script>
import { LicenseConfig, RoleConfig, TabBar } from '../../organisms'

export default {
    components: {
        LicenseConfig,
        RoleConfig,
        TabBar
    },
    props: {
        tabs: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeTab: this.tabs.ROLE_PERMISSION
        }
    },
    methods: {
        async updateActiveTab(newVal) {
            this.activeTab = newVal
        }
    }
}
</script>
