var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row__container item__align--center",class:!_vm.showName ? 'horizon__align--center' : ''},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[(_vm.profile.profile_picture.thumbnail)?_c('img',_vm._g({class:_vm.profile.display_circle
                            ? _vm.isAdvisor
                                ? _vm.isIcon
                                    ? 'display__advisor--icon'
                                    : 'display__advisor--circle'
                                : _vm.isIcon
                                ? 'display__profile--icon'
                                : 'display__profile--circle'
                            : 'display__icon--normal',attrs:{"src":_vm.profile.profile_picture.thumbnail}},_vm.showTooltip && !_vm.showName && on)):_c('div',_vm._g({class:_vm.profile.display_circle
                            ? _vm.isAdvisor
                                ? _vm.isIcon
                                    ? 'display__advisor--icon'
                                    : 'display__advisor--circle'
                                : _vm.isIcon
                                ? 'display__profile--icon'
                                : 'display__profile--circle'
                            : 'display__icon--normal'},_vm.showTooltip && !_vm.showName && on),[_vm._v(" "+_vm._s(_vm.profile.abbr)+" ")])])]}}])},[_c('span',{staticClass:"normal__text--white font-bold"},[_vm._v(_vm._s(_vm.profile.full))])]),(_vm.showName)?_c('span',{staticClass:"normal__text--primary font-bold left__margin--small"},[_vm._v(_vm._s(_vm.profile.full))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }