import { type, keyValidator, typeValidator } from '../../../utill'
import { advisorsValidator } from './index'

const unrealizedPlValidator = unrealizedPl => {
    if (!keyValidator(unrealizedPl, ['value', 'percent'])) {
        return false
    }
    if (
        !typeValidator([
            { value: unrealizedPl.value, type: type.NUMBER },
            { value: unrealizedPl.percent, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const realizedPlValidator = realizedPl => {
    if (!keyValidator(realizedPl, ['total_realized', 'realized_amount', 'ar_amount', 'dividend_amount'])) {
        return false
    }
    if (
        !typeValidator([
            { value: realizedPl.total_realized, type: type.NUMBER },
            { value: realizedPl.realized_amount, type: type.NUMBER },
            { value: realizedPl.ar_amount, type: type.NUMBER },
            { value: realizedPl.dividend_amount, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const investmentValidator = investment => {
    if (!keyValidator(investment, ['total_cost', 'total_outstanding', 'total_return'])) {
        return false
    }
    if (
        !typeValidator([
            { value: investment.total_cost, type: type.STRING },
            { value: investment.total_outstanding, type: type.STRING },
            { value: investment.total_return, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const noteBoxValidator = noteDetail => {
    if (!keyValidator(noteDetail, ['see_more', 'note', 'customer_code', 'advisor'])) {
        return false
    }
    if (
        !typeValidator([
            { value: noteDetail.see_more, type: type.STRING },
            { value: noteDetail.note, type: type.OBJECT },
            { value: noteDetail.customer_code, type: type.STRING },
            { value: noteDetail.advisor, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return true
}

const noteDetailValidator = noteDetail => {
    if (!keyValidator(noteDetail, ['id', 'title', 'note', 'updated_at', 'created_at', 'advisor'])) {
        return false
    }
    if (
        !typeValidator([
            { value: noteDetail.id, type: type.NUMBER },
            { value: noteDetail.title, type: type.STRING },
            { value: noteDetail.note, type: type.STRING },
            { value: noteDetail.updated_at, type: type.STRING },
            { value: noteDetail.created_at, type: type.STRING },
            { value: noteDetail.advisor, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return true
}

const investBoxValidator = investmentBox => {
    if (!keyValidator(investmentBox, ['see_more', 'investment'])) {
        return false
    }
    if (
        !typeValidator([
            { value: investmentBox.see_more, type: type.STRING },
            { value: investmentBox.investment, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return investmentValidator(investmentBox.investment)
}

const planValidator = plan => {
    if (!keyValidator(plan, ['need_pra', 'invested_plan', 'total_plan'])) {
        return false
    }
    if (
        !typeValidator([
            { value: plan.need_pra, type: type.NUMBER },
            { value: plan.invested_plan, type: type.NUMBER },
            { value: plan.total_plan, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const planBoxValidator = planBox => {
    if (!keyValidator(planBox, ['see_more', 'plan'])) {
        return false
    }
    if (
        !typeValidator([
            { value: planBox.see_more, type: type.STRING },
            { value: planBox.plan, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return planValidator(planBox.plan)
}

const planListValidator = planList => {
    for (let obj of planList) {
        if (!typeValidator([{ value: obj, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(obj, ['port_id', 'plan_name', 'type', 'cost', 'unrealized_pl', 'advisor', 'pra', 'dca'])) {
            return false
        }
        if (
            !typeValidator([
                { value: obj.port_id, type: type.STRING },
                { value: obj.plan_name, type: type.STRING },
                { value: obj.type, type: type.STRING },
                { value: obj.cost, type: type.STRING },
                { value: obj.unrealized_pl, type: type.OBJECT },
                { value: obj.advisor, type: type.ARRAY },
                { value: obj.pra, type: type.BOOLEAN },
                { value: obj.dca, type: type.NUMBER }
            ])
        ) {
            return false
        }
        if (!unrealizedPlValidator(obj.unrealized_pl) || !advisorsValidator(obj.advisor)) {
            return false
        }
    }
    return true
}
const plValidator = pl => {
    if (!keyValidator(pl, ['unrealized_pl', 'realized_pl'])) {
        return false
    }
    if (
        !typeValidator([
            { value: pl.unrealized_pl, type: type.OBJECT },
            { value: pl.realized_pl, type: type.OBJECT }
        ])
    ) {
        return false
    }
    if (!unrealizedPlValidator(pl.unrealized_pl) || !realizedPlValidator(pl.realized_pl)) {
        return false
    }
    return true
}

const plBoxValidator = plBox => {
    if (!keyValidator(plBox, ['see_more', 'pl'])) {
        return false
    }
    if (
        !typeValidator([
            { value: plBox.see_more, type: type.STRING },
            { value: plBox.pl, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return plValidator(plBox.pl)
}

const totalInvestmentValidator = investment => {
    if (!keyValidator(investment, ['total_customer', 'total_cost', 'total_outstanding'])) {
        return false
    }
    if (
        !typeValidator([
            { value: investment.total_customer, type: type.NUMBER },
            { value: investment.total_cost, type: type.NUMBER },
            { value: investment.total_outstanding, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const totalPlanValidator = plan => {
    if (!keyValidator(plan, ['cust_need_pra'])) {
        return false
    }
    if (!typeValidator([{ value: plan.cust_need_pra, type: type.NUMBER }])) {
        return false
    }
    return true
}

export {
    investBoxValidator,
    investmentValidator,
    planValidator,
    planBoxValidator,
    planListValidator,
    plBoxValidator,
    plValidator,
    realizedPlValidator,
    totalInvestmentValidator,
    totalPlanValidator,
    unrealizedPlValidator,
    noteDetailValidator,
    noteBoxValidator
}
