import { getAdvisors, getInvestStatusText, getUnrealizedPL } from './index'
import { orderByConst } from '../../../utill'

const buildCustList = custList => {
    let list = []
    for (let cust of custList) {
        list.push({
            customer_code: cust.customer_code,
            title_th: cust.title_th,
            name_th: cust.name_th,
            service_level: cust.level ? getServiceLevel(cust.level) : '-',
            cost: cust.realized_pl.unrealized_pl.sum_of_cost == null ? 0.0 : cust.realized_pl.unrealized_pl.sum_of_cost,
            outstanding:
                cust.realized_pl.unrealized_pl.sum_of_market_value == null
                    ? 0.0
                    : cust.realized_pl.unrealized_pl.sum_of_market_value,
            unrealized_pl: getUnrealizedPL(cust.realized_pl),
            account_status: getAccountStatus(cust.pending_detail || { oa_status: '', required: [] }, cust.wt_status),
            investment_status: getInvestStatusText(cust.wt_status),
            advisors: getAdvisors(cust.advisors ? cust.advisors : []),
            actions: cust.action_require == undefined ? [] : cust.action_require,
            port_total_action: cust.port_total_action,
            is_favorite: cust.is_favorite || false,
            email: cust.email,
            tel: cust.tel,
            pending_detail: {
                oa_status: (cust.pending_detail && cust.pending_detail.oa_status) || '',
                required: (cust.pending_detail && cust.pending_detail.required) || []
            }
        })
    }
    return list
}

const getAccountStatus = (pendingDetail, wtStatus) => {
    let result = []
    if (pendingDetail.oa_status === 'closed') {
        return 'freeze'
    }
    if (wtStatus === 4) {
        return 'ready_to_invest'
    }
    if ((wtStatus === 5 || wtStatus === 6) && pendingDetail.oa_status !== 'closed') {
        return 'invested'
    }
    if (pendingDetail.oa_status === 'rejected' && !pendingDetail.required.length) {
        return 'oa_rejected'
    }
    if (pendingDetail.oa_status === 'reviewing' && !pendingDetail.required.length) {
        return 'reviewing'
    }
    if (pendingDetail.oa_status === 'rejected' && pendingDetail.required.length) {
        result.push('oa_rejected')
    }
    pendingDetail.required.forEach((required, index) => {
        let key = required.toString()
        if (index >= pendingDetail.required.length - 1) {
            key += '_required'
        }
        result.push(key)
    })
    return result.join('_')
}

const getInvestmentStatusTextByIndexFilter = idx => {
    let investmentStatus = ['Account Pending', 'Ready to Invest', 'Invested']
    return idx >= 0 && idx < 3 ? investmentStatus[idx] : '-'
}

const quickFilterFunc = (current, quickFilter) => {
    switch (quickFilter) {
        case 'wt2':
            return (
                current.investment_status.toUpperCase().search(getInvestmentStatusTextByIndexFilter(0).toUpperCase()) >=
                0
            )
        case 'wt4':
            return (
                current.investment_status.toUpperCase().search(getInvestmentStatusTextByIndexFilter(1).toUpperCase()) >=
                0
            )
        case 'wt5':
            return (
                current.investment_status.toUpperCase().search(getInvestmentStatusTextByIndexFilter(2).toUpperCase()) >=
                0
            )
    }
    return true
}

const filterFunc = (current, filterList) => {
    if (
        current.cost < parseInt(filterList.cost.min == '' ? '0' : filterList.cost.min.replace(/,/g, '')) ||
        current.cost > parseInt(filterList.cost.max == '' ? '99999999999' : filterList.cost.max.replace(/,/g, ''))
    ) {
        return false
    }
    if (
        current.outstanding <
            parseInt(filterList.outstanding.min == '' ? '0' : filterList.outstanding.min.replace(/,/g, '')) ||
        current.outstanding >
            parseInt(filterList.outstanding.max == '' ? '99999999999' : filterList.outstanding.max.replace(/,/g, ''))
    ) {
        return false
    }
    if (
        current.unrealized_pl.percent <
            parseFloat(
                filterList.unrealized_pl.min == '' ? '-100.00' : filterList.unrealized_pl.min.replace(/,/g, '')
            ) ||
        current.unrealized_pl.percent >
            parseFloat(filterList.unrealized_pl.max == '' ? '2000.00' : filterList.unrealized_pl.max.replace(/,/g, ''))
    ) {
        return false
    }
    if (!filterList.investment_status.wt2 && quickFilterFunc(current, 'wt2')) {
        return false
    }
    if (!filterList.investment_status.wt4 && quickFilterFunc(current, 'wt4')) {
        return false
    }
    if (!filterList.investment_status.wt5 && quickFilterFunc(current, 'wt5')) {
        return false
    }
    if (!filterList.favorite_status.favorite && current.is_favorite) {
        return false
    }
    if (!filterList.favorite_status.regular && !current.is_favorite) {
        return false
    }
    return true
}

const searchFunc = (current, searchWord) => {
    // INFO: search by 'advisor name' or 'customer name' or 'customer email' or 'customer tel'
    for (let advisor of current.advisors) {
        if (advisor.full.toUpperCase().search(searchWord.toUpperCase()) >= 0) {
            return true
        }
    }
    return (
        current.name_th.toUpperCase().search(searchWord.toUpperCase()) >= 0 ||
        (current.email &&
            current.email.length &&
            current.email.toUpperCase().search(new RegExp(searchWord.toUpperCase(), 'gi')) >= 0) ||
        (current.tel &&
            current.tel.length &&
            current.tel.toUpperCase().search(new RegExp(searchWord.toUpperCase(), 'gi')) >= 0)
    )
}

const getSortValueByKey = (a, b, key) => {
    switch (key) {
        case 'service_level':
            return { firstValue: a.service_level, secondValue: b.service_level }
        case 'unrealized_pl':
            return {
                firstValue: a.unrealized_pl.percent,
                secondValue: b.unrealized_pl.percent
            }
        case 'advisors':
            return {
                firstValue: a.advisors[0].full,
                secondValue: b.advisors[0].full
            }
        case 'actions':
            return {
                firstValue: a.port_total_action,
                secondValue: b.port_total_action
            }
        case 'investment_status':
            return { firstValue: a.account_status, secondValue: b.account_status }
        case 'name_th':
        case 'cost':
        case 'outstanding':
        default:
            return { firstValue: a[key], secondValue: b[key] }
    }
}

const sortFunc = (a, b, sorting) => {
    let { firstValue, secondValue } = getSortValueByKey(a, b, sorting.by)
    if (firstValue < secondValue) {
        return sorting.order == orderByConst.ASC ? -1 : 1
    } else if (firstValue > secondValue) {
        return sorting.order == orderByConst.ASC ? 1 : -1
    }
}

const getVisibledCustList = (allCustList, { sorting, searchWord }) => {
    return allCustList.filter(current => searchFunc(current, searchWord)).sort((a, b) => sortFunc(a, b, sorting))
}

const convertDelimitNumberStringToNumber = delimitNumber => {
    if (delimitNumber) {
        return +delimitNumber.split(',').join('')
    }
    return 0
}

const getAccountStatusFromFilter = accountStatusFilter => {
    const accountStatuses = []
    Object.keys(accountStatusFilter).map(key => {
        if (accountStatusFilter[key]) {
            accountStatuses.push(key)
        }
    })
    return accountStatuses
}

const getServiceLevelFromFilter = serviceLevelFilter => {
    const serviceLevels = []
    Object.keys(serviceLevelFilter).map(key => {
        if (serviceLevelFilter[key]) {
            serviceLevels.push(key)
        }
    })
    return serviceLevels
}

const quickFilterCustList = (custList, quickFilter) => {
    const filterCustList = custList.filter(list => {
        if (quickFilter === 'all') {
            return true
        }
        return quickFilterFunc(list, quickFilter)
    })
    return filterCustList
}

const advisorFilterCustList = (custList, advisorFilter = []) => {
    const advisorFilters = advisorFilter.map(advisor => advisor.toLowerCase())
    const filterCustList = custList.filter(list => {
        if (advisorFilters.includes('all advisors') || !advisorFilters.length) {
            return true
        }

        return list.advisors.find(adv => advisorFilters.includes(adv.identifier.toLowerCase()))
    })
    return filterCustList
}

const getOthersStatusFromFilter = other => {
    let others = []
    if (other.has_action_required) {
        others.push('has_action_required')
    }
    if (other.has_dca_profiles) {
        others.push('has_dca_profiles')
    }
    return others
}

const getServiceLevel = level => {
    const getServiceLevelText = internal_service_level => {
        switch (internal_service_level) {
            case 'Exclusive':
                return 'Exclusive'
            case 'Exclusive Prime':
                return 'Exclusive Prime'
            case 'Private Wealth (FPW)':
            case 'Private':
                return 'FPW'
            case 'Private Wealth Plus (FPW Plus)':
            case 'Private Plus':
                return 'FPW Plus'
            case 'Private Banking (FPB)':
            case 'Ultra':
                return 'FPB'
            case 'Private Banking ex (FPB Ex)':
            case 'Ultra Ex':
                return 'FPB Ex'
            case 'Private Banking Ultra (FPB Ultra)':
            case 'Ultra Ultra':
                return 'FPB Ultra'
            case 'Club Member':
            case 'Club':
                return 'Member'
            default:
                return '-'
        }
    }

    if (level && level.internal_service_level) {
        return getServiceLevelText(level.internal_service_level).replace(/\(|\)/g, '')
    }

    return '-'
}

export {
    advisorFilterCustList,
    buildCustList,
    filterFunc,
    getInvestmentStatusTextByIndexFilter,
    getSortValueByKey,
    getVisibledCustList,
    quickFilterFunc,
    searchFunc,
    sortFunc,
    convertDelimitNumberStringToNumber,
    getOthersStatusFromFilter,
    getAccountStatusFromFilter,
    quickFilterCustList,
    getServiceLevel,
    getServiceLevelFromFilter
}
