<template>
    <v-container class="list__container">
        <span class="topic__text--primary bottom__margin--small">Advisor</span>
        <div :class="info.length == 1 ? 'advisor__info' : 'row__container advisor__info'">
            <span v-if="info.length == 0">-</span>
            <profile-display
                key="advisor-profile-picture"
                :profile="info[0]"
                :show-name="info.length == 1"
                :show-tooltip="info.length > 1"
                :class="info.length == 1 ? '' : 'right__margin--small'"
            />
            <div v-if="info.length > 4">
                <button class="topic__text--primary" @click="seeMore()">
                    {{ `+${info.length - 4}` }}
                </button>
            </div>
        </div>
    </v-container>
</template>
<script>
import { ProfileDisplay } from '../../atoms'
import { advisorsValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        ProfileDisplay
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        info: {
            type: Array,
            required: true,
            validator: advisorsValidator
        }
    },
    methods: {
        seeMore() {
            this.$emit('seeMore', this.seeMoreTab)
        }
    }
}
</script>
<style lang="scss" scoped>
.advisor__info {
    height: 80px;
    align-items: baseline;
}
</style>
