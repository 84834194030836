<template>
    <a v-if="external" :class="className" :href="to" :_target="target">
        <slot></slot>
    </a>
    <router-link v-else :to="to" :class="className">
        <slot></slot>
    </router-link>
</template>
<script>
export default {
    props: {
        external: {
            type: Boolean,
            required: false,
            default: true
        },
        to: {
            type: String,
            required: true
        },
        className: {
            type: String,
            required: false,
            default: ''
        },
        dataAlias: {
            type: String,
            required: false,
            default: ''
        },
        target: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>
