const type = {
    BOOLEAN: 'boolean',
    STRING: 'string',
    NUMBER: 'number',
    ARRAY: 'array',
    OBJECT: 'object'
}

const quantification = {
    SOME: 'some',
    ALL: 'all'
}

const keyValidator = (obj, keys) => {
    let keyArr = Object.keys(obj)
    for (let key of keys) {
        if (!keyArr.includes(key)) {
            return false
        }
    }
    return true
}

const typeValidator = variableList => {
    for (let variable of variableList) {
        if (variable.type != 'array' && typeof variable.value != variable.type) {
            return false
        } else if (variable.type == 'array' && !Array.isArray(variable.value)) {
            return false
        }
    }
    return true
}

const dataValidator = (quant, dataList) => {
    for (let data of dataList) {
        if (quant == quantification.ALL && data.key != data.value) {
            return false
        } else if (quant == quantification.SOME && data.key == data.value) {
            return true
        }
    }
    return true
}

const sortingValidator = sorting => {
    if (!keyValidator(sorting, ['by', 'order'])) {
        return false
    }
    if (
        !typeValidator([
            { value: sorting.by, type: type.STRING },
            { value: sorting.order, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

export { dataValidator, keyValidator, quantification, sortingValidator, type, typeValidator }
