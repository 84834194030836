<template>
    <div v-if="recievedProps">
        <div class="row__container bottom__margin--normal">
            <v-spacer />
            <grey-button class="right__margin--small" :text="'Expand All'" @click.native="isExpandAll(true)" />
            <grey-button :text="'Collapse All'" @click.native="isExpandAll(false)" />
        </div>
        <table ref="tableAsset" class="table__secondary--normal">
            <thead class="head__table--primary bottom__border--solid">
                <tr>
                    <th class="first__column first__column--fix"></th>
                    <th class="horizon__align--left">Fund</th>
                    <th class="horizon__align--right display__tablet--none lessthan">Change</th>
                    <th class="horizon__align--right display__phablet--none lessthan">%</th>
                    <th class="horizon__align--right display__phablet--none lessthan">% Model</th>
                    <th class="horizon__align--right">Value</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(asset, idx) in filterAssetList(assetList)">
                    <tr :key="`asset-row-${idx}`" :class="expandList[idx] ? '' : 'bottom__border--solid'">
                        <td class="first__column pointer__cursor first__column--fix" @click="arrowClicked(idx)">
                            <i :class="expandList[idx] ? iconsConst.ARROW_DOWN : iconsConst.ARROW_RIGHT" />
                        </td>
                        <td class="horizon__align--left">
                            <span class="normal__text--primary font-bold">
                                {{ asset.fund }}
                                <external-link @click.native="$emit('open-fund-tab', asset.fund)" />
                            </span>
                        </td>
                        <td class="list__container horizon__align--right display__tablet--none lessthan">
                            <span :class="getClassNameColorForProfit(asset.change.value, 2)">
                                {{ getNumberFormat(asset.change.value, true, 2, '') }}
                            </span>
                            <span :class="getClassNameColorForProfit(asset.change.value, 2)">
                                ({{ getNumberFormat(asset.change.percent, true, 2, '%') }})
                            </span>
                        </td>
                        <td class="horizon__align--right display__phablet--none lessthan">
                            <span class="normal__text--primary">
                                {{ getNumberFormat(asset.weight, false, 2, '%') }}
                            </span>
                        </td>
                        <td class="horizon__align--right display__phablet--none lessthan">
                            <span class="normal__text--primary">
                                {{ getNumberFormat(asset.weight_model, false, 2, '%') }}
                            </span>
                        </td>
                        <td class="horizon__align--right">
                            <span class="normal__text--primary">{{ getNumberFormat(asset.value, false, 2, '') }}</span>
                        </td>
                    </tr>
                    <tr v-if="expandList[idx]" :key="`asset-row-expand-${idx}`" class="bottom__border--solid">
                        <td></td>
                        <td :colspan="colspan">
                            <div class="row__container">
                                <div class="expand__row--responsive list__container">
                                    <table-responsive-display
                                        class="display__tablet--none morethan"
                                        :title="'%:'"
                                        :value="getNumberFormat(asset.weight, false, 2, '%')"
                                    />
                                    <table-responsive-display
                                        class="display__tablet--none morethan"
                                        :title="'% Model:'"
                                        :value="getNumberFormat(asset.weight_model, false, 2, '%')"
                                    />
                                    <table-responsive-display
                                        :title="'Unit:'"
                                        :value="getNumberFormat(asset.unit, false, 4, '')"
                                    />
                                    <table-responsive-display
                                        class="display__desktop--none"
                                        :title="'Change:'"
                                        :value="getNumberFormat(asset.change.value, true, 2, '')"
                                        :value-color="getClassNameColorForProfit(asset.change.value, 2)"
                                    />
                                    <table-responsive-display
                                        class="display__desktop--none"
                                        :title="'% Change:'"
                                        :value="getNumberFormat(asset.change.percent, true, 2, '%')"
                                        :value-color="getClassNameColorForProfit(asset.change.percent, 2)"
                                    />
                                    <table-responsive-display
                                        class="display__phablet--none"
                                        :title="'Cost:'"
                                        :value="getNumberFormat(asset.cost, false, 2, '')"
                                    />
                                    <table-responsive-display
                                        class="display__tablet--none lessthan"
                                        :title="'NAV Cost:'"
                                        :value="getNumberFormat(asset.nav_cost, false, 4, '')"
                                    />
                                    <table-responsive-display
                                        class="display__phablet--none morethan"
                                        :title="'NAV Current:'"
                                        :value="getNumberFormat(asset.nav_current, false, 4, '')"
                                    />
                                    <table-responsive-display
                                        class="display__phablet--none morethan"
                                        :title="'NAV Date:'"
                                        :value="asset.nav_date"
                                    />
                                </div>
                                <div class="expand__row--responsive list__container display__mobile--none">
                                    <table-responsive-display
                                        class="display__tablet--none morethan"
                                        :title="'Cost:'"
                                        :value="getNumberFormat(asset.cost, false, 2, '')"
                                    />
                                    <table-responsive-display
                                        class="display__desktop--none"
                                        :title="'NAV Cost:'"
                                        :value="getNumberFormat(asset.nav_cost, false, 4, '')"
                                    />
                                    <table-responsive-display
                                        :title="'NAV Current:'"
                                        :value="getNumberFormat(asset.nav_current, false, 4, '')"
                                    />
                                    <table-responsive-display :title="'NAV Date:'" :value="asset.nav_date" />
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
                <!-- All -->
                <tr
                    :key="`asset-row-${expandList.length - 1}`"
                    :class="expandList[expandList.length - 1] ? '' : 'bottom__border--solid'"
                >
                    <td
                        class="first__column pointer__cursor first__column--fix display__desktop--none"
                        @click="arrowClicked(expandList.length - 1)"
                    >
                        <i
                            :class="expandList[expandList.length - 1] ? iconsConst.ARROW_DOWN : iconsConst.ARROW_RIGHT"
                        />
                    </td>
                    <td class="first__column--fix display__tablet--none lessthan"></td>
                    <td class="horizon__align--left">
                        <span class="normal__text--primary font-bold">All</span>
                    </td>
                    <td class="list__container horizon__align--right display__tablet--none lessthan">
                        <span :class="getClassNameColorForProfit(allAssets.change.value, 2)">
                            {{ getNumberFormat(allAssets.change.value, true, 2, '') }}
                        </span>
                        <span :class="getClassNameColorForProfit(allAssets.change.percent, 2)">
                            ({{ getNumberFormat(allAssets.change.percent, true, 2, '%') }})
                        </span>
                    </td>
                    <td class="horizon__align--right display__mobile--none display__phablet--none lessthan"></td>
                    <td class="horizon__align--right display__mobile--none display__phablet--none lessthan"></td>
                    <td class="horizon__align--right">
                        <span class="normal__text--primary">{{ getNumberFormat(allAssets.value, false, 2, '') }}</span>
                    </td>
                </tr>
                <tr
                    v-if="expandList[expandList.length - 1]"
                    :key="`asset-row-expand-${expandList.length - 1}`"
                    class="display__desktop--none bottom__border--solid"
                >
                    <td></td>
                    <td :colspan="colspan">
                        <div class="row__container">
                            <div class="expand__row--responsive list__container">
                                <table-responsive-display
                                    :title="'Change:'"
                                    :value="getNumberFormat(allAssets.change.value, true, 2, '')"
                                    :value-color="getClassNameColorForProfit(allAssets.change.value, 2)"
                                />
                                <table-responsive-display
                                    class="display__tablet--none display__phablet--none"
                                    :title="'% Change:'"
                                    :value="getNumberFormat(allAssets.change.percent, true, 2, '%')"
                                    :value-color="getClassNameColorForProfit(allAssets.change.percent, 2)"
                                />
                                <table-responsive-display
                                    class="display__tablet--none lessthan"
                                    :title="'Value:'"
                                    :value="getNumberFormat(allAssets.value, false, 2, '')"
                                />
                            </div>
                            <div class="expand__row--responsive list__container display__mobile--none">
                                <table-responsive-display
                                    :title="'% Change:'"
                                    :value="getNumberFormat(allAssets.change.percent, true, 2, '%')"
                                    :value-color="getClassNameColorForProfit(allAssets.change.percent, 2)"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>

<script>
import { allAssetsValidator, assetListValidator } from '../../../store/modules/port/validator'
import { getClassNameColorForProfit, getNumberFormat } from '../../../store/utill'
import { ExternalLink, GreyButton, TableResponsiveDisplay, Spinner } from '../../atoms'
import { iconsConst } from '../../../store/utill'

export default {
    components: {
        ExternalLink,
        GreyButton,
        TableResponsiveDisplay,
        Spinner
    },
    props: {
        allAssets: {
            type: Object,
            required: true,
            validator: allAssetsValidator
        },
        assetList: {
            type: Array,
            required: true,
            validator: assetListValidator
        }
    },
    data() {
        return {
            iconsConst,
            recievedProps: false,
            expandList: [],
            colspan: 1
        }
    },
    async mounted() {
        this.expandList = await this.getAllExpand(false)
        this.recievedProps = true
    },
    methods: {
        getClassNameColorForProfit,
        getNumberFormat,
        async getAllExpand(value) {
            let list = []
            for (let idx in this.assetList) {
                list[idx] = value
            }
            // INFO: for the last row (All)
            list.push(value)
            return list
        },
        arrowClicked(idx) {
            this.colspan = this.$refs.tableAsset.rows[0].cells.length - 1
            this.expandList = this.expandList
                .slice(0, idx)
                .concat(!this.expandList[idx])
                .concat(this.expandList.slice(idx + 1, this.expandList.length))
        },
        async isExpandAll(value) {
            this.colspan = this.$refs.tableAsset.rows[0].cells.length - 1
            this.expandList = await this.getAllExpand(value)
        },
        filterAssetList(assetList) {
            return assetList.filter(asset => asset.cost !== 0 || asset.weight_model > 0)
        }
    }
}
</script>

<style lang="scss" scoped>
.first__column--fix {
    width: 20px !important;
}
</style>
