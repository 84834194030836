import { type, keyValidator, typeValidator } from '../../../utill'

const generalInfoValidator = generalInfo => {
    if (!keyValidator(generalInfo, ['port_icon', 'port_id', 'customer_name'])) {
        return false
    }
    if (
        !typeValidator([
            { value: generalInfo.port_icon, type: type.STRING },
            { value: generalInfo.port_id, type: type.STRING },
            { value: generalInfo.customer_name, type: type.STRING },
            { value: generalInfo.customer_code, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

const portInfoValidator = portInfo => {
    if (!keyValidator(portInfo, ['plan_name', 'plan_type', 'sub_plan_type', 'goal_slot'])) {
        return false
    }
    if (
        !typeValidator([
            { value: portInfo.plan_name, type: type.STRING },
            { value: portInfo.plan_type, type: type.STRING },
            { value: portInfo.sub_plan_type, type: type.STRING },
            { value: portInfo.goal_slot, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const dateInfoValidator = dateInfo => {
    if (!keyValidator(dateInfo, ['created_date', 'last_edit_date', 'first_invest_date', 'last_trans_date'])) {
        return false
    }
    if (
        !typeValidator([
            { value: dateInfo.created_date, type: type.STRING },
            { value: dateInfo.last_edit_date, type: type.STRING },
            { value: dateInfo.first_invest_date, type: type.STRING },
            { value: dateInfo.last_trans_date, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

const basicPortInfoValidator = basicPortInfo => {
    if (!keyValidator(basicPortInfo, ['general_info', 'port_info', 'date_info'])) {
        return false
    }
    if (
        !generalInfoValidator(basicPortInfo.general_info) ||
        !portInfoValidator(basicPortInfo.port_info) ||
        !dateInfoValidator(basicPortInfo.date_info)
    ) {
        return false
    }
    return true
}

export { basicPortInfoValidator, dateInfoValidator, portInfoValidator }
