<template>
    <div v-if="useDataFn" class="dropdown__container">
        <v-select :items="list" :value="value" dense outlined @input="updateValue">
            <template v-slot:selection="data" lot-scope="data">
                <span>{{ data.item.text }}</span>
            </template>
            <template v-slot:item="data" lot-scope="data">
                <span
                    data-fn-location="customer-list"
                    data-fn-action="filter_apply"
                    :data-fn-params="mappingDataParams(data.item.text)"
                    >{{ data.item.text }}</span
                >
            </template>
        </v-select>
    </div>
    <div v-else class="dropdown__container">
        <v-select :items="list" :value="value" dense outlined @input="updateValue">
            <template v-slot:selection="data" lot-scope="data">
                <span>{{ data.item.text }}</span>
            </template>
            <template v-slot:item="data" lot-scope="data">
                <span>{{ data.item.text }}</span>
            </template>
        </v-select>
    </div>
</template>

<script>
import { dropdownListValidator } from '../../../store/modules/general/validator'

export default {
    props: {
        list: {
            type: Array,
            required: true,
            validator: dropdownListValidator
        },
        value: {
            type: String,
            required: true
        },
        useDataFn: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('value', value)
        },
        mappingDataParams(data) {
            return JSON.stringify({
                quick: data
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.dropdown__container {
    margin-bottom: -10px;
    margin-right: 14px;
    font-family: DBHeaventRez !important;
    font-size: 12px !important;
    width: 250px;
    @include multiple-responsive(mobile, phablet) {
        width: 100%;
    }
}
</style>
