import { api, requestApi } from '../../api/api'
import * as HttpStatus from 'http-status-codes'
import { getRedirectByHTTPStatus, iconsConst } from '../../utill'

import {
    buildApprovalList,
    buildRequestList,
    buildLeadCustomerInfo,
    getVisibledApprovalList,
    getVisibledLeadRequestList,
    leadRequestAction,
    leadRequestMessages
} from './config'

export const actions = {
    async getApprovalList({ commit }, body) {
        try {
            let response = await requestApi(api.leadRequestAPI.approvalList, {
                headers: { 'X-Authenticated-UUID': body.uuid }
            })
            if (response.status == HttpStatus.OK) {
                let approvalList = response.response.data
                await commit('setState', {
                    type: 'approvalList',
                    data: buildApprovalList(approvalList)
                })
                return {
                    show: false,
                    type: 'success',
                    msg: 'Load approval list success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load approval list",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when get approval list',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },

    async getLeadRequestList({ commit }, body) {
        try {
            let response = await requestApi(api.leadRequestAPI.leadRequestList, {
                headers: { 'X-Authenticated-UUID': body.uuid }
            })
            if (response.status == HttpStatus.OK) {
                let leadRequestList = response.response.data
                await commit('setState', {
                    type: 'leadRequestList',
                    data: buildRequestList(leadRequestList)
                })
                return {
                    show: false,
                    type: 'success',
                    msg: 'Load lead request list success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load approval list",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when get lead request list',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async approvalAction(_, body) {
        const { uuid, ...payload } = body
        try {
            let response = await requestApi(api.leadRequestAPI.approvalAction, {
                headers: { 'X-Authenticated-UUID': uuid },
                data: payload
            })
            if (response.status == HttpStatus.OK) {
                return {
                    show: false,
                    type: 'success',
                    msg: `${body.action.toUpperCase()} Success`,
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            }
        } catch (e) {
            if (e.response.status == HttpStatus.UNAUTHORIZED) {
                return {
                    show: true,
                    type: 'error',
                    msg: `${body.action.toUpperCase()} Failed`,
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            } else if (e.response.status == HttpStatus.UNPROCESSABLE_ENTITY) {
                try {
                    payload.action = leadRequestAction.REJECTED
                    payload.remark_message = leadRequestMessages.ALREADY_SET_IA_MSG
                    let response = await requestApi(api.leadRequestAPI.approvalAction, {
                        headers: { 'X-Authenticated-UUID': uuid },
                        data: payload
                    })
                    if (response.status == HttpStatus.OK) {
                        return {
                            show: false,
                            type: 'error',
                            msg: '422'
                        }
                    }
                } catch (err) {
                    return {
                        show: true,
                        type: 'error',
                        msg: 'Internal Server When Doing Approval Action',
                        icon: iconsConst.EXCLAM_TRI,
                        redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                        err: e.response ? e.response : e
                    }
                }
            }

            return {
                show: true,
                type: 'error',
                msg: 'Internal Server When Doing Approval Action',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async approvalAllAction(_, body) {
        const { uuid, action, ...payload } = body
        try {
            let response = await requestApi(api.leadRequestAPI.approvalAllAction, {
                headers: { 'X-Authenticated-UUID': uuid },
                data: payload
            })
            if (response.status == HttpStatus.OK) {
                return {
                    show: false,
                    type: 'success',
                    msg: `${action.toUpperCase()} Success`,
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null,
                    data: response.response.data
                }
            }
        } catch (e) {
            if (e.response.status == HttpStatus.UNAUTHORIZED) {
                return {
                    show: true,
                    type: 'error',
                    msg: `${action.toUpperCase()} Failed`,
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            } else if (e.response.status == HttpStatus.FORBIDDEN) {
                return {
                    show: true,
                    type: 'error',
                    msg: `${action.toUpperCase()} Failed`,
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }

            return {
                show: true,
                type: 'error',
                msg: 'Internal Server When Doing Approval All Action',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    getAdjustApprovalList({ state }, body) {
        return getVisibledApprovalList(state.approvalList, body)
    },
    getAdjustLeadRequestList({ state }, body) {
        return getVisibledLeadRequestList(state.leadRequestList, body)
    },
    async getLeadCustomerInfo({ commit }, body) {
        let defaultMsg = leadRequestMessages.SEARCH_FAILED
        let res = {
            status: false,
            action: 'search',
            msg: ''
        }
        try {
            let response = await requestApi(api.leadRequestAPI.search, {
                headers: { 'X-Authenticated-UUID': body.uuid },
                variable: body.portId
            })
            if (response.status == HttpStatus.OK) {
                let custInfo = response.response.data
                await commit('setState', {
                    type: 'customerInfo',
                    data: buildLeadCustomerInfo(custInfo)
                })
                res.status = true
            } else {
                res.status = false
                res.msg = res.error_message ? res.error_message : defaultMsg
            }
            return res
        } catch (e) {
            console.error(e)
            res.status = false
            if (e.response && e.response.data && e.response.data.error_message) {
                res.msg = e.response.data.error_message
            } else {
                res.msg = defaultMsg
            }
            return res
        }
    },
    async createLeadRequest(commit, payload) {
        let defaultMsg = leadRequestMessages.CREATE_FAILED
        let res = {
            status: false,
            action: 'create',
            msg: ''
        }
        try {
            let response = await requestApi(api.leadRequestAPI.create, {
                headers: { 'X-Authenticated-UUID': payload.uuid },
                data: {
                    customer_code: payload.customer_code,
                    agent_account_id: payload.agent_account_id,
                    note: payload.note
                }
            })
            if (response.status == HttpStatus.OK) {
                res.status = true
            } else {
                res.status = false
                res.msg = res.error_message ? res.error_message : defaultMsg
            }
            return res
        } catch (e) {
            console.error(e)
            res.status = false
            if (e.response && e.response.data && e.response.data.error_message) {
                res.msg = e.response.data.error_message
            } else {
                res.msg = defaultMsg
            }
            return res
        }
    }
}
