import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                highlightPrimary: '#f7a501',
                textPrimary: '#444444',
                textSecondary: '#999999',
                border: '#e4e8ea',
                button: '#e6e6e6'
            },
            dark: {
                primary: colors.yellow.accent4,
                secondary: colors.yellow.lighten2,
                darkGrey: colors.grey.darken2,
                darkGreen: colors.green.darken3,
                accent: colors.shades.black,
                error: colors.red.accent3
            }
        }
    }
})
