<template>
    <table class="table__secondary--normal">
        <thead class="head__table--primary bottom__border--solid">
            <tr>
                <template v-for="(head, idx) in headerList">
                    <th
                        :key="`column-${idx}`"
                        class="pointer__cursor"
                        :class="`horizon__align--${head.align} ${getHeaderClass(head.value)}`"
                        @click="updateSorting(head.value)"
                    >
                        {{ head.text }}
                        <i
                            v-if="sorting.by == head.value"
                            class="normal__text--primary"
                            :class="
                                sorting.order == orderByConst.ASC
                                    ? iconsConst.FULL_ARROW_DOWN
                                    : iconsConst.FULL_ARROW_UP
                            "
                        />
                    </th>
                </template>
            </tr>
        </thead>
        <tbody v-if="list.length > 0">
            <template v-for="(cust, idx) in list">
                <tr id="cust-row" :key="`cust-row-${idx}`" class="bottom__border--solid border__tablet--none lessthan">
                    <td class="horizon__align--left">
                        <div class="row__container item__align--center">
                            <link-anchor
                                :to="`${getCustomerUrl(cust)}?tab=overview`"
                                class-name="btn__secondary--normal font-bold link"
                                :external="featureCustomerInfoV2Enabled"
                            >
                                {{ cust.title_th }} {{ cust.name_th }}
                            </link-anchor>
                            <favorite-display v-if="cust.is_favorite" class="bottom__margin--small" />
                        </div>
                    </td>
                    <td class="horizon__align--left service-level--container">
                        <service-level
                            :level="cust.service_level"
                            :feature-service-level-rebranding="featureServiceLevelRebranding"
                        />
                    </td>
                    <td class="horizon__align--right  display__phablet--none lessthan">
                        <span class="normal__text--primary">{{
                            getNumberFormat(cust.cost, false, 2, '')
                        }}</span>
                    </td>
                    <td class="horizon__align--right  display__phablet--none lessthan">
                        <span class="normal__text--primary">{{
                            getNumberFormat(cust.outstanding, false, 2, '')
                        }}</span>
                    </td>
                    <td class="horizon__align--right  display__phablet--none lessthan">
                        <div class="list__container">
                            <span
                                :class="getClassNameColorForProfit(cust.unrealized_pl.percent, 2)"
                            >
                                {{ getNumberFormat(cust.unrealized_pl.value, true, 2) }}
                            </span>
                            <span
                                :class="getClassNameColorForProfit(cust.unrealized_pl.percent, 2)"
                            >
                                {{ getNumberFormat(cust.unrealized_pl.percent, true, 2, '%') }}
                            </span>
                        </div>
                    </td>
                    <td class="horizon__align--left display__tablet--none lessthan">
                        <span
                            v-if="
                                (cust.investment_status === 'Invested' ||
                                    cust.investment_status === 'Ready to Invest') &&
                                    checkOAStatus(cust.pending_detail)
                            "
                            class="normal__text--primary"
                        >
                            {{ cust.investment_status }}
                        </span>
                        <pending-detail
                            v-else
                            :pending-detail="cust.pending_detail"
                        />
                    </td>
                    <td class="horizon__align--left display__tablet--none lessthan">
                        <table-advisor :advisor-list="cust.advisors" />
                    </td>
                    <td class="horizon__align--left display__mobile--none lessthan">
                        <table-port-total-action
                            :total-action="cust.port_total_action"
                            :link="`${getCustomerUrl(cust)}?tab=invest`"
                            :is-customer-v2="featureCustomerInfoV2Enabled"
                        />
                    </td>
                </tr>
                <tr
                    id="cust-row-responsive"
                    :key="`cust-row-responsive-${idx}`"
                    class="display__desktop--none bottom__border--solid"
                >
                    <td :colspan="headerList.length" class="horizon__align--left">
                        <div class="row__container">
                            <div class="left__col--responsive list__container">
                                <table-responsive-display
                                    class="bottom__margin--small display__tablet--none"
                                    :title="'Cost:'"
                                    :value="getNumberFormat(cust.cost, false, 2, '')"
                                />
                                <table-responsive-display
                                    class="bottom__margin--small display__tablet--none"
                                    :title="'Outstanding:'"
                                    :value="getNumberFormat(cust.outstanding, false, 2, '')"
                                />
                                <table-responsive-display
                                    class="bottom__margin--small display__tablet--none"
                                    :title="'Unrealized P/L:'"
                                    :value="getUnrealizedPL(cust)"
                                    :value-color="getClassNameColorForProfit(cust.unrealized_pl.percent, 2)"
                                />
                                <table-responsive-display
                                    v-if="
                                        (cust.investment_status === 'Invested' ||
                                            cust.investment_status === 'Ready to Invest') &&
                                            checkOAStatus(cust.pending_detail)
                                    "
                                    class="bottom__margin--small display__phablet--none"
                                    :title="'Acc. Status:'"
                                    :value="cust.investment_status"
                                />
                                <table-pending-detail-responsive-display
                                    v-else
                                    class="bottom__margin--small display__phablet--none"
                                    :title="'Acc. Status:'"
                                    :pending-detail="cust.pending_detail"
                                />
                                <div
                                    class="row__container item__align--center bottom__margin--small display__tablet--none display__phablet--none"
                                >
                                    <span class="font-bold topic__text--primary right__margin--small">Advisors:</span>
                                    <table-advisor :advisor-list="cust.advisors" />
                                </div>
                                <div
                                    class="row__container item__align--center bottom__margin--small display__tablet--none display__phablet--none"
                                >
                                    <span
                                        class="font-bold topic__text--primary right__margin--small"
                                        style="margin-bottom: 0"
                                        >Action:</span
                                    >
                                    <table-port-total-action
                                        :total-action="cust.port_total_action"
                                        :link="`${getCustomerUrl(cust)}?tab=invest`"
                                        :is-customer-v2="featureCustomerInfoV2Enabled"
                                    />
                                </div>
                            </div>
                            <div class="right__col--responsive list__container">
                                <table-responsive-display
                                    v-if="
                                        (cust.investment_status === 'Invested' ||
                                            cust.investment_status === 'Ready to Invest') &&
                                            checkOAStatus(cust.pending_detail)
                                    "
                                    class="wrap__content bottom__margin--small display__tablet--none display__mobile--none"
                                    :title="'Acc. Status:'"
                                    :value="cust.investment_status"
                                />
                                <table-pending-detail-responsive-display
                                    v-else
                                    class="wrap__content bottom__margin--small display__tablet--none display__mobile--none"
                                    :title="'Acc. Status:'"
                                    :pending-detail="cust.pending_detail"
                                />
                                <div class="row__container item__align--center display__mobile--none">
                                    <span class="font-bold topic__text--primary right__margin--small">Advisors:</span>
                                    <table-advisor :advisor-list="cust.advisors" />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
        <tbody v-else>
            <tr class="bottom__border--solid">
                <td :colspan="headerList.length" class="no__result">No Result</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionsConst } from '../../../store/modules/customer/config'
import { custListValidator, headerCustListValidator, sortingValidator } from '../../../store/modules/customer/validator'
import { getClassNameColorForProfit, getNumberFormat, iconsConst, orderByConst, pagesConst } from '../../../store/utill'
import { FEATURE_CUSTOMER_INFO_V2 } from '../../../store/utill/config/constant'
import LinkAnchor from '../../atoms/LinkAnchor'
import {
    FavoriteDisplay,
    TableResponsiveDisplay,
    PendingDetail,
    TablePendingDetailResponsiveDisplay,
    ServiceLevel
} from '../../atoms'
import { TableAdvisor, TablePortTotalAction } from './index'

export default {
    components: {
        FavoriteDisplay,
        TablePortTotalAction,
        TableAdvisor,
        TableResponsiveDisplay,
        PendingDetail,
        TablePendingDetailResponsiveDisplay,
        ServiceLevel,
        LinkAnchor
    },
    props: {
        headerList: {
            type: Array,
            required: true,
            validator: headerCustListValidator
        },
        sorting: {
            type: Object,
            required: true,
            validator: sortingValidator
        },
        list: {
            type: Array,
            required: true,
            validator: custListValidator
        },
        featureServiceLevelRebranding: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            actionsConst,
            iconsConst,
            orderByConst,
            featureCustomerInfoV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSetting()
        }
    },
    created() {
        this.updateFeatureSettings()
    },
    methods: {
        ...mapActions('feature', ['isEnabled']),
        getClassNameColorForProfit,
        getNumberFormat,
        getUnrealizedPL(cust) {
            return `${this.getNumberFormat(cust.unrealized_pl.value, true, 2)} (${this.getNumberFormat(
                cust.unrealized_pl.percent,
                false,
                2,
                '%'
            )})`
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_INFO_V2).then(flag => {
                this.featureCustomerInfoV2Enabled = flag
            })
        },
        getHeaderClass(headerValue) {
            switch (headerValue) {
                case 'investment_status':
                case 'advisors':
                    return 'display__tablet--none lessthan'
                case 'cost':
                case 'outstanding':
                case 'unrealized_pl':
                    return 'display__phablet--none lessthan'
                case 'actions':
                    return 'display__mobile--none'
                default:
                    return ''
            }
        },
        updateSorting(sortBy) {
            let orderBy = this.orderByConst.ASC
            if (this.sorting.by == sortBy) {
                orderBy = this.sorting.order == this.orderByConst.ASC ? this.orderByConst.DESC : this.orderByConst.ASC
            }
            this.$emit('sorting', { by: sortBy, order: orderBy })
        },
        showMultiAdvisors(advisorList) {
            let fullName = ''
            for (let idx in advisorList) {
                fullName += idx < advisorList.length - 1 ? `${advisorList[idx].full}, ` : advisorList[idx].full
            }
            return {
                abbr: advisorList.length.toString(),
                full: fullName,
                profile_picture: {}
            }
        },
        getCustomerUrl(customer) {
            return this.featureCustomerInfoV2Enabled
                ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-info/${customer.customer_code}`
                : `${pagesConst.CUSTOMER_INFO}/${customer.customer_code}`
        },
        checkOAStatus(pendingDetail) {
            if (pendingDetail.oa_status === 'closed') {
                return false
            }
            return true
        }
    }
}
</script>

<style lang="scss" scoped>
.border__tablet--none {
    @include multiple-responsive(mobile, phablet, tablet) {
        border-bottom: none !important;
    }
}
.left__col--responsive {
    @include multiple-responsive(phablet, tablet) {
        display: flex;
        width: 50%;
    }
    @include responsive(mobile) {
        display: flex;
        width: 100%;
    }
}
.right__col--responsive {
    @include multiple-responsive(phablet, tablet) {
        display: flex;
        width: 50%;
    }
    @include responsive(mobile) {
        display: none;
    }
}
.link {
    text-decoration: none;
}
.service-level--container {
    padding-top: 16px !important;
    min-width: 120px;
}
</style>
