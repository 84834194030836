<template>
    <v-container class="list__container round__block--primary">
        <title-box
            id="btn-more-plan"
            :title="'Plan'"
            :see-more-tab="seeMoreTab"
            :show-more="showMore"
            @seeMore="seeMore"
        />
        <div class="list__container bottom__margin--normal">
            <span class="topic__text--primary"> Need PRA </span>
            <span class="big__text--red"> {{ plan.need_pra }} </span>
        </div>
        <div class="row__container item__align--center">
            <div class="list__container bottom__margin--normal width__container--fifty">
                <span class="topic__text--primary"> Invested Plan </span>
                <span class="big__text--greyPri"> {{ plan.invested_plan }} </span>
            </div>
            <div class="list__container bottom__margin--normal width__container--fifty">
                <span class="topic__text--primary"> Total Plan </span>
                <span class="big__text--greyPri"> {{ plan.total_plan }} </span>
            </div>
        </div>
    </v-container>
</template>
<script>
import { TitleBox } from '../Title'
import { planValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        plan: {
            type: Object,
            required: true,
            validator: planValidator
        }
    },
    methods: {
        seeMore(newVal) {
            this.$emit('seeMore', newVal)
        }
    }
}
</script>
