<template>
    <label
        class="row__container item__align--center checkbox"
        :class="canEdit ? 'checkbox__primary--normal' : 'checkbox__primary--disable'"
    >
        <input
            type="checkbox"
            :checked="item.checked"
            :disabled="!canEdit"
            @change="changeSelected"
        />
        <span
            v-if="showLabel && !featureServiceLevelRebranding"
            :class="showLabel ? 'left__margin--small checkbox-label' : ''"
            >{{ item.text }}</span
        >
        <span
            v-else-if="showLabel && featureServiceLevelRebranding"
            class="left__margin--small checkbox-label"
            :class="item.text == '-' ? 'service__level--text' : 'service__level--checkbox'"
        >
            <service-level :level="item.text" :feature-service-level-rebranding="featureServiceLevelRebranding" />
        </span>
        <span class="checkmark"></span>
    </label>
</template>

<script>
import { ServiceLevel } from '../Display'
import { checkboxValidator } from '../../../store/modules/general/validator'

export default {
    components: {
        ServiceLevel
    },
    props: {
        showLabel: {
            type: Boolean,
            required: false,
            default: true
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: true
        },
        item: {
            type: Object,
            required: true,
            validator: checkboxValidator
        },
        featureServiceLevelRebranding: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeSelected(event) {
            this.$emit('change', event.target.checked)
        }
    }
}
</script>
<style lang="scss" scoped>
.checkbox {
    position: relative;
    width: fit-content;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            background-color: $highlightPrimary;
            border: 2px solid $highlightPrimary;
            &:after {
                display: block;
            }
        }
    }
    .checkbox-label {
        padding-left: 18px;
    }
    .checkmark {
        position: absolute;
        top: 1;
        left: 0;
        height: 16px;
        width: 16px;
        border: 2px solid $textSecondary;
        border-radius: 2px;
        &:after {
            content: '';
            position: absolute;
            display: none;
        }
    }
}

.checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.service__level--checkbox {
    padding-top: 0.4rem;
}

.service__level--text {
    font-weight: normal;
    padding-top: 0.45rem;
    height: 2.375rem;
}
</style>
