<template>
    <div>
        <div class="pra-container container-radius">
            <div>
                <i class="fas fa-check-circle mr-2 normal__text--green size__font--16px"></i>
                <span class="normal__text--primary font-bold size__font--16px">พร้อมลงทุนแล้ว</span>
            </div>
        </div>
        <div class="pra-container container-item">
            <div class="normal__text--primary size__font--16px">
                เริ่มต้นลงทุนตามสัดส่วนโมเดลพอร์ต หลังจากนั้นคำแนะนำจะปรากฏที่นี่ หากเราพบว่าคุณ
                ควรต้องมีการปรับปรุงแผนการลงทุน
            </div>
            <div class="button-container">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <button
                            v-bind="attrs"
                            class="yellow__btn-pra--disable btn__rounded--large font-bold size__font--16px"
                            v-on="on"
                        >
                            เริ่มต้นลงทุน
                        </button>
                    </template>
                    <span>Buttons are clickable on customer's site/app.</span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
.pra-container {
    border: 1px solid $greyPrimary;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    &.container-radius {
        border-radius: 4px 4px 0 0;
        border-bottom: 0;
    }
}

.button-container {
    display: flex;
    width: 100%;
    margin-top: 16px;
    justify-content: flex-end;
    flex-direction: column;

    @include responsive(tablet) {
        flex-direction: row;
        margin-top: 32px;
    }

    @include responsive(desktop) {
        flex-direction: row;
        margin-top: 32px;
    }
}

.margin__btn-responsive {
    margin-bottom: 8px;

    @include responsive(tablet) {
        margin-bottom: unset;
        margin-right: 8px;
    }

    @include responsive(desktop) {
        margin-bottom: unset;
        margin-right: 8px;
    }
}

.margin__y--16px {
    margin: 16px 0 !important;
}

.size__font--16px {
    font-size: 14px !important;

    @include responsive(phablet) {
        font-size: 16px !important;
    }

    @include responsive(tablet) {
        font-size: 16px !important;
    }

    @include responsive(desktop) {
        font-size: 16px !important;
    }
}
</style>
