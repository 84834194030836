<template>
    <div :class="['row__container wrap__content', customClass]">
        <span class="font-bold topic__text--primary right__margin--small">{{ title }}</span>
        <span :class="valueColor">{{ value }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        valueColor: {
            type: String,
            required: false,
            default: 'normal__text--primary'
        },
        customClass: {
            type: String,
            default: null
        },
        dataAlias: {
            type: String,
            default: null
        }
    }
}
</script>
