<template>
    <div v-if="showTooltip" id="user-tooltip" class="position--relative tooltip__index">
        <div class="arrow__tooltip--up" />
        <div class="list__container tooltip--container">
            <button
                v-if="showSettingTab"
                class="normal__text--white navigation__btn row__container bottom__border--grey top__padding--smaller"
                @click="goToSettingPage"
            >
                <p class="font-bold">My Account</p>
            </button>
            <button class="normal__text--white navigation__btn row__container" @click="logout">
                <p class="font-bold">Logout</p>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        showTooltip: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showSettingTab: false
        }
    },
    computed: {
        ...mapGetters('authentication', ['getUserData'])
    },
    async created() {
        const userData = await this.getUserData
        if (!userData.isPartner) {
            this.showSettingTab = true
        }
    },
    methods: {
        logout() {
            this.$emit('logout')
        },
        goToSettingPage() {
            window.location.href = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/setting`
        }
    }
}
</script>

<style lang="scss" scoped>
.tooltip__index {
    z-index: 2;
}
</style>
