import { api, requestApi } from '../../api/api'
import * as HttpStatus from 'http-status-codes'
import { getAbbrName, iconsConst, pagesConst, getRedirectByHTTPStatus, excludeThaiVowelFromString } from '../../utill'

const buildPermissionsKey = permissions => {
    let list = {}
    for (let permission of permissions) {
        list[permission.key] = permission.id
    }
    return list
}

export const actions = {
    async createPassword(_, { password, confirmPassword, token, uuid }) {
        try {
            let response = await requestApi(api.authAPI.createPassword, {
                headers: { 'X-Authenticated-UUID': uuid },
                data: {
                    password: password,
                    confirm_password: confirmPassword,
                    token_password_reset: token
                }
            })
            if (response.status == HttpStatus.OK) {
                return {
                    show: false,
                    type: 'success',
                    msg: `Create password successfully`,
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: pagesConst.LOGIN
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: e.response && e.response.data && e.response.data.message,
                icon: iconsConst.EXCLAM_TRI,
                redirect: null,
                err: e.response ? e.response : e
            }
        }
    },
    async requestCSRFToken({ commit }) {
        try {
            let response = await requestApi(api.authAPI.CSRFToken)
            if (response.status == HttpStatus.OK) {
                let csrf = response.response.data.csrf
                await commit('setState', { type: 'csrf', data: csrf })
                return {
                    show: false,
                    type: 'success',
                    msg: 'Authenticate success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't authenticate",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when authenticate',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async login({ commit }, body) {
        try {
            let response = await requestApi(api.authAPI.login, {
                headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': body.csrf },
                data: {
                    username: body.email,
                    password: body.password
                }
            })
            if (response.status == HttpStatus.OK) {
                let res = response.response.data
                const firstNameTH = excludeThaiVowelFromString(res.first_name_th)[0]
                const lastNameTH = excludeThaiVowelFromString(res.last_name_th)[0]

                await commit('setState', { type: 'uuid', data: res.uuid })
                await commit('setState', {
                    type: 'userData',
                    data: {
                        id: res.id,
                        username: res.username,
                        email: res.email,
                        abbr: getAbbrName(`${firstNameTH} ${lastNameTH}`),
                        full: `${res.first_name_th} ${res.last_name_th}`,
                        profile_picture: res.profile_picture,
                        display_circle: true,
                        show_whats_new: res.show_whats_new
                    }
                })
                await commit('setState', {
                    type: 'permissions',
                    data: {
                        role: res.role,
                        permissions: res.permissions
                    }
                })

                response = await requestApi(api.permissionConfigAPI.permissions, {
                    headers: { 'X-Authenticated-UUID': res.uuid }
                })
                if (response.status == HttpStatus.OK) {
                    let permissions = response.response.data
                    await commit('setState', { type: 'permissionsKey', data: buildPermissionsKey(permissions) })
                } else {
                    return {
                        show: true,
                        type: 'error',
                        msg: "Can't get permission list",
                        icon: iconsConst.EXCLAM_TRI,
                        redirect: null
                    }
                }

                return {
                    show: false,
                    type: 'success',
                    msg: 'Login success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't login",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg:
                    e.response &&
                    (e.response.status == HttpStatus.UNAUTHORIZED || e.response.status == HttpStatus.FORBIDDEN)
                        ? e.response.data.message
                        : 'Internal Server error when login',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async verifyUser({ commit }, uuid) {
        try {
            let response = await requestApi(api.authAPI.verify, {
                headers: { 'X-Authenticated-UUID': uuid }
            })
            if (response.status == HttpStatus.OK) {
                let res = response.response.data
                const firstNameTH = excludeThaiVowelFromString(res.first_name_th)[0]
                const lastNameTH = excludeThaiVowelFromString(res.last_name_th)[0]

                await commit('setState', { type: 'uuid', data: res.uuid })
                await commit('setState', {
                    type: 'userData',
                    data: {
                        username: res.username,
                        email: res.email,
                        abbr: getAbbrName(`${firstNameTH} ${lastNameTH}`),
                        full: `${res.first_name_th} ${res.last_name_th}`,
                        profile_picture: res.profile_picture,
                        refcode: res.refcode_key,
                        display_circle: true,
                        isPartner: res.is_partner,
                        isExclusive: res.is_exclusive,
                        show_whats_new: res.show_whats_new
                    }
                })
                await commit('setState', {
                    type: 'permissions',
                    data: {
                        role: res.role,
                        permissions: res.permissions
                    }
                })

                response = await requestApi(api.permissionConfigAPI.permissions, {
                    headers: { 'X-Authenticated-UUID': res.uuid }
                })
                if (response.status == HttpStatus.OK) {
                    let permissions = response.response.data
                    await commit('setState', { type: 'permissionsKey', data: buildPermissionsKey(permissions) })
                } else {
                    return {
                        show: true,
                        type: 'error',
                        msg: "Can't get permission list",
                        icon: iconsConst.EXCLAM_TRI,
                        redirect: null
                    }
                }

                return {
                    show: true,
                    type: 'success',
                    msg: 'Verify user success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't verify user",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: pagesConst.LOGIN
                }
            }
        } catch (e) {
            let queryParam = ''
            if (e.response.status == 401 && window.location.pathname != '/') {
                queryParam = `?redirect_path=${window.location.href}`
            }
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when verify user',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? `${getRedirectByHTTPStatus(e.response.status)}${queryParam}` : null,
                err: e.response ? e.response : e
            }
        }
    },
    async logout({ commit }, uuid) {
        try {
            let response = await requestApi(api.authAPI.logout, {
                headers: { 'X-Authenticated-UUID': uuid }
            })
            if (response.status == HttpStatus.OK) {
                await commit('setState', { type: 'uuid', data: '' })
                await commit('setState', {
                    type: 'userData',
                    data: {}
                })
                await commit('setState', {
                    type: 'permissions',
                    data: {}
                })
                await commit('setState', { type: 'permissionsKey', data: {} })

                return {
                    show: false,
                    type: 'success',
                    msg: 'Logout success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: pagesConst.LOGIN
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't logout",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when logout',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    verifyPermissions({ state }, body) {
        let havePermissions = true
        if (state.permissions.permissions == undefined || state.permissions.role == undefined) {
            return {
                show: true,
                type: 'error',
                msg: 'Unahorized',
                icon: iconsConst.EXCLAM_TRI,
                redirect: getRedirectByHTTPStatus(HttpStatus.UNAUTHORIZED)
            }
        }
        if (body.type == 'permissions') {
            let userPermissions = state.permissions.permissions
            for (let permission of body.data) {
                if (!userPermissions.includes(permission)) {
                    havePermissions = false
                }
            }
        } else if (body.type == 'roles') {
            let userRole = state.permissions.role
            if (!body.data.includes(userRole)) {
                havePermissions = false
            }
        }

        if (havePermissions) {
            return {
                show: false,
                type: 'success',
                msg: 'Verify permissions success',
                icon: iconsConst.CHECK_CIRCLE,
                redirect: null
            }
        } else {
            return {
                show: true,
                type: 'error',
                msg: "You don't have permissions for this.",
                icon: iconsConst.EXCLAM_TRI,
                redirect: pagesConst.FORBIDDEN
            }
        }
    },
    async forgotPassword(_, { email, csrf }) {
        try {
            let response = await requestApi(api.authAPI.forgotPassword, {
                headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
                data: {
                    email: email
                }
            })
            if (response.status == HttpStatus.OK) {
                return {
                    show: false,
                    type: 'success',
                    msg: `Send forgot password successfully`,
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: pagesConst.LOGIN
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: e.response && e.response.data && e.response.data.message,
                icon: iconsConst.REGULAR_EXCLAM_CIRCLE,
                redirect: null,
                err: e.response ? e.response : e
            }
        }
    }
}
