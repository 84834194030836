<template>
    <div class="row__container sortby__content top__border--solid vertical__padding--normal horizon__padding--big">
        <button
            class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold"
            @click="buttonClicked('reset')"
        >
            Reset
        </button>
        <v-spacer />
        <button
            class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small"
            @click="buttonClicked('cancel')"
        >
            Cancel
        </button>
        <button
            class="yellow__btn--normal vertical__padding--small horizon_padding--normal font-bold"
            :data-fn-action="dataFnActionApply"
            :data-fn-params="dataFnParamsApply"
            @click="buttonClicked('apply')"
        >
            Apply
        </button>
    </div>
</template>

<script>
export default {
    props: {
        prefixAlias: {
            type: String,
            default: ''
        },
        dataFnActionApply: {
            type: String,
            default: ''
        },
        dataFnParamsApply: {
            type: String,
            default: ''
        }
    },
    methods: {
        buttonClicked(buttonName) {
            this.$emit('click', buttonName)
        }
    }
}
</script>
