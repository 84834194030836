<template>
    <div class="search--primary search--container">
        <input
            type="text"
            :value="value"
            :placeholder="placeholder"
            class="input__primary--normal fixed__input"
            @keyup="updateValue"
        />
        <i
            :class="emptySearch ? iconsConst.SEARCH : iconsConst.CROSS"
            class="icon__search--primary normal__text--secondary pointer__cursor"
            @click="clearSearch"
        />
    </div>
</template>

<script>
import { iconsConst } from '../../../store/utill'

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        dataAlias: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            iconsConst,
            emptySearch: true
        }
    },
    watch: {
        value: {
            handler(newVal) {
                this.value = newVal
                this.emptySearch = this.value == ''
            }
        }
    },
    created() {
        this.emptySearch = !this.value
    },
    methods: {
        updateValue(event) {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.emptySearch = event.target.value == ''
            this.$emit('value', event.target.value)
        },
        clearSearch() {
            if (!this.emptySearch) {
                this.$emit('value', '')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search__container {
    width: 250px;
    @include multiple-responsive(mobile, phablet) {
        width: 100%;
        min-width: 250px;
    }
}
.fixed__input {
    width: 100%;
    height: 40px;
    margin: 0px;
}
</style>
