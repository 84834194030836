import { type, keyValidator, typeValidator } from '../../../utill'

const generalInfoValidator = generalInfo => {
    for (let infoArr of generalInfo) {
        if (!typeValidator([{ value: infoArr, type: type.ARRAY }])) {
            return false
        }
        for (let info of infoArr) {
            if (!typeValidator([{ value: info, type: type.OBJECT }])) {
                return false
            }
            if (!keyValidator(info, ['topic', 'value'])) {
                return false
            }
            if (!typeValidator([{ value: info.topic, type: type.STRING }])) {
                return false
            }
        }
    }
    return true
}

const familyInfoValidator = familyInfo => {
    for (let infoArr of familyInfo) {
        if (!typeValidator([{ value: infoArr, type: type.ARRAY }])) {
            return false
        }
        for (let info of infoArr) {
            if (!typeValidator([{ value: info, type: type.OBJECT }])) {
                return false
            }
        }
    }
    return true
}

const familyListValidator = familyList => {
    for (let family of familyList) {
        if (!typeValidator([{ value: family, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(family, ['relation', 'full_name', 'personal_id', 'country'])) {
            return false
        }
        if (
            !typeValidator([
                { value: family.relation, type: type.STRING },
                { value: family.full_name, type: type.STRING },
                { value: family.personal_id, type: type.STRING },
                { value: family.country, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

export { familyInfoValidator, familyListValidator, generalInfoValidator }
