import { type, keyValidator, typeValidator } from '../../../utill'

const checkboxValidator = checkbox => {
    if (!keyValidator(checkbox, ['checked', 'text', 'dataAlias'])) {
        return false
    }
    if (
        !typeValidator([
            { value: checkbox.checked, type: type.BOOLEAN },
            { value: checkbox.text, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

const checkboxListValidator = checkboxList => {
    for (let checkbox of checkboxList) {
        if (!typeValidator([{ value: checkbox, type: type.OBJECT }])) {
            return false
        }
        if (!checkboxValidator(checkbox)) {
            return false
        }
    }
    return true
}

const dropdownListValidator = dropdownList => {
    for (let item of dropdownList) {
        if (!typeValidator([{ value: item, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(item, ['value', 'text', 'dataAlias'])) {
            return false
        }
    }
    return true
}

const attributeListValidator = attributeList => {
    for (let attr of attributeList) {
        if (!typeValidator([{ value: attr, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(attr, ['topic', 'value'])) {
            return false
        }
        if (!typeValidator([{ value: attr.topic, type: type.STRING }])) {
            return false
        }
    }
    return true
}

const checkboxTableRowsValidator = rows => {
    for (let obj of rows) {
        if (!typeValidator([{ value: obj, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(obj, ['id', 'text', 'checked'])) {
            return false
        }
        if (
            !typeValidator([
                { value: obj.id, type: type.NUMBER },
                { value: obj.text, type: type.STRING },
                { value: obj.checked, type: type.ARRAY }
            ])
        ) {
            return false
        }
        for (let check of obj.checked) {
            if (!typeValidator([{ value: check, type: type.BOOLEAN }])) {
                return false
            }
        }
    }
    return true
}

const checkboxTableColumnsValidator = columns => {
    for (let obj of columns) {
        if (!typeValidator([{ value: obj, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(obj, ['id', 'text'])) {
            return false
        }
        if (
            !typeValidator([
                { value: obj.id, type: type.NUMBER },
                { value: obj.text, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

const validationValidator = validation => {
    if (!keyValidator(validation, ['show', 'msg', 'dataAlias'])) {
        return false
    }
    if (
        !typeValidator([
            { value: validation.show, type: type.BOOLEAN },
            { value: validation.msg, type: type.STRING },
            { value: validation.dataAlias, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

const iconValidator = icon => {
    if (!keyValidator(icon, ['isIcon', 'icon'])) {
        return false
    }
    if (
        !typeValidator([
            { value: icon.isIcon, type: type.BOOLEAN },
            { value: icon.icon, type: type.STRING }
        ])
    ) {
        return false
    }
    return true
}

const iconListValidatior = iconList => {
    for (let icon of iconList) {
        if (!iconValidator(icon)) {
            return false
        }
    }
    return true
}

export {
    attributeListValidator,
    checkboxListValidator,
    checkboxTableColumnsValidator,
    checkboxTableRowsValidator,
    checkboxValidator,
    dropdownListValidator,
    iconValidator,
    iconListValidatior,
    validationValidator
}
