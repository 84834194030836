import { HTTP_REQUEST, API_PREFIX } from './apiConfig'

export const permissionConfigAPI = {
    // Permissions
    permissions: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API_AUTHORITY}/permissions` },

    // Role Config
    roleList: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API_AUTHORITY}/roles` },
    rolePermission: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API_AUTHORITY}/role-permission` },
    updateRolePermission: { method: HTTP_REQUEST.PATCH, url: `${API_PREFIX.API_AUTHORITY}/role-permission` },

    // License Config
    licenseList: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API_AUTHORITY}/licenses` },
    licensePermission: { method: HTTP_REQUEST.GET, url: `${API_PREFIX.API_AUTHORITY}/license-permission` },
    updateLicensePermission: { method: HTTP_REQUEST.PATCH, url: `${API_PREFIX.API_AUTHORITY}/license-permission` }
}
