import { type, keyValidator, typeValidator } from '../../../utill'

const planSettingValidator = planSetting => {
    if (
        !keyValidator(planSetting, [
            'target',
            'target_duration',
            'planned_first_invest',
            'planned_monthly_dca',
            'risk_level'
        ])
    ) {
        return false
    }
    if (
        !typeValidator([
            { value: planSetting.target, type: type.NUMBER },
            { value: planSetting.target_duration, type: type.NUMBER },
            { value: planSetting.planned_first_invest, type: type.NUMBER },
            { value: planSetting.planned_monthly_dca, type: type.NUMBER },
            { value: planSetting.risk_level, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const planSettingBoxValidator = planSetting => {
    if (!keyValidator(planSetting, ['see_more', 'plan_setting'])) {
        return false
    }
    if (
        !typeValidator([
            { value: planSetting.see_more, type: type.STRING },
            { value: planSetting.plan_setting, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return planSettingValidator(planSetting.plan_setting)
}

const assetChangeValidator = assetChange => {
    if (!keyValidator(assetChange, ['value', 'percent'])) {
        return false
    }
    if (
        !typeValidator([
            { value: assetChange.value, type: type.NUMBER },
            { value: assetChange.percent, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const allAssetsValidator = allAssets => {
    if (!keyValidator(allAssets, ['change', 'weight', 'weight_model', 'value'])) {
        return false
    }
    if (
        !typeValidator([
            { value: allAssets.change, type: type.OBJECT },
            { value: allAssets.weight, type: type.NUMBER },
            { value: allAssets.weight_model, type: type.NUMBER },
            { value: allAssets.value, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return assetChangeValidator(allAssets.change)
}

const assetListValidator = assetList => {
    for (let asset of assetList) {
        if (!typeValidator([{ value: asset, type: type.OBJECT }])) {
            return false
        }
        if (
            !keyValidator(asset, [
                'fund',
                'change',
                'weight',
                'weight_model',
                'value',
                'unit',
                'cost',
                'nav_cost',
                'nav_current',
                'nav_date'
            ])
        ) {
            return false
        }
        if (
            !typeValidator([
                { value: asset.fund, type: type.STRING },
                { value: asset.change, type: type.OBJECT },
                { value: asset.weight, type: type.NUMBER },
                { value: asset.weight_model, type: type.NUMBER },
                { value: asset.value, type: type.NUMBER },
                { value: asset.unit, type: type.NUMBER },
                { value: asset.cost, type: type.NUMBER },
                { value: asset.nav_cost, type: type.NUMBER },
                { value: asset.nav_current, type: type.NUMBER },
                { value: asset.nav_date, type: type.STRING }
            ])
        ) {
            return false
        }
        if (!assetChangeValidator(asset.change)) {
            return false
        }
    }
    return true
}

const assetsValidator = assets => {
    if (!keyValidator(assets, ['all_assets', 'asset_list'])) {
        return false
    }
    if (
        !typeValidator([
            { value: assets.all_assets, type: type.OBJECT },
            { value: assets.asset_list, type: type.ARRAY }
        ])
    ) {
        return false
    }
    if (!allAssetsValidator(assets.all_assets) || !assetListValidator(assets.asset_list)) {
        return false
    }
    return true
}

export { allAssetsValidator, assetListValidator, assetsValidator, planSettingValidator, planSettingBoxValidator }
