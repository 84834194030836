const permissionsConst = {
    VIEW_CUST_LIST: 'view-customer-list',
    // TODO: [Next Phase] edit 'VIEW_CUST_DETAIL' to 'VIEW_CUST_INFO' and 'customer-object' to 'customer-info'
    VIEW_CUST_DETAIL: 'view-customer-object',
    VIEW_PORTFOLIO_LIST: 'view-portfolio-list',
    VIEW_PORT_LIST: 'view-port-list',
    VIEW_PORT_INFO: 'view-port-info',
    VIEW_LEAD_REQUEST: 'view-lead-request',
    VIEW_LEAD_APPROVE: 'view-lead-approve',
    VIEW_COMMISSION_REPORT: 'view-commission-report',
    VIEW_OUTSTANDING_LIST: 'view-outstanding-list',
    VIEW_GNAVI: 'view-gnavi',
    VIEW_DATA_EXPORT: 'view-data-export',
    VIEW_PROPOSAL_ORDER: 'view-proposal-order'
}

const rolesConst = {
    SUPER_ADMIN: 'super-admin',
    ADMIN: 'admin'
}

const planTypesConst = {
    GOAL: 'goal-based',
    FIRST_MILLION: '1stm',
    DIY: 'space'
}

const chartColors = ['#5c90f3', '#de4577', '#be3443', '#de3c3f', '#f49831', '#fdc527', '#d2e25a', '#5875c2']

const pagesConst = {
    HOME: '/home',
    NOT_FOUND: '/not-foud',
    FORBIDDEN: '/forbidden',
    LOGIN: '/login',
    CUSTOMER_INFO: '/customer-info',
    CUSTOMER_LIST: '/customer-list',
    LEAD_REQUEST: '/lead-request',
    LEAD_REQUEST_APPROVAL: '/lead-request-approval',
    // SETTING: '/setting',
    PORT_INFO: '/port-info',
    PORT_LIST: '/port-list',
    PERMISSION_CONFIG: '/permission-config',
    CREATE_PASSWORD: '/create-password',
    FORGOT_PASSWORD: '/forgot/password'
}

const headerTitle = {
    ADVISOR: { original: 'ADVISOR', rebranding: 'F4A' },
    HOME: { original: 'HOME', rebranding: 'HOME' },
    PERMISSION_CONFIGURATION: { original: 'PERMISSION CONFIGURATION', rebranding: 'PERMISSION CONFIGURATION' },
    SETTING: { origin: 'SETTING', rebranding: 'SETTING' }
}

const orderType = {
    ALL: 'all',
    BUY: 'buy',
    SELL: 'sell',
    SWITCH: 'switch',
    TRANSFER: 'transfer',
    TRANS_IN: 'trans_in',
    TRANS_OUT: 'trans_out',
    SWITCH_CROSS_AMC: 'switchcrossamc'
}

const orderTypeDisplay = {
    BUY: 'Buy',
    SELL: 'Sell',
    SWITCH: 'Switch'
}

const distributionType = {
    DIVIDEND: 'ปันผล',
    AR: 'Auto Redem.'
}

const embedType = {
    IMAGE: 'image/jpg',
    WEB: 'text/html',
    VIDEO: 'video/webm'
}

const iconsConst = {
    ARROW_UP: 'far fa-chevron-up',
    ARROW_DOWN: 'far fa-chevron-down',
    ARROW_LEFT: 'fal fa-chevron-left',
    ARROW_RIGHT: 'fal fa-chevron-right',
    LIGHT__ARROW__RIGHT: 'far fa-long-arrow-alt-right',
    LONG_ARROW_RIGHT: 'far fa-long-arrow-right',
    SOLID_EXCLAM_CIRCLE: 'far fa-exclamation-circle',
    REGULAR_EXCLAM_CIRCLE: 'fas fa-exclamation-circle',
    EXCLAM_TRI: 'far fa-exclamation-triangle',
    EXTERNAL_LINK: 'far fa-external-link',
    CHECK_CIRCLE: 'far fa-check-circle',
    INFO_CIRCLE: 'far fa-info-circle',
    FULL_ARROW_UP: 'fas fa-caret-up',
    FULL_ARROW_DOWN: 'fas fa-caret-down',
    SORT: 'far fa-sort',
    FILTER: 'far fa-filter',
    ASC_SORT: 'far fa-sort-alpha-down',
    DESC_SORT: 'far fa-sort-alpha-up',
    CROSS: 'far fa-times',
    CROSS_SQUARE: 'far fa-times-square',
    EMPTY_STAR: 'far fa-star',
    FULL_STAR: 'fas fa-star',
    SEARCH: 'far fa-search',
    HAM_MENU: 'far fa-bars',
    SERVICE_LEVEL: 'fas fa-medal',
    PERCENTAGE: 'far fa-percent',
    FILE_PIE: 'far fa-file-chart-pie',
    HOURGLASS: 'far fa-hourglass-half',
    SAD_FACE: 'far fa-frown',
    REFRESH: 'far fa-sync',
    CIRCLE: 'fas fa-circle',
    CALENDAR: 'far fa-calendar',
    CHECKMARK: 'fas fa-check',
    PLUS: 'far fa-plus',
    SPINNER: 'fas fa-spinner',
    CHECK_DOUBLE: 'fas fa-check-double',
    COMMENT_LINES: 'far fa-comment-alt-lines'
}

const orderByConst = {
    ASC: 'asc',
    DESC: 'desc'
}

const loadingConst = {
    COLOR: '#f7a501'
}

const url = {
    FINNO_FUND: 'https://www.finnomena.com/fund/'
}

const httpStatus = {
    NOT_FOUND: 404
}

const bankConst = {
    BOT: 'แห่งประเทศไทย',
    BBL: 'กรุงเทพ',
    KBANK: 'กสิกรไทย',
    ABN: 'เดอะรอยัลแบงก์ฯ',
    KTB: 'กรุงไทย',
    JPM: 'เจพีมอร์แกนฯ',
    OCBC: 'โอซีบีซี',
    BTMU: 'แห่งโตเกียวฯ',
    TMB: 'ทหารไทย',
    SCB: 'ไทยพาณิชย์',
    CITI: 'ซิตี้แบงก์',
    SMBC: 'ซูมิโตโม',
    SCBT: 'สแตนดาร์ดชาร์เตอร์ด (ไทย)',
    CIMBT: 'ซีไอเอ็มบี ไทย',
    RHB: 'อาร์ เอช บี',
    UOB: 'ยูโอบี',
    BAY: 'กรุงศรีอยุธยา',
    MEGA: 'เมกะฯ',
    BA: 'แห่งอเมริกา',
    CALYON: 'เครดิต อะกริกอลฯ',
    IOB: 'อินเดียนโอเวอร์ซีส์',
    GSB: 'ออมสิน',
    HSBC: 'ฮ่องกงและเซี่ยงไฮ้ฯ',
    DB: 'ดอยซ์แบงก์',
    GHB: 'อาคารสงเคราะห์',
    BAAC: 'เพื่อการเกษตรฯ',
    EXIM: 'เพื่อการส่งออกฯ',
    MHCB: 'มิซูโฮ',
    BNP: 'บีเอ็นพี',
    BOC: 'แห่งประเทศจีน',
    TBANK: 'ธนชาต',
    IBANK: 'อิสลามแห่งประเทศไทย',
    TISCO: 'ทิสโก้',
    KK: 'เกียรตินาคิน',
    ICBC: 'ไอซีบีซี (ไทย)',
    TCRB: 'ไทยเครดิต',
    'LH BANK': 'แลนด์แอนด์เฮ้าส์',
    SME: 'พัฒนาวิสาหกิจฯ'
}

const actionStatusDisplay = {
    NEED_ADJUSTMENT: 'PRA',
    NEED_CHECKUP: 'Checkup',
    NEED_TOPUP_MIN_INVEST: 'Top-up',
    PENDING_ORDER: 'Pending',
    NO_ACTION: 'No'
}

const actionStatusKey = {
    NEED_ADJUSTMENT: 'need-adjustment',
    NEED_CHECKUP: 'need-checkup',
    NEED_TOPUP_MIN_INVEST: 'need-topup-min-invest',
    PENDING_ORDER: 'pending-order',
    OPTIONAL_ADJUSTMENT: 'optional-adjustment',
    TRADE_MAINTAIN: 'trade-maintain'
}

const displayAccountStatus = {
    ACCOUNT_CLOSE: 'ACCOUNT_CLOSE',
    ACCOUNT_LACK_INFO: 'ACCOUNT_LACK_INFO',
    ACCOUNT_UPDATING: 'ACCOUNT_UPDATING',
    ACCOUNT_FREEZE: 'ACCOUNT_FREEZE',
    NONE: 'NONE'
}

const praDCAStatusType = {
    NEED_ADJUSTMENT: 'need-adjustment',
    NEED_SET: 'need-set',
    NEED_REMOVE: 'need-remove',
    MATCH: 'match',
    NONE: 'none'
}

export const FEATURE_CUSTOMER_INFO_V2 = 'advisor.customer-info-v2.release'
export const FEATURE_CUSTOMER_LIST_V2 = 'advisor.customer-list-v2.release'
export const F4A_SERVICE_LEVEL_REBRANDING = 'advisor.service-level-rebranding.release'
export const F4A_LOGO_REBRANDING = 'advisor.logo-rebranding.release'

export {
    chartColors,
    distributionType,
    embedType,
    iconsConst,
    loadingConst,
    orderByConst,
    orderType,
    pagesConst,
    headerTitle,
    permissionsConst,
    planTypesConst,
    rolesConst,
    url,
    httpStatus,
    bankConst,
    actionStatusDisplay,
    actionStatusKey,
    displayAccountStatus,
    orderTypeDisplay,
    praDCAStatusType
}
