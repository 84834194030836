<template>
    <v-container class="list__container round__block--primary">
        <title-box id="btn-more-plan" :title="'Model Port'" :see-more-tab="''" :show-more="false" />
        <div class="row__container responsive__chart">
            <div class="right__margin--small bottom__margin--normal pie__chart">
                <pie-chart :chart-data="chartData" :chart-option="chartOption" />
            </div>
            <div class="list__container">
                <span v-for="(label, idx) in labels" :key="`label-${idx}`" class="row__container label__font">
                    <i :class="iconsConst.CIRCLE" class="right__margin--small" :style="`color: ${chartColors[idx]}`" />
                    {{ label }}
                </span>
            </div>
        </div>
    </v-container>
</template>
<script>
import { categoriesValidator } from '../../../store/modules/port/validator'
import { chartColors, iconsConst } from '../../../store/utill'
import { PieChart } from '../../atoms'
import { TitleBox } from '../Title'

export default {
    components: {
        PieChart,
        TitleBox
    },
    props: {
        categories: {
            type: Array,
            required: true,
            validator: categoriesValidator
        }
    },
    data() {
        return {
            chartColors,
            iconsConst,
            series: [],
            labels: [],
            chartData: {},
            chartOption: {}
        }
    },
    mounted() {
        for (let category of this.categories) {
            this.series.push(category.percent)
            this.labels.push(`${category.percent}% ${category.name}`)
        }

        this.chartData = {
            datasets: [
                {
                    borderWidth: 0,
                    backgroundColor: this.chartColors,
                    data: this.series
                }
            ]
        }

        this.chartOption = {
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: 2,
            cutoutPercentage: 50,
            tooltips: {
                enabled: false
            },
            legend: {
                display: false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.responsive__chart {
    justify-content: center;
    @include responsive(mobile) {
        flex-wrap: wrap;
    }
}
.pie__chart {
    width: 140px;
}
.label__font {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 8px;
}
</style>
