<template>
    <div class="list__container">
        <div class="row__container item__align--center bottom__margin--small">
            <span class="topic__text--primary">Plan Name: </span>
            <span class="normal__text--primary font-bold">{{ info.plan_name }}</span>
        </div>
        <div class="row__container item__align--center bottom__margin--small">
            <span class="topic__text--primary">Plan Type: </span
            ><span class="normal__text--primary font-bold">{{ info.plan_type }}</span>
        </div>
        <div class="row__container item__align--center bottom__margin--small">
            <span class="topic__text--primary">Sub-Type: </span
            ><span class="normal__text--primary font-bold">{{ info.sub_plan_type }}</span>
        </div>
        <div class="row__container item__align--center">
            <span class="topic__text--primary">Goal Slot: </span
            ><span class="normal__text--primary font-bold">{{ info.goal_slot }}</span>
        </div>
    </div>
</template>

<script>
import { portInfoValidator } from '../../../store/modules/port/validator'

export default {
    props: {
        info: {
            type: Object,
            required: true,
            validator: portInfoValidator
        }
    }
}
</script>
