<template>
    <div v-if="featureServiceLevelRebranding">
        <span v-if="getServiceLevelNameRebranding(level)">
            <img
                :src="getServiceLevelImagePath(getServiceLevelNameRebranding(level))"
                height="24"
            />
        </span>
        <span v-else>-</span>
    </div>
    <div v-else>
        <i v-if="isShowCrown(level)" class="fa fa-crown mr-1 text-pink" :class="getColor(level)" />
        <span :class="getColor(level)" class="font-bold size__font--14px">
            {{ getServiceLevelName(level) }}
        </span>
    </div>
</template>

<script>
import { getServiceLevelImagePath } from '../../../store/utill/generalFormat'

export default {
    props: {
        level: {
            type: String,
            required: true
        },
        featureServiceLevelRebranding: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            getServiceLevelImagePath
        }
    },
    methods: {
        isShowCrown(level) {
            switch (level) {
                case '-':
                case 'Club':
                case 'Member':
                case 'Exclusive':
                case 'Exclusive Prime':
                    return false
                default:
                    return true
            }
        },
        getColor(level) {
            switch (level) {
                case 'Exclusive':
                case 'Exclusive Prime':
                    return 'text-orange'
                case 'FPW':
                case 'FPW Plus':
                case 'Private':
                case 'Private Plus':
                    return 'text-blue'
                case 'FPB':
                case 'FPB Ex':
                case 'FPB Ultra':
                case 'Ultra':
                case 'Ultra Ex':
                case 'Ultra Ultra':
                    return 'text-pink'
                default:
                    return ''
            }
        },
        getServiceLevelName(level) {
            switch (level) {
                case 'Club':
                case 'Member':
                    return 'Member'
                case 'FPW':
                case 'Private':
                    return 'FPW'
                case 'FPW Plus':
                case 'Private Plus':
                    return 'FPW Plus'
                case 'FPB':
                case 'Ultra':
                    return 'FPB'
                case 'FPB Ex':
                case 'Ultra Ex':
                    return 'FPB Ex'
                case 'FPB Ultra':
                case 'Ultra Ultra':
                    return 'FPB Ultra'
                case 'Exclusive':
                    return 'Exclusive'
                case 'Exclusive Prime':
                    return 'Exclusive Prime'
                default:
                    return '-'
            }
        },
        getServiceLevelNameRebranding(level) {
            switch (level) {
                case 'Club':
                case 'Member':
                    return 'club'
                case 'FPW':
                case 'Private':
                    return 'private'
                case 'FPW Plus':
                case 'Private Plus':
                    return 'private-plus'
                case 'FPB':
                case 'Ultra':
                    return 'ultra'
                case 'FPB Ex':
                case 'Ultra Ex':
                    return 'ultra-ex'
                case 'FPB Ultra':
                case 'Ultra Ultra':
                    return 'ultra-ultra'
                case 'Exclusive':
                    return 'exclusive'
                case 'Exclusive Prime':
                    return 'exclusive-prime'
                default:
                    return ''
            }
        }
    }
}
</script>

<style scoped>
.text-pink {
    color: #ce8f7e;
}
.text-blue {
    color: #2e6c8d;
}
.text-orange {
    color: #e97200;
}
</style>
