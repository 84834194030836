<template>
    <button class="decline__btn" @click="$emit('click')">
        <i :class="iconsConst.CROSS"></i>
    </button>
</template>

<script>
import { iconsConst } from '../../../store/utill'
export default {
    data() {
        return {
            iconsConst
        }
    }
}
</script>

<style lang="scss" scoped>
.decline__btn {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: $greySecondary;
    color: $redPrimary;
}
</style>
