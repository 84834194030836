import { type, keyValidator, typeValidator } from '../../../utill'

const pathValidator = path => {
    for (let obj of path) {
        if (!typeValidator([{ value: obj, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(obj, ['text', 'link'])) {
            return false
        }
        if (
            !typeValidator([
                { value: obj.text, type: type.STRING },
                { value: obj.link, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

const headerValidator = header => {
    if (!keyValidator(header, ['title', 'path'])) {
        return false
    }
    if (
        !typeValidator([
            { value: header.title, type: type.STRING },
            { value: header.path, type: type.ARRAY }
        ])
    ) {
        return false
    }
    return pathValidator(header.path)
}

export { headerValidator, pathValidator }
