var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.completedData)?_c('div',{staticClass:"general__modal filter__modal",attrs:{"data-fn-location":"filter-modal"}},[_c('title-modal',{attrs:{"title":'Filter',"prefix-alias":"filter"},on:{"close":_vm.close}}),_c('div',{staticClass:"horizon__padding--big top__padding--big"},[_c('line-checkbox',{staticClass:"bottom__margin--normal",attrs:{"title":"Favorite","list":[
                {
                    checked: _vm.filterTmp.favorite_status.favorite,
                    text: 'Favorite',
                    dataAlias: 'filter-favorite-check'
                },
                { checked: _vm.filterTmp.favorite_status.regular, text: 'Regular', dataAlias: 'filter-regular-check' }
            ]},on:{"change":function (list) { return _vm.updateFilter('favorite_status', list); }}}),_c('range-input',{staticClass:"bottom__margin--normal",attrs:{"title":"Cost","min":_vm.filterTmp.cost.min,"max":_vm.filterTmp.cost.max,"icons":[
                { isIcon: false, icon: '฿' },
                { isIcon: false, icon: '฿' }
            ],"validation":{
                show: _vm.validation.cost,
                msg: 'ค่า Cost ไม่ถูกต้อง'
            }},on:{"range":function (range) { return _vm.updateFilter('cost', range); }}}),_c('range-input',{staticClass:"bottom__margin--normal",attrs:{"title":"Outstanding","min":_vm.filterTmp.outstanding.min,"max":_vm.filterTmp.outstanding.max,"icons":[
                { isIcon: false, icon: '฿' },
                { isIcon: false, icon: '฿' }
            ],"validation":{
                show: _vm.validation.outstanding,
                msg: 'ค่า Outstanding ไม่ถูกต้อง'
            }},on:{"range":function (range) { return _vm.updateFilter('outstanding', range); }}}),_c('range-input',{staticClass:"bottom__margin--normal",attrs:{"title":"Unrealized P/L","min":_vm.filterTmp.unrealized_pl.min,"max":_vm.filterTmp.unrealized_pl.max,"icons":[
                { isIcon: true, icon: _vm.iconsConst.PERCENTAGE },
                { isIcon: true, icon: _vm.iconsConst.PERCENTAGE }
            ],"validation":{
                show: _vm.validation.unrealized_pl,
                msg: 'ค่า Unrealized P/L ไม่ถูกต้อง'
            },"allow-negative-number":true},on:{"range":function (range) { return _vm.updateFilter('unrealized_pl', range); }}}),_c('autocomplete',{attrs:{"label":"Contain Fund:","items":_vm.funds,"item-text":"fund","item-value":"fund","value":_vm.filterTmp.funds},on:{"change":function (val) { return _vm.updateFilter('funds', val); }}}),_c('autocomplete',{attrs:{"label":"Contain Plan:","value":_vm.filterTmp.plan_type,"item-text":"default_name","item-value":"plan_type_shortcode","items":_vm.planTypes,"max-selected":1},on:{"change":function (val) { return _vm.updateFilter('plan_type', val); }}}),_c('line-checkbox',{staticClass:"bottom__margin--bigger",attrs:{"title":"Account Status","list":[
                {
                    checked: _vm.filterTmp.investment_status.ready_to_invest,
                    text: 'Ready to Invest',
                    dataAlias: 'filter-ready_to_invest-check'
                },
                {
                    checked: _vm.filterTmp.investment_status.invested,
                    text: 'Invested',
                    dataAlias: 'filter-invested-check'
                },
                {
                    checked: _vm.filterTmp.investment_status.closed,
                    text: 'Freeze',
                    dataAlias: 'filter-oa_closed-check'
                },
                {
                    checked: _vm.filterTmp.investment_status.oa_reviewing,
                    text: 'Reviewing',
                    dataAlias: 'filter-oa_reviewing-check'
                },
                {
                    checked: _vm.filterTmp.investment_status.oa_rejected,
                    text: 'OA Rejected',
                    dataAlias: 'filter-oa_rejected-check'
                },
                {
                    checked: _vm.filterTmp.investment_status.ats,
                    text: 'ATS Required',
                    dataAlias: 'filter-ats_required-check'
                }
            ],"section":"account_status","feature-service-level-rebranding":_vm.featureServiceLevelRebranding},on:{"change":function (list) { return _vm.updateFilter('investment_status', list); }}}),(_vm.featureServiceLevelRebranding)?_c('line-checkbox',{staticClass:"bottom__margin--bigger",attrs:{"title":"Service Level","list":[
                {
                    checked: _vm.filterTmp.service_level.none,
                    text: '-',
                    dataAlias: 'filter-service_level_none-check'
                },
                {
                    checked: _vm.filterTmp.service_level.club,
                    text: 'Club',
                    dataAlias: 'filter-service_level_club-check'
                },
                {
                    checked: _vm.filterTmp.service_level.exclusive,
                    text: 'Exclusive',
                    dataAlias: 'filter-service_level_exclusive-check'
                },
                {
                    checked: _vm.filterTmp.service_level.exclusive_prime,
                    text: 'Exclusive Prime',
                    dataAlias: 'filter-service_level_exclusive_prime-check'
                },
                {
                    checked: _vm.filterTmp.service_level.private,
                    text: 'Private',
                    dataAlias: 'filter-service_level_private-check'
                },
                {
                    checked: _vm.filterTmp.service_level.private_plus,
                    text: 'Private Plus',
                    dataAlias: 'filter-service_level_private_plus-check'
                },
                {
                    checked: _vm.filterTmp.service_level.ultra,
                    text: 'Ultra',
                    dataAlias: 'filter-service_level_ultra-check'
                },
                {
                    checked: _vm.filterTmp.service_level.ultra_ex,
                    text: 'Ultra Ex',
                    dataAlias: 'filter-service_level_ultra-check'
                },
                {
                    checked: _vm.filterTmp.service_level.ultra_ultra,
                    text: 'Ultra Ultra',
                    dataAlias: 'filter-service_level_ultra_ultra-check'
                }
            ],"section":"service_level","feature-service-level-rebranding":_vm.featureServiceLevelRebranding},on:{"change":function (list) { return _vm.updateFilter('service_level', list); }}}):_c('line-checkbox',{staticClass:"bottom__margin--bigger",attrs:{"title":"Service Level","list":[
                {
                    checked: _vm.filterTmp.service_level.none,
                    text: '-',
                    dataAlias: 'filter-service_level_none-check'
                },
                {
                    checked: _vm.filterTmp.service_level.member,
                    text: 'Member',
                    dataAlias: 'filter-service_level_member-check'
                },
                {
                    checked: _vm.filterTmp.service_level.exclusive,
                    text: 'Exclusive',
                    dataAlias: 'filter-service_level_exclusive-check'
                },
                {
                    checked: _vm.filterTmp.service_level.exclusive_prime,
                    text: 'Exclusive Prime',
                    dataAlias: 'filter-service_level_exclusive_prime-check'
                },
                {
                    checked: _vm.filterTmp.service_level.fpw,
                    text: 'FPW',
                    dataAlias: 'filter-service_level_fpw-check'
                },
                {
                    checked: _vm.filterTmp.service_level.fpw_plus,
                    text: 'FPW Plus',
                    dataAlias: 'filter-service_level_fpw_plus-check'
                },
                {
                    checked: _vm.filterTmp.service_level.fpb,
                    text: 'FPB',
                    dataAlias: 'filter-service_level_fpb-check'
                },
                {
                    checked: _vm.filterTmp.service_level.fpb_ex,
                    text: 'FPB Ex',
                    dataAlias: 'filter-service_level_fpb_ex-check'
                },
                {
                    checked: _vm.filterTmp.service_level.fpb_ultra,
                    text: 'FPB Ultra',
                    dataAlias: 'filter-service_level_fpb_ultra-check'
                }
            ],"section":"service_level","feature-service-level-rebranding":_vm.featureServiceLevelRebranding},on:{"change":function (list) { return _vm.updateFilter('service_level', list); }}}),_c('line-checkbox',{staticClass:"bottom__margin--bigger",attrs:{"title":"Show Only","list":[
                {
                    checked: _vm.filterTmp.others.has_action_required,
                    text: 'Has Action Required',
                    dataAlias: 'filter-action-btn'
                },
                {
                    checked: _vm.filterTmp.others.has_dca_profiles,
                    text: 'Has DCA Profiles',
                    dataAlias: 'filter-dca-btn'
                }
            ]},on:{"change":function (list) { return _vm.updateFilter('others', list); }}})],1),_c('line-button',{attrs:{"prefix-alias":"filter","data-fn-action-apply":"filter_apply","data-fn-params-apply":_vm.combinedDataParams},on:{"click":_vm.buttonClicked}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }