<template>
    <div>
        <span
            class="right__margin--small"
            :class="getTextColorClassByType(type) + (type == leadRequestStatus.PENDING ? '' : ' font-bold')"
        >
            <i :class="getPrefixIcon" />
            {{ capitalizeFirstLetter }}
        </span>
    </div>
</template>

<script>
import { getTextColorClassByType, iconsConst } from '../../../store/utill'
import { leadRequestStatus } from '../../../store/modules/leadRequest/config'

export default {
    props: {
        type: {
            type: String,
            required: true
        }
    },
    data: () => {
        return {
            iconsConst,
            leadRequestStatus
        }
    },
    computed: {
        getPrefixIcon() {
            if (this.type == leadRequestStatus.REJECTED) {
                return iconsConst.CROSS
            } else if (this.type == leadRequestStatus.APPROVED) {
                return iconsConst.CHECKMARK
            }
            return ''
        },
        capitalizeFirstLetter() {
            return this.type.charAt(0).toUpperCase() + this.type.slice(1)
        }
    },
    methods: {
        getTextColorClassByType
    }
}
</script>
