<template>
    <div>
        <slot v-if="variant.enabled" :name="variant.name">
            <slot name="fallback"></slot>
        </slot>
        <slot v-else name="fallback"></slot>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        feature: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            variant: {
                name: 'fallback',
                enabled: false
            }
        }
    },
    computed: {
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateVariantSetting()
        }
    },
    created() {
        this.updateVariantSetting()
    },
    methods: {
        ...mapActions('feature', ['getVariant']),
        getDebugVariant() {
            const debugVariant = this.$cookies.get(`Debug-Variant-${this.feature}`)
            this.getVariant(this.feature).then(variant => {
                this.variant.name = debugVariant || variant.name || 'fallback'
                this.variant.enabled = variant.enabled || false
            })
        },
        getDebugVariantName() {
            const debugVariant = this.$cookies.get(`Debug-Variant-${this.feature}`)
            if (debugVariant) {
                this.variant.name = debugVariant
            } else {
                this.getVariant(this.feature).then(variant => {
                    this.variant.name = variant.name || 'fallback'
                })
            }
        },
        updateVariantSetting() {
            // enable debug feature functionality for development build
            if (process.env.VUE_APP_ALLOW_DEBUG_TOOLS === 'true') {
                const debugFlag = this.$cookies.get(`Debug-Feature-${this.feature}`)
                switch (debugFlag) {
                    case 'true':
                        this.getDebugVariantName()
                        this.variant.enabled = true
                        break
                    case 'false':
                        this.getDebugVariantName()
                        this.variant.enabled = false
                        break
                    default:
                        this.getDebugVariant()
                        break
                }
            } else {
                this.getVariant(this.feature).then(variant => {
                    this.variant.name = variant.name || 'fallback'
                    this.variant.enabled = variant.enabled || false
                })
            }
        }
    }
}
</script>
