<template>
    <div v-if="activeDataFn" class="tab__bar">
        <div
            v-for="(tab, idx) in tabs"
            :id="`btn-tab-${idx + 1}`"
            :key="`tab-${idx + 1}`"
            class="font-bold right__margin--big"
            :class="tab == activeTab ? 'btn__primary--active' : 'btn__primary--normal'"
            data-fn-action="tab_change"
            :data-fn-params="getDataFnLocationAndAction(tab)"
            @click="selectTab(tab)"
        >
            {{ tab }}
        </div>
    </div>
    <div v-else class="tab__bar">
        <div
            v-for="(tab, idx) in tabs"
            :id="`btn-tab-${idx + 1}`"
            :key="`tab-${idx + 1}`"
            class="big__text--primary font-bold right__margin--big"
            :class="tab == activeTab ? 'btn__primary--active' : 'btn__primary--normal'"
            :v-if="tabs.UPCOMING"
            @click="selectTab(tab)"
        >
            {{ tab }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Object,
            required: true
        },
        activeTab: {
            type: String,
            required: true
        },
        activeDataFn: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            dataFnLocation: '',
            dataFnAction: ''
        }
    },
    methods: {
        selectTab(tabIdx) {
            this.$emit('activeTab', tabIdx)
        },
        getDataFnLocationAndAction(tab) {
            switch (tab) {
                case this.tabs.CURRENT:
                    return 'port-pra-current'
                case this.tabs.UPCOMING:
                    return 'port-pra-upcoming'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tab__bar {
    display: flex;
    flex-direction: row;
    padding-bottom: 32px;
    overflow-x: auto;
    overflow-y: hidden;
}

.big__text--primary {
    font-size: 16px !important;

    @include responsive(phablet) {
        font-size: 20px !important;
    }

    @include responsive(tablet) {
        font-size: 20px !important;
    }

    @include responsive(desktop) {
        font-size: 20px !important;
    }
}
</style>
