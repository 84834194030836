import { api, requestApi } from '../../api/api'
import { iconsConst, getRedirectByHTTPStatus } from '../../../store/utill'
import * as HttpStatus from 'http-status-codes'

export const mutations = {
    setState(state, data) {
        state[data.type] = data.data
    },
    async setFavoriteStatus(state, payload) {
        try {
            let response = await requestApi(api.favoriteAPI.updateFavorite, {
                headers: { 'X-Authenticated-UUID': payload.uuid },
                data: {
                    action: payload.action,
                    type: 'customer',
                    identifier: payload.id
                }
            })
            if (response.status == HttpStatus.OK) {
                state['favoriteStatus'] = payload.updatedStatus
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't Update favorite status",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when set favorite status',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    }
}
