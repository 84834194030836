<template>
    <v-container>
        <v-alert v-if="alert.show" :type="alert.type" text :icon="alert.icon">{{ alert.msg }}</v-alert>
        <v-container class="list__container">
            <title-action
                :prefix="'license'"
                :title="`License 's Permissions`"
                :can-edit="canEdit"
                @canEdit="updateCanEdit"
            />
            <table-checkbox
                :prefix="'licenses'"
                :rows="permissions"
                :columns="licenses"
                :can-edit="canEdit"
                @change="updatePermission"
            />
        </v-container>
    </v-container>
</template>

<script>
import * as HttpStatus from 'http-status-codes'
import { api, requestApi } from '../../../store/api/api'
import { iconsConst } from '../../../store/utill'
import { TableCheckbox, TitleAction } from '../../molecules'

export default {
    components: {
        TableCheckbox,
        TitleAction
    },
    data() {
        return {
            licenses: [],
            permissions: [],
            permissionsChanged: {},
            canEdit: false,
            alert: {},
            iconsConst
        }
    },
    async beforeMount() {
        try {
            let canGetAllData = true

            // get license list
            // TODO: [Next Phase] move to store
            let response = await requestApi(api.permissionConfigAPI.licenseList)
            if (response.status == HttpStatus.OK) {
                let licenseList = response.response.data
                for (let license of licenseList) {
                    this.licenses.push({
                        id: license.id,
                        text: license.license_name_en
                    })
                }
            } else {
                canGetAllData = false
            }

            // get license permissions
            let status = await this.getLicensePermissions()
            if (status != HttpStatus.OK) {
                canGetAllData = false
            }

            // check data
            if (!canGetAllData) {
                this.alert = {
                    show: true,
                    type: 'error',
                    msg: "Can't load all data when get license list and license permissions",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            this.alert = {
                show: true,
                type: 'error',
                msg: `Internal Server error when get license list and license permissions`,
                icon: iconsConst.EXCLAM_TRI,
                redirect: null,
                err: e.response ? e.response : e
            }
        }
    },
    methods: {
        async getLicensePermissions() {
            // TODO: [Next Phase] move to store
            let response = await requestApi(api.permissionConfigAPI.licensePermission)
            if (response.status == HttpStatus.OK) {
                this.permissions = []
                this.permissionsChanged = {}
                let permissionList = response.response.data
                for (let permission of permissionList) {
                    let checked = []
                    for (let license of this.licenses) {
                        checked.push(permission.has_license.includes(license.id))
                    }
                    this.permissions.push({
                        id: permission.permission_id,
                        text: permission.permission_name,
                        checked
                    })
                    this.permissionsChanged[permission.permission_id] = [...checked]
                }
            }
            return response.status
        },
        async updateCanEdit(newVal) {
            this.alert = {
                show: false,
                type: '',
                msg: '',
                icon: '',
                redirect: null
            }

            if (this.canEdit) {
                try {
                    let data = {}
                    let hasChange = false
                    this.licenses.forEach((license, idx) => {
                        let obj = {
                            add: [],
                            remove: []
                        }
                        this.permissions.forEach(permission => {
                            if (permission.checked[idx] != this.permissionsChanged[permission.id][idx]) {
                                if (permission.checked[idx]) {
                                    obj['remove'].push(permission.id)
                                } else {
                                    obj['add'].push(permission.id)
                                }
                                hasChange = true
                            }
                        })
                        data[license.id] = obj
                    })

                    if (hasChange) {
                        // TODO: [Next Phase] move to store
                        let response = await requestApi(api.permissionConfigAPI.updateLicensePermission, { data })
                        if (response.status == HttpStatus.OK) {
                            this.alert = {
                                show: true,
                                type: 'success',
                                msg: 'Update license permissions Success',
                                icon: iconsConst.CHECK_CIRCLE,
                                redirect: null
                            }
                            let status = await this.getLicensePermissions()
                            if (status != HttpStatus.OK) {
                                this.alert = {
                                    show: true,
                                    type: 'error',
                                    msg: "Can't get license permissions after update license permissions",
                                    icon: iconsConst.EXCLAM_TRI,
                                    redirect: null
                                }
                            }
                        } else {
                            this.alert = {
                                show: true,
                                type: 'error',
                                msg: "Can't update license permissions",
                                icon: iconsConst.EXCLAM_TRI,
                                redirect: null
                            }
                        }
                    } else {
                        this.alert = {
                            show: true,
                            type: 'info',
                            msg: `No data to update license permissions`,
                            icon: iconsConst.INFO_CIRCLE,
                            redirect: null
                        }
                    }
                } catch (e) {
                    this.alert = {
                        show: true,
                        type: 'error',
                        msg: `Internal Server error when update license permissions`,
                        icon: iconsConst.EXCLAM_TRI,
                        redirect: null,
                        err: e.response ? e.response : e
                    }
                }
            }

            this.canEdit = newVal
        },
        updatePermission(value) {
            let permissionIdChanged = value.rowId
            let licenseIdxChanged = value.columnIdx
            this.permissionsChanged[permissionIdChanged][licenseIdxChanged] = value.boolean
        }
    }
}
</script>
