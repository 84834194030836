<template>
    <base-modal
        title="Filter"
        :visible="visible"
        :reset="handleReset"
        :apply="handleApply"
        :cancel="handleClose"
        enable-line-button
        :close="handleClose"
    >
        <line-checkbox
            title="Order Type"
            :list="[
                { checked: filterTmp.orderType.buy, text: 'Buy' },
                { checked: filterTmp.orderType.sell, text: 'Sell' },
                { checked: filterTmp.orderType.swap, text: 'Switch' },
                { checked: filterTmp.orderType.transfer, text: 'Transfer' }
            ]"
            class="bottom__margin--normal"
            @change="updateOrderTypeCheckbox"
        />
        <div class="quicksearch__margin">
            AMC
            <quick-search :list="amcList" :value="filterTmp.amc" @value="val => updateFilterValueByKey('amc', val)" />
        </div>
        <line-checkbox
            title="Status"
            :list="[
                { checked: filterTmp.status.awaitingConfirmation, text: 'On Process' },
                { checked: filterTmp.status.success, text: 'Success' },
                { checked: filterTmp.status.cancel, text: 'Cancelled' }
            ]"
            class="bottom__margin--normal"
            @change="updateStatusCheckbox"
        />
        <div class="datepicker__margin">
            Period
            <date-range-picker
                :date-range="filterTmp.dateRange"
                @input="val => updateFilterValueByKey('dateRange', val)"
            />
        </div>
    </base-modal>
</template>

<script>
import { BaseModal } from './index'
import { QuickSearch } from '../../atoms'
import { LineCheckbox, DateRangePicker } from '../../molecules'
import { mapState } from 'vuex'
export default {
    components: {
        BaseModal,
        LineCheckbox,
        QuickSearch,
        DateRangePicker
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        toggleModal: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            filterTmp: {
                orderType: {
                    buy: true,
                    sell: true,
                    swap: true,
                    transfer: true
                },
                status: {
                    awaitingConfirmation: true,
                    success: true,
                    cancel: true
                },
                dateRange: {
                    startDate: '',
                    endDate: ''
                },
                amc: '',
                isFilter: false
            },
            defaultFilter: {
                orderType: {
                    buy: true,
                    sell: true,
                    swap: true,
                    transfer: true
                },
                status: {
                    awaitingConfirmation: true,
                    success: true,
                    cancel: true
                },
                dateRange: {
                    startDate: '',
                    endDate: ''
                },
                amc: '',
                isFilter: false
            },
            orderTypeMap: {
                BUY: 0,
                SELL: 1,
                SWAP: 2,
                TRANSFER: 3
            },
            statusMap: {
                AWAITING_CONFIRMATION: 0,
                SUCCESS: 1,
                CANCEL: 2
            }
        }
    },
    computed: {
        ...mapState('port', ['amcs']),
        amcList() {
            const formattedAMC = this.amcs.map(amc => {
                return {
                    value: amc.amc_name,
                    text: amc.amc_name
                }
            })
            formattedAMC.push({
                value: '',
                text: 'All AMC'
            })
            return formattedAMC
        }
    },
    watch: {
        value(newValue) {
            this.filterTmp = JSON.parse(JSON.stringify(newValue))
        }
    },
    created() {
        this.resetFilter()
    },
    methods: {
        updateOrderTypeCheckbox(updatedList) {
            let updateValue = this.filterTmp
            updatedList.forEach((type, index) => {
                if (this.orderTypeMap.BUY === index) {
                    updateValue.orderType.buy = type
                } else if (this.orderTypeMap.SELL === index) {
                    updateValue.orderType.sell = type
                } else if (this.orderTypeMap.SWAP === index) {
                    updateValue.orderType.swap = type
                } else if (this.orderTypeMap.TRANSFER === index) {
                    updateValue.orderType.transfer = type
                }
            })
        },
        updateStatusCheckbox(updatedList) {
            let updateValue = this.filterTmp
            updatedList.forEach((type, index) => {
                if (this.statusMap.AWAITING_CONFIRMATION === index) {
                    updateValue.status.awaitingConfirmation = type
                } else if (this.statusMap.SUCCESS === index) {
                    updateValue.status.success = type
                } else if (this.statusMap.CANCEL === index) {
                    updateValue.status.cancel = type
                }
            })
        },
        updateFilterValueByKey(key, val) {
            let updateValue = this.filterTmp
            updateValue[key] = val
        },
        handleReset() {
            this.resetFilter()
        },
        handleClose() {
            this.resetFilter()
            this.toggleModal()
        },
        handleApply() {
            let updateValue = this.filterTmp
            updateValue.isFilter = true
            this.$emit('input', updateValue)
            this.toggleModal()
        },
        resetFilter() {
            this.filterTmp = JSON.parse(JSON.stringify(this.defaultFilter))
            this.$emit('input', this.filterTmp)
        }
    }
}
</script>

<style lang="scss" scoped>
.quicksearch__margin {
    margin: 30px 0 32px 0;
}
.datepicker__margin {
    margin: 32px 0 64px 0;
}
</style>
