<template>
    <base-modal
        title="Note"
        :visible="visible"
        :close="close"
        :reset="() => {}"
        :apply="() => {}"
        :prefix-alias="closeModalDataAlias"
    >
        <div class="general__modal--content">
            {{ note }}
        </div>
        <div class="row__container sortby__content top__border--solid vertical__padding--normal horizon__padding--big">
            <v-spacer />
            <button
                class="grey__btn--normal vertical__padding--small horizon_padding--normal font-bold right__margin--small"
                @click="close"
            >
                Close
            </button>
        </div>
    </base-modal>
</template>

<script>
import { BaseModal } from './index'
export default {
    components: {
        BaseModal
    },
    props: {
        visible: Boolean,
        close: {
            type: Function,
            required: true
        },
        request: {
            type: Object,
            default: function() {
                return { note: '' }
            }
        },
        noteDataAlias: {
            type: String,
            default: null
        },
        btnDataAlias: {
            type: String,
            default: null
        },
        closeModalDataAlias: {
            type: String,
            default: null
        }
    },
    computed: {
        note() {
            return this.request && this.request.note
        }
    }
}
</script>

<style lang="scss" scoped>
.general__modal {
    width: 416px;
    height: max-content;
    border-radius: 8px;
    border: 1px solid $greyPrimary;
    background: $white;
    @include responsive(mobile) {
        width: 100%;
        max-width: 320px;
    }
    .general__modal--label {
        color: $textSecondary;
        font-size: 16px;
    }
    .general__modal--content {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 59px;
    }
}
</style>
