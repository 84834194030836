import { getDateFormat, getNumberFormat } from '../../../utill'
import { orderType } from '../../../utill/config'

const getHistoryList = historyList => {
    let list = []
    for (let history of historyList) {
        list.push({
            fund_name: history.fund_name || '-',
            amc_name: history.amc_name || '',
            order_ref_no: history.order_ref_no || '-',
            order_type: history.order_type || '-',
            order_source: history.order_source || '-',
            amount: history.amount != null ? getNumberFormat(history.amount, false, 2, '฿') : '- ฿',
            unit: history.unit != null ? getNumberFormat(history.unit, false, 4, 'Unit') : '- Unit',
            nav: history.nav ? getNumberFormat(history.nav, false, 4, '') : '-',
            status: history.status || '-',
            status_detail: history.status_detail || '-',
            buy_status: history.buy_status || '-',
            buy_status_no: history.buy_status_no || '-',
            buy_status_detail: history.buy_status_detail || '-',
            transaction_date: history.transaction_date ? history.transaction_date : '-',
            order_date: history.order_date ? getDateFormat(history.order_date, 'dd/mm/yy') : '-',
            allotment_date: history.allotment_date ? getDateFormat(history.allotment_date, 'dd/mm/yy') : '-',
            payment_date: history.payment_date ? getDateFormat(history.payment_date, 'dd/mm/yy') : '-',
            payment_method: history.payment_method || '-',
            bank_account: history.bank_account || '-',
            sw_to_fund: history.sw_to_fund || '-',
            sw_in_allotment_date: history.sw_in_allotment_date
                ? getDateFormat(history.sw_in_allotment_date, 'dd/mm/yy')
                : '-',
            sw_in_amount: history.sw_in_amount != null ? getNumberFormat(history.sw_in_amount, false, 2, '฿') : '- ฿',
            sw_in_unit: history.sw_in_unit != null ? getNumberFormat(history.sw_in_unit, false, 4, 'Unit') : '- Unit',
            sw_in_nav: history.sw_in_nav ? getNumberFormat(history.sw_in_nav, false, 4, '') : '-',
            realized_gl: history.realized_gl,
            xwt_fund: history.xwt_fund,
            xwt_ref_no: history.xwt_ref_no,
            xamc_error_desc: history.xamc_error_desc,
            proposal_id: history.proposal_id && history.proposal_id.slice(-12)
        })
    }
    return list
}

const quickFilterFunc = (current, quickFilter) => {
    switch (quickFilter) {
        case orderType.BUY:
        case orderType.SELL:
        case orderType.SWITCH:
            return current.order_type == quickFilter
        case orderType.TRANSFER:
            return [orderType.TRANS_IN, orderType.TRANS_OUT].includes(current.order_type)
        default:
            return true
    }
}

const searchFunc = (current, quickFilter, searchWord) => {
    // proposal-order
    if (
        current['proposal_id'] !== null &&
        current['proposal_id'].toUpperCase() === searchWord.toUpperCase() &&
        quickFilterFunc(current, quickFilter)
    ) {
        return true
    }

    // INFO: search by fund
    let searchList = ['fund_name', 'sw_to_fund']
    for (let searchKey of searchList) {
        if (
            current[searchKey].toUpperCase().search(searchWord.toUpperCase()) >= 0 &&
            quickFilterFunc(current, quickFilter)
        ) {
            return true
        }
    }
    return false
}

const getVisibledHistoryList = (allHistoryList, { quickFilter, searchWord }) => {
    return allHistoryList.filter(current => searchFunc(current, quickFilter, searchWord))
}

export { getHistoryList, getVisibledHistoryList }
