<template>
    <div>
        <div v-if="completedData">
            <bread-crumb :path="header.path" />
            <div class="background--normal__grey content__page">
                <v-alert v-if="alert.show" :type="alert.type" class="" text :icon="alert.icon">{{ alert.msg }}</v-alert>
                <div class="big__text--greyPri bottom__margin--big">Customer List</div>
            </div>
            <div class="background--normal__white bottom__padding--footer top__padding--big">
                <cust-list
                    :cust-list="custList"
                    :sorting="sorting"
                    :quick-filter-value="quickFilterValue"
                    :search-value="tempSearchValue"
                    :filter-values="filterValues"
                    :advisor-filter-value="advisorFilterValue"
                    @sorting="updateSorting"
                    @quickFilter="updateQuickFilter"
                    @filter="updateFilter"
                    @search="updateSearch"
                    @advisorFilter="updateAdvisorFilter"
                />
            </div>
        </div>
        <div v-else>
            <header-skeleton-list />
            <content-skeleton-list />
        </div>
    </div>
</template>

<script>
import { CustList, BreadCrumb } from '../../organisms'
import { HeaderSkeletonList, ContentSkeletonList } from '../../molecules'
import { defaultFilter } from '../../../store/modules/customer/config'
import { mapActions, mapGetters } from 'vuex'
import { orderByConst, pagesConst, FEATURE_CUSTOMER_LIST_V2 } from '../../../store/utill/config/constant'
import { getVisibledCustList, quickFilterCustList, advisorFilterCustList } from '../../../store/modules/customer/config'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export default {
    components: { CustList, HeaderSkeletonList, ContentSkeletonList, BreadCrumb },
    data() {
        return {
            header: {
                title: 'ADVISOR',
                path: [
                    {
                        // TODO: [Next Phase] link should be HOME
                        text: 'Home',
                        link: pagesConst.CUSTOMER_LIST,
                        dataAlias: 'Home-link',
                        external: false
                    }
                ]
            },
            orderByConst,
            sorting: {
                by: 'name_th',
                order: orderByConst.ASC
            },
            defaultFilter,
            filterValues: {},
            quickFilterValue: 'all',
            advisorFilterValue: [],
            searchValue: '',
            alert: {},
            completedData: false,
            pagesConst,
            tempSearchValue: '',
            searchValueTimeout: null,
            featureCustomerListV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('customer', ['getCustList']),
        ...mapGetters('feature', ['getTimestamp']),
        custList() {
            let visibleCustList = getVisibledCustList(this.getCustList, {
                sorting: this.sorting,
                searchWord: this.searchValue
            })

            visibleCustList = quickFilterCustList(visibleCustList, this.quickFilterValue)
            visibleCustList = advisorFilterCustList(visibleCustList, this.advisorFilterValue)
            return visibleCustList
        }
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSettings()
        },
        featureCustomerListV2Enabled(val) {
            this.$set(this.header.path, 0, {
                text: 'Home',
                dataAlias: 'Home-link',
                link: val ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list` : pagesConst.CUSTOMER_LIST,
                external: val
            })
            if (val) {
                window.location.href = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list`
            }
        }
    },
    mounted() {
        this.updateFeatureSettings()
    },
    async created() {
        this.updateFeatureSettings()
        this.filterValues = this.defaultFilter
        await this.updateSearch('')
        await this.getFunds(this.$cookies.get('uuid'))
        await this.getPlanTypes(this.$cookies.get('uuid'))
        await this.updateCustList()
        await this.getAdvisorList(this.$cookies.get('uuid'))
    },
    methods: {
        ...mapActions('customer', ['getCustomerList', 'getFunds', 'getPlanTypes', 'getAdvisorList']),
        ...mapActions('feature', ['isEnabled']),
        async updateSorting(newVal) {
            this.sorting = newVal
        },
        async updateAdvisorFilter(newVal) {
            this.advisorFilterValue = newVal
        },
        async updateQuickFilter(newVal) {
            this.quickFilterValue = newVal
        },
        async updateFilter(newVal) {
            this.filterValues = newVal
            await this.updateCustList()
        },
        async updateSearch(newVal) {
            this.tempSearchValue = newVal
            clearTimeout(this.searchValueTimeout)
            this.searchValueTimeout = setTimeout(() => {
                this.searchValue = newVal
            }, 700)
        },
        async updateCustList() {
            this.completedData = false
            this.alert = await this.getCustomerList({
                uuid: this.$cookies.get('uuid'),
                sorting: this.sorting,
                quickFilter: this.quickFilterValue,
                advisorFilter: this.advisorFilterValue,
                filterList: { ...this.filterValues, funds: this.filterValues.funds.map(fund => fund.fund) },
                searchWord: this.searchValue
            })
            if (this.alert.redirect != null) {
                this.redirect(this.alert.redirect)
            }
            this.completedData = true
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_LIST_V2).then(flag => {
                this.featureCustomerListV2Enabled = flag
            })
        }
    }
}
</script>
