import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

const state = {
    custList: [],
    funds: [],
    advisorList: [],
    basicInfo: {},
    advisors: [],
    info: {},
    invest: {},
    bank: {},
    note: {},
    planTypes: [],
    favoriteStatus: false,
    pdpaStatus: {},
    CRSStatus: {},
    custDisability: {}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
