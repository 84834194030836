<template>
    <div class="list__container">
        <span>{{ title }}</span>
        <div class="search--primary">
            <textarea
                :class="['full__width', 'textarea__primary--normal', { 'textarea__resize--disable': !resize }]"
                :value="value"
                :rows="rows"
                :cols="cols"
                :maxlength="maxlength"
                @change="updateValue"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        rows: {
            type: String,
            required: false,
            default: ''
        },
        cols: {
            type: String,
            required: false,
            default: ''
        },
        resize: {
            type: Boolean,
            required: false,
            default: true
        },
        value: {
            type: String,
            required: false,
            default: ''
        },
        maxlength: {
            type: String,
            required: false,
            default: ''
        },
        dataAlias: {
            type: String,
            required: false,
            default: null
        }
    },
    methods: {
        updateValue(event) {
            this.$emit('value', event.target.value)
        }
    }
}
</script>
