<template>
    <div v-if="recievedProps" class="list__container">
        <div class="row__container wrap__content bottom__margin--bigger">
            <box-investment
                :see-more-tab="investmentBox.see_more"
                :show-more="false"
                :investment="investmentBox.investment"
            />
            <box-pl :see-more-tab="plBox.see_more" :show-more="false" :pl="plBox.pl" />
            <box-plan :see-more-tab="planBox.see_more" :show-more="false" :plan="planBox.plan" />
        </div>
        <table-plan :plan-list="planList" :advisors="advisors" @showModalActionInfo="showModalActionInfo" />
        <action-info-modal :visible="isShowModalActionInfo" @closeModal="showModalActionInfo(false)" />
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { BoxInvestment, BoxPlan, TablePlan, BoxPl } from '../../molecules'
import { ActionInfoModal } from '../Modal'

import {
    investBoxValidator,
    planBoxValidator,
    planListValidator,
    plBoxValidator
} from '../../../store/modules/customer/validator'

export default {
    components: {
        BoxInvestment,
        BoxPl,
        BoxPlan,
        TablePlan,
        Spinner,
        ActionInfoModal
    },
    props: {
        investmentBox: {
            type: Object,
            required: true,
            validator: investBoxValidator
        },
        planBox: {
            type: Object,
            required: true,
            validator: planBoxValidator
        },
        plBox: {
            type: Object,
            required: true,
            validator: plBoxValidator
        },
        planList: {
            type: Array,
            required: true,
            validator: planListValidator
        },
        advisors: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            recievedProps: false,
            isShowModalActionInfo: false
        }
    },
    mounted() {
        this.recievedProps = true
    },
    methods: {
        showModalActionInfo(showInfo) {
            this.isShowModalActionInfo = showInfo
        }
    }
}
</script>
