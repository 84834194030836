import { getters } from './getters'
import { actions } from './actions'

const state = {
    csrf: '',
    uuid: '',
    userData: {},
    permissions: {},
    permissionsKey: {}
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations: {
        setState: (state, data) => {
            state[data.type] = data.data
        }
    }
}
