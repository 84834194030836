import { type, keyValidator, typeValidator } from '../../../utill'

const advisorsValidator = advisors => {
    for (let advisor of advisors) {
        if (!typeValidator([{ value: advisor, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(advisor, ['abbr', 'full', 'profile_picture', 'identifier'])) {
            return false
        }
        if (
            !typeValidator([
                { value: advisor.abbr, type: type.STRING },
                { value: advisor.full, type: type.STRING },
                { value: advisor.profile_picture, type: type.OBJECT },
                { value: advisor.identifier, type: type.STRING }
            ])
        ) {
            return false
        }
    }
    return true
}

const advisorBoxValidator = advisorBox => {
    if (!keyValidator(advisorBox, ['see_more', 'advisors'])) {
        return false
    }
    if (
        !typeValidator([
            { value: advisorBox.see_more, type: type.STRING },
            { value: advisorBox.advisors, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return advisorsValidator(advisorBox.advisors)
}

export { advisorBoxValidator, advisorsValidator }
