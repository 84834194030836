<template>
    <div v-if="recievedProps" class="list__container item__align--center">
        <table-dca
            :dca-list="visibledDcaList"
            class="full__width bottom__margin--normal"
            @open-fund-tab="openFundTab"
        />
        <pagination :current-page="currentPage" :total-pages="totalPages" @currentPage="updateCurrentPage" />
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { dcaListValidator } from '../../../store/modules/port/validator'
import { Pagination, TableDca } from '../../molecules'

export default {
    components: {
        Pagination,
        TableDca,
        Spinner
    },
    props: {
        dcaList: {
            type: Array,
            required: true,
            validator: dcaListValidator
        }
    },
    data() {
        return {
            amountContent: 10,
            currentPage: 1,
            totalPages: 0,
            visibledDcaList: [],
            recievedProps: false
        }
    },
    mounted() {
        this.recievedProps = true
    },
    async created() {
        this.totalPages = Math.ceil(this.dcaList.length / this.amountContent)
        await this.updateCurrentPage(1)
    },
    methods: {
        async updatevisibledDcaList() {
            this.visibledDcaList = this.dcaList.slice(
                (this.currentPage - 1) * this.amountContent,
                this.currentPage * this.amountContent
            )
        },
        async updateCurrentPage(newVal) {
            this.currentPage = newVal
            await this.updatevisibledDcaList()
        },
        async openFundTab(newVal) {
            this.$emit('openFundTab', newVal)
        }
    }
}
</script>
