const headerLeadRequestList = [
    {
        value: 'agent_account_id',
        text: 'Req. Port ID',
        align: 'left'
    },
    {
        value: 'customer_name',
        text: 'Cust. Name',
        align: 'left'
    },
    {
        value: 'request_date',
        text: 'Req. Date',
        align: 'left'
    },
    {
        value: 'updated_at',
        text: 'Update Date',
        align: 'left'
    },
    {
        value: 'note',
        text: 'Note',
        align: 'left'
    },
    {
        value: 'status',
        text: 'Status',
        align: 'left'
    }
]

const leadRequestStatus = {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected'
}
const leadRequestAction = {
    APPROVED: 'approved',
    APPROVED_ALL: 'approved all',
    REJECTED: 'rejected'
}

const leadRequestMessages = {
    ALREADY_SET_IA_MSG: 'ไม่สามารถ Approve ได้ เนื่องจากลูกค้ามีผู้ดูแลแล้ว',
    ALREADY_SET_IA_TITLE: 'Can not approve this request',
    SEARCH_FAILED: 'ไม่สามารถค้นหาข้อมูลลูกค้าได้',
    CREATE_FAILED: 'ไม่สามารถสร้าง Lead Request ได้'
}

export { headerLeadRequestList, leadRequestAction, leadRequestStatus, leadRequestMessages }
