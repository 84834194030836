<template>
    <div>
        <div class="top__info">
            <profile-name
                class="info__blog"
                :profile="{
                    title: 'Portfolio',
                    display_circle: false,
                    profile_picture: portInfo.general_info.port_icon,
                    abbr: '',
                    full: portInfo.general_info.port_id
                }"
            />
            <status-display :title="'Customer'" :status="portInfo.general_info.customer_name" />
        </div>
        <div v-if="showFull" class="bottom__info">
            <port-info class="info__blog" :info="portInfo.port_info" />
            <date-info class="info__blog" :info="portInfo.date_info" />
            <advisors-info :see-more-tab="advisors.see_more" :info="advisors.advisors" @seeMore="updateActiveTab" />
        </div>
        <div id="btn-up-info" class="absolute__btn--primary" @click="showFull = !showFull">
            <i :class="showFull ? iconsConst.ARROW_UP : iconsConst.ARROW_DOWN"></i>
        </div>
    </div>
</template>
<script>
import { advisorBoxValidator } from '../../../store/modules/customer/validator'
import { basicPortInfoValidator } from '../../../store/modules/port/validator'
import { AdvisorsInfo, DateInfo, PortInfo, ProfileName } from '../../molecules'
import { iconsConst } from '../../../store/utill'
import { StatusDisplay } from '../../atoms'

export default {
    components: {
        AdvisorsInfo,
        DateInfo,
        PortInfo,
        ProfileName,
        StatusDisplay
    },
    props: {
        portInfo: {
            type: Object,
            required: true,
            validator: basicPortInfoValidator
        },
        advisors: {
            type: Object,
            required: true,
            validator: advisorBoxValidator
        }
    },
    data() {
        return {
            showFull: true,
            iconsConst
        }
    },
    methods: {
        async updateActiveTab(newVal) {
            this.$emit('activeTab', newVal)
        }
    }
}
</script>
<style lang="scss" scoped>
.top__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    @include responsive(mobile) {
        flex-wrap: wrap;
    }
    .info__blog {
        min-width: 300px;
        margin-bottom: 18px;
        @include responsive(mobile) {
            width: 100%;
            max-width: 210px;
        }
    }
}

.bottom__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    .info__blog {
        min-width: 300px;
        @include responsive(mobile) {
            width: 100%;
            max-width: 210px;
        }
    }
}
</style>
