<template>
    <v-container class="background--normal__white border--normal horizon__padding--big">
        <div class="vertical__margin--big">
            <v-alert v-if="alert.show" :type="alert.type" text :icon="alert.icon">
                <span>{{ alert.msg }}</span>
            </v-alert>
            <div class="big__text--greyPri vertical__margin--big">Create Your Password</div>
            <form @submit.prevent="validate">
                <input-custom
                    title="Password"
                    type="password"
                    :value="password.value"
                    :validation="password.validation"
                    class="bottom--margin"
                    @value="value => updateValue('password', value)"
                />

                <input-custom
                    title="Confirm Password"
                    type="password"
                    :value="confirmPassword.value"
                    :validation="confirmPassword.validation"
                    class="bottom--margin"
                    @value="value => updateValue('confirmPassword', value)"
                />

                <div class="row__container bottom--margin item__align--center">
                    <button type="submit" class="login__btn--normal font-bold">Submit</button>
                    <v-spacer />
                </div>
            </form>
        </div>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { pagesConst } from '../../../store/utill'
import { InputCustom } from '../../atoms'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
    components: {
        InputCustom
    },
    props: {
        type: {
            type: String,
            default: 'create-password'
        }
    },
    data() {
        return {
            pagesConst,
            csrf: '',
            confirmPassword: {
                value: '',
                validation: {
                    show: false,
                    msg: '',
                    dataAlias: 'forgot-password_validate-confirm-pass'
                }
            },
            password: {
                value: '',
                validation: {
                    show: false,
                    msg: '',
                    dataAlias: 'forgot-password_validate-pass'
                }
            },
            alert: {}
        }
    },
    computed: {
        ...mapGetters('authentication', ['getCSRF', 'getUUID']),
        token() {
            return this.$route.params.token
        }
    },
    methods: {
        ...mapActions('authentication', ['createPassword']),
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push({ name: pathName })
        },
        updateValue(variable, value) {
            this[variable].value = value
        },
        isPasswordValid(password) {
            // eslint-disable-next-line no-useless-escape
            let regEx = /^([!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]?)(?=.*[A-Za-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?\"()<>\[\]-]{6,}$/g
            return regEx.test(password)
        },
        async validate() {
            let validData = true
            const password = this.password.value
            const confirmPassword = this.confirmPassword.value

            // reset password validation
            this.password.validation = {
                show: false,
                msg: '',
                dataAlias: this.password.validation.dataAlias
            }
            // reset confirmPassword validation
            this.confirmPassword.validation = {
                show: false,
                msg: '',
                dataAlias: this.confirmPassword.validation.dataAlias
            }

            if (password == '') {
                this.password.validation = {
                    show: true,
                    msg: 'Please input your password',
                    dataAlias: this.password.validation.dataAlias
                }
                validData = false
            } else if (password.length < 6) {
                this.password.validation = {
                    show: true,
                    msg: 'At least 6 characters',
                    dataAlias: this.password.validation.dataAlias
                }
                validData = false
            } else if (password.length > 20) {
                this.password.validation = {
                    show: true,
                    msg: 'Must have at most 20 characters',
                    dataAlias: ''
                }
                validData = false
            } else if (!this.isPasswordValid(password)) {
                this.password.validation = {
                    show: true,
                    msg: 'Password must be at least 6 characters including english alphabet and number',
                    dataAlias: this.password.validation.dataAlias
                }
                validData = false
            }

            if (confirmPassword != password) {
                this.confirmPassword.validation = {
                    show: true,
                    msg: 'Password mismatch',
                    dataAlias: this.confirmPassword.validation.dataAlias
                }
                validData = false
            }
            if (validData) {
                let uuid = this.$cookies.get('uuid')
                this.alert = await this.createPassword({
                    password: password,
                    confirmPassword: confirmPassword,
                    token: this.token,
                    uuid: uuid
                })
                if (this.alert.type == 'success') {
                    this.redirect(this.alert.redirect)
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.bottom--margin {
    margin-bottom: 32px;
}
</style>
