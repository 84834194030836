<template>
    <div>
        <v-select v-model="selectedValue" :items="list" dense outlined @input="updateValue" />
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        },
        defaultValue: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedValue: ''
        }
    },
    mounted() {
        this.selectedValue = this.defaultValue
    },
    methods: {
        updateValue(val) {
            this.$emit('selectValue', val)
        }
    }
}
</script>
