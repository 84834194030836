const custInfoTopic = {
    general_info: {
        customer_code: 'Customer ID',
        investment_status: 'Investment Status',
        account_type: 'Account Type',
        customer_type: 'Customer Type',
        service_level: 'Service Level',
        is_pp20: 'VAT',
        is_wht: 'หักภาษี',
        is_accept_money_transfer: 'ความเสี่ยงอัตราแลกเปลี่ยน',
        open_account_at: 'วันที่เปิดบัญชี',
        confirm_by: 'วิธีรับเอกสาร',
        assessment: 'Assessment',
        risk_level: 'Risk Level',
        suitability_date: 'วันที่ประเมิน',
        suitability_expiry_date: 'วันหมดอายุ',
        empty: '',
        kyc_level: 'KYC Level',
        kyc_date: 'วันที่ประเมิน',
        kyc_expiry_date: 'วันหมดอายุ',
        crs_status: 'สถานะ CRS',
        crs_expiry_date: 'วันหมดอายุ',
        space: '',
        knowledge_assessment: 'Knowledge Assessment',
        is_ka_accept_result: 'มีความรู้ทางการเงินหรือ \nมีประสบการณ์การลงทุน',
        contact: 'Contact',
        email: 'Email',
        mobile_phone: 'Mobile',
        phone: 'Phone',
        privacy_consent: 'Privacy Consent (PDPA)',
        pdpa_call_status: 'Contact (phone)',
        pdpa_promotion_status: 'Contact (email & notification)',
        pdpa_analytic_status: 'Data Collection'
    },
    personal_info: {
        name_th: 'ชื่อไทย',
        name_en: 'ชื่ออังกฤษ',
        birth_date: 'วันเกิด',
        personal_id: 'เลขปชช',
        sources_of_income: 'แหล่งที่มารายได้',
        occupation: 'อาชีพ',
        business_type: 'ประเภทธุรกิจ',
        work_place: 'ชื่อที่ทำงาน',
        monthly_income: 'รายได้ต่อเดือน',
        load_spend: 'ภาระทางการเงินต่อปี',
        initial: 'เงินที่ต้องการลงทุน',
        fatca: 'FATCA',
        relation_with_politician: 'ความสัมพันธ์นักการเมือง',
        scam_money: 'มีประวัติฟอกเงินใน 3 ปี',
        is_sue: 'เคยหรืออยู่ระหว่างการถูกฟ้องร้องในความผิดมูลฐานและความผิดเกี่ยวกับหลักทรัพย์และตลาดหลักทรัพย์',
        is_rejected: 'เคยถูกปฎิเสธการเปิดบัญชีจากบ.หลักทรัพย์อื่น',
        current_address: 'ที่อยู่ปัจจุบัน',
        special_needs: 'ความต้องการพิเศษ',
        is_visually_impaired: 'ผู้พิการทางสายตา',
        is_hearing_impaired: 'ผู้พิการทางการได้ยิน',
        is_age_over_60: 'อายุตั้งแต่ 60 ปีขึ้นไป'
    },
    family_info: {
        marital_status: 'สถานะ',
        underage_children: 'จำนวนบุตรที่ยังไม่บรรลุนิติภาวะ',
        spouse_relation: 'คู่สมรส',
        spouse_display_name: 'ชื่อ-นามสกุล',
        spouse_citizen_id: 'เลขบัตรปชช./Passport',
        spouse_nationality: 'ประเทศ',
        child_relation: 'บุตร ',
        child_display_name: 'ชื่อ-นามสกุล',
        child_citizen_id: 'เลขบัตรปชช./Passport',
        child_nationality: 'ประเทศ'
    }
}

const quickFilterList = [
    {
        value: 'all',
        text: 'All Customers',
        dataAlias: 'quick_filter-all-selector'
    },
    {
        value: 'wt5',
        text: 'Invested',
        dataAlias: 'quick_filter-invested-selector'
    },
    {
        value: 'wt4',
        text: 'Ready to Invest',
        dataAlias: 'quick_filter-ready-selector'
    },
    {
        value: 'wt2',
        text: 'Account Pending',
        dataAlias: 'quick_filter-pending-selector'
    }
]

const defaultFilter = {
    cost: {
        min: '',
        max: ''
    },
    outstanding: {
        min: '',
        max: ''
    },
    unrealized_pl: {
        min: '',
        max: ''
    },
    investment_status: {
        ready_to_invest: true,
        invested: true,
        closed: true,
        oa_reviewing: true,
        oa_rejected: true,
        ats: true,
        kyc: true
    },
    favorite_status: {
        favorite: true,
        regular: true
    },
    funds: [],
    plan_type: [],
    others: {
        has_action_required: false,
        has_dca_profiles: false
    },
    service_level: {
        none: true,
        member: true,
        exclusive: true,
        exclusive_prime: true,
        fpw: true,
        fpw_plus: true,
        fpb: true,
        fpb_ex: true,
        fpb_ultra: true,
        club: true,
        private: true,
        private_plus: true,
        ultra: true,
        ultra_ex: true,
        ultra_ultra: true
    }
}

const headerCustList = [
    {
        value: 'name_th',
        text: 'Name',
        align: 'left',
        dataAlias: 'sort_name'
    },
    {
        value: 'service_level',
        text: 'Service',
        align: 'left',
        dataAlias: 'sort_service'
    },
    {
        value: 'cost',
        text: 'Cost',
        align: 'right',
        dataAlias: 'sort_cost'
    },
    {
        value: 'outstanding',
        text: 'Outstanding',
        align: 'right',
        dataAlias: 'sort_outstanding'
    },
    {
        value: 'unrealized_pl',
        text: 'Unrealized P/L',
        align: 'right',
        dataAlias: 'sort_unrealized-pl'
    },
    {
        value: 'investment_status',
        text: 'Acc. Status',
        align: 'left',
        dataAlias: 'sort_acc-status'
    },
    {
        value: 'advisors',
        text: 'Advisors',
        align: 'left',
        dataAlias: 'sort_advisor'
    },
    {
        value: 'actions',
        text: 'Action',
        align: 'left',
        dataAlias: 'sort_action'
    }
]

const actionsConst = {
    PRA: 'PRA'
}

const knowledgeAssessmentChoiceList = {
    '0': 'ฉันไม่เคยลงทุนในสินทรัพย์ข้างต้น',
    '1': 'Structured Notes',
    '2': 'Basel III',
    '3': 'Derivatives',
    '4':
        'กองทุนรวมที่มีการลงทุนใน Derivatives ที่กลยุทธ์แบบซับซ้อน หรือการลงทุน Exotic Derivatives โดยมีการใช้ VAR Approach เพื่อคำนวณฐานะการลงทุนของตราสาร',
    '5': 'กองทุนรวมที่ลงทุนในตราสารหนี้ Non-Investment grade/unrated bond เกินกว่า 60% ของ NAV',
    '6': 'Basel III',
    '7': 'Derivatives',
    '8': 'Unrated Bond',
    '9': 'Hybrid Securities',
    '10': 'กองทุนรวม Complex Return',
    '11': 'กองทุนรวมทองคำ / น้ำมันที่ไม่ได้ Track sport'
}

const RetryError = {
    PDPA: 'PDPA',
    CRS: 'CRS',
    ANEO: 'ANEO'
}

export {
    actionsConst,
    custInfoTopic,
    defaultFilter,
    headerCustList,
    quickFilterList,
    knowledgeAssessmentChoiceList,
    RetryError
}
