import { render, staticRenderFns } from "./AdvisorsInfo.vue?vue&type=template&id=65e2cf19&scoped=true&"
import script from "./AdvisorsInfo.vue?vue&type=script&lang=js&"
export * from "./AdvisorsInfo.vue?vue&type=script&lang=js&"
import style0 from "./AdvisorsInfo.vue?vue&type=style&index=0&id=65e2cf19&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e2cf19",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
