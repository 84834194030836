<template>
    <div class="width__table bottom__margin--normal">
        <table class="table__secondary--normal">
            <thead>
                <tr class="head__table--primary bottom__border--solid">
                    <th
                        class="horizon__align--left display__mobile--none table__col--medium"
                    >
                        Advisor
                    </th>
                    <th
                        class="horizon__align--left display__mobile--none table__col--medium"
                    >
                        Cust. Name
                    </th>
                    <th
                        class="horizon__align--left display__phablet--none lessthan table__col--medium"
                    >
                        Cust. AUA
                    </th>
                    <th
                        class="horizon__align--left display__phablet--none lessthan table__col--small"
                    >
                        Req. Date
                    </th>
                    <th
                        class="horizon__align--left display__phablet--none lessthan table__col--large"
                    >
                        Note
                    </th>
                    <th
                        class="horizon__align--left display__phablet--none lessthan table__col--medium"
                    >
                        Approve?
                    </th>
                </tr>
            </thead>
            <tbody v-if="leadRequests.length > 0">
                <template v-for="(row, idx) in leadRequests">
                    <tr :key="`row-${idx}`" class="bottom__border--solid border__phablet--none">
                        <td class=" table__col--medium">
                            <span
                                class="normal__text--primary"
                                >{{ row.ia_name }}</span
                            >
                        </td>
                        <td class="horizon__align--left display__mobile--none table__col--medium">
                            <link-anchor
                                :to="getCustomerUrl(row)"
                                class-name="btn__secondary--normal"
                                :external="featureCustomerInfoV2Enabled"
                            >
                                {{ row.customer_name }}
                            </link-anchor>
                        </td>
                        <td class="horizon__align--left display__mobile--none table__col--medium">
                            <span
                                class="normal__text--primary"
                                >{{ row.outstanding }}</span
                            >
                        </td>
                        <td class="horizon__align--left display__phablet--none lessthan table__col--small">
                            <span
                                class="normal__text--primary"
                                >{{ getDateFormat(row.request_date) }}</span
                            >
                        </td>
                        <td class="horizon__align--left display__phablet--none lessthan table__col--large eplisis">
                            <div class="normal__text--primary table__col--elipsis">
                                <span>{{
                                    row.note
                                }}</span>
                                <span
                                    v-if="row.note.length > 20"
                                    class="btn__secondary--normal table__more"
                                    @click="$emit('note', idx)"
                                    >More</span
                                >
                            </div>
                        </td>
                        <td class="horizon__align--left display__phablet--none lessthan table__col--medium">
                            <!-- Display Approve Button Here -->
                            <div v-if="row.status === leadRequestStatus.PENDING" class="table__button-container">
                                <affirmative-button
                                    @click="$emit('approve', idx)"
                                />
                                <decline-button
                                    @click="$emit('reject', idx)"
                                />
                            </div>
                            <div
                                v-else-if="row.status === leadRequestStatus.APPROVED"
                                class="table__button-container table__green"
                            >
                                <i :class="iconsConst.CHECKMARK" /> Approved
                            </div>
                            <div
                                v-else-if="row.status === leadRequestStatus.REJECTED"
                                class="table__button-container table__red"
                            >
                                <i :class="iconsConst.CROSS" /> Rejected
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
            <tbody v-else>
                <tr class="bottom__border--solid">
                    <td colspan="6" class="no__result">No Result</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AffirmativeButton, DeclineButton } from '../../atoms'
import { pagesConst, iconsConst, getDateFormat } from '../../../store/utill'
import { leadRequestStatus } from '../../../store/modules/leadRequest/config'
import LinkAnchor from '../../atoms/LinkAnchor'
import { FEATURE_CUSTOMER_INFO_V2 } from '../../../store/utill/config/constant'

export default {
    components: {
        AffirmativeButton,
        DeclineButton,
        LinkAnchor
    },
    props: {
        leadRequests: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            leadRequestStatus,
            featureCustomerInfoV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSetting()
        }
    },
    created() {
        this.updateFeatureSettings()
    },
    methods: {
        ...mapActions('feature', ['isEnabled']),
        getDateFormat,
        getCustomerUrl(customer) {
            return this.featureCustomerInfoV2Enabled
                ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-info/${customer.customer_code}`
                : `${pagesConst.CUSTOMER_INFO}/${customer.customer_code}`
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        },
        dataAliasRowIdx(idx) {
            return idx + 1
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_INFO_V2).then(flag => {
                this.featureCustomerInfoV2Enabled = flag
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.width__table {
    margin: 0;
    padding: 0;
}
.table__more {
    position: absolute;
    font-size: 12px;
    right: 0;
}
.table__red {
    font-size: 14px;
    color: $redPrimary;
}
.table__green {
    font-size: 14px;
    color: $greenPrimary;
}
.table__col--small {
    width: 57px;
}
.table__col--medium {
    width: 96px;
}
.table__col--large {
    width: 198px;
}
.table__col--elipsis {
    position: relative;
    width: 198px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
}
.table__button-container {
    button + button {
        margin-left: 8px;
    }
}
.border__phablet--none {
    @include multiple-responsive(mobile, phablet) {
        border-bottom: none !important;
    }
}
.link {
    text-decoration: none;
}
</style>
