import { DATE_PICKER_END, DATE_PICKER_START } from '../../../utill'
import { orderType } from '../../../utill/config'

const quickFilterList = [
    { value: orderType.ALL, text: 'All Orders' },
    { value: orderType.BUY, text: 'Buy Orders' },
    { value: orderType.SELL, text: 'Sell Orders' },
    { value: orderType.SWITCH, text: 'Switch Orders' },
    { value: orderType.TRANSFER, text: 'Transfer Orders' }
]

const defaultFilter = {
    orderType: {
        ar: true,
        dividend: true
    },
    paymentDate: {
        startDate: DATE_PICKER_START,
        endDate: DATE_PICKER_END
    }
}

export { defaultFilter, quickFilterList }
