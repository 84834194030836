<template>
    <div class="list__container">
        <checkbox
            v-for="(item, idx) in list"
            :id="`checkbox-${prefix}-${idx + 1}`"
            :key="`checkbox-${idx}`"
            :show-label="showLabel"
            :can-edit="canEdit"
            :item="item"
            class="bottom__margin--small"
            @change="boolean => changeSelected(boolean, idx)"
        />
    </div>
</template>

<script>
import { Checkbox } from '../../atoms'
import { checkboxListValidator } from '../../../store/modules/general/validator'

export default {
    components: {
        Checkbox
    },
    props: {
        prefix: {
            type: String,
            required: true
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: true
        },
        list: {
            type: Array,
            required: true,
            validator: checkboxListValidator
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        changeSelected(boolean, idx) {
            this.$emit('change', { boolean, idx })
        }
    }
}
</script>
