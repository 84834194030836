<template>
    <span v-if="praStatusText !== actionStatusDisplay.NO_ACTION" :class="`font-bold ${classTextColor}`">
        <i :class="actionIcon"></i> {{ praStatusText }}
    </span>
    <span v-else class="normal__text--secondary">{{ actionStatusDisplay.NO_ACTION }}</span>
</template>

<script>
import { iconsConst, actionStatusDisplay, actionStatusKey } from '../../../store/utill'

export default {
    props: {
        praStatus: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            actionStatusDisplay,
            praStatusText: '',
            classTextColor: '',
            actionIcon: ''
        }
    },
    mounted() {
        switch (this.praStatus) {
            case actionStatusKey.NEED_CHECKUP:
                this.praStatusText = actionStatusDisplay.NEED_CHECKUP
                this.classTextColor = 'normal__text--red'
                this.actionIcon = iconsConst.REGULAR_EXCLAM_CIRCLE
                break
            case actionStatusKey.NEED_ADJUSTMENT:
                this.praStatusText = actionStatusDisplay.NEED_ADJUSTMENT
                this.classTextColor = 'normal__text--red'
                this.actionIcon = iconsConst.REGULAR_EXCLAM_CIRCLE
                break
            case actionStatusKey.NEED_TOPUP_MIN_INVEST:
                this.praStatusText = actionStatusDisplay.NEED_TOPUP_MIN_INVEST
                this.classTextColor = 'normal__text--red'
                this.actionIcon = iconsConst.REGULAR_EXCLAM_CIRCLE
                break
            case actionStatusKey.PENDING_ORDER:
                this.praStatusText = actionStatusDisplay.PENDING_ORDER
                this.classTextColor = 'normal__text--secondary'
                this.actionIcon = iconsConst.HOURGLASS
                break
            default:
                this.praStatusText = actionStatusDisplay.NO_ACTION
                this.classTextColor = 'normal__text--secondary'
                break
        }
    }
}
</script>

<style scoped>
.normal__text--orange {
    color: #e97200;
    font-size: 14px;
    line-height: 1.4;
}
</style>
