import { type, keyValidator, typeValidator } from '../../../utill'

const sumBankValidator = bankAccount => {
    if (!keyValidator(bankAccount, ['subscription', 'redemption'])) {
        return false
    }
    if (
        !typeValidator([
            { value: bankAccount.subscription, type: type.NUMBER },
            { value: bankAccount.redemption, type: type.NUMBER }
        ])
    ) {
        return false
    }
    return true
}

const bankBoxValidator = bankBox => {
    if (!keyValidator(bankBox, ['see_more', 'bank_account'])) {
        return false
    }
    if (
        !typeValidator([
            { value: bankBox.see_more, type: type.STRING },
            { value: bankBox.bank_account, type: type.OBJECT }
        ])
    ) {
        return false
    }
    return sumBankValidator(bankBox.bank_account)
}

const bankListValidator = bankList => {
    for (let bank of bankList) {
        if (!typeValidator([{ value: bank, type: type.OBJECT }])) {
            return false
        }
        if (!keyValidator(bank, ['bank', 'branch', 'account_no', 'primary'])) {
            return false
        }
        if (
            !typeValidator([
                { value: bank.bank, type: type.STRING },
                { value: bank.branch, type: type.STRING },
                { value: bank.account_no, type: type.STRING },
                { value: bank.primary, type: type.BOOLEAN }
            ])
        ) {
            return false
        }
    }
    return true
}

export { bankBoxValidator, bankListValidator, sumBankValidator }
