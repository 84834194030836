<template>
    <div class="background--normal__grey content__page pt-23 pb-30">
        <div class="pb-34">
            <skeleton :width="144" :height="18" />
        </div>
        <div class="flex pb-34">
            <div class="mr-2">
                <skeleton :width="45" :height="55" />
            </div>
            <div>
                <skeleton :width="144" :height="18" class="mb-5p" />
                <skeleton :width="177" :height="32" />
            </div>
        </div>
        <div class="flex">
            <skeleton :width="274" :height="129" class="mr-6" />
            <skeleton :width="274" :height="129" />
        </div>
    </div>
</template>

<script>
import { Skeleton } from '../../atoms'

export default {
    components: { Skeleton },
    props: {},
    data() {
        Skeleton
        return {}
    }
}
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
}
.mb-5p {
    margin-bottom: 5px;
}
.pb-5 {
    padding-bottom: 5px;
}
.pb-34 {
    padding-bottom: 34px;
}
.pt-23 {
    padding-top: 23px;
}
.pb-30 {
    padding-bottom: 30px;
}
</style>
