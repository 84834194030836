<template>
    <div v-if="featureServiceLevelRebranding">
        <div v-if="section == 'account_status'" :class="section == '' && 'list__container'">
            <span class="size__font--16px normal__text--primary bottom__margin--small">{{ title }}</span>
            <div :class="section == 'account_status' ? 'account__status--container' : 'row__container wrap__content'">
                <checkbox
                    v-for="(item, idx) in list"
                    :key="`checkbox-${idx}`"
                    class="size__font--14px right__margin--normal bottom__margin--smallest"
                    :show-label="true"
                    :can-edit="true"
                    :item="item"
                    @change="boolean => updateCheckbox(idx, boolean)"
                />
            </div>
        </div>
        <div v-else-if="section == 'service_level'">
            <span class="size__font--16px normal__text--primary">{{ title }}</span>
            <div class="service__level--container">
                <div v-for="(item, idx) in list" :key="`checkbox-${idx}`">
                    <checkbox
                        :show-label="true"
                        :can-edit="true"
                        :item="item"
                        :feature-service-level-rebranding="featureServiceLevelRebranding"
                        @change="boolean => updateCheckbox(idx, boolean)"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-else class="list__container">
        <span class="size__font--16px normal__text--primary bottom__margin--small">{{ title }}</span>
        <div class="row__container wrap__content">
            <checkbox
                v-for="(item, idx) in list"
                :key="`checkbox-${idx}`"
                class="right__margin--normal"
                :show-label="true"
                :can-edit="true"
                :item="item"
                @change="boolean => updateCheckbox(idx, boolean)"
            />
        </div>
    </div>
</template>

<script>
import { Checkbox } from '../../atoms'
import { checkboxListValidator } from '../../../store/modules/general/validator'

export default {
    components: {
        Checkbox
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        list: {
            type: Array,
            required: true,
            validator: checkboxListValidator
        },
        section: {
            type: String,
            required: false,
            default: ''
        },
        featureServiceLevelRebranding: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        updateCheckbox(idx, boolean) {
            let changedList = []
            for (let index in this.list) {
                if (index == idx) {
                    changedList.push(boolean)
                } else {
                    changedList.push(this.list[index].checked)
                }
            }
            this.$emit('change', changedList)
        }
    }
}
</script>
<style lang="scss" scoped>
.account__status--container {
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr;
    row-gap: 0.4rem;
    margin-top: 0.5rem;

    @include responsive(mobile) {
        grid-template-columns: 1fr;
    }
}
.service__level--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.2rem;
    font-weight: bold;
    margin-top: 0.4rem;

    @include responsive(mobile) {
        grid-template-columns: 1fr;
    }
}
</style>
