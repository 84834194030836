<template>
    <div>
        <div v-if="completedData">
            <knowledge-assessment-modal
                title="Knowledge Assessment"
                :visible="knowledgeAssessmentVisible"
                :close="toggleKnowledgeAssessmentModal"
                :data="info.knowledge_assessment_v2"
            />
            <div class="background--normal__grey">
                <v-alert v-if="alert.show" :type="alert.type" text :icon="alert.icon" class="content__page">{{
                    alert.msg
                }}</v-alert>
                <div class="row__contianer position--relative">
                    <bread-crumb
                        :path="header.path"
                        :identifier="customerCode"
                        :show-favorite-icon="showFavoriteIcon"
                    />
                </div>
                <cust-basic-info
                    :customer="basicInfo"
                    :advisors="{ see_more: tabs.INVEST, advisors }"
                    class="wrap__content content__page"
                    @activeTab="updateActiveTab"
                />
            </div>
            <div class="background--normal__white bottom__padding--footer content__page">
                <tab-bar :tabs="tabs" :active-tab="activeTab" @activeTab="updateActiveTab" />
                <cust-overview
                    v-if="activeTab == tabs.OVERVIEW && invest && advisors && bank"
                    :investment-box="{ see_more: tabs.INVEST, investment: invest.investment_box }"
                    :plan-box="{ see_more: tabs.INVEST, plan: invest.plan_box }"
                    :advisor-box="{ see_more: tabs.INVEST, advisors: advisors }"
                    :bank-box="{ see_more: tabs.BANK, bank_account: bank.bank_box }"
                    :note-box="{
                        see_more: tabs.INVEST,
                        note: note,
                        customer_code: customerCode,
                        advisor: noteAdvisor
                    }"
                    @activeTab="updateActiveTab"
                />
                <cust-info
                    v-if="activeTab == tabs.INFO && info"
                    :general="info.general_info"
                    :personal="info.personal_info"
                    :family="info.family_info"
                    @reload="retryError"
                    @toggleKnowledgeAssessmentModal="toggleKnowledgeAssessmentModal"
                />
                <cust-invest
                    v-else-if="activeTab == tabs.INVEST && invest"
                    :investment-box="{ see_more: tabs.INVEST, investment: invest.investment_box }"
                    :pl-box="{ see_more: tabs.INVEST, pl: invest.pl_box }"
                    :plan-box="{ see_more: tabs.INVEST, plan: invest.plan_box }"
                    :plan-list="invest.plan_list"
                    :advisors="advisors"
                />
                <cust-bank
                    v-else-if="activeTab == tabs.BANK && bank"
                    :bank-box="{ see_more: tabs.BANK, bank_account: bank.bank_box }"
                    :sub-bank-list="bank.sub_bank_list"
                    :red-bank-list="bank.red_bank_list"
                />
                <cust-log v-else-if="activeTab == tabs.LOG" :log-list="log || []" />
                <template v-else-if="activeTab == tabs.OVERVIEW"></template>
                <template v-else-if="activeTab == tabs.GNAVI"></template>
                <template v-else-if="activeTab == tabs.NOTES"></template>
                <template v-else>
                    <div v-if="reloadingTab" class="horizon__align--center item__align--center pt-16">
                        <spinner />
                    </div>
                    <error-refresh v-else :current-tab="activeTab" @refresh="refresh" />
                </template>
            </div>
        </div>
        <div v-else>
            <header-skeleton-object />
            <content-skeleton-object />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { Spinner } from '../../atoms'
import { HeaderSkeletonObject, ContentSkeletonObject } from '../../molecules'

import {
    CustBasicInfo,
    CustBank,
    CustInfo,
    CustInvest,
    CustOverview,
    CustLog,
    ErrorRefresh,
    TabBar,
    BreadCrumb
} from '../../organisms'

import { pagesConst } from '../../../store/utill'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { FEATURE_CUSTOMER_INFO_V2, FEATURE_CUSTOMER_LIST_V2, httpStatus } from '../../../store/utill/config/constant'
import KnowledgeAssessmentModal from '../../organisms/Modal/KnowledgeAssessmentModal.vue'
import { RetryError } from '../../../store/modules/customer/config/constant'

Vue.use(VueCookies)

export default {
    components: {
        CustBasicInfo,
        CustBank,
        CustInfo,
        CustInvest,
        CustOverview,
        ErrorRefresh,
        TabBar,
        BreadCrumb,
        HeaderSkeletonObject,
        ContentSkeletonObject,
        Spinner,
        CustLog,
        KnowledgeAssessmentModal
    },
    props: {
        tabs: {
            type: Object,
            required: true
        },
        customerCode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            header: {
                title: 'ADVISOR',
                path: [
                    {
                        // TODO: [Next Phase] link should be HOME
                        text: 'Home',
                        link: pagesConst.CUSTOMER_LIST,
                        dataAlias: 'Home-link',
                        external: false
                    },
                    {
                        text: 'Customers',
                        link: pagesConst.CUSTOMER_LIST,
                        external: false
                    }
                ]
            },
            pagesConst,
            alert: {},
            completedData: false,
            activeTab: this.tabs.OVERVIEW,
            reloadingTab: false,
            basicInfo: {},
            advisors: [],
            noteAdvisor: {},
            info: {},
            invest: {},
            bank: {},
            log: {},
            note: {},
            showFavoriteIcon: true,
            knowledgeAssessment: {},
            knowledgeAssessmentVisible: false,
            featureCustomerInfoV2Enabled: false,
            featureCustomerListV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('customer', [
            'getBasicInfo',
            'getAdvisors',
            'getInfo',
            'getInvest',
            'getBank',
            'getLog',
            'getNote'
        ]),
        ...mapGetters('authentication', ['getUserData']),
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        async featureCustomerInfoV2Enabled() {
            if (this.$route.query.tab && this.tabs[this.$route.query.tab.toUpperCase()]) {
                this.handleCheckTabRedirect(this.$route.query.tab)
                this.activeTab = this.tabs[this.$route.query.tab.toUpperCase()]
            }
            this.alert = await this.getCustInfo({
                uuid: this.$cookies.get('uuid'),
                custCode: this.customerCode,
                scope: 'overview,info,invest,bank,favorite,log'
            })

            if (
                this.alert &&
                this.alert.err &&
                this.alert.err.status &&
                this.alert.err.status === httpStatus.NOT_FOUND
            ) {
                this.redirect('/notfound')
            }

            if (this.alert.redirect != null) {
                this.redirect(this.alert.redirect)
            }
        },
        featureCustomerListV2Enabled(val) {
            this.$set(this.header.path, 0, {
                text: 'Home',
                dataAlias: 'Home-link',
                link: val ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list` : pagesConst.CUSTOMER_LIST,
                external: val
            })
            this.$set(this.header.path, 1, {
                text: 'Customers',
                link: val ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list` : pagesConst.CUSTOMER_LIST,
                external: val
            })
        },
        getTimestamp() {
            this.updateFeatureSettings()
        }
    },
    beforeDestroy() {
        clearInterval(this.loading.interval)
    },
    mounted() {
        this.updateFeatureSettings()
    },
    async created() {
        this.updateFeatureSettings()
        if (this.$route.query.tab && this.tabs[this.$route.query.tab.toUpperCase()]) {
            this.handleCheckTabRedirect(this.$route.query.tab)
            this.activeTab = this.tabs[this.$route.query.tab.toUpperCase()]
        }
        this.alert = await this.getCustInfo({
            uuid: this.$cookies.get('uuid'),
            custCode: this.customerCode,
            scope: 'overview,info,invest,bank,favorite,log'
        })

        if (this.alert && this.alert.err && this.alert.err.status && this.alert.err.status === httpStatus.NOT_FOUND) {
            this.redirect('/notfound')
        }

        if (this.alert.redirect != null) {
            this.redirect(this.alert.redirect)
        }

        this.basicInfo = await this.getBasicInfo
        this.advisors = await this.getAdvisors
        this.info = await this.getInfo
        this.invest = await this.getInvest
        this.bank = await this.getBank
        this.log = await this.getLog
        this.note = await this.getNote
        this.completedData = true

        this.noteAdvisor = {
            abbr: this.getUserData.abbr,
            full: this.getUserData.full,
            profile_picture: this.getUserData.profile_picture,
            display_circle: true,
            identifier: ''
        }
    },
    methods: {
        ...mapActions('customer', ['getCustInfo', 'updatePDPAStatus', 'updateCRSStatus', 'updateCustomerDisability']),
        ...mapActions('feature', ['isEnabled']),
        async updateActiveTab(newVal) {
            // IF tab GNAVI -> redirect to V2 customer info GNAVI tab
            this.handleCheckTabRedirect(newVal)
            this.activeTab = newVal
            let keyTab = this.getTabKeyByValue(newVal)
            if (keyTab) {
                this.$router.replace({ query: { tab: keyTab.toLowerCase() } })
            }
        },
        handleCheckTabRedirect(tab) {
            const baseUrl = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-info/${this.customerCode}`
            if (this.featureCustomerInfoV2Enabled && tab.toUpperCase() === this.tabs.OVERVIEW) {
                window.location.href = `${baseUrl}?tab=overview`
            } else if (this.featureCustomerInfoV2Enabled && tab.toUpperCase() === this.tabs.INFO) {
                window.location.href = `${baseUrl}?tab=info`
            } else if (this.featureCustomerInfoV2Enabled && tab.toUpperCase() === this.tabs.INVEST) {
                window.location.href = `${baseUrl}?tab=invest`
            } else if (this.featureCustomerInfoV2Enabled && tab.toUpperCase() === this.tabs.LOG) {
                window.location.href = `${baseUrl}?tab=log`
            } else if (this.featureCustomerInfoV2Enabled && tab.toUpperCase() === this.tabs.BANK) {
                window.location.href = `${baseUrl}?tab=bank`
            } else if (tab.toUpperCase() === this.tabs.GNAVI) {
                window.location.href = `${baseUrl}?tab=gnavi`
            } else if (tab.toUpperCase() === this.tabs.NOTES) {
                window.location.href = `${baseUrl}?tab=notes`
            }
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_INFO_V2).then(flag => {
                this.featureCustomerInfoV2Enabled = flag
            })
            this.isEnabled(FEATURE_CUSTOMER_LIST_V2).then(flag => {
                this.featureCustomerListV2Enabled = flag
            })
        },
        getTabKeyByValue(val) {
            return Object.keys(this.tabs).find(key => this.tabs[key] === val)
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        },
        async refresh(tab) {
            this.reloadingTab = true
            switch (tab) {
                case this.tabs.OVERVIEW:
                    this.alert = await this.getCustInfo({
                        uuid: this.$cookies.get('uuid'),
                        custCode: this.customerCode,
                        scope: 'overview,invest,bank'
                    })
                    if (this.alert.redirect != null) {
                        this.redirect(this.alert.redirect)
                    }
                    this.basicInfo = this.alert.type == 'success' ? await this.getBasicInfo : null
                    this.advisors = this.alert.type == 'success' ? await this.getAdvisors : null
                    break
                case this.tabs.INFO:
                    this.alert = await this.getCustInfo({
                        uuid: this.$cookies.get('uuid'),
                        custCode: this.customerCode,
                        scope: 'info'
                    })
                    if (this.alert.redirect != null) {
                        this.redirect(this.alert.redirect)
                    }
                    this.info = this.alert.type == 'success' ? await this.getInfo : null
                    break
                case this.tabs.INVEST:
                    this.alert = await this.getCustInfo({
                        uuid: this.$cookies.get('uuid'),
                        custCode: this.customerCode,
                        scope: 'overview,invest'
                    })
                    if (this.alert.redirect != null) {
                        this.redirect(this.alert.redirect)
                    }
                    this.invest = this.alert.type == 'success' ? await this.getInvest : null
                    break
                case this.tabs.BANK:
                    this.alert = await this.getCustInfo({
                        uuid: this.$cookies.get('uuid'),
                        custCode: this.customerCode,
                        scope: 'overview,bank'
                    })
                    if (this.alert.redirect != null) {
                        this.redirect(this.alert.redirect)
                    }
                    this.bank = this.alert.type == 'success' ? await this.getBank : null
                    break
                case this.tabs.LOG:
                    this.alert = await this.getCustInfo({
                        uuid: this.$cookies.get('uuid'),
                        custCode: this.customerCode,
                        scope: 'log'
                    })
                    if (this.alert.redirect != null) {
                        this.redirect(this.alert.redirect)
                    }
                    this.log = this.alert.type == 'success' ? await this.getLog : null
                    break
                default:
                    this.alert = await this.getCustInfo({
                        uuid: this.$cookies.get('uuid'),
                        custCode: this.customerCode,
                        scope: 'overview,info,invest,bank,log'
                    })
                    if (this.alert.redirect != null) {
                        this.redirect(this.alert.redirect)
                    }
                    this.basicInfo = this.alert.type == 'success' ? await this.getBasicInfo : null
                    this.advisors = this.alert.type == 'success' ? await this.getAdvisors : null
                    this.info = this.alert.type == 'success' ? await this.getInfo : null
                    this.invest = this.alert.type == 'success' ? await this.getInvest : null
                    this.bank = this.alert.type == 'success' ? await this.getBank : null
                    this.log = this.alert.type == 'success' ? await this.getLog : null
            }
            this.reloadingTab = false
            clearInterval(this.loadingTab.interval)
        },

        async retryError(topic) {
            switch (topic) {
                case RetryError.PDPA:
                    return this.reloadPDPAStatus()
                case RetryError.CRS:
                    return this.reloadCRSStatus()
                case RetryError.ANEO:
                    return this.reloadANEODisability()
            }
        },
        async reloadPDPAStatus() {
            await this.updatePDPAStatus({
                uuid: this.$cookies.get('uuid'),
                custCode: this.$route.params.customerCode
            })
        },
        async reloadCRSStatus() {
            await this.updateCRSStatus({
                uuid: this.$cookies.get('uuid'),
                custCode: this.$route.params.customerCode
            })
        },
        async reloadANEODisability() {
            await this.updateCustomerDisability({
                uuid: this.$cookies.get('uuid'),
                custCode: this.$route.params.customerCode
            })
        },
        toggleKnowledgeAssessmentModal() {
            this.knowledgeAssessmentVisible = !this.knowledgeAssessmentVisible
        }
    }
}
</script>
