<template>
    <v-container>
        <v-layout text-center wrap class="list__container">
            <v-autocomplete
                v-model="selectedValue"
                :label="label"
                :items="list"
                :disabled="disabled"
                :chips="chips"
                color="#f4f7f9"
                item-color="#444444"
            ></v-autocomplete>
        </v-layout>
    </v-container>
</template>
<script>
import { dropdownListValidator } from '../../../store/modules/general/validator'

export default {
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        list: {
            type: Array,
            required: true,
            validator: dropdownListValidator
        },
        value: {
            type: [String, Number],
            required: false,
            default: ''
        },
        chips: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            selectedValue: this.value
        }
    },
    watch: {
        selectedValue(newVal) {
            this.$emit('value', newVal)
        }
    }
}
</script>
