<template>
    <div>
        <table ref="TableDistribution" class="table__secondary--normal bottom__margin--normal">
            <thead class="head__table--primary bottom__border--solid">
                <tr>
                    <th class="first__column first__column--fix"></th>
                    <th class="horizon__align--left width__container--Fund">Fund</th>
                    <th class="horizon__align--left width__container--data_received">Data Received</th>
                    <th class="horizon__align--left display__phablet--none lessthan width__container--type">Type</th>
                    <th class="horizon__align--right display__mobile--none lessthan width__container--amount">
                        Amount
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(data, idx) in distributionList">
                    <tr :key="`order-row-${idx}`" :class="expandList[idx] ? '' : 'bottom__border--solid'">
                        <!-- ARROW -->
                        <td
                            class="pointer__cursor first__column--fix"
                            :class="'item__align--top'"
                            :data-idx="idx"
                            @click="arrowClicked(idx)"
                        >
                            <i :class="arrowDirection(expandList[idx])" />
                        </td>
                        <!-- Fund -->
                        <td class="horizon__align--left">
                            <div class="list__container">
                                <div class="row__container wrap__content item__align--center">
                                    <div class="normal__text--primary font-bold">
                                        {{ data.fund_name }}
                                        <external-link @click.native="$emit('open-fund-tab', data.fund_name)" />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <!-- วันที่ได้รับ -->
                        <td class="horizon__align--left">
                            <div class="normal__text--primary">
                                {{ getDateFormat(data.payment_date) }}
                            </div>
                        </td>
                        <!-- ประเภท -->
                        <td class="horizon__align--left display__phablet--none lessthan" style="white-space: nowrap">
                            <div class="normal__text--primary">
                                {{ data.order_type }}
                            </div>
                        </td>
                        <!-- มูลค่า -->
                        <td class="horizon__align--right display__mobile--none lessthan" style="white-space: nowrap">
                            <div>
                                <div class="normal__text--primary">{{ data.amount }}</div>
                            </div>
                        </td>
                    </tr>
                    <!-- Expansion -->
                    <tr v-if="expandList[idx]" :key="`order-row-expand-${idx}`" class="bottom__border--solid">
                        <td></td>
                        <td :colspan="colspan">
                            <div class="row__container">
                                <div class="expand__row--responsive phablet list__container">
                                    <div class="row__container display__phablet--none morethan">
                                        <template v-if="data.order_type == distributionType.DIVIDEND">
                                            <span class="font-bold topic__text--primary right__margin--small"
                                                >Unit:
                                            </span>
                                            <div class="normal__text--primary">{{ data.amount }}</div>
                                        </template>
                                    </div>
                                    <div class="row__container display__tablet--none morethan">
                                        <span class="font-bold topic__text--primary right__margin--small">Type:</span>
                                        <div class="normal__text--primary">{{ data.order_type }}</div>
                                    </div>
                                    <!-- order_type == dividend -->
                                    <template v-if="data.order_type == distributionType.DIVIDEND">
                                        <table-responsive-display :title="'Dividend Rate:'" :value="data.nav" />
                                        <table-responsive-display :title="'Unit:'" :value="data.unit" />
                                        <table-responsive-display :title="'XD Date:'" :value="data.book_closing_date" />
                                        <table-responsive-display
                                            :title="'Payment Date:'"
                                            :value="getDateFormat(data.payment_date)"
                                        />
                                        <div class="display__tablet--none morethan">
                                            <table-responsive-display
                                                :title="'Payment Method:'"
                                                :value="data.payment_method"
                                            />
                                            <table-responsive-display
                                                :title="'Bank Account:'"
                                                :value="data.bank_account"
                                            />
                                            <table-responsive-display :title="'Trade Date:'" :value="data.trade_date" />
                                        </div>
                                    </template>
                                    <!-- order_type == auto redemtion -->
                                    <template v-else>
                                        <table-responsive-display :title="'Amount:'" :value="data.amount" />
                                        <table-responsive-display :title="'NAV:'" :value="data.nav" />
                                        <table-responsive-display :title="'Unit:'" :value="data.unit" />
                                        <div class="display__tablet--none morethan">
                                            <table-responsive-display
                                                :title="'Payment Method:'"
                                                :value="data.payment_method"
                                            />
                                            <table-responsive-display :title="'Trade Date:'" :value="data.trade_date" />
                                        </div>
                                    </template>
                                </div>
                                <div class="expand__row--responsive list__container display__phablet--none lessthan">
                                    <!-- order_type == dividend -->
                                    <template v-if="data.order_type == distributionType.DIVIDEND">
                                        <table-responsive-display
                                            :title="'Payment Method:'"
                                            :value="data.payment_method"
                                        />
                                        <table-responsive-display :title="'Bank Account:'" :value="data.bank_account" />
                                        <table-responsive-display :title="'Trade Date:'" :value="data.trade_date" />
                                    </template>
                                    <!-- order_type == auto redemtion -->
                                    <template v-else>
                                        <table-responsive-display
                                            :title="'Payment Method:'"
                                            :value="data.payment_method"
                                        />
                                        <table-responsive-display :title="'Trade Date:'" :value="data.trade_date" />
                                    </template>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { distributionListValidator } from '../../../store/modules/port/validator'
import { getDateFormat } from '../../../store/utill'
import { iconsConst, distributionType } from '../../../store/utill/config'
import { ExternalLink, TableResponsiveDisplay } from '../../atoms'

export default {
    components: {
        ExternalLink,
        TableResponsiveDisplay
    },
    props: {
        distributionList: {
            type: Array,
            required: true,
            validator: distributionListValidator
        }
    },
    data() {
        return {
            colspan: 1,
            expandList: [],
            iconsConst,
            distributionType
        }
    },
    watch: {
        distributionList: {
            async handler() {
                this.expandList = await this.getAllExpand(false)
            }
        }
    },
    async mounted() {
        this.expandList = await this.getAllExpand(false)
    },
    methods: {
        getDateFormat,
        arrowClicked(idx) {
            this.colspan = this.$refs.TableDistribution.rows[0].cells.length - 1
            this.expandList = this.expandList
                .slice(0, idx)
                .concat(!this.expandList[idx])
                .concat(this.expandList.slice(idx + 1, this.expandList.length))
        },
        async getAllExpand(value) {
            let list = []
            for (let idx in this.distributionList) {
                list[idx] = value
            }
            return list
        },
        arrowDirection(expand) {
            if (expand) {
                return iconsConst.ARROW_DOWN
            } else {
                return iconsConst.ARROW_RIGHT
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.first__column--fix {
    width: 45px !important;
}
.table__padding--zero {
    padding: 0%;
}
.width__container--Fund {
    width: 300px;
}
.width__container--data_received {
    width: 100px;
}
.width__container--type {
    width: 10%;
}
.width__container--amount {
    width: 10%;
}
</style>
