import { HTTP_REQUEST, API_PRIVATE_V1_PREFIX } from './apiConfig'

export const leadRequestAPI = {
    leadRequestList: { method: HTTP_REQUEST.GET, url: `${API_PRIVATE_V1_PREFIX.API}/lead/request-list` },
    create: { method: HTTP_REQUEST.POST, url: `${API_PRIVATE_V1_PREFIX.API}/lead/request` },
    search: { method: HTTP_REQUEST.GET, url: `${API_PRIVATE_V1_PREFIX.API}/lead/search` },
    approvalList: { method: HTTP_REQUEST.GET, url: `${API_PRIVATE_V1_PREFIX.API}/lead/approval-list` },
    approvalAction: { method: HTTP_REQUEST.POST, url: `${API_PRIVATE_V1_PREFIX.API}/lead/approval` },
    approvalAllAction: { method: HTTP_REQUEST.POST, url: `${API_PRIVATE_V1_PREFIX.API}/lead/approvals` }
}
