<template>
    <v-container class="list__container round__block--primary">
        <title-box
            id="btn-more-bank"
            :title="'Bank Account'"
            :see-more-tab="seeMoreTab"
            :show-more="showMore"
            @seeMore="seeMore"
        />
        <div class="row__container item__align--center">
            <div class="list__container bottom__margin--normal width__container--fifty">
                <span class="topic__text--primary"> Subscription </span>
                <span class="big__text--greyPri"> {{ bankAccount.subscription }} </span>
            </div>
            <div class="list__container bottom__margin--normal width__container--fifty">
                <span class="topic__text--primary"> Redemption </span>
                <span class="big__text--greyPri"> {{ bankAccount.redemption }} </span>
            </div>
        </div>
    </v-container>
</template>
<script>
import { TitleBox } from '../Title'
import { sumBankValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        bankAccount: {
            type: Object,
            required: true,
            validator: sumBankValidator
        }
    },
    methods: {
        seeMore(newVal) {
            this.$emit('seeMore', newVal)
        }
    }
}
</script>
