<template>
    <div v-if="showMenu" data-fn-location="menu" class="menu__list">
        <template v-for="menu in menuList">
            <div
                v-if="menu.show && menu.use_data_fn"
                :key="`menu-${menu.redirect}`"
                class="row__container horizon__padding--medium plain__btn"
                :data-fn-action="menu.data_fn_action ? menu.data_fn_action : ''"
                @click="menuClicked(menu)"
            >
                <span class="size__font--16px big__text--white">{{ menu.menu_name }}</span>
            </div>
            <div
                v-else-if="menu.show"
                :key="`menu-${menu.redirect}`"
                class="row__container horizon__padding--medium plain__btn"
                @click="menuClicked(menu)"
            >
                <span class="size__font--16px big__text--white">{{ menu.menu_name }}</span>
            </div>
        </template>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { pagesConst, permissionsConst, FEATURE_CUSTOMER_LIST_V2 } from '../../../store/utill/config/constant'
import { permissionsValidator } from '../../../store/modules/general/validator'

export default {
    props: {
        permissions: {
            type: Object,
            required: true,
            validator: permissionsValidator
        }
    },
    data() {
        return {
            pagesConst,
            permissionsConst,
            permissionsKey: {},
            menuList: [],
            showMenu: false,
            featureCustomerListV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('authentication', ['getPermissionsKey']),
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSettings()
        },
        featureCustomerListV2Enabled(val) {
            this.$set(this.menuList, 0, {
                show: false,
                menu_name: 'Customers',
                redirect: val
                    ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list`
                    : this.pagesConst.CUSTOMER_LIST,
                external: val,
                permissions: {
                    type: 'permissions',
                    data: [
                        this.permissionsKey[this.permissionsConst.VIEW_CUST_LIST],
                        this.permissionsKey[this.permissionsConst.VIEW_CUST_DETAIL]
                    ]
                }
            })
            this.verifyMenu()
        }
    },
    mounted() {
        this.updateFeatureSettings()
    },
    async created() {
        this.updateFeatureSettings()
        this.permissionsKey = await this.getPermissionsKey
        this.menuList = [
            {
                show: false,
                menu_name: 'Customers',
                redirect: this.pagesConst.CUSTOMER_LIST,
                permissions: {
                    type: 'permissions',
                    data: [
                        this.permissionsKey[this.permissionsConst.VIEW_CUST_LIST],
                        this.permissionsKey[this.permissionsConst.VIEW_CUST_DETAIL]
                    ]
                }
            },
            {
                show: false,
                menu_name: 'Lead Request',
                redirect: this.pagesConst.LEAD_REQUEST,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_LEAD_REQUEST]]
                }
            },
            {
                show: false,
                menu_name: 'Portfolios',
                redirect: `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/portfolio`,
                external: true,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_PORTFOLIO_LIST]]
                }
            },
            {
                show: false,
                menu_name: 'Lead Req. Approval',
                redirect: this.pagesConst.LEAD_REQUEST_APPROVAL,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_LEAD_APPROVE]]
                }
            },
            {
                show: false,
                menu_name: 'Commission Report',
                redirect: `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/commission-report`,
                data_fn_action: 'commission-report_click',
                use_data_fn: true,
                external: true,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_COMMISSION_REPORT]]
                }
            },
            {
                show: false,
                menu_name: 'Outstanding Report',
                redirect: `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/outstanding-report`,
                external: true,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_OUTSTANDING_LIST]]
                }
            },
            {
                show: false,
                menu_name: 'Investment View',
                redirect: `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/investment-view`,
                external: true,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_CUST_DETAIL]]
                }
            },
            {
                show: false,
                menu_name: 'Goals Navigator',
                redirect: `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/goals-navigator`,
                external: true,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_GNAVI]]
                }
            },
            {
                show: false,
                menu_name: 'Proposal Order',
                redirect: `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/proposal-order`,
                external: true,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_PROPOSAL_ORDER]]
                }
            },
            {
                show: false,
                menu_name: 'Data Export',
                redirect: `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/data-export`,
                external: true,
                permissions: {
                    type: 'permissions',
                    data: [this.permissionsKey[this.permissionsConst.VIEW_DATA_EXPORT]]
                }
            }
        ]
        await this.verifyMenu()
        this.showMenu = true
    },
    methods: {
        ...mapActions('authentication', ['verifyPermissions']),
        ...mapActions('feature', ['isEnabled']),
        async verifyMenu() {
            for (let menu of this.menuList) {
                let show = await this.verifyPermissions({
                    type: menu.permissions.type,
                    data: menu.permissions.data
                })
                menu.show = show.type == 'success'
            }
        },
        menuClicked(menu) {
            this.$emit('showMenu', false)
            if (menu.external === true) {
                window.location.href = menu.redirect
                return
            }
            if (this.$route.redirect !== menu.redirect) {
                this.$router.push(menu.redirect)
            }
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_LIST_V2).then(flag => {
                this.featureCustomerListV2Enabled = flag
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.menu__list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
</style>
