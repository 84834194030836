<template>
    <v-container class="background--normal__white border--normal horizon__padding--big">
        <div class="vertical__margin--big">
            <v-alert v-if="alert.show" :type="alert.type" text :icon="alert.icon">{{ alert.msg }}</v-alert>
            <div class="big__text--greyPri vertical__margin--big">Advisor Login</div>
            <form @submit.prevent="validate">
                <!-- TODO: [Next Phase] -->
                <!-- <input-custom
                    title="Email"
                    type="email"
                    :validation="email.validation"
                    class="bottom--margin"
                    @value="value => updateValue('email', value)"
                /> -->
                <input-custom
                    title="Email"
                    type="text"
                    autocomplete="email"
                    :value="email.value"
                    :validation="email.validation"
                    class="bottom--margin"
                    @value="value => updateValue('email', value)"
                />

                <input-custom
                    title="Password"
                    type="password"
                    :value="password.value"
                    :validation="password.validation"
                    class="bottom--margin"
                    @value="value => updateValue('password', value)"
                />

                <F4ACookieNote class="bottom--margin" />

                <div class="row__container bottom--margin item__align--center button-wrap">
                    <button type="submit" class="login__btn--normal font-bold">Login</button>
                    <v-spacer />
                    <div class="flex forgot-button">
                        <button
                            type="button"
                            class="icon__btn--secondary font-bold forgot-password__font-size"
                            @click="redirect(`${pagesConst.FORGOT_PASSWORD}`)"
                        >
                            Forgot Password?
                        </button>
                    </div>
                </div>
            </form>
            <F4AAccountNote class="bottom__padding--normal" />
        </div>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { pagesConst, FEATURE_CUSTOMER_LIST_V2 } from '../../../store/utill/config/constant'
import { F4AAccountNote, F4ACookieNote, InputCustom } from '../../atoms'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
    components: {
        F4AAccountNote,
        F4ACookieNote,
        InputCustom
    },
    data() {
        return {
            pagesConst,
            csrf: '',
            email: {
                value: '',
                validation: {
                    show: false,
                    msg: ''
                }
            },
            password: {
                value: '',
                validation: {
                    show: false,
                    msg: ''
                }
            },
            alert: {},
            featureCustomerListV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('authentication', ['getCSRF', 'getUUID', 'getUserData']),
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSettings()
        }
    },
    async mounted() {
        this.updateFeatureSettings()
        // if uuid is valid then redirect to home page
        const verifiedResult = await this.verifyUser(this.$cookies.get('uuid'))
        if (verifiedResult.err == null) {
            if (this.featureCustomerListV2Enabled) {
                window.location.href = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list`
            } else {
                this.redirect(pagesConst.CUSTOMER_LIST)
            }
        }
    },
    created() {
        this.updateFeatureSettings()
    },
    methods: {
        ...mapActions('authentication', ['login', 'requestCSRFToken', 'verifyUser']),
        ...mapActions('feature', ['isEnabled']),
        async requestCSRF() {
            this.alert = await this.requestCSRFToken()
            this.csrf = await this.getCSRF
        },
        updateValue(variable, value) {
            this[variable].value = value
        },
        validEmail(email) {
            let regEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            return regEx.test(email)
        },
        async validate() {
            let validData = true
            this.email.validation = {
                show: false,
                msg: ''
            }
            this.password.validation = {
                show: false,
                msg: ''
            }
            if (this.email.value == '') {
                this.email.validation = {
                    show: true,
                    msg: 'Please fill email.'
                }
                validData = false
            } else if (this.email.value != '') {
                // TODO: [Next Phase]
                // let valid = this.validEmail(this.email.value)
                // if (!valid) {
                //     this.email.validation = {
                //         show: true,
                //         msg: 'Email is invalid.'
                //     }
                //     validData = false
                // }
            }
            if (this.password.value == '') {
                this.password.validation = {
                    show: true,
                    msg: 'Please fill password.'
                }
                validData = false
            }
            if (validData) {
                await this.requestLogin()
            }
        },
        async requestLogin() {
            await this.requestCSRF()
            this.alert = await this.login({
                csrf: this.csrf,
                email: this.email.value,
                password: this.password.value
            })
            if (this.alert.type == 'success') {
                this.$cookies.set('uuid', this.getUUID)
                // setVisitorTraits(window, this.getUserData.id)
                if (this.$route.query.redirect_path && process.env.VUE_APP_WHITELIST_DOMAIN) {
                    try {
                        let domain = new URL(this.$route.query.redirect_path)
                        let whitelist = process.env.VUE_APP_WHITELIST_DOMAIN.split(',')
                        if (whitelist.includes(domain.hostname)) {
                            window.location.href = this.$route.query.redirect_path
                        } else {
                            throw 'redirect_path does not exist in the whitelist'
                        }
                    } catch (err) {
                        console.error(err)
                        if (this.featureCustomerListV2Enabled) {
                            window.location.href = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list`
                        } else {
                            this.redirect(pagesConst.CUSTOMER_LIST)
                        }
                    }
                } else {
                    if (this.featureCustomerListV2Enabled) {
                        window.location.href = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list`
                    } else {
                        this.redirect(pagesConst.CUSTOMER_LIST)
                    }
                }
            }
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_LIST_V2).then(flag => {
                this.featureCustomerListV2Enabled = flag
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.button-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (min-width: 480px) {
        flex-direction: row;
        align-items: flex-start;
    }

    .login__btn--normal {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: 480px) {
        .login__btn--normal {
            margin-bottom: unset;
        }
    }

    .forgot-button {
        width: 100%;

        @media screen and (min-width: 480px) {
            text-align: center;
        }
    }
}
.bottom--margin {
    margin-bottom: 32px;
}
.forgot-password__font-size {
    font-size: 14px;
}
</style>
