import Vue from 'vue'
import VueRouter from 'vue-router'

const CustomerInfo = () => import(/* webpackChunkName: "customer-info" */ '../pages/CustomerInfo.vue')
const CustomerList = () => import(/* webpackChunkName: "customer-list" */ '../pages/CustomerList.vue')
const PortInfo = () => import(/* webpackChunkName: "port-info" */ '../pages/PortInfo.vue')
const LeadRequestApproval = () => import(/* webpackChunkName: "lead-approval" */ '../pages/LeadRequestApproval.vue')
const LeadRequestList = () => import(/* webpackChunkName: "lead-request" */ '../pages/LeadRequestList.vue')
const Forbidden = () => import(/* webpackChunkName: "forbidden" */ '../pages/Forbidden.vue')
const Login = () => import(/* webpackChunkName: "login" */ '../pages/Login.vue')
const NotFound = () => import(/* webpackChunkName: "not-found" */ '../pages/NotFound.vue')
const CreatePassword = () => import(/* webpackChunkName: "create-password" */ '../pages/CreatePassword.vue')
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ '../pages/ForgotPassword.vue')

import { pagesConst } from '../store/utill'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: pagesConst.LOGIN,
        component: Login
    },
    {
        path: '/forgot/password',
        name: pagesConst.FORGOT_PASSWORD,
        component: ForgotPassword
    },
    {
        path: '/user/password/:token',
        name: pagesConst.CREATE_PASSWORD,
        component: CreatePassword
    },
    {
        path: '/',
        name: pagesConst.HOME,
        component: CustomerList
    },
    {
        path: '/customer-info/:customerCode',
        name: pagesConst.CUSTOMER_INFO,
        component: CustomerInfo
    },
    {
        path: '/customer-list',
        name: pagesConst.CUSTOMER_LIST,
        component: CustomerList
    },
    {
        path: '/lead-request',
        name: pagesConst.LEAD_REQUEST,
        component: LeadRequestList
    },
    {
        path: '/port-info/:portId',
        name: pagesConst.PORT_INFO,
        component: PortInfo
    },
    {
        path: '/lead-request-approval',
        name: pagesConst.LEAD_REQUEST_APPROVAL,
        component: LeadRequestApproval
    },
    // {
    //     path: '/setting',
    //     name: pagesConst.SETTING,
    //     component: Setting
    // },
    // TODO: [Next Phase]
    // {
    //     path: '/permission-config',
    //     name: pagesConst.PERMISSION_CONFIG,
    //     component: PermissionConfig
    // },
    {
        path: '/forbidden',
        name: pagesConst.FORBIDDEN,
        component: Forbidden
    },
    {
        path: '*',
        name: pagesConst.NOT_FOUND,
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
