<template>
    <div>
        <account-lack-info v-if="accountStatus.ACCOUNT_LACK_INFO == displayAccountStatus" />
        <account-close v-else-if="accountStatus.ACCOUNT_CLOSE == displayAccountStatus" />
        <account-updating v-else-if="accountStatus.ACCOUNT_UPDATING == displayAccountStatus" />
        <account-freeze v-else-if="accountStatus.ACCOUNT_FREEZE == displayAccountStatus" />
    </div>
</template>

<script>
import { AccountClose, AccountLackInfo, AccountUpdating, AccountFreeze } from './AccountStatus/index'
import { displayAccountStatus } from '../../../../store/utill/config/constant'

export default {
    components: {
        AccountClose,
        AccountLackInfo,
        AccountUpdating,
        AccountFreeze
    },
    props: {
        displayAccountStatus: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            accountStatus: displayAccountStatus
        }
    }
}
</script>
