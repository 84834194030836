<template>
    <div v-if="visible" class="modal__container">
        <div class="general__modal" :style="width ? `width: ${width}px;` : ''">
            <title-modal :title="title" :color="color" :icon="icon" :prefix-alias="prefixAlias" @close="close" />
            <div :class="!clearPaddingContent ? 'horizon__padding--big top__padding--big' : ''">
                <slot></slot>
            </div>
            <line-button v-if="enableLineButton" @click="handleButtonClicked" />
        </div>
    </div>
</template>

<script>
import { LineButton, TitleModal } from '../../molecules'
export default {
    components: {
        TitleModal,
        LineButton
    },
    props: {
        visible: Boolean,
        reset: {
            type: Function,
            required: true
        },
        cancel: {
            type: Function,
            required: false,
            default: () => {}
        },
        apply: {
            type: Function,
            required: true,
            default: () => {}
        },
        title: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        close: {
            type: Function,
            required: true,
            default: () => {}
        },
        enableLineButton: {
            type: Boolean,
            default: false
        },
        prefixAlias: {
            type: String,
            default: null
        },
        width: {
            type: Number,
            default: null
        },
        clearPaddingContent: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleButtonClicked(buttonName) {
            switch (buttonName) {
                case 'reset':
                    this.reset()
                    break
                case 'cancel':
                    this.cancel()
                    break
                case 'apply':
                    this.apply()
                    break
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.general__modal {
    width: 416px;
    height: max-content;
    border-radius: 8px;
    border: 1px solid $greyPrimary;
    background: $white;
    @include responsive(mobile) {
        width: 100%;
        max-width: 320px;
    }
}
</style>
