<template>
    <div>
        <div v-if="completedData" class="list__container content__page">
            <div class="row__container big__text--greyPri bottom__margin--big">
                Your Lead Request
                <v-spacer />
                <yellow-outlined-button
                    :text="'Request a Lead'"
                    :icon="iconsConst.PLUS"
                    class="right__margin--small bottom__margin--normal"
                    @click.native="showLeadRequestModal = true"
                />
            </div>
            <div class="list__container item__align--center">
                <table-lead-request
                    class="full__width bottom__margin--normal"
                    :header-list="headerLeadRequestList"
                    :sorting="sorting"
                    :list="visibledleadRequestList"
                    @seeCustomer="seeCustomer"
                    @showMoreNote="showMoreNote"
                />
                <pagination :current-page="currentPage" :total-pages="totalPages" @currentPage="updateCurrentPage" />
            </div>
        </div>
        <div v-else class="vertical__margin--big horizon__align--center item__align--center">
            <spinner />
        </div>
        <div v-if="showLeadRequestModal" class="modal__container">
            <modal-lead-request
                :customer-info="leadCustomerInfo"
                :lead-status="leadStatus"
                @close="closeLeadRequestModal"
                @clearCustomerInfo="clearCustomerInfo"
                @searchLeadRequest="searchLeadRequest"
                @saveLeadRequest="saveLeadRequest"
            />
        </div>
    </div>
</template>

<script>
import { headerLeadRequestList } from '../../../store/modules/leadRequest/config'
import { leadRequestListValidator, sortingValidator } from '../../../store/modules/leadRequest/validator'
import { Pagination, TableLeadRequest } from '../../molecules'
import { YellowOutlinedButton, Spinner } from '../../atoms'
import { iconsConst } from '../../../store/utill'
import { ModalLeadRequest } from '../Modal'

export default {
    components: {
        Pagination,
        TableLeadRequest,
        ModalLeadRequest,
        YellowOutlinedButton,
        Spinner
    },
    props: {
        leadRequestList: {
            type: Array,
            required: true,
            validator: leadRequestListValidator
        },
        sorting: {
            type: Object,
            required: true,
            validator: sortingValidator
        },
        leadCustomerInfo: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        },
        leadStatus: {
            type: Object,
            required: false,
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            iconsConst,
            headerLeadRequestList,
            amountContent: 5,
            completedData: false,
            currentPage: 1,
            totalPages: 0,
            visibledleadRequestList: [],
            showLeadRequestModal: false
        }
    },
    async created() {
        this.totalPages = await this.updateTotalPages()
        await this.updateCurrentPage(1)
        this.completedData = true
    },
    methods: {
        async updateCurrentPage(newVal) {
            this.currentPage = newVal
            await this.updateVisibledLeadRequestList()
        },
        async updateTotalPages() {
            return Math.ceil(this.leadRequestList.length / this.amountContent)
        },
        async updateVisibledLeadRequestList() {
            this.visibledleadRequestList = this.leadRequestList.slice(
                (this.currentPage - 1) * this.amountContent,
                this.currentPage * this.amountContent
            )
        },
        seeCustomer(custCode) {
            this.$emit('seeCustomer', custCode)
        },
        showMoreNote(note) {
            this.$emit('showMoreNote', note)
        },
        searchLeadRequest(portId) {
            this.$emit('searchLeadRequest', portId)
        },
        clearCustomerInfo() {
            this.$emit('clearCustomerInfo')
        },
        clearLeadStatus() {
            this.$emit('clearLeadStatus')
        },
        saveLeadRequest(reqBody) {
            this.$emit('saveLeadRequest', reqBody)
        },
        closeLeadRequestModal() {
            this.showLeadRequestModal = false
            this.clearCustomerInfo()
            this.clearLeadStatus()
        }
    }
}
</script>

<style lang="scss" scoped></style>
