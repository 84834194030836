import { getDateFormat } from '../../../utill'

const getAssetList = assetList => {
    let list = []
    let allAssets = {
        weight: 0,
        weight_model: 0,
        cost: 0,
        value: 0
    }
    for (let asset of assetList) {
        allAssets = {
            weight: allAssets.weight + (asset.weight || 0),
            weight_model: allAssets.weight_model + (asset.weight_model || 0),
            value: allAssets.value + (asset.market_value || 0)
        }
        list.push({
            fund: asset.asset_code || '-',
            change: {
                value: asset.profit || 0,
                percent: asset.profit_percent || 0
            },
            weight: asset.weight || 0,
            weight_model: asset.weight_model || 0,
            value: asset.market_value || 0,
            unit: asset.unit_cost || 0,
            cost: asset.cost || 0,
            nav_cost: asset.nav_cost || 0,
            nav_current: asset.nav_current || 0,
            nav_date: asset.nav_date ? getDateFormat(asset.nav_date) : '-'
        })
    }
    return { list, allAssets }
}

const getTotalTaxCost = totalTax => {
    let taxCostByYear = {}
    if (!totalTax) return taxCostByYear
    totalTax.map(taxCost => {
        let rmf = taxCost.cost?.rmf || 0
        let ssf = taxCost.cost?.ssf || 0
        let tesg = taxCost.cost?.tesg || 0
        taxCostByYear[taxCost.year] = taxCost.cost
        taxCostByYear[taxCost.year]['sum'] = rmf + ssf + tesg
    })
    return taxCostByYear
}

export { getAssetList, getTotalTaxCost }
