<template>
    <div>
        <div class="pra-container container-radius horizon__align--center list__container">
            <div class="list__container">
                <img class="bcp-pic" src="../../../../../public/img/icons/Under-maintenance.png" />
                <span class="normal__text--primary size__font--16px font-bold">ปิดปรับปรุงระบบชั่วคราว</span>
            </div>
            <div class="margin--medium size__font--16px">
                <span v-for="item of bcp" :key="item.message" :style="`color: ${getColor(item.type)}`">
                    {{ item.message }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        bcp: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getColor(type) {
            if (type === 'link') return '#F7A501'
            else return '#444'
        }
    }
}
</script>
<style lang="scss" scoped>
.pra-container {
    border: 1px solid $greyPrimary;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: center;
    background-color: white;

    &.container-radius {
        border-radius: 4px;
    }
}

.bcp-pic {
    width: 240px;

    @include responsive(mobile) {
        width: 200px;
    }
}

.size__font--16px {
    font-size: 14px !important;
    white-space: pre-wrap;

    @include responsive(phablet) {
        font-size: 16px !important;
    }

    @include responsive(tablet) {
        font-size: 16px !important;
    }

    @include responsive(desktop) {
        font-size: 16px !important;
    }
}
</style>
