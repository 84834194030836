<template>
    <div v-if="receivedProps">
        <table ref="tablePortChange" class="table__secondary--normal">
            <thead class="head__table--primary bottom__border--solid">
                <tr>
                    <th class="first__column first__column--fix"></th>
                    <th class="horizon__align--left">Fund</th>
                    <th class="horizon__align--right">Old %</th>
                    <th class="horizon__align--right">New %</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(change, idx) in changeList">
                    <tr :key="`port-change-row-${idx}`" :class="expandList[idx] ? '' : 'bottom__border--solid'">
                        <td
                            v-if="change.content_view && change.content_view != ''"
                            class="first__column pointer__cursor first__column--fix"
                            @click="arrowClicked(idx)"
                        >
                            <i :class="expandList[idx] ? iconsConst.ARROW_DOWN : iconsConst.ARROW_RIGHT" />
                        </td>
                        <td v-else class="first__column first__column--fix"></td>
                        <td class="horizon__align--left">
                            <span class="normal__text--primary font-bold">
                                <span class="mr-2">{{ change.fund_shortcode }}</span>
                                <external-link @click.native="$emit('open-fund-tab', change.fund_shortcode)"
                            /></span>
                        </td>
                        <td class="horizon__align--right">
                            <span class="normal__text--primary">
                                {{ getNumberFormat(change.percent_old, false, 2, '%') }}
                            </span>
                        </td>
                        <td class="horizon__align--right">
                            <span :class="getClassNameColorForProfit(change.percent_new - change.percent_old, 2)">
                                {{ getNumberFormat(change.percent_new, false, 2, '%') }}
                            </span>
                        </td>
                    </tr>
                    <tr v-if="expandList[idx]" :key="`port-change-row-expand-${idx}`" class="bottom__border--solid">
                        <td></td>
                        <td colspan="3">
                            <span>
                                {{ change.content_view }}
                            </span>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>

<script>
import { changeListValidator } from '../../../store/modules/port/validator'
import { getClassNameColorForProfit, getNumberFormat } from '../../../store/utill'
import { iconsConst } from '../../../store/utill/config'
import { ExternalLink, Spinner } from '../../atoms'

export default {
    components: {
        ExternalLink,
        Spinner
    },
    props: {
        changeList: {
            type: Array,
            required: true,
            validator: changeListValidator
        }
    },
    data() {
        return {
            iconsConst,
            receivedProps: false,
            expandList: []
        }
    },
    async mounted() {
        this.expandList = await this.getAllExpand(false)
        this.receivedProps = true
    },
    methods: {
        getClassNameColorForProfit,
        getNumberFormat,
        async getAllExpand(value) {
            let list = []
            for (let idx in this.changeList) {
                list[idx] = value
            }
            return list
        },
        arrowClicked(idx) {
            this.expandList = this.expandList
                .slice(0, idx)
                .concat(!this.expandList[idx])
                .concat(this.expandList.slice(idx + 1, this.expandList.length))
        }
    }
}
</script>

<style lang="scss" scoped>
.first__column--fix {
    width: 20px !important;
    padding: 12px 4px !important;

    @include responsive(phablet) {
        padding: 12px 8px !important;
    }

    @include responsive(tablet) {
        padding: 12px 8px !important;
    }

    @include responsive(desktop) {
        padding: 12px 8px !important;
    }
}

.table__secondary--normal td {
    padding: 12px 0px !important;

    @include responsive(phablet) {
        padding: 12px 8px !important;
    }

    @include responsive(tablet) {
        padding: 12px 8px !important;
    }

    @include responsive(desktop) {
        padding: 12px 8px !important;
    }
}

.normal__text--primary {
    font-size: 12px !important;

    @include responsive(phablet) {
        font-size: 14px !important;
    }

    @include responsive(tablet) {
        font-size: 14px !important;
    }

    @include responsive(desktop) {
        font-size: 14px !important;
    }
}

.normal__text--green {
    font-size: 12px !important;

    @include responsive(phablet) {
        font-size: 14px !important;
    }

    @include responsive(tablet) {
        font-size: 14px !important;
    }

    @include responsive(desktop) {
        font-size: 14px !important;
    }
}

.normal__text--red {
    font-size: 12px !important;

    @include responsive(phablet) {
        font-size: 14px !important;
    }

    @include responsive(tablet) {
        font-size: 14px !important;
    }

    @include responsive(desktop) {
        font-size: 14px !important;
    }
}
</style>
