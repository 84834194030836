<template>
    <div class="box-container">
        <span class="normal__text--primary size__font--16px">
            This is an upcoming investment view , approved on {{ getApprovedDate(approvedDate) }}, to be sent on
            {{ getPublishDate(publishedDate) }}
        </span>
    </div>
</template>

<script>
import { getDateFormat } from '../../../../store/utill'

export default {
    components: {},
    props: {
        approvedDate: {
            type: String,
            required: true
        },
        publishedDate: {
            type: String,
            required: true
        }
    },
    methods: {
        getPublishDate(date) {
            if (!date) {
                return 'waiting publish'
            }
            return getDateFormat(date, 'DD MMM YY', false)
        },
        getApprovedDate(date) {
            if (!date) {
                return 'waiting approve'
            }
            return getDateFormat(date, 'DD MMM YY', false)
        },
        getDateFormat
    }
}
</script>

<style lang="scss" scoped>
.box-container {
    width: 100%;
    background: rgba($yellowPrimary, 0.2);
    border: 1px solid $yellowPrimary;
    border-radius: 4px;
    padding: 16px 26px;
}
</style>
