<template>
    <div class="list__container">
        <span class="big__text--primary font-bold bottom__margin--big"
            >Latest Portfolio Change <b>{{ `(${praDate})` }}</b></span
        >
        <table-port-change :change-list="changeList" @open-fund-tab="openFundTab" />
    </div>
</template>

<script>
import { changeListValidator } from '../../../../store/modules/port/validator'
import { TablePortChange } from '../../../molecules'

export default {
    components: {
        TablePortChange
    },
    props: {
        praDate: {
            type: String,
            required: true
        },
        changeList: {
            type: Array,
            required: true,
            validator: changeListValidator
        }
    },
    methods: {
        async openFundTab(newVal) {
            this.$emit('openFundTab', newVal)
        }
    }
}
</script>

<style lang="scss" scoped>
.big__text--primary {
    font-size: 16px !important;

    @include responsive(phablet) {
        font-size: 20px !important;
    }

    @include responsive(tablet) {
        font-size: 20px !important;
    }

    @include responsive(desktop) {
        font-size: 20px !important;
    }
}
</style>
