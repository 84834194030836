<template>
    <div>
        <div class="pra-item-container">
            <div class="flex between">
                <action-type :type="pra.suggest_type.toLowerCase()" />
            </div>
            <div class="flex between">
                <div>
                    <a
                        :href="getFundLink(pra.fund_shortcode)"
                        class="normal__text--primary font-bold size__font--16px fund__label"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {{ pra.fund_shortcode }}
                    </a>
                    <div class="normal__text--primary font-bold ml-1">
                        <span
                            v-if="
                                pra.suggest_type.toLowerCase() == orderType.SWITCH ||
                                    pra.suggest_type.toLowerCase() == orderType.SWITCH_CROSS_AMC
                            "
                        >
                            <i
                                class="normal__text--blue font-bold mr-1 size__font--16px"
                                :class="iconsConst.LIGHT__ARROW__RIGHT"
                            />
                            <span class="size__font--16px fund__label">{{ pra.sw_fund_shortcode }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="amount--col mb-3">
                <span v-if="pra.unit != -1" class="normal__text--primary size__font--16px">
                    {{ getPraAmount() }}
                </span>
                <span v-else class="normal__text--primary size__font--16px">ทั้งหมด</span>
            </div>
            <pra-order-date :order-date="pra.order_date" />
        </div>
        <div class="pra-item-container-tablet">
            <div class="suggest__type--col">
                <action-type :type="pra.suggest_type.toLowerCase()" />
            </div>
            <div class="row__container justify-space-between mb-3">
                <div class="fund--col">
                    <span class="normal__text--primary font-bold size__font--16px">
                        <a
                            :href="getFundLink(pra.fund_shortcode)"
                            class="fund__label"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {{ pra.fund_shortcode }}
                        </a>
                        <div class="normal__text--primary font-bold ml-1">
                            <span
                                v-if="
                                    pra.suggest_type.toLowerCase() == orderType.SWITCH ||
                                        pra.suggest_type.toLowerCase() == orderType.SWITCH_CROSS_AMC
                                "
                            >
                                <i
                                    class="normal__text--blue font-bold mr-1 size__font--16px"
                                    :class="iconsConst.LIGHT__ARROW__RIGHT"
                                />
                                <a
                                    :href="getFundLink(pra.sw_fund_shortcode)"
                                    class="size__font--16px fund__label"
                                    target="_blank"
                                    rel="noreferrer"
                                    >{{ pra.sw_fund_shortcode }}</a
                                >
                            </span>
                        </div>
                    </span>
                </div>
                <div class="amount--col align-self-end">
                    <span v-if="pra.unit != -1" class="normal__text--primary size__font--16px">
                        {{ getPraAmount() }}
                    </span>
                    <span v-else class="normal__text--primary size__font--16px">ทั้งหมด</span>
                </div>
            </div>
            <pra-order-date :order-date="pra.order_date" />
        </div>
    </div>
</template>

<script>
import { iconsConst, orderType, url } from '../../../../store/utill/config'
import { getNumberFormat, getDateFormat, getFundShortNameURI } from '../../../../store/utill'
import { ActionType, PraOrderDate } from '../../../atoms'
import { praValidator } from '../../../../store/modules/port/validator'

export default {
    components: { ActionType, PraOrderDate },
    props: {
        pra: {
            type: Object,
            required: true,
            validator: praValidator
        },
        dataAlias: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            iconsConst,
            orderType,
            url
        }
    },
    methods: {
        getDateFormat,
        getNumberFormat,
        getPraAmount() {
            try {
                if (!this.pra.amount && this.pra.unit) {
                    return `${getNumberFormat(this.pra.unit, false, 4, '')} หน่วย`
                }
                return `${getNumberFormat(this.pra.amount, false, 2, '')} ฿`
            } catch (error) {
                console.error(new Date(), error)
                return ''
            }
        },
        getFundLink(fund) {
            const preparedFundName = getFundShortNameURI(fund)
            return `${this.url.FINNO_FUND}${preparedFundName}`
        }
    }
}
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
}

.between {
    justify-content: space-between;
}

.fund__label {
    border-bottom: 1px solid #000;
    display: inline-block;
    text-decoration: none;
    color: #444;
}
.pra-item-container {
    border-bottom: 1px solid $greyPrimary;
    width: 100%;
    min-height: 60px;
    height: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 8px 0;

    @include responsive(tablet) {
        padding: 0px 16px;
        display: none;
    }

    @include responsive(desktop) {
        padding: 0px 16px;
        display: none;
    }
}

.pra-item-container-tablet {
    border-bottom: 1px solid $greyPrimary;
    align-items: center;
    padding: 0px 16px;
    margin: 8px 0;

    @include responsive(mobile) {
        display: none;
    }

    @include responsive(phablet) {
        display: none;
    }

    .suggest__type--col {
        width: 100%;
        min-width: 70px;
    }
}

.amount--col {
    text-align: right;
    font-weight: bold;
}

.size__font--16px {
    font-size: 14px !important;
}
</style>
