<template>
    <div class="header__bar--fixed bottom__border--solid">
        <div v-if="showMenu" class="blank__container" @click.self="toggleMenu(!showMenu)">
            <side-menu
                :title="headerTitleText"
                :user="user"
                :permissions="permissions"
                :show-menu="showMenu"
                @toggleMenu="toggleMenu"
                @logout="requestLogout"
            />
        </div>

        <div class="background--normal__grey header__bar">
            <button
                id="btn-nav-header"
                class="right__margin--normal"
                @click="toggleMenu(!showMenu)"
            >
                <i :class="iconsConst.HAM_MENU"></i>
            </button>
            <span class="big__text--greyPri">{{ headerTitleText }}</span>
            <v-spacer></v-spacer>
            <!-- Next Phase -->
            <!-- <button id="btn-search-header" class="icon__btn--primary">
            <i :class="iconsConst.SEARCH"></i>
        </button> -->
            <whats-new-button
                v-if="enableWhatsNew && getUserData.show_whats_new"
                class-name="right__margin--normal"
                data-fn-location="f4a-header"
                data-fn-action="whatsnew-button_click"
                @click="redirectWhatsNew"
            >
                <template v-slot:prefixIcon="{ isHover }">
                    <i :class="`fa-regular fa-party-horn ${isHover ? 'fa-bounce' : ''}`"></i>
                </template>
                <template>
                    What's new
                </template>
            </whats-new-button>
            <div id="btn-profile-header" class="right__margin--small">
                <!-- Use <v-menu> for trigger variable 'showTooltip' only! -->
                <v-menu v-model="showTooltip">
                    <template v-slot:activator="{ on }">
                        <div class="pointer__cursor" v-on="on">
                            <profile-display :profile="user" :show-name="false" :show-tooltip="false" />
                        </div>
                    </template>
                </v-menu>
                <menu-tooltip :show-tooltip="showTooltip" @logout="requestLogout" />
            </div>
        </div>
    </div>
</template>
<script>
import { iconsConst } from '../../../store/utill'
import { mapActions, mapGetters } from 'vuex'
import { MenuTooltip } from '../../molecules'
import { permissionsConst } from '../../../store/utill'
import { ProfileDisplay, WhatsNewButton } from '../../atoms'
import { permissionsValidator, userHeaderValidator } from '../../../store/modules/general/validator'
import { default as SideMenu } from './SideMenu'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import { F4A_LOGO_REBRANDING, headerTitle } from '../../../store/utill/config/constant'

Vue.use(VueCookies)

export default {
    components: {
        MenuTooltip,
        ProfileDisplay,
        SideMenu,
        WhatsNewButton
    },
    props: {
        title: {
            type: String,
            required: true
        },
        user: {
            type: Object,
            required: true,
            validator: userHeaderValidator
        },
        permissions: {
            type: Object,
            required: true,
            validator: permissionsValidator
        }
    },
    data() {
        return {
            iconsConst,
            showMenu: false,
            showTooltip: false,
            permissionsConst,
            permissionsKey: {},
            alert: {},
            featureLogoRebranding: false,
            enableWhatsNew: process.env.VUE_APP_ENABLE_WHATS_NEW === 'true'
        }
    },
    computed: {
        ...mapGetters('authentication', ['getPermissionsKey', 'getUserData']),
        ...mapGetters('feature', ['getTimestamp']),
        headerTitleText() {
            if (this.featureLogoRebranding) {
                return headerTitle[this.title]?.rebranding ?? this.title
            } else {
                return headerTitle[this.title]?.original ?? this.title
            }
        }
    },
    watch: {
        getTimestamp() {
            this.isEnabled(F4A_LOGO_REBRANDING).then(flag => {
                this.featureLogoRebranding = flag
            })
        }
    },
    async created() {
        this.permissionsKey = await this.getPermissionsKey
        this.isEnabled(F4A_LOGO_REBRANDING).then(flag => {
            this.featureLogoRebranding = flag
        })
    },
    methods: {
        ...mapActions('authentication', ['logout']),
        ...mapActions('feature', ['isEnabled']),
        toggleMenu(newVal) {
            this.showMenu = newVal
        },
        toggleTooltip() {
            this.showTooltip = !this.showTooltip
        },
        async requestLogout() {
            this.alert = await this.logout(this.$cookies.get('uuid'))
            this.$cookies.remove('uuid')
            if (this.alert.redirect != null) {
                this.$emit('redirect', this.alert.redirect)
            }
        },
        redirectWhatsNew() {
            window.dataLayer.push({
                event: 'custom_event',
                name: 'whatsnew-button_click',
                location: 'f4a-header'
            })
            window.location.href = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/whats-new`
        }
    }
}
</script>

<style lang="scss" scoped>
.header__bar--fixed {
    padding: 10px 24px;
}
.header__bar {
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>
