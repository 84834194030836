<script>
import { Doughnut } from 'vue-chartjs'

export default {
    extends: Doughnut,
    props: {
        chartData: {
            type: Object,
            required: true
        },
        chartOption: {
            type: Object,
            required: true
        }
    },
    watch: {
        chartData: {
            handler: function() {
                this.drawChart()
            },
            deep: true
        }
    },
    methods: {
        drawChart() {
            this.renderChart(this.chartData, this.chartOption)
        }
    }
}
</script>
