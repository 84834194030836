<template>
    <div
        :class="`${type == 'modal' ? 'modal__badge' : 'badge'} `"
        :style="{ color, backgroundColor }"
    >
        {{ text }}
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: '#000'
        },
        backgroundColor: {
            type: String,
            default: '#fff'
        },
        type: {
            type: String,
            default: ''
        },
        dataAlias: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.badge {
    max-width: fit-content;
    padding: 4px 8px;
    border-radius: 1.5rem;
    font-size: 0.75rem;
    font-weight: bold;
}
.modal__badge {
    display: flex;
    color: #fff;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 1.5rem;
    max-width: fit-content;
}
</style>
