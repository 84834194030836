import { render, staticRenderFns } from "./LineRecommendedAction.vue?vue&type=template&id=0ba9a174&scoped=true&"
import script from "./LineRecommendedAction.vue?vue&type=script&lang=js&"
export * from "./LineRecommendedAction.vue?vue&type=script&lang=js&"
import style0 from "./LineRecommendedAction.vue?vue&type=style&index=0&id=0ba9a174&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba9a174",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VSpacer})
