<template>
    <div class="horizon__padding--normal">
        <table class="table__secondary--normal">
            <thead>
                <tr class="head__table--primary bottom__border--solid">
                    <th class="first__column"></th>
                    <th class="horizon__align--left">Port ID</th>
                    <th class="horizon__align--left">Plan Name</th>
                    <th class="horizon__align--left display__mobile--none">Type</th>
                    <th class="horizon__align--right display__phablet--none lessthan">Cost</th>
                    <th class="horizon__align--right display__phablet--none lessthan">Unrealized P/L</th>
                    <th class="horizon__align--right display__phablet--none lessthan">DCA</th>
                    <th class="horizon__align--center display__tablet--none lessthan">Advisor</th>
                    <th class="horizon__align--left display__tablet--none lessthan">
                        Action
                        <i
                            class="fas fa-info-circle action-info-btn pointer__cursor"
                            @click="showModalActionInfo()"
                        ></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(row, idx) in planList">
                    <tr :key="`row-${idx}`" class="bottom__border--solid border__tablet--none">
                        <td class="first__column">
                            <span class="normal__text--primary">{{ idx + 1 }}</span>
                        </td>
                        <td class="horizon__align--left">
                            <link-anchor
                                :to="getPortUrl(row)"
                                class-name="btn__secondary--normal font-bold link"
                                :external="false"
                            >
                                {{ row.port_id }}
                            </link-anchor>
                        </td>
                        <td class="horizon__align--left">
                            <span class="normal__text--primary">{{ row.plan_name }}</span>
                        </td>
                        <td class="horizon__align--left display__mobile--none">
                            <span class="normal__text--primary">{{ row.type }}</span>
                        </td>
                        <td class="horizon__align--right display__phablet--none lessthan">
                            <span class="normal__text--primary">{{ row.cost }}</span>
                        </td>
                        <td class="horizon__align--right display__phablet--none lessthan">
                            <div class="list__container">
                                <span :class="getClassNameColorForProfit(row.unrealized_pl.value, 2)">
                                    {{ getNumberFormat(row.unrealized_pl.value, true, 2) }}
                                </span>
                                <span :class="getClassNameColorForProfit(row.unrealized_pl.percent, 2)">
                                    {{ getNumberFormat(row.unrealized_pl.percent, true, 2, '%') }}
                                </span>
                            </div>
                        </td>
                        <td class="horizon__align--right display__phablet--none lessthan">
                            <span class="normal__text--primary">{{ row.dca }}</span>
                        </td>
                        <td class="horizon__align--center display__tablet--none lessthan">
                            <profile-display :profile="getAdvisor()" :show-name="false" :show-tooltip="true" />
                        </td>
                        <td class="horizon__align--left display__tablet--none lessthan">
                            <pra-action :pra-status="row.pra" />
                        </td>
                    </tr>
                    <!-- Row for responsive -->
                    <tr :key="`row-responsive-${idx}`" class="bottom__border--solid display__desktop--none">
                        <td colspan="10">
                            <div class="block__container">
                                <div class="display__phablet--none morethan block__col--responsive">
                                    <div class="subblock__col--responsive">
                                        <span class="normal__text--secondary font-weight-bold">Type: </span>
                                        <span class="normal__text--primary">{{ row.type }}</span>
                                    </div>
                                </div>
                                <div class="display__tablet--none morethan block__col--responsive">
                                    <div class="subblock__col--responsive">
                                        <span class="normal__text--secondary font-weight-bold">Cost: </span>
                                        <span class="normal__text--primary">{{ row.cost }}</span>
                                    </div>
                                    <div class="subblock__col--responsive">
                                        <span class="normal__text--secondary font-weight-bold">Unrealized P/L: </span>
                                        <span :class="getClassNameColorForProfit(row.unrealized_pl.value, 2)">
                                            {{ getNumberFormat(row.unrealized_pl.value, true, 2) }}
                                        </span>
                                        <span :class="getClassNameColorForProfit(row.unrealized_pl.percent, 2)">
                                            ({{ getNumberFormat(row.unrealized_pl.percent, false, 2, '%') }})
                                        </span>
                                    </div>
                                    <div class="subblock__col--responsive">
                                        <span class="normal__text--secondary font-weight-bold">DCA: </span>
                                        <span>
                                            <pra-action v-if="row.pra" />
                                            <span v-else class="normal__text--secondary">{{ row.dca }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="block__col--responsive">
                                    <div class="subblock__col--responsive">
                                        <span class="normal__text--secondary font-weight-bold">Advisor: </span>
                                        <span class="d-inline-block">
                                            <profile-display
                                                :profile="getAdvisor()"
                                                :show-name="false"
                                                :show-tooltip="true"
                                            />
                                        </span>
                                    </div>
                                    <div class="subblock__col--responsive">
                                        <span class="normal__text--secondary font-weight-bold">
                                            Action
                                            <i
                                                class="fas fa-info-circle action-info-btn pointer__cursor"
                                                @click="showModalActionInfo()"
                                            ></i>
                                            :
                                        </span>
                                        <span>
                                            <pra-action :pra-status="row.pra" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { getClassNameColorForProfit, getNumberFormat, pagesConst } from '../../../store/utill'
import { PraAction, ProfileDisplay } from '../../atoms'
import { planListValidator } from '../../../store/modules/customer/validator'
import LinkAnchor from '../../atoms/LinkAnchor'

export default {
    components: {
        PraAction,
        ProfileDisplay,
        LinkAnchor
    },
    props: {
        planList: {
            type: Array,
            required: true,
            validator: planListValidator
        },
        advisors: {
            type: Array,
            required: true
        }
    },
    methods: {
        getClassNameColorForProfit,
        getNumberFormat,
        getPortUrl(port) {
            return `${pagesConst.PORT_INFO}/${port.port_id}`
        },
        getAdvisor() {
            if (this.advisors.length > 0) {
                return this.advisors[0]
            }
        },
        showModalActionInfo() {
            this.$emit('showModalActionInfo', true)
        }
    }
}
</script>
<style lang="scss" scoped>
.action-info-btn {
    color: $greySecondary;
}
.border__tablet--none {
    @include multiple-responsive(mobile, phablet, tablet) {
        border-bottom: none !important;
    }
}
.block__col--responsive {
    line-height: 2;
    @include responsive(phablet) {
        display: inline-block;
        width: 50%;
    }
    @include responsive(mobile) {
        display: block;
        width: 100%;
    }
}
.subblock__col--responsive {
    display: inline-block;
    width: 50%;
    @include multiple-responsive(mobile, phablet) {
        width: 100%;
        display: block;
    }
}
.block__container {
    display: flex;
    flex-direction: column;
    @include responsive(phablet) {
        flex-direction: row;
    }
}
.link {
    text-decoration: none;
}
</style>
