<template>
    <div class="cust__list-advisor__dropdown">
        <div
            :class="
                `cust__list-advisor__dropdown-inner ${isFocused ? 'cust__list-advisor__dropdown-inner__focus' : ''}`
            "
            :style="`width: ${width};`"
            @click="onContainerClick"
        >
            <div class="cust__list-advisor__dropdown-selection" @scroll="onSearchInputScrollEnd">
                <div ref="anchor_point" />
                <template v-if="allSelected">
                    <span style="white-space: nowrap">{{
                        allSelectedText
                    }}</span>
                </template>
                <template v-else>
                    <template v-for="(item, index) in selectedItems">
                        <div
                            v-if="checked.length == 1"
                            :key="item[itemValue]"
                            class="cust__list-advisor__dropdown-first__chip"
                        >
                            <profile-display
                                :profile="customProfile(item)"
                                :show-name="false"
                                :show-tooltip="false"
                                :is-icon="true"
                            />
                            <div
                                style="margin: 0 0.3rem; white-space: nowrap;"
                            >
                                {{ item[itemTitle] }}
                                <span v-if="item.is_logged_in_user">(คุณ)</span>
                            </div>
                        </div>
                        <div
                            v-else-if="showProfileIcon(index)"
                            ref="chips"
                            :key="item[itemValue]"
                            style="margin-right: 0.3rem"
                        >
                            <profile-display
                                :profile="customProfile(item)"
                                :show-name="false"
                                :show-tooltip="false"
                                :is-icon="true"
                            />
                        </div>
                        <div
                            v-else-if="showStackedIcon(index)"
                            :key="item[itemValue]"
                            style="margin-right: 0.3rem"
                        >
                            <profile-display
                                :profile="stackedProfile()"
                                :show-name="false"
                                :show-tooltip="false"
                                :is-icon="true"
                                :is-advisor="true"
                            />
                        </div>
                    </template>
                </template>
                <input
                    ref="advisor_dropdown_input"
                    type="text"
                    :placeholder="!checked.length ? placeholder : ''"
                    :class="
                        allSelected || collaspAnimationEnded ? 'cust__list-advisor__dropdown-selection-input__blur' : ''
                    "
                    :value="input"
                    :readonly="readOnly"
                    @focus="onSearchInputFocus"
                    @blur="onSearchInputBlur"
                    @input="e => onSearchInputChange(e.target.value)"
                    @keydown="onSearchInputKeydown"
                />
            </div>
            <i
                :class="
                    `fas fa-caret-down cust__list-advisor__dropdown-selection__icon ${
                        isFocused ? 'cust__list-advisor__dropdown-selection__icon__focus' : ''
                    }`
                "
            ></i>
        </div>
        <div class="cust__list-empty__space">
            <div
                :class="
                    `cust__list-advisor__dropdown-menu ${!isFocused ? 'cust__list-advisor__dropdown-menu__hidden' : ''}`
                "
                @mouseover="onMenuHover"
                @click="onContainerClick"
            >
                <label :class="`cust__list-advisor__dropdown-menu__option`">
                    <checkbox
                        :show-label="true"
                        :can-edit="true"
                        :item="{
                            checked: allSelected,
                            text: '',
                            dataAlias: 'advisor_dropdown-checkbox-input'
                        }"
                        @change="val => onSelectAllChange(val)"
                    />
                    <div
                        class="cust__list-advisor__dropdown-menu__option-select__all"
                    >
                        <span>Select All</span>
                        <button class="select-all-clear" @click.stop="onClearClick">Clear</button>
                    </div>
                </label>
                <template v-for="(item, index) in filteredItems">
                    <label
                        :key="item[itemValue]"
                        :class="
                            `cust__list-advisor__dropdown-menu__option ${
                                index == currentIndex ? 'cust__list-advisor__dropdown-menu__option-selected' : ''
                            }`
                        "
                    >
                        <div v-if="index == currentIndex" ref="option"></div>
                        <checkbox
                            :show-label="true"
                            :can-edit="true"
                            :item="{
                                checked: checked.includes(item[itemValue]),
                                text: '',
                                dataAlias: 'advisor_dropdown-checkbox-input'
                            }"
                            @change="val => onCheckboxChange(item[itemValue], val)"
                        />
                        <profile-display
                            :profile="customProfile(item)"
                            :show-name="false"
                            :show-tooltip="false"
                            style="margin-right: 0.5rem; white-space: nowrap;"
                        />
                        <div>
                            {{ item[itemTitle] }}
                            <span v-if="item.is_logged_in_user" class="ml-1">(คุณ)</span>
                        </div>
                    </label>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { ProfileDisplay } from '../../atoms'
import Checkbox from '../../atoms/Form/Checkbox.vue'
import { getAbbrName } from '../../../store/utill'

export default {
    components: {
        ProfileDisplay,
        Checkbox
    },
    props: {
        placeholder: {
            type: String,
            required: false,
            default: () => ''
        },
        value: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        },
        items: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        },
        filteredItems: {
            type: Array,
            required: true,
            default: () => {
                return []
            }
        },
        dataAlias: {
            type: String,
            required: false,
            default: null
        },
        itemTitle: {
            type: String,
            required: false,
            default: () => 'text'
        },
        itemValue: {
            type: String,
            required: false,
            default: () => 'value'
        },
        defaultOverflowDelta: {
            type: Number,
            required: false,
            default: 6
        },
        width: {
            type: String,
            required: false,
            default: '256px'
        },
        allSelectedText: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            input: '',
            // for custom dropdown handling
            checked: [...this.value],
            // for preventing user to type when `All Advisors` option is selected
            readOnly: this.value.length === this.items.length,
            // for manual focus state
            isFocused: false,
            // manual focus debounce
            focusDebounce: undefined,
            // for handling selection with arrow keys
            currentIndex: undefined,
            // for smooth selection collasp animation when focus-out
            collaspAnimationEnded: true,
            // collasp animation ended state debounce
            collaspAnimationEndedDebounce: undefined
        }
    },
    computed: {
        allSelected() {
            return this.checked.length === this.items.length
        },
        selectedItems() {
            return this.checked.map(self => this.items.find(item => item[this.itemValue] === self))
        },
        allItems() {
            return this.items.map(item => item[this.itemValue])
        },
        iconOverflowedDelta() {
            return this.checked.length - this.defaultOverflowDelta
        }
    },
    methods: {
        showStackedIcon(index) {
            // show stacked icon if total selected is over allowed range + 1,
            // allowed range being number of icon that will alway be displayed
            const indexIsExactlyAtOverflowCount = index == this.defaultOverflowDelta
            const overflowCountIsMoreThanOne = this.iconOverflowedDelta > 1
            return indexIsExactlyAtOverflowCount && !this.isFocused && overflowCountIsMoreThanOne
        },
        showProfileIcon(index) {
            // show icon if index is within allowed range and +1, depending on the
            // condition, will display icon at allowed range + 1 if overflow delta
            // is exactly at 1. Otherwise only display within allowed range
            const isExactlyAtOverflowCountButCountIsOne =
                index <= this.defaultOverflowDelta && this.iconOverflowedDelta == 1
            const isLowerThanOverflowCount = index < this.defaultOverflowDelta
            return isExactlyAtOverflowCountButCountIsOne || isLowerThanOverflowCount || this.isFocused
        },
        customProfile(advisor) {
            return {
                abbr: getAbbrName(advisor[this.itemTitle]),
                full: advisor[this.itemTitle],
                profile_picture: advisor.profile_picture ? advisor.profile_picture : {},
                display_circle: true,
                identifier: advisor[this.itemValue]
            }
        },
        stackedProfile() {
            return {
                abbr: `+${this.iconOverflowedDelta}`,
                full: '',
                profile_picture: {},
                display_circle: true,
                identifier: ''
            }
        },
        onContainerClick() {
            this.$refs.advisor_dropdown_input.focus()
            setTimeout(() => {
                this.showCurrent()
            }, 5)
        },
        onMenuHover() {
            this.$refs.advisor_dropdown_input.focus()
        },
        onSelectAllChange(checked) {
            if (checked) {
                this.handleChange(this.allItems)
            } else {
                this.handleChange([])
            }
        },
        onCheckboxChange(identifier, checked) {
            if (checked) {
                this.handleChange([...this.checked, identifier])
            } else {
                this.handleChange(this.checked.filter(self => self !== identifier))
            }
        },
        onSearchInputFocus() {
            clearTimeout(this.focusDebounce)
            this.isFocused = true
            this.collaspAnimationEnded = false
        },
        onSearchInputScrollEnd() {
            // handle selection collasp animation which make selection un-scrollable
            // when scroll back animation has ended
            if (!this.isFocused) {
                clearTimeout(this.collaspAnimationEndedDebounce)
                this.collaspAnimationEndedDebounce = setTimeout(() => {
                    this.collaspAnimationEnded = true
                }, 20)
            }
        },
        onSearchInputBlur() {
            // debounce focus-out
            this.focusDebounce = setTimeout(() => {
                this.isFocused = false
                this.onSearchInputChange('')
                this.currentIndex = undefined
                this.collaspCurrent()
                this.$emit('lazyChange', this.checked)
            }, 150)
        },
        onSearchInputChange(val) {
            this.input = val
            this.$emit('input', val)
        },
        onSearchInputKeydown(val) {
            if (val.key == 'Backspace' && !this.input && !this.readOnly) {
                const checked = this.checked.filter((_, index) => index !== this.checked.length - 1)
                this.handleChange(checked)
            } else if (val.key == 'ArrowUp') {
                if (!this.currentIndex) {
                    this.currentIndex = 0
                } else {
                    this.currentIndex -= 1
                    this.$nextTick(() => this.showCurrentOption())
                }
            } else if (val.key == 'ArrowDown') {
                if (this.currentIndex === undefined) {
                    this.currentIndex = 0
                } else if (this.currentIndex < this.filteredItems.length - 1) {
                    this.currentIndex += 1
                    this.$nextTick(() => this.showCurrentOption())
                }
            } else if (val.key == 'Enter') {
                if (this.currentIndex !== undefined) {
                    const identifier = this.filteredItems[this.currentIndex][this.itemValue]
                    const checked = this.checked.includes(identifier) || this.allSelected
                    this.onCheckboxChange(identifier, !checked)
                } else {
                    this.$refs.advisor_dropdown_input.blur()
                }
            } else {
                this.currentIndex = undefined
            }
        },
        onClearClick() {
            this.$refs.advisor_dropdown_input.focus()
            this.handleChange([])
        },
        handleChange(val = []) {
            if (val.length === this.items.length) {
                this.checked = this.allItems
                this.readOnly = true
            } else {
                this.checked = val
                this.readOnly = false
            }
            this.$emit('change', this.checked)
            this.$nextTick(() => this.showCurrent())
            if (this.input) {
                this.onSearchInputChange('')
            }
        },
        showCurrent() {
            this.$refs.advisor_dropdown_input?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        },
        collaspCurrent() {
            this.$refs.anchor_point?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' })
        },
        showCurrentOption() {
            this.$refs.option[0].scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'center' })
        }
    }
}
</script>

<style lang="scss">
.select-all-clear {
    color: #f7a501;
    margin-left: auto;
}
.cust__list-advisor__dropdown {
    display: flex;
    flex-direction: column;

    .cust__list-advisor__dropdown-inner__focus {
        outline: 1px solid #f7a501 !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    }

    .cust__list-advisor__dropdown-inner {
        outline: 1px solid #e4e8ea;
        border-radius: 5px;
        padding: 2px 0.75rem;
        flex-grow: 1;
        display: flex;
        align-items: center;

        .cust__list-advisor__dropdown-selection__icon {
            transition: transform 0.2s ease-in-out;
            color: #444444;
        }

        .cust__list-advisor__dropdown-selection__icon__focus {
            transform: rotateZ(180deg);
        }

        .cust__list-advisor__dropdown-selection {
            display: flex;
            height: 100%;
            align-items: center;
            overflow-x: auto;
            transition: width 0.2s ease-in-out;
            flex-grow: 1;
            font-size: 14px;

            input[type='text'] {
                &:focus {
                    outline: none;
                }
            }

            input[type='text'].cust__list-advisor__dropdown-selection-input__blur {
                width: 100%;
            }

            .cust__list-advisor__dropdown-first__chip {
                display: flex;
                white-space: nowrap;
                align-items: center;
            }
        }
    }
}
.cust__list-empty__space {
    min-height: 16px;
    position: relative;

    .cust__list-advisor__dropdown-menu__hidden {
        max-height: 0 !important;
    }

    .cust__list-advisor__dropdown-menu {
        margin-top: 0.5rem;
        position: absolute;
        width: 260px;
        left: -2px;
        max-height: 20rem;
        overflow-y: auto;
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        z-index: 100;
        transition: max-height 0.15s ease-in-out;

        .cust__list-advisor__dropdown-menu__option {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.75rem;
            box-sizing: border-box;
            font-size: 14px;
            min-height: 52px;

            &:hover {
                background-color: #e4e8ea;
            }
        }
        .cust__list-advisor__dropdown-menu__option-selected {
            background-color: #e4e8ea;
        }
        .cust__list-advisor__dropdown-menu__option-select__all {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0.25rem 0;
        }
    }
}
</style>
