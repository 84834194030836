<template>
    <div>
        <div v-if="completedData">
            <bread-crumb :path="header.path" />
            <div class="background--normal__grey content__page">
                <div class="big__text--greyPri bottom__margin--big">Lead Request</div>
            </div>
            <div class="background--normal__white bottom__padding--footer top__padding--big">
                <lead-request-list
                    :lead-request-list="leadRequestList"
                    :sorting="sorting"
                    :lead-customer-info="leadRequestCustomerInfo"
                    :lead-status="leadStatus"
                    @seeCustomer="seeCustomer"
                    @showMoreNote="showMoreNote"
                    @searchLeadRequest="searchLeadCustomerInfo"
                    @clearCustomerInfo="clearCustomerInfo"
                    @clearLeadStatus="clearLeadStatus"
                    @saveLeadRequest="saveLeadRequest"
                />
                <lead-request-note-modal
                    :visible="noteModalVisible"
                    :request="{ note: note }"
                    :close="toggleNoteModal"
                />
            </div>
        </div>
        <div v-else>
            <header-skeleton-list />
            <content-skeleton-list />
        </div>
    </div>
</template>

<script>
import { HeaderSkeletonList, ContentSkeletonList } from '../../molecules'
import { LeadRequestList, LeadRequestNoteModal, BreadCrumb } from '../../organisms'
import { mapActions, mapGetters } from 'vuex'
import { orderByConst, pagesConst, FEATURE_CUSTOMER_LIST_V2 } from '../../../store/utill/config/constant'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export default {
    components: { LeadRequestList, LeadRequestNoteModal, HeaderSkeletonList, ContentSkeletonList, BreadCrumb },
    data() {
        return {
            header: {
                title: 'ADVISOR',
                path: [
                    {
                        // TODO: [Next Phase] link should be HOME
                        text: 'Home',
                        dataAlias: 'Home-link',
                        link: pagesConst.CUSTOMER_LIST,
                        external: false
                    }
                ]
            },
            orderByConst,
            sorting: {
                by: 'request_date',
                order: orderByConst.DESC
            },
            completedData: false,
            alert: {},
            loading: {
                interval: {},
                value: 0
            },
            leadRequestList: [],
            pagesConst,
            noteModalVisible: false,
            note: '',
            leadRequestCustomerInfo: {},
            leadStatus: {},
            featureCustomerListV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('leadRequest', ['getLeadCustInfo']),
        ...mapGetters('feature', ['getTimestamp'])
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSettings()
        },
        featureCustomerListV2Enabled(val) {
            this.$set(this.header.path, 0, {
                text: 'Home',
                dataAlias: 'Home-link',
                link: val ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list` : pagesConst.CUSTOMER_LIST,
                external: val
            })
        }
    },
    beforeDestroy() {
        clearInterval(this.loading.interval)
    },
    mounted() {
        this.updateFeatureSettings()
        this.loading.interval = setInterval(() => {
            if (this.loading.value === 100) {
                clearInterval(this.loading.interval)
            } else {
                this.loading.value += 1
            }
        }, 200)
    },
    async created() {
        this.loadLeadRequestList()
        this.updateFeatureSettings()
    },
    methods: {
        ...mapActions('leadRequest', [
            'getAdjustLeadRequestList',
            'getLeadRequestList',
            'getLeadCustomerInfo',
            'createLeadRequest'
        ]),
        ...mapActions('feature', ['isEnabled']),
        async updateSorting(newVal) {
            this.sorting = newVal
            await this.setLeadRequestList()
        },
        async setLeadRequestList() {
            this.leadRequestList = await this.getAdjustLeadRequestList({
                sorting: this.sorting,
                searchWord: ''
            })
        },
        async loadLeadRequestList() {
            this.completedData = false
            let uuid = this.$cookies.get('uuid')
            this.alert = await this.getLeadRequestList({
                uuid: uuid
            })
            if (this.alert.redirect != null) {
                this.redirect(this.alert.redirect)
            }
            await this.setLeadRequestList()
            this.completedData = true
        },
        seeCustomer(customerCode) {
            this.$cookies.set('customerCode', customerCode)
            window.open(pagesConst.CUSTOMER_INFO)
        },
        showMoreNote(note) {
            this.note = note
            this.noteModalVisible = true
        },
        toggleNoteModal() {
            this.noteModalVisible = !this.noteModalVisible
        },
        async searchLeadCustomerInfo(portId) {
            this.leadStatus = {}
            this.leadStatus = await this.getLeadCustomerInfo({
                uuid: this.$cookies.get('uuid'),
                portId: portId
            })
            if (this.leadStatus && this.leadStatus.status === true) {
                this.leadRequestCustomerInfo = await this.getLeadCustInfo
            }
        },
        clearCustomerInfo() {
            this.leadRequestCustomerInfo = {}
        },
        clearLeadStatus() {
            this.leadStatus = {}
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        },
        async saveLeadRequest(reqBody) {
            reqBody.uuid = this.$cookies.get('uuid')
            this.leadStatus = await this.createLeadRequest(reqBody)
            if (this.leadStatus && this.leadStatus.status === true) {
                this.clearLeadStatus()
                this.clearCustomerInfo()
                await this.loadLeadRequestList()
            }
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_LIST_V2).then(flag => {
                this.featureCustomerListV2Enabled = flag
            })
        }
    }
}
</script>
