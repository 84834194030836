<template>
    <div><slot /></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    created() {
        const config = {
            url: process.env.VUE_APP_UNLEASH_API_PATH,
            clientKey: process.env.VUE_APP_UNLEASH_API_KEY,
            refreshInterval: process.env.VUE_APP_UNLEASH_API_REFRESH_INTERVAL,
            appName: process.env.VUE_APP_UNLEASH_APP_NAME
        }
        this.initClient(config)
        if (process.env.VUE_APP_ALLOW_DEBUG_TOOLS === 'true') {
            // debug tools setup
            if (!window.debug) {
                window.debug = {}
            }
            if (!window.debug.feature) {
                window.debug.feature = {}
            }
            if (!window.debug.feature.variant) {
                window.debug.feature.variant = {}
            }
            // debug tools
            window.debug.feature.enable = feature => {
                this.$cookies.set(`Debug-Feature-${feature}`, 'true')
                this.triggerUpdate()
                return `feature: ${feature} enabled!`
            }
            window.debug.feature.disable = feature => {
                this.$cookies.set(`Debug-Feature-${feature}`, 'false')
                this.triggerUpdate()
                return `feature: ${feature} disabled!`
            }
            window.debug.feature.clear = feature => {
                this.$cookies.set(`Debug-Feature-${feature}`, '')
                this.triggerUpdate()
                return `feature: ${feature} override cleared!`
            }
            window.debug.feature.clearAll = () => {
                for (const key of this.$cookies.keys()) {
                    const cookie = this.$cookies.get(key)
                    if (key.startsWith('Debug-Feature-') && !!cookie) {
                        this.$cookies.set(key, '')
                        // eslint-disable-next-line
                        console.log(`cleared feature: ${key}`)
                    }
                }
                this.triggerUpdate()
                return 'all feature overrides have been cleared!'
            }
            window.debug.feature.variant.set = (feature, variant) => {
                this.$cookies.set(`Debug-Variant-${feature}`, variant)
                this.triggerUpdate()
                return `feature: ${feature} variant set to ${variant}`
            }
            window.debug.feature.variant.clear = feature => {
                this.$cookies.set(`Debug-Variant-${feature}`, '')
                this.triggerUpdate()
                return `feature: ${feature} variant override cleared!`
            }
            window.debug.feature.variant.clearAll = () => {
                for (const key of this.$cookies.keys()) {
                    const cookie = this.$cookies.get(key)
                    if (key.startsWith('Debug-Variant-') && !!cookie) {
                        this.$cookies.set(key, '')
                        // eslint-disable-next-line
                        console.log(`cleared variant: ${key}`)
                    }
                }
                this.triggerUpdate()
                return 'all variant overrides have been cleared!'
            }
        }
    },
    methods: {
        ...mapActions('feature', ['initClient', 'triggerUpdate'])
    }
}
</script>
