<template>
    <div v-if="recievedProps" class="list__container">
        <div class="row__container item__align--center wrap__content bottom__margin--bigger">
            <box-bank :see-more-tab="bankBox.see_more" :show-more="false" :bank-account="bankBox.bank_account" />
        </div>
        <div class="list__container bottom__margin--bigger horizon__padding--normal">
            <span class="big__text--greyPri bottom__margin--normal">Subscription Bank Account</span>
            <table-bank :bank-list="subBankList" />
        </div>
        <div class="list__container top__margin--big horizon__padding--normal ">
            <span class="big__text--greyPri bottom__margin--normal">Redemption Bank Account</span>
            <table-bank :bank-list="redBankList" />
        </div>
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { Spinner } from '../../atoms'
import { BoxBank, TableBank } from '../../molecules'
import { bankBoxValidator, bankListValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        BoxBank,
        TableBank,
        Spinner
    },
    props: {
        bankBox: {
            type: Object,
            required: true,
            validator: bankBoxValidator
        },
        subBankList: {
            type: Array,
            required: true,
            validator: bankListValidator
        },
        redBankList: {
            type: Array,
            required: true,
            validator: bankListValidator
        }
    },
    data() {
        return {
            recievedProps: false
        }
    },
    mounted() {
        this.recievedProps = true
    }
}
</script>
