import { api, requestApi } from '../../api/api'

import * as HttpStatus from 'http-status-codes'
import {
    buildCustList,
    getAdvisors,
    getBankList,
    getFamilyInfo,
    getGeneralInfo,
    getInvestStatusText,
    getPersonalInfo,
    getUnrealizedPL,
    getPlanList,
    getRealizedPL,
    getTotalReturn,
    convertDelimitNumberStringToNumber,
    getOthersStatusFromFilter,
    getAccountStatusFromFilter,
    getServiceLevel,
    getServiceLevelFromFilter
} from './config'

import { getAbbrName, getNumberFormat, getPhoneFormat, getRedirectByHTTPStatus, iconsConst } from '../../utill'

export const actions = {
    async getCustomerList({ commit }, { uuid, filterList, quickFilter }) {
        let customerListFilter = null
        if (filterList && quickFilter) {
            const {
                favorite_status,
                cost,
                investment_status,
                outstanding,
                unrealized_pl,
                funds,
                others,
                service_level,
                plan_type
            } = filterList

            customerListFilter = {
                regular: favorite_status.regular,
                favorite: favorite_status.favorite,
                cost: {
                    max: cost.max === '' ? null : convertDelimitNumberStringToNumber(cost.max),
                    min: cost.min === '' ? null : convertDelimitNumberStringToNumber(cost.min)
                },
                outstanding: {
                    max: outstanding.max === '' ? null : convertDelimitNumberStringToNumber(outstanding.max),
                    min: outstanding.min === '' ? null : convertDelimitNumberStringToNumber(outstanding.min)
                },
                unrealized_pl: {
                    max: unrealized_pl.max === '' ? null : convertDelimitNumberStringToNumber(unrealized_pl.max),
                    min: unrealized_pl.min === '' ? null : convertDelimitNumberStringToNumber(unrealized_pl.min)
                },
                funds: funds,
                others: getOthersStatusFromFilter(others),
                account_status: getAccountStatusFromFilter(investment_status),
                service_level: getServiceLevelFromFilter(service_level),
                plan_type: plan_type
            }
        }

        try {
            let apiOption = {
                headers: { 'X-Authenticated-UUID': uuid }
            }
            if (customerListFilter) {
                apiOption = {
                    ...apiOption,
                    params: {
                        filter: JSON.stringify(customerListFilter)
                    }
                }
            }
            let response = await requestApi(api.customerInfoAPI.customerList, apiOption)
            if (response.status == HttpStatus.OK) {
                if (response.response.data) {
                    const custList = buildCustList(response.response.data)
                    await commit('setState', {
                        type: 'custList',
                        data: custList
                    })
                } else {
                    await commit('setState', {
                        type: 'custList',
                        data: []
                    })
                }
                return {
                    show: false,
                    type: 'success',
                    msg: 'Load customer list success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load customer list",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when get customer list',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async getFunds({ commit }, uuid) {
        try {
            let response = await requestApi(api.fundAPI.fund, { headers: { 'X-Authenticated-UUID': uuid } })
            if (response.status == HttpStatus.OK) {
                commit('setState', {
                    type: 'funds',
                    data: response.response.data
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    async getPlanTypes({ commit }, uuid) {
        try {
            let response = await requestApi(api.planAPI.planType, { headers: { 'X-Authenticated-UUID': uuid } })
            if (response.status == HttpStatus.OK) {
                commit('setState', {
                    type: 'planTypes',
                    data: response.response.data
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    async commitBasicInfo({ commit }, custBasic) {
        await commit('setState', {
            type: 'basicInfo',
            data: custBasic
                ? {
                      abbr: custBasic.name_th ? getAbbrName(custBasic.name_th) : '',
                      full: custBasic.name_th || '-',
                      profile_picture: {},
                      display_circle: true,
                      investment_status: custBasic.wt_status ? getInvestStatusText(custBasic.wt_status) : '-',
                      service_level: custBasic.level ? getServiceLevel(custBasic.level) : '-',
                      customer_code: custBasic.customer_code || '-',
                      phone: custBasic.tel ? getPhoneFormat(custBasic.tel) : '-',
                      email: custBasic.email || '-'
                  }
                : null
        })
    },
    async commitFavoriteStatus({ commit }, custFavorite) {
        await commit('setState', {
            type: 'favoriteStatus',
            data: custFavorite
        })
    },
    async commitAdvisors({ commit }, custAdvisors) {
        await commit('setState', {
            type: 'advisors',
            data: custAdvisors == null ? [] : getAdvisors(custAdvisors)
        })
    },
    async commitInfo({ commit }, custInfo) {
        await commit('setState', {
            type: 'info',
            data: custInfo
                ? {
                      general_info: getGeneralInfo(custInfo),
                      personal_info: getPersonalInfo(custInfo),
                      family_info: getFamilyInfo(custInfo),
                      knowledge_assessment: custInfo.knowledge_assessment,
                      knowledge_assessment_v2: custInfo.knowledge_assessment_v2
                  }
                : null
        })
    },
    async commitPDPAStatus({ commit }, pdpaStatus) {
        await commit('setState', {
            type: 'pdpaStatus',
            data: pdpaStatus
        })
    },
    async commitCRSStatus({ commit }, crsStatus) {
        await commit('setState', {
            type: 'CRSStatus',
            data: crsStatus
        })
    },
    async commitCustDisability({ commit }, custDisability) {
        await commit('setState', {
            type: 'custDisability',
            data: custDisability
        })
    },
    async commitInvest({ commit }, { investBox, planBox, custInvest }) {
        await commit('setState', {
            type: 'invest',
            data:
                investBox && planBox && custInvest
                    ? {
                          investment_box: {
                              total_cost:
                                  investBox && investBox.unrealized_pl
                                      ? getNumberFormat(investBox.unrealized_pl.sum_of_cost, false, 2, '฿')
                                      : '-',
                              total_outstanding:
                                  investBox && investBox.unrealized_pl
                                      ? getNumberFormat(investBox.unrealized_pl.sum_of_market_value, false, 2, '฿')
                                      : '-',
                              total_return: investBox ? getTotalReturn(investBox) : 0.0
                          },
                          pl_box: {
                              unrealized_pl: investBox ? getUnrealizedPL(investBox) : { value: 0, percent: 0.0 },
                              realized_pl: investBox
                                  ? getRealizedPL(investBox)
                                  : {
                                        total_realized: 0,
                                        realized_amount: 0,
                                        ar_amount: 0,
                                        dividend_amount: 0
                                    }
                          },
                          plan_list: custInvest ? getPlanList(custInvest) : [],
                          plan_box: planBox
                      }
                    : null
        })
    },
    async commitBank({ commit }, { bankBox, custBank }) {
        await commit('setState', {
            type: 'bank',
            data:
                bankBox && custBank
                    ? {
                          bank_box: bankBox,
                          sub_bank_list: getBankList(custBank.subscription),
                          red_bank_list: getBankList(custBank.redemption)
                      }
                    : null
        })
    },
    async commitNote({ commit }, { noteBox }) {
        await commit('setState', {
            type: 'note',
            data: noteBox
        })
    },
    async getCustInfo({ dispatch }, body) {
        try {
            let response = await requestApi(api.customerInfoAPI.customer, {
                headers: { 'X-Authenticated-UUID': body.uuid },
                variable: body.custCode,
                query: `scope=${body.scope}`
            })
            if (response.status == HttpStatus.OK) {
                let scopes = body.scope.split(',')
                let customer = response.response.data
                let custBasic = customer.overview.customer || null
                let custAdvisors = customer.overview.advisors || null
                let investBox = customer.overview.investment || null
                let planBox = customer.overview.plan || null
                let bankBox = customer.overview.bank_account || null
                let noteBox = customer.overview.note || null
                let custInfo = customer.info || null
                let custInvest = customer.invest || null
                let custBank = customer.bank || null
                let custFavorite = customer.favorite
                let custLog = customer.log || null

                if (scopes.includes('overview')) {
                    await dispatch('commitBasicInfo', custBasic)
                    await dispatch('commitAdvisors', custAdvisors)
                }
                if (scopes.includes('favorite')) {
                    await dispatch('commitFavoriteStatus', custFavorite)
                }
                if (scopes.includes('info')) {
                    await dispatch('commitInfo', custInfo)
                }
                if (scopes.includes('overview') && scopes.includes('invest')) {
                    await dispatch('commitInvest', { investBox, planBox, custInvest })
                }
                if (scopes.includes('overview') && scopes.includes('bank')) {
                    await dispatch('commitBank', { bankBox, custBank })
                }
                if (scopes.includes('overview')) {
                    await dispatch('commitNote', { noteBox })
                }
                if (scopes.includes('log')) {
                    await dispatch('commitLog', custLog)
                }

                return {
                    show: false,
                    type: 'success',
                    msg: 'Load customer overview success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load customer overview",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when get customer overview',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async updateFavorite({ commit }, payload) {
        await commit('setFavoriteStatus', payload)
    },
    async updatePDPAStatus({ dispatch }, body) {
        try {
            let apiOption = {
                headers: { 'X-Authenticated-UUID': body.uuid },
                variable: body.custCode
            }
            let response = await requestApi(api.customerInfoAPI.pdpa, apiOption)
            if (response.status == HttpStatus.OK) {
                if (response.response.data) {
                    const pdpaStatus = response.response.data
                    await dispatch('commitPDPAStatus', pdpaStatus)
                }
                return {
                    show: false,
                    type: 'success',
                    msg: 'Load pdpa status success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load pdpa status",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when get pdpa status',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async updateCRSStatus({ dispatch }, body) {
        try {
            let apiOption = {
                headers: { 'X-Authenticated-UUID': body.uuid },
                variable: body.custCode
            }
            let response = await requestApi(api.customerInfoAPI.crs, apiOption)
            if (response.status == HttpStatus.OK) {
                if (response.response.data) {
                    const crsStatus = response.response.data
                    await dispatch('commitCRSStatus', crsStatus)
                }
                return {
                    show: false,
                    type: 'success',
                    msg: 'Load crs status success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load crs status",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when get crs status',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },

    async updateCustomerDisability({ dispatch }, body) {
        try {
            let apiOption = {
                headers: { 'X-Authenticated-UUID': body.uuid },
                variable: body.custCode
            }
            let response = await requestApi(api.customerInfoAPI.disability, apiOption)
            if (response.status == HttpStatus.OK) {
                if (response.response.data) {
                    const disability = response.response.data
                    await dispatch('commitCustDisability', disability)
                }
                return {
                    show: false,
                    type: 'success',
                    msg: 'Load customer disability success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load customer disability",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg: 'Internal Server error when get customer disability',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async commitLog({ commit }, custLog) {
        await commit('setState', {
            type: 'log',
            data: custLog
        })
    },
    async getAdvisorList({ commit }, uuid) {
        try {
            let response = await requestApi(api.customerInfoAPI.advisors, { headers: { 'X-Authenticated-UUID': uuid } })
            if (response.status == HttpStatus.OK) {
                commit('setState', {
                    type: 'advisorList',
                    data: response.response.data
                })
            }
        } catch (e) {
            console.error(e)
        }
    }
}
