<template>
    <div>
        <div v-if="completedData">
            <div>
                <bread-crumb :path="header.path" />
                <div class="background--normal__grey content__page">
                    <v-alert v-if="alert.show" :type="alert.type" class="" text :icon="alert.icon">{{
                        alert.msg
                    }}</v-alert>
                    <div class="big__text--greyPri bottom__margin--big">Lead Request Approval</div>
                </div>
                <div class="background--normal__white bottom__padding--footer top__padding--big full__height">
                    <div class="list__container content__page">
                        <div
                            class="row__container bottom__margin--normal wrap__content display__phablet--none lessthan justify-space-between"
                        >
                            <search
                                class="bottom__margin--normal"
                                :value="searchValue"
                                @value="updateSearch"
                            />
                            <approve-all-button
                                v-if="isExclusive"
                                :disabled="enableApproveAllButton"
                                @click="toggleApproveAllModal"
                            />
                        </div>
                        <div class="list__container bottom__margin--normal display__tablet--none morethan">
                            <search class="bottom__margin--normal" :value="searchValue" @value="updateSearch" />
                        </div>
                        <table-lead-request-approval
                            :lead-requests="filterLeadRequest"
                            @approve="requestApprove"
                            @reject="requestReject"
                            @note="openNote"
                        />
                        <lead-approval-modal
                            :visible="approvalModalVisible"
                            :request="workingLeadRequest"
                            :close="toggleApproveModal"
                            @approve="approveRequest"
                        />
                        <lead-reject-modal
                            :visible="rejectModalVisible"
                            :request="workingLeadRequest"
                            :close="toggleRejectModal"
                            @reject="rejectRequest"
                        />
                        <lead-request-note-modal
                            :visible="noteModalVisible"
                            :request="workingLeadRequest"
                            :close="toggleNoteModal"
                            close-modal-data-alias="approval_note-cross_mark"
                            btn-data-alias="approval_note-close-btn"
                            note-data-alias="approval_note-req_note-data"
                        />
                        <alert-modal
                            :visible="alertModalVisible"
                            :close="toggleAlertModal"
                            :title="alertTitle"
                            :message="alertMsg"
                        />
                        <lead-approval-all-modal
                            :visible="approveAllModalVisible"
                            :requests="pendingLeadRequest"
                            @close="toggleApproveAllModal"
                            @approve="approveAllRequest"
                        />
                        <lead-approval-all-result-modal
                            :visible="approveAllStatModalVisible"
                            :request-count="approvalStat.count"
                            :approved-count="approvalStat.success"
                            :rejected-count="approvalStat.failed"
                            @close="toggleApproveAllStatModal"
                        />
                        <div class="row__container horizon__align--center">
                            <pagination
                                :current-page="currentPage"
                                :total-pages="totalPages"
                                prev-btn-data-alias="approval-paginate_prev-btn"
                                next-btn-data-alias="approval-paginate_next-btn"
                                page-data-alias="approval-paginate_page-input"
                                @currentPage="updateCurrentPage"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <header-skeleton-list />
            <content-skeleton-list />
        </div>
    </div>
</template>

<script>
import { Search, ApproveAllButton } from '../../atoms'
import { Pagination, TableLeadRequestApproval, HeaderSkeletonList, ContentSkeletonList } from '../../molecules'
import {
    AlertModal,
    LeadApprovalModal,
    LeadRejectModal,
    LeadRequestNoteModal,
    BreadCrumb,
    LeadApprovalAllModal,
    LeadApprovalAllResultModal
} from '../../organisms'

import { mapActions, mapGetters } from 'vuex'
import { pagesConst, FEATURE_CUSTOMER_LIST_V2 } from '../../../store/utill/config/constant'
import { leadRequestAction, leadRequestMessages } from '../../../store/modules/leadRequest/config'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export default {
    components: {
        AlertModal,
        TableLeadRequestApproval,
        LeadApprovalModal,
        LeadRejectModal,
        LeadRequestNoteModal,
        Pagination,
        Search,
        BreadCrumb,
        HeaderSkeletonList,
        ContentSkeletonList,
        LeadApprovalAllModal,
        ApproveAllButton,
        LeadApprovalAllResultModal
    },
    data() {
        return {
            header: {
                title: 'ADVISOR',
                path: [
                    {
                        // TODO: [Next Phase] link should be HOME
                        text: 'Home',
                        dataAlias: 'Home-link',
                        link: pagesConst.CUSTOMER_LIST,
                        external: false
                    }
                ]
            },
            leadRequestIndex: -1,
            alert: {},
            completedData: false,
            loading: {
                interval: {},
                value: 0
            },
            alertModalVisible: false,
            approvalModalVisible: false,
            rejectModalVisible: false,
            noteModalVisible: false,
            approveAllModalVisible: false,
            approveAllStatModalVisible: false,
            approvalStat: {
                count: 0,
                success: 0,
                failed: 0
            },
            isExclusive: false,
            searchValue: '',
            currentPage: 1,
            amountContent: 5,
            approvalList: [],
            alertMsg: '',
            alertTitle: '',
            featureCustomerListV2Enabled: false
        }
    },
    computed: {
        ...mapGetters('authentication', ['getUserData']),
        ...mapGetters('feature', ['getTimestamp']),
        workingLeadRequest() {
            if (this.leadRequestIndex !== -1) {
                return this.filterLeadRequest[this.leadRequestIndex]
            }
            return null
        },
        filterLeadRequest() {
            return this.approvalList.slice(
                (this.currentPage - 1) * this.amountContent,
                this.currentPage * this.amountContent
            )
        },
        pendingLeadRequest() {
            return this.approvalList.filter(request => request.status === 'pending')
        },
        totalPages() {
            return Math.ceil(this.approvalList.length / this.amountContent)
        },
        enableApproveAllButton() {
            return this.pendingLeadRequest.length < 1
        }
    },
    watch: {
        getTimestamp() {
            this.updateFeatureSettings()
        },
        featureCustomerListV2Enabled(val) {
            this.$set(this.header.path, 0, {
                text: 'Home',
                dataAlias: 'Home-link',
                link: val ? `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/customer-list` : pagesConst.CUSTOMER_LIST,
                external: val
            })
        }
    },
    beforeDestroy() {
        clearInterval(this.loading.interval)
    },
    mounted() {
        this.updateFeatureSettings()
        this.loading.interval = setInterval(() => {
            if (this.loading.value === 100) {
                clearInterval(this.loading.interval)
            } else {
                this.loading.value += 1
            }
        }, 200)
    },
    async created() {
        this.updateFeatureSettings()
        let uuid = this.$cookies.get('uuid')
        this.alert = await this.getApprovalList({
            uuid: uuid
        })
        this.approvalList = await this.getAdjustApprovalList({
            sorting: '',
            searchWord: this.searchValue
        })
        if (this.alert.redirect != null) {
            this.redirect(this.alert.redirect)
        }
        this.updateSearch('')
        this.completedData = true
        this.isExclusive = this.getUserData.isExclusive
    },
    methods: {
        ...mapActions('leadRequest', [
            'getAdjustApprovalList',
            'getApprovalList',
            'approvalAction',
            'approvalAllAction'
        ]),
        ...mapActions('feature', ['isEnabled']),
        updateCurrentPage(newVal) {
            this.currentPage = newVal
        },
        async updateSearch(val) {
            if (val && val.length) {
                this.updateCurrentPage(1)
                this.searchValue = val
            } else {
                this.searchValue = ''
            }
            this.approvalList = await this.getAdjustApprovalList({
                sorting: '',
                searchWord: this.searchValue
            })
        },
        redirect(pathName) {
            if (this.$route.path !== pathName) this.$router.push(pathName)
        },
        toggleAlertModal() {
            this.alertModalVisible = !this.alertModalVisible
        },
        toggleApproveModal() {
            this.approvalModalVisible = !this.approvalModalVisible
        },
        toggleRejectModal() {
            this.rejectModalVisible = !this.rejectModalVisible
        },
        toggleNoteModal() {
            this.noteModalVisible = !this.noteModalVisible
        },
        toggleApproveAllModal() {
            this.approveAllModalVisible = !this.approveAllModalVisible
        },
        toggleApproveAllStatModal() {
            this.approveAllStatModalVisible = !this.approveAllStatModalVisible
        },
        requestApprove(index) {
            this.leadRequestIndex = index
            this.toggleApproveModal()
        },
        requestReject(index) {
            this.leadRequestIndex = index
            this.toggleRejectModal()
        },
        openNote(index) {
            this.leadRequestIndex = index
            this.toggleNoteModal()
        },
        async updateApprovalRequestList() {
            let uuid = this.$cookies.get('uuid')
            await this.getApprovalList({
                uuid: uuid
            })
            this.approvalList = await this.getAdjustApprovalList({
                sorting: '',
                searchWord: this.searchValue
            })
        },
        async approveRequest(requestID) {
            let uuid = this.$cookies.get('uuid')
            const payload = {
                action: leadRequestAction.APPROVED,
                request_id: requestID,
                uuid: uuid
            }
            this.alert = await this.approvalAction(payload)
            if (this.alert.msg === '422') {
                this.alertTitle = leadRequestMessages.ALREADY_SET_IA_TITLE
                this.alertMsg = leadRequestMessages.ALREADY_SET_IA_MSG
                this.toggleAlertModal()
            }
            if (this.alert.redirect != null) {
                this.redirect(this.alert.redirect)
            }
            this.toggleApproveModal()
            await this.updateApprovalRequestList()
        },
        async rejectRequest(requestID) {
            let uuid = this.$cookies.get('uuid')
            const payload = {
                action: leadRequestAction.REJECTED,
                request_id: requestID,
                uuid: uuid
            }
            this.alert = await this.approvalAction(payload)
            if (this.alert.redirect != null) {
                this.redirect(this.alert.redirect)
            }
            this.toggleRejectModal()
            await this.updateApprovalRequestList()
        },
        async approveAllRequest() {
            let uuid = this.$cookies.get('uuid')
            this.approvalStat.count = this.pendingLeadRequest.length
            const payload = {
                action: leadRequestAction.APPROVED_ALL,
                requests: this.pendingLeadRequest.map(request => ({
                    request_id: request.request_id,
                    action: leadRequestAction.APPROVED
                })),
                uuid: uuid
            }
            this.alert = await this.approvalAllAction(payload)
            this.approvalStat.success = this.alert.data.success
            this.approvalStat.failed = this.alert.data.failed
            if (this.alert.redirect != null) {
                this.redirect(this.alert.redirect)
            }
            this.toggleApproveAllModal()
            this.toggleApproveAllStatModal()
            await this.updateApprovalRequestList()
        },
        updateFeatureSettings() {
            this.isEnabled(FEATURE_CUSTOMER_LIST_V2).then(flag => {
                this.featureCustomerListV2Enabled = flag
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.table--container {
    margin-top: 65px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.full__height {
    min-height: 100vh;
}
</style>
