import { getAdvisors } from './index'
import { getNumberFormat } from '../../../utill'

const getUnrealizedPL = data => {
    let percent = data.unrealized_pl.unrealized_pl_percentage || 0.0
    return { value: data.unrealized_pl.sum_of_unrealized_pl || 0, percent }
}

const getRealizedPL = data => {
    return {
        total_realized: data.total_realized_amount ? data.total_realized_amount : 0,
        realized_amount: data.realized_amount ? data.realized_amount : 0,
        ar_amount: data.ar_amount ? data.ar_amount : 0,
        dividend_amount: data.dividend_amount ? data.dividend_amount : 0
    }
}

const getTotalReturn = data => {
    let totalReturn = 0.0
    if (data.unrealized_pl.sum_of_unrealized_pl != null && data.total_realized_amount != null) {
        totalReturn = data.unrealized_pl.sum_of_unrealized_pl + data.total_realized_amount
    }
    return totalReturn
}

const getPlanList = planList => {
    let list = []
    for (let plan of planList) {
        list.push({
            port_id: plan.agent_account_id,
            plan_name: plan.plan_name ? plan.plan_name : '-',
            type: plan.plan_type ? plan.plan_type : '-',
            cost: getNumberFormat(plan.cost, false, 2, ''),
            unrealized_pl: getUnrealizedPL(plan.realized_pl),
            advisor: getAdvisors([plan.advisor]),
            pra: plan.pra_status,
            dca: plan.dca
        })
    }
    return list
}

export { getPlanList, getRealizedPL, getTotalReturn, getUnrealizedPL }
