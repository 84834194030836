<template>
    <div class="row__container item__align--center">
        <profile-display :profile="profile" :show-name="false" :show-tooltip="false" />
        <div class="list__container left__margin--normal">
            <span class="topic__text--primary bottom__margin--small">{{ profile.title }}</span>
            <span class="big__text--greyPri">{{ profile.full }}</span>
        </div>
    </div>
</template>
<script>
import { ProfileDisplay } from '../../atoms'
import { userProfileValidator } from '../../../store/modules/general/validator'

export default {
    components: {
        ProfileDisplay
    },
    props: {
        profile: {
            type: Object,
            required: true,
            validator: userProfileValidator
        }
    }
}
</script>
