<template>
    <div>
        <span class="right__margin--small font-bold" :class="getTextColorClassByType(type)">
            {{ getTextByActionType(type, lang) }}
        </span>
    </div>
</template>

<script>
import { getTextColorClassByType, getTextByActionType } from '../../../store/utill'

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        lang: {
            type: String,
            default: 'th'
        },
        dataAlias: {
            type: String,
            default: ''
        }
    },
    methods: {
        getTextColorClassByType,
        getTextByActionType
    }
}
</script>
