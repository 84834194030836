import { type, keyValidator, typeValidator } from '../../../utill'

const dcaListValidator = dcaList => {
    for (let dca of dcaList) {
        if (!typeValidator([{ value: dca, type: type.OBJECT }])) {
            return false
        }
        if (
            !keyValidator(dca, [
                'fund_name',
                'update_date',
                'bank_account',
                'dca_start_date',
                'dca_end_date',
                'occurrence',
                'value'
            ])
        ) {
            return false
        }
        if (
            !typeValidator([
                { value: dca.fund_name, type: type.STRING },
                { value: dca.update_date, type: type.STRING },
                { value: dca.bank_account, type: type.STRING },
                { value: dca.dca_start_date, type: type.STRING },
                { value: dca.dca_end_date, type: type.STRING },
                { value: dca.occurrence, type: type.STRING },
                { value: dca.value, type: type.NUMBER }
            ])
        ) {
            return false
        }
    }
    return true
}

export { dcaListValidator }
