<template>
    <div :class="modalClass">
        <need-adjustment v-if="dcaStatus == praDCAStatusType.NEED_ADJUSTMENT" />
        <need-set v-if="dcaStatus == praDCAStatusType.NEED_SET" :plan-name="getPlanName()" />
        <match v-if="dcaStatus == praDCAStatusType.MATCH" />
        <need-remove v-if="dcaStatus == praDCAStatusType.NEED_REMOVE" />
    </div>
</template>

<script>
import { NeedAdjustment, NeedSet, Match, NeedRemove } from './DcaStatus/index'
import { praCurrentValidator, praUpcomingValidator } from '../../../../store/modules/port/validator'
import { praDCAStatusType } from '../../../../store/utill/config'

export default {
    components: {
        NeedAdjustment,
        NeedSet,
        Match,
        NeedRemove
    },
    props: {
        current: {
            type: Object,
            required: true,
            validator: praCurrentValidator
        },
        upcoming: {
            type: Object,
            default: () => {},
            validator: praUpcomingValidator
        },
        activeTab: {
            type: String,
            required: true
        },
        tabs: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            praDCAStatusType
        }
    },
    computed: {
        dcaStatus() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.dca.status || ''
                    }
                    case this.tabs.CURRENT: {
                        return this.current.dca.status || ''
                    }
                    default: {
                        return ''
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return ''
            }
        },
        modalClass() {
            return this.dcaStatus == praDCAStatusType.NEED_ADJUSTMENT ||
                this.dcaStatus == praDCAStatusType.NEED_SET ||
                this.dcaStatus == praDCAStatusType.NEED_REMOVE
                ? 'pra__dca--wrapper pra__dca--wrapper-warning'
                : 'pra__dca--wrapper'
        }
    },
    methods: {
        getPlanName() {
            try {
                switch (this.activeTab) {
                    case this.tabs.UPCOMING: {
                        return this.upcoming.plan.plan_name || ''
                    }
                    case this.tabs.CURRENT: {
                        return this.current.plan.plan_name || ''
                    }
                    default: {
                        return ''
                    }
                }
            } catch (error) {
                console.error(new Date(), error)
                return ''
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pra__dca--wrapper {
    background-color: #fff;

    &.pra__dca--wrapper-warning {
        background: linear-gradient(0deg, rgba(255, 205, 0, 0.1) 0%, rgba(255, 205, 0, 0.1) 100%), #fff;
    }
}

.pra-container {
    border: 1px solid $greyPrimary;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    &.container-radius {
        border-radius: 4px 4px 0 0;
        border-bottom: 0;
    }

    &.container-item {
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding: 32px 35px;
    }
}

.button-container {
    display: flex;
    width: 100%;
    margin-top: 32px;
    justify-content: flex-end;
    flex-direction: column;

    @include responsive(tablet) {
        flex-direction: row;
    }

    @include responsive(desktop) {
        flex-direction: row;
    }
}

.margin__btn-responsive {
    margin-bottom: 8px;

    @include responsive(tablet) {
        margin-bottom: unset;
        margin-right: 8px;
    }

    @include responsive(desktop) {
        margin-bottom: unset;
        margin-right: 8px;
    }
}

.margin__y--16px {
    margin: 16px 0 !important;
}
</style>
