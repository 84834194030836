<template>
    <button class="approve__all__btn" :disabled="disabled" @click="$emit('click')">
        <i :class="`${iconsConst.CHECK_DOUBLE} pr-2`"></i>Approve All
    </button>
</template>

<script>
import { iconsConst } from '../../../store/utill'
export default {
    props: {
        disabled: Boolean,
        dataAlias: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            iconsConst
        }
    }
}
</script>

<style lang="scss" scoped>
.approve__all__btn {
    height: 40px;
    border-radius: 8px;
    background-color: $greySecondary;
    color: $textPrimary;
    padding: 0 1rem;
    font-weight: bold;

    &:disabled {
        color: $textSecondary;
    }
}
</style>
