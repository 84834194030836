<template>
    <div class="row__container item__align--center">
        <button
            class="margin__button"
            :class="disabledPrevious ? 'grey__btn--disable' : 'grey__btn--normal'"
            :disabled="disabledPrevious"
            @click="previousClicked"
        >
            <i :class="iconsConst.ARROW_LEFT" />
        </button>
        <div class="dropdown__container">
            <v-select
                :items="pageList"
                :value="currentPage"
                dense
                outlined
                @input="updateValue"
            ></v-select>
        </div>
        <button
            class="margin__button"
            :class="disabledNext ? 'grey__btn--disable' : 'grey__btn--normal'"
            :disabled="disabledNext"
            @click="nextClicked"
        >
            <i :class="iconsConst.ARROW_RIGHT" />
        </button>
    </div>
</template>

<script>
import { iconsConst } from '../../../store/utill'

export default {
    props: {
        currentPage: {
            type: Number,
            required: false,
            default: 1
        },
        totalPages: {
            type: Number,
            required: false,
            default: 1
        },
        prevBtnDataAlias: {
            type: String,
            default: null
        },
        nextBtnDataAlias: {
            type: String,
            default: null
        },
        pageDataAlias: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            iconsConst,
            disabledPrevious: false,
            disabledNext: false,
            pageList: []
        }
    },
    watch: {
        totalPages: {
            async handler(newVal) {
                this.totalPages = newVal
                this.updatePageList()
                this.updateDisablePage()
            },
            deep: true
        },
        currentPage: {
            async handler(newVal) {
                this.currentPage = newVal
                this.updateDisablePage()
            },
            deep: true
        }
    },
    created() {
        this.disabledPrevious = this.currentPage <= 1
        this.disabledNext = this.currentPage >= this.totalPages
        this.updatePageList()
    },
    methods: {
        updatePageList() {
            this.pageList = [1]
            for (let i = 2; i <= this.totalPages; i++) {
                this.pageList.push(i)
            }
        },
        updateDisablePage() {
            this.disabledPrevious = this.currentPage <= 1
            this.disabledNext = this.currentPage >= this.totalPages
        },
        updateValue(newVal) {
            this.$emit('currentPage', newVal)
        },
        previousClicked() {
            if (this.currentPage - 1 >= 1) {
                this.$emit('currentPage', this.currentPage - 1)
            }
        },
        nextClicked() {
            if (this.currentPage + 1 <= this.totalPages) {
                this.$emit('currentPage', this.currentPage + 1)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dropdown__container {
    margin-left: 16px;
    margin-right: 16px;
    width: 90px;
    font-family: DBHeaventRez !important;
    font-size: 16px !important;
}
.margin__button {
    margin-top: -24px;
}
</style>
