<template>
    <div v-if="visible" class="modal__container">
        <div class="note__modal">
            <div class="row__container note__modal--close-icon">
                <button class="big__text--greyPri" @click="close">
                    <i :class="iconsConst.CROSS" />
                </button>
            </div>
            <div class="note__container">
                <div
                    :class="note.title == '' ? 'note__modal--no-title' : 'note__modal--title'"
                >
                    {{ getDecodedText(note.title) }}
                </div>
                <div class="normal__text--primary note__modal--content">
                    <template v-for="(content, idx) in noteContent">
                        <a v-if="content.type == 'link'" :key="idx" :href="content.text" target="_blank">{{
                            getDecodedText(content.text)
                        }}</a>
                        <br v-else-if="content.type == 'newline'" :key="idx" />
                        <p v-else-if="content.type == 'plain'" :key="idx">{{ getDecodedText(content.text) }}</p>
                    </template>
                </div>
                <div class="vertical__padding--normal fixed__bottom">
                    <div class="row__container top__border--solid top__padding--smaller">
                        <div class="note__modal--advisor-img">
                            <profile-display
                                :profile="advisor"
                                :show-name="false"
                                :show-tooltip="false"
                                class="right__margin--small"
                            />
                        </div>
                        <div class="list__container">
                            <p
                                class="normal__text--primary note__modal--advisor-data"
                            >
                                {{ advisor.full }}
                            </p>
                            <p
                                class="normal__text--secondary note__modal--advisor-data"
                            >
                                {{ noteDate }}
                            </p>
                        </div>
                    </div>
                    <button
                        class="note__modal--close-btn grey__btn--normal vertical__padding--small font-bold"
                        @click="close"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ProfileDisplay } from '../../atoms'
import { iconsConst, getDateFormat } from '../../../store/utill'
import linkifyStr from 'linkify-string'
import { decode } from 'html-entities'

export default {
    components: {
        ProfileDisplay
    },
    props: {
        visible: Boolean,
        close: {
            type: Function,
            required: true
        },
        note: {
            type: Object,
            required: true
        },
        advisor: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            noteDate: '',
            noteContent: ''
        }
    },
    mounted() {
        const date = this.note.updated_at ? this.note.updated_at : this.note.created_at
        const dateObj = new Date(date)
        this.noteDate = getDateFormat(dateObj)
        if (this.note.updated_at) {
            this.noteDate = this.noteDate + ' (Edited)'
        }
        this.noteContent = this.getNoteParagraphs(this.note.note)
    },
    methods: {
        getNoteParagraphs(note) {
            const linkDetector = /<a href=".*">[^\s]*<\/a>/
            const noteResult = note
                .split(/\n/)
                .map((paragraph, i) => {
                    const texts = paragraph.split(/\s/).flatMap(text => {
                        let detectedLink = ''
                        const linkifiedText = linkifyStr(text, {
                            target: '_blank',
                            validate: (value, type, token) => {
                                if (type === 'url' && token.tk.find(tokenEach => tokenEach.t === 'SLASH_SCHEME')) {
                                    detectedLink = value
                                    return true
                                }
                                return false
                            }
                        })
                        if (linkDetector.test(linkifiedText)) {
                            const [prefix, suffix] = linkifiedText.split(linkDetector, 2)
                            return [
                                { type: 'plain', text: prefix },
                                { type: 'link', text: detectedLink },
                                {
                                    type: 'plain',
                                    text: suffix || ' '
                                }
                            ]
                        } else {
                            return [
                                {
                                    type: 'plain',
                                    text
                                }
                            ]
                        }
                    })
                    return i ? [{ type: 'newline' }, ...texts] : texts
                })
                .reduce(
                    (result, current) => {
                        const texts = [...result]
                        for (const text of current) {
                            switch (text.type) {
                                case 'plain': {
                                    const latest = texts[texts.length - 1]
                                    if (latest?.type === 'plain') {
                                        latest.text = `${latest.text}${latest.text ? ' ' : ''}${text.text}`
                                    } else {
                                        texts.push(text)
                                    }
                                    break
                                }
                                case 'newline':
                                case 'link':
                                default:
                                    texts.push(text)
                                    break
                            }
                        }
                        return texts
                    },
                    [{ type: 'plain', text: '' }]
                )
            return noteResult
        },
        getDecodedText(text) {
            const decodedText = decode(text)
            return decodedText
        }
    }
}
</script>

<style lang="scss" scoped>
.note__modal {
    position: relative;
    width: 560px;
    height: 422px;
    border-radius: 8px;
    border: 1px solid $greyPrimary;
    background: $white;
    margin: 0 8px;
    .note__container {
        padding: 0 32px;
        @include responsive(mobile) {
            padding: 0 16px;
        }
    }
    .note__modal--title {
        color: $textPrimary;
        font-size: 20px;
        font-weight: bold;
        word-break: break-word;
    }
    .note__modal--no-title {
        height: 32px;
    }
    .note__modal--content {
        font-family: DBHeaventRez !important;
        font-size: 16px;
        overflow-y: auto;
        --note-line-height: 1.5rem;
        --note-line-count: 6;
        line-height: var(--note-line-height);
        height: calc(var(--note-line-height) * var(--note-line-count));
        white-space: pre-line;
        word-break: break-word;
        margin: 4px 0 24px 0;
        @include responsive(mobile) {
            height: 120px;
        }
        & p {
            display: inline;
        }
        & a {
            text-decoration: none;
        }
    }
    .note__modal--close-icon {
        display: flex;
        justify-content: flex-end;
        margin: 16px 22px 0 22px;
    }
    .note__modal--close-btn {
        width: 100%;
        margin-top: 24px;
    }
    .note__modal--advisor-img {
        margin-top: 2px;
    }
    .note__modal--advisor-data {
        margin-bottom: 4px;
    }
    .fixed__bottom {
        position: absolute;
        bottom: 32px;
        left: 0;
        width: 100%;
        padding: 0 32px;
        @include responsive(mobile) {
            padding: 0 16px;
        }
    }
}
</style>
