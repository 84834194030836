<template>
    <div v-if="showMenu" id="side-menu" class="list__container background--full__black menu__container">
        <div class="row__container horizon__padding--medium vertical__padding--normal">
            <button
                id="btn-nav-header"
                class="right__margin--normal"
                @click="toggleMenu(!showMenu)"
            >
                <i :class="iconsConst.HAM_MENU"></i>
            </button>
            <div class="big__text--white">{{ title }}</div>
        </div>
        <menu-list :permissions="permissions" @showMenu="toggleMenu" />
        <v-spacer></v-spacer>
        <div
            class="row__container horizon__padding--medium vertical__padding--medium item__align--center size__font--14px"
        >
            <profile-display :profile="user" :show-name="false" :show-tooltip="false" class="right__margin--small" />
            <span class="font-bold">{{ user.full }}</span>
        </div>
        <div class="row__container horizon__padding--medium bottom__padding--normal size__font--14px">
            <button
                v-if="enableWhatsNew"
                data-fn-location="f4a-menu"
                data-fn-action="whatsnew-menu_click"
                @click="redirectWhatsNew"
            >
                <i
                    :class="
                        `fa-regular fa-party-horn ${getUserData.show_whats_new ? 'fa-bounce' : ''} right__margin--small`
                    "
                ></i>
                What's new
            </button>
            <span v-else />
            <v-spacer></v-spacer>
            <button class="left__margin--normal font-bold" @click="logout">Logout</button>
        </div>
    </div>
</template>
<script>
import { iconsConst } from '../../../store/utill'
import { mapGetters } from 'vuex'
import { MenuList } from '../../molecules'
import { permissionsConst } from '../../../store/utill'
import { ProfileDisplay } from '../../atoms'
import { permissionsValidator, userProfileValidator } from '../../../store/modules/general/validator'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export default {
    components: {
        MenuList,
        ProfileDisplay
    },
    props: {
        title: {
            type: String,
            required: true
        },
        user: {
            type: Object,
            required: true,
            validator: userProfileValidator
        },
        permissions: {
            type: Object,
            required: true,
            validator: permissionsValidator
        },
        showMenu: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            iconsConst,
            permissionsConst,
            permissionsKey: {},
            enableWhatsNew: process.env.VUE_APP_ENABLE_WHATS_NEW === 'true'
        }
    },
    computed: {
        ...mapGetters('authentication', ['getPermissionsKey', 'getUserData'])
    },
    async created() {
        this.permissionsKey = await this.getPermissionsKey
    },
    methods: {
        toggleMenu(newVal) {
            this.$emit('toggleMenu', newVal)
        },
        logout() {
            this.$emit('logout')
        },
        redirectWhatsNew() {
            window.dataLayer.push({
                event: 'custom_event',
                name: 'whatsnew-menu_click',
                location: 'f4a-menu'
            })
            window.location.href = `${process.env.VUE_APP_SALESTOOLS_V2_ENDPOINT}/whats-new`
        }
    }
}
</script>
<style lang="scss" scoped>
.menu__container {
    position: fixed;
    z-index: 3;
    width: 300px;
}

.size__font--14px {
    font-size: 14px;
}
</style>
