<template>
    <div v-if="completedData" class="top__padding--normal">
        <cross-amc-order-modal
            :orders="crossAMCOrders"
            :advisor="advisor"
            :customer-info="customerInfo.general_info"
            :visible="crossAMCModalVisible"
            :close="toggleCrossAMCModal"
            title="Cross AMC Switch Order"
            @open-fund-tab="openFundTab"
        />
        <div class="row__container bottom__margin--normal display__mobile--none">
            <span class="quick__filter__btn--container">
                <quick-search :list="quickFilterList" :value="quickFilterValue" @value="updateQuickFilter" />
            </span>
            <search class="bottom__margin--normal" :value="searchValue" placeholder="Search" @value="updateSearch" />
            <v-spacer />
            <span class="filter__btn--container left__margin--small">
                <grey-button :icon="iconsConst.FILTER" text="Filter" @click.native="toggleFilterModal" />
            </span>
        </div>
        <div class="list__container bottom__margin--normal display__phablet--none morethan">
            <quick-search :list="quickFilterList" :value="quickFilterValue" @value="updateQuickFilter" />
            <search class="bottom__margin--normal" :value="searchValue" placeholder="Search" @value="updateSearch" />
            <grey-button :icon="iconsConst.FILTER" text="Filter" @click.native="toggleFilterModal" />
        </div>
        <blue-banner v-if="currentFilter" :title="'Filter: '" :value="currentFilter" @clear="clearFilter" />
        <div class="list__container item__align--center">
            <table-order-history
                :order-list="filterOrderList"
                :full-order-list="fullOrderList"
                class="full__width bottom__margin--normal"
                @open-fund-tab="openFundTab"
                @toggleCrossAMCModal="toggleCrossAMCModal"
            />
            <pagination :current-page="currentPage" :total-pages="totalPages" @currentPage="updateCurrentPage" />
        </div>
        <filter-history-modal v-model="filter" :visible="filterModalVisible" :toggle-modal="toggleFilterModal" />
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>

<script>
import { quickFilterList } from '../../../store/modules/port/config'
import { iconsConst, DATE_PICKER_START, DATE_PICKER_END } from '../../../store/utill'
import { orderType } from '../../../store/utill/config'
import { QuickSearch, Search, BlueBanner, GreyButton, Spinner } from '../../atoms'
import { Pagination, TableOrderHistory } from '../../molecules'
import { FilterHistoryModal, CrossAmcOrderModal } from '../../organisms'
import { orderListValidator } from '../../../store/modules/port/validator'
import { advisorsValidator } from '../../../store/modules/customer/validator'
import dayjs from 'dayjs'

export default {
    components: {
        Pagination,
        QuickSearch,
        Search,
        TableOrderHistory,
        GreyButton,
        BlueBanner,
        FilterHistoryModal,
        CrossAmcOrderModal,
        Spinner
    },
    props: {
        orderList: {
            type: Array,
            required: true,
            validator: orderListValidator
        },
        advisor: {
            type: String,
            required: true,
            validator: advisorsValidator
        },
        customerInfo: {
            type: Object,
            required: true
        },
        quickFilterValue: {
            type: String,
            required: false,
            default: 'all'
        },
        searchValue: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            iconsConst,
            quickFilterList,
            amountContent: 20,
            currentPage: 1,
            visibledHistoryList: [],
            completedData: false,
            filterModalVisible: false,
            DATE_PICKER_START,
            DATE_PICKER_END,
            totalPages: 0,
            crossAMCOrders: [],
            crossAMCModalVisible: false,
            fullOrderList: this.orderList,
            filter: {
                orderType: {
                    buy: true,
                    sell: true,
                    swap: true,
                    transfer: true
                },
                status: {
                    awaitingConfirmation: true,
                    success: true,
                    cancel: true
                },
                dateRange: {
                    startDate: '',
                    endDate: ''
                },
                amc: '',
                isFilter: false
            }
        }
    },
    computed: {
        isFilter() {
            return this.filter != ''
        },
        currentFilter() {
            let currentFilter = []

            // check default filter order type
            const isDefaultFilterOrderType = orderTypeFilter => {
                return orderTypeFilter.buy && orderTypeFilter.sell && orderTypeFilter.swap && orderTypeFilter.transfer
            }

            // check default filter status
            const isDefaultFilterStatus = status => {
                return status.awaitingConfirmation && status.success && status.cancel
            }

            if (this.filter && this.filter.isFilter) {
                const { orderType: orderTypeFilter, status, amc, dateRange } = this.filter

                // filter order type
                if (
                    !isDefaultFilterOrderType(orderTypeFilter) &&
                    (orderTypeFilter.buy || orderTypeFilter.sell || orderTypeFilter.swap || orderTypeFilter.transfer)
                ) {
                    currentFilter.push('Order Type')
                }

                // filter status
                if (
                    !isDefaultFilterStatus(status) &&
                    (status.awaitingConfirmation || status.success || status.cancel)
                ) {
                    currentFilter.push('Status')
                }

                // filter amc
                if (amc) {
                    currentFilter.push('AMC')
                }

                // filter startDate && endDate
                if (dateRange.startDate || dateRange.endDate) {
                    currentFilter.push('Date')
                }

                return currentFilter.join(', ') || ''
            } else {
                return ''
            }
        },
        filterOrderList() {
            const { status, orderType: orderTypeFilter, dateRange, amc } = this.filter
            let filterOrderList = this.orderList
            if (this.filter.isFilter) {
                filterOrderList = filterOrderList
                    .map(order => {
                        let isFilter = false
                        //Filter Order Type
                        //Filter Status
                        if (status.awaitingConfirmation && order.status === 'On Process') {
                            isFilter = true
                        } else if (status.success && order.status === 'Success') {
                            isFilter = true
                        } else if (status.cancel && order.status === 'Cancelled') {
                            isFilter = true
                        } else {
                            return
                        }

                        if (orderTypeFilter.buy && order.order_type === orderType.BUY) {
                            isFilter = true
                        } else if (orderTypeFilter.sell && order.order_type === orderType.SELL) {
                            isFilter = true
                        } else if (orderTypeFilter.swap && order.order_type === orderType.SWITCH) {
                            isFilter = true
                        } else if (
                            orderTypeFilter.transfer &&
                            (order.order_type === orderType.TRANS_IN || order.order_type === orderType.TRANS_OUT)
                        ) {
                            isFilter = true
                        } else {
                            return
                        }

                        if (amc) {
                            if (order.amc_name === amc) {
                                isFilter = true
                            } else {
                                return
                            }
                        }

                        const parseOrderDate = dayjs(order.transaction_date)
                        if (dateRange.startDate && dateRange.startDate !== DATE_PICKER_START) {
                            const parseStartDate = dayjs(dateRange.startDate).endOf('days')
                            if (parseStartDate.isBefore(parseOrderDate)) {
                                isFilter = true
                            } else {
                                return
                            }
                        }
                        if (dateRange.endDate && dateRange.startDate !== DATE_PICKER_END) {
                            const parseEndDate = dayjs(dateRange.endDate).endOf('days')
                            if (parseEndDate.isAfter(parseOrderDate)) {
                                isFilter = true
                            } else {
                                return
                            }
                        }
                        if (isFilter) {
                            return order
                        }
                    })
                    .filter(x => x)
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalPages = this.amountContent ? Math.ceil(filterOrderList.length / this.amountContent) : 1
            if (filterOrderList) {
                filterOrderList = filterOrderList.slice(
                    (this.currentPage - 1) * this.amountContent,
                    this.currentPage * this.amountContent
                )
            }
            return filterOrderList
        }
    },
    watch: {
        filter() {
            this.currentPage = 1
        }
    },
    async mounted() {
        this.completedData = true
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search)
        const proposalIdFromUrl = urlParams.get('proposal_order_id')
        if (proposalIdFromUrl) {
            this.$emit('search', proposalIdFromUrl)
        }
    },
    methods: {
        async updateCurrentPage(newVal) {
            this.currentPage = newVal
            this.visibledHistoryList = this.filterOrderList.slice(
                (this.currentPage - 1) * this.amountContent,
                this.currentPage * this.amountContent
            )
        },
        updateQuickFilter(item) {
            this.updateCurrentPage(1)
            this.$emit('quickFilter', item)
        },
        updateSearch(newVal) {
            this.updateCurrentPage(1)
            this.$emit('search', newVal)
        },
        toggleFilterModal() {
            this.filterModalVisible = !this.filterModalVisible
        },
        clearFilter() {
            this.updateCurrentPage(1)
            this.filter = {
                orderType: {
                    buy: true,
                    sell: true,
                    swap: true,
                    transfer: true
                },
                status: {
                    awaitingConfirmation: true,
                    success: true,
                    cancel: true
                },
                dateRange: {
                    startDate: '',
                    endDate: ''
                },
                amc: '',
                isFilter: false
            }
        },
        toggleCrossAMCModal(orders) {
            if (orders.length > 0) {
                this.crossAMCOrders = orders.filter(order => order != null)
            }
            this.crossAMCModalVisible = !this.crossAMCModalVisible
        },
        async openFundTab(newVal) {
            this.$emit('openFundTab', newVal)
        }
    }
}
</script>

<style lang="scss" scoped>
.filter__btn--container {
    min-width: 75px;
}
.quick__filter__btn--container {
    @include multiple-responsive(mobile, phablet) {
        margin-right: 8px;
    }
}
</style>
