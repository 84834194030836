<template>
    <v-container class="list__container round__block--primary">
        <title-box id="btn-more-pl" :title="'P/L'" :see-more-tab="seeMoreTab" :show-more="showMore" />
        <div class="row__container item__align--center bottom__margin--small wrap__content">
            <span class="topic__text--primary">Unrealized P/L: </span>
            <span class="row__container">
                <span
                    :class="getClassNameColorForProfit(pl.unrealized_pl.value, 2)"
                    class="font-bold right__margin--small"
                >
                    {{ getNumberFormat(pl.unrealized_pl.value, true, 2, '฿') }}
                </span>
                <span :class="getClassNameColorForProfit(pl.unrealized_pl.percent, 2)" class="font-bold">
                    {{ `(${getNumberFormat(pl.unrealized_pl.percent, true, 2, '%')})` }}
                </span>
            </span>
        </div>
        <div class="row__container item__align--center bottom__margin--small wrap__content">
            <span class="topic__text--primary">Realized P/L: </span>
            <span :class="getClassNameColorForProfit(pl.realized_pl.total_realized, 2)" class="font-bold">
                {{ getNumberFormat(pl.realized_pl.total_realized, true, 2, '฿') }}</span
            >
        </div>
        <div class="position--relative">
            <div class="left__border--absolute"></div>
            <div class="row__container item__align--center bottom__margin--small wrap__content">
                <span class="topic__text--primary indent__text--small">- Dividend: </span>
                <span :class="getClassNameColorForProfit(pl.realized_pl.dividend_amount, 2)" class="font-bold">
                    {{ getNumberFormat(pl.realized_pl.dividend_amount, true, 2, '฿') }}</span
                >
            </div>
            <div class="row__container item__align--center bottom__margin--small wrap__content">
                <span class="topic__text--primary indent__text--small">- Mutual Funds: </span>
                <span :class="getClassNameColorForProfit(pl.realized_pl.realized_amount, 2)" class="font-bold">
                    {{ getNumberFormat(pl.realized_pl.realized_amount, true, 2, '฿') }}</span
                >
            </div>
        </div>
    </v-container>
</template>
<script>
import { getClassNameColorForProfit, getNumberFormat } from '../../../store/utill'
import { TitleBox } from '../Title'
import { plValidator } from '../../../store/modules/customer/validator'

export default {
    components: {
        TitleBox
    },
    props: {
        seeMoreTab: {
            type: String,
            required: true
        },
        showMore: {
            type: Boolean,
            required: false,
            default: true
        },
        pl: {
            type: Object,
            required: true,
            validator: plValidator
        }
    },
    methods: {
        getClassNameColorForProfit,
        getNumberFormat
    }
}
</script>
