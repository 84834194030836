<template>
    <button
        :class="disabled ? 'grey__btn--disable' : 'grey__btn--normal'"
        class="normal__text--primary font-bold fixed__btn"
        :disabled="disabled"
    >
        <i :class="icon" /> <span>{{ text }}</span>
    </button>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dataAlias: {
            type: String,
            default: null
        }
    }
}
</script>

<style lang="scss" scoped>
.fixed__btn {
    min-width: min-content;
    height: 40px;
    padding: 5px 10px;
}
.grey__btn--disable {
    border-radius: 8px;
    background: $greySecondary;
    opacity: 50%;
    padding: 3px 10px;
    cursor: not-allowed;
}
</style>
