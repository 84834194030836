<template>
    <p class="normal__text--secondary size__font--12px">
        วันที่ทำรายการ <b>{{ getDateFormat(orderDate, 'full-thai', true) }}</b>
    </p>
</template>

<script>
import { getDateFormat } from '../../../store/utill'

export default {
    props: {
        orderDate: {
            type: String,
            required: true
        }
    },
    methods: {
        getDateFormat
    }
}
</script>
