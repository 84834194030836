<template>
    <div v-if="recievedProps" class="list__container all__padding--big item__align--center">
        <i :class="iconsConst.SAD_FACE" class="icon--big bottom__margin--big" />
        <span class="normal__text--primary bottom__margin--big"
            >There’s a problem loading this page. Please try again.</span
        >
        <grey-button :icon="iconsConst.REFRESH" :text="'Refresh'" @click.native="refresh" />
    </div>
    <div v-else class="vertical__margin--big horizon__align--center item__align--center">
        <spinner />
    </div>
</template>
<script>
import { GreyButton, Spinner } from '../../atoms'
import { iconsConst } from '../../../store/utill/config'

export default {
    components: {
        GreyButton,
        Spinner
    },
    props: {
        currentTab: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            recievedProps: false
        }
    },
    mounted() {
        this.recievedProps = true
    },
    methods: {
        refresh() {
            this.$emit('refresh', this.currentTab)
        }
    }
}
</script>
