import { api, requestApi } from '../../api/api'
import * as HttpStatus from 'http-status-codes'
import { getAdvisors, getRealizedPL, getTotalReturn, getUnrealizedPL } from '../customer/config'
import {
    // buildActionList,
    getAssetList,
    getAssetModelList,
    getDcaList,
    getHistoryList,
    getDistributionList,
    getVisibledHistoryList,
    getVisibledDistribution,
    getTotalTaxCost
} from './config'
import { getNumberFormat, getDateFormat, getRedirectByHTTPStatus, iconsConst } from '../../utill'

export const actions = {
    async commitAdvisor({ commit }, advisor) {
        await commit('setState', {
            type: 'advisor',
            data: advisor ? getAdvisors([advisor]) : null
        })
    },
    async commitBasicInfo({ commit }, portBasic) {
        await commit('setState', {
            type: 'basicInfo',
            data: portBasic
                ? {
                      general_info: {
                          port_icon: portBasic.plan_icon,
                          port_id: portBasic.agent_account_id || '-',
                          customer_name: portBasic.name_th || '-',
                          customer_code: portBasic.customer_code || '-'
                      },
                      port_info: {
                          plan_name: portBasic.plan_name || '-',
                          plan_type: portBasic.plan_type || '-',
                          sub_plan_type: portBasic.sub_type || '-',
                          goal_slot: portBasic.goal_slot || 0
                      },
                      date_info: {
                          created_date: portBasic.create_date ? getDateFormat(portBasic.create_date) : '-',
                          last_edit_date: portBasic.update_date ? getDateFormat(portBasic.update_date) : '-',
                          first_invest_date: '-',
                          last_trans_date: '-'
                      }
                  }
                : null
        })
    },
    async commitOutstanding({ commit }, outstanding) {
        let { list, allAssets } =
            outstanding && outstanding.assets ? getAssetList(outstanding.assets) : { list: null, allAssets: null }
        allAssets.change = getUnrealizedPL(outstanding.investment)
        await commit('setState', {
            type: 'outstanding',
            data: outstanding
                ? {
                      investment_box: {
                          total_cost: getNumberFormat(outstanding.investment.unrealized_pl.sum_of_cost, false, 2, '฿'),
                          total_outstanding: getNumberFormat(
                              outstanding.investment.unrealized_pl.sum_of_market_value,
                              false,
                              2,
                              '฿'
                          ),
                          total_return: getTotalReturn(outstanding.investment)
                      },
                      pl_box: {
                          unrealized_pl: getUnrealizedPL(outstanding.investment),
                          realized_pl: getRealizedPL(outstanding.investment)
                      },
                      plan_setting_box: {
                          target: outstanding.plan.target || -1,
                          target_duration: outstanding.plan.target_duration || -1,
                          planned_first_invest: outstanding.plan.initial || -1,
                          planned_monthly_dca: outstanding.plan.dca || -1,
                          risk_level: outstanding.plan.risk_level || -1
                      },
                      all_assets: allAssets,
                      asset_list: list,
                      tax_cost: outstanding.total_tax_cost
                  }
                : null
        })
    },
    async commitDca({ commit }, dca) {
        await commit('setState', {
            type: 'dca',
            data: dca
                ? {
                      dca_list: getDcaList(dca)
                  }
                : null
        })
    },
    async commitHistory({ commit }, history) {
        await commit('setState', {
            type: 'history',
            data: history
                ? {
                      history_list: getHistoryList(history)
                  }
                : null
        })
    },
    async commitDistribution({ commit }, distribution) {
        await commit('setState', {
            type: 'distribution',
            data: distribution
                ? {
                      distribution_list: getDistributionList(distribution)
                  }
                : null
        })
    },
    async commitPra({ commit }, pra) {
        await commit('setState', {
            type: 'pra',
            data: pra ? pra : null
        })
    },
    async commitModel({ commit }, model) {
        await commit('setState', {
            type: 'model',
            data: model
                ? {
                      plan_type: model.plan_type || '',
                      categories: model.categories || [],
                      assetModelList:
                          model.assets && model.initial ? getAssetModelList(model.assets, model.initial, model.dca) : []
                  }
                : null
        })
    },
    async getAMC({ commit }, uuid) {
        try {
            let response = await requestApi(api.amcAPI.amc, { headers: { 'X-Authenticated-UUID': uuid } })
            if (response.status == HttpStatus.OK) {
                commit('setState', {
                    type: 'amcs',
                    data: response.response.data.amc
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    async getPortInfo({ dispatch }, body) {
        try {
            let response = await requestApi(api.portInfoAPI.portInfo, {
                headers: { 'X-Authenticated-UUID': body.uuid },
                variable: body.portId,
                query: `scope=${body.scope}`
            })

            if (response.status == HttpStatus.OK) {
                let scopes = body.scope.split(',')
                let portInfo = response.response.data
                let portBasic = portInfo.general || null
                let outstanding = portInfo.outstanding || null
                let dca = portInfo.dca || null
                let history = portInfo.history || null
                let distribution = portInfo.distribution || null
                let pra = portInfo.pra || null
                let model = portInfo.model || null

                if (scopes.includes('general')) {
                    await dispatch('commitAdvisor', portBasic && portBasic.advisor ? portBasic.advisor : null)
                    await dispatch('commitBasicInfo', portBasic)
                }
                if (scopes.includes('outstanding')) {
                    await dispatch('commitOutstanding', outstanding)
                }
                if (scopes.includes('dca')) {
                    await dispatch('commitDca', dca)
                }
                if (scopes.includes('history')) {
                    await dispatch('commitHistory', history)
                }
                if (scopes.includes('distribution')) {
                    await dispatch('commitDistribution', distribution)
                }
                if (scopes.includes('pra')) {
                    await dispatch('commitPra', pra)
                }
                if (scopes.includes('model')) {
                    await dispatch('commitModel', model)
                }

                return {
                    show: false,
                    type: 'success',
                    msg: 'Load port information success',
                    icon: iconsConst.CHECK_CIRCLE,
                    redirect: null
                }
            } else {
                return {
                    show: true,
                    type: 'error',
                    msg: "Can't load port information",
                    icon: iconsConst.EXCLAM_TRI,
                    redirect: null
                }
            }
        } catch (e) {
            return {
                show: true,
                type: 'error',
                msg:
                    e.response && e.response.status == HttpStatus.NOT_FOUND
                        ? e.response.data.message
                        : 'Internal Server error when get port information',
                icon: iconsConst.EXCLAM_TRI,
                redirect: e.response ? getRedirectByHTTPStatus(e.response.status) : null,
                err: e.response ? e.response : e
            }
        }
    },
    async getAdjustedHistory({ state }, body) {
        return getVisibledHistoryList(state.history ? state.history.history_list : [], body)
    },
    async getAdjustedDistribution({ state }, body) {
        return getVisibledDistribution(state.distribution ? state.distribution.distribution_list : [], body)
    },
    async getAdjustedTotalTaxCost({ state }) {
        return getTotalTaxCost(state.outstanding && state.outstanding.tax_cost)
    }
}
