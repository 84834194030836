<template>
    <div v-if="advisorList.length >= 1" class="row__container item__align--center">
        <profile-display
            :profile="advisorList.length == 1 ? advisorList[0] : showMultiAdvisors(advisorList)"
            :is-advisor="advisorList.length > 1"
            :show-name="false"
            :show-tooltip="true"
            class="right__margin--small"
        />
    </div>
    <div v-else>
        -
    </div>
</template>

<script>
import { advisorsValidator } from '../../../store/modules/customer/validator'
import { ProfileDisplay } from '../../atoms'

export default {
    components: {
        ProfileDisplay
    },
    props: {
        advisorList: {
            type: Array,
            required: true,
            validator: advisorsValidator
        },
        dataAlias: {
            type: String,
            default: null
        }
    },
    methods: {
        showMultiAdvisors(advisorList) {
            let fullName = ''
            for (let idx in advisorList) {
                fullName += idx < advisorList.length - 1 ? `${advisorList[idx].full}, ` : advisorList[idx].full
            }
            return {
                abbr: advisorList.length.toString(),
                full: fullName,
                profile_picture: {},
                display_circle: true
            }
        }
    }
}
</script>
