const buildLeadCustomerInfo = info => {
    let custInfo = {
        customer_code: info.customer_code,
        customer_name: info.customer_name,
        warning_message: info.warning_message || null
    }
    return custInfo
}

export { buildLeadCustomerInfo }
