<template>
    <table class="table__secondary--normal">
        <thead class="head__table--primary bottom__border--solid">
            <tr>
                <template v-for="(head, idx) in headerList">
                    <th
                        :key="`column-${idx}`"
                        class="pointer__cursor"
                        :class="`horizon__align--${head.align} ${getHeaderClass(head.value)}`"
                    >
                        {{ head.text }}
                        <i
                            v-if="sorting.by == head.value"
                            class="normal__text--primary"
                            :class="
                                sorting.order == orderByConst.ASC
                                    ? iconsConst.FULL_ARROW_DOWN
                                    : iconsConst.FULL_ARROW_UP
                            "
                        />
                    </th>
                </template>
            </tr>
        </thead>
        <tbody v-if="list.length > 0">
            <template v-for="(request, idx) in list">
                <tr :key="`req-row-${idx}`" class="bottom__border--solid">
                    <td class="horizon__align--left">
                        <span class="normal__text--primary font-bold">{{ request.agent_account_id }}</span>
                    </td>
                    <td class="horizon__align--left">
                        <span class="normal__text--primary">
                            {{ request.customer_name }}
                        </span>
                    </td>
                    <td class="horizon__align--left">
                        <span class="normal__text--primary">
                            {{ getDateFormat(request.request_date) }}
                        </span>
                    </td>
                    <td class="horizon__align--left">
                        <span class="normal__text--primary">
                            {{ getDateFormat(request.updated_at) }}
                        </span>
                    </td>
                    <td class="horizon__align--left">
                        <span class="normal__text--primary">{{ getNoteDisplayFormat(request.note) }}</span>
                        <span v-if="request.note.length > 20">
                            ...
                            <span class="btn__secondary--normal font-bold" @click="showMoreNote(request.note)">
                                More
                            </span>
                        </span>
                    </td>
                    <td class="horizon__align--left">
                        <lead-request-status :type="request.status" />
                    </td>
                </tr>
            </template>
        </tbody>
        <tbody v-else>
            <tr class="bottom__border--solid">
                <td :colspan="headerList.length" class="no__result">No Result</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { iconsConst, getDateFormat, orderByConst } from '../../../store/utill'
import { LeadRequestStatus } from '../../atoms/'
export default {
    components: {
        LeadRequestStatus
    },
    props: {
        headerList: {
            type: Array,
            required: true
        },
        sorting: {
            type: Object,
            required: true
        },
        list: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            iconsConst,
            orderByConst
        }
    },
    methods: {
        getDateFormat,
        getHeaderClass(headerValue) {
            switch (headerValue) {
                case 'note':
                    return 'note__column'
                default:
                    return ''
            }
        },
        getNoteDisplayFormat(note) {
            return note.length > 50 ? note.slice(0, 50) : note
        },
        customerClicked(custCode) {
            this.$emit('seeCustomer', custCode)
        },
        showMoreNote(note) {
            this.$emit('showMoreNote', note)
        }
    }
}
</script>

<style lang="scss" scoped>
.note__column {
    width: 40%;
}
</style>
