<template>
    <div class="row__container wrap__content">
        <span class="font-bold topic__text--primary right__margin--small">{{ title }}</span>
        <div style="margin-top: -3px;">
            <span v-for="(status, index) in statuses" :key="index">
                <span :class="status.className">
                    {{ status.value }}
                </span>
                <span v-if="index < statuses.length - 1" class="normal__text--primary">, </span>
            </span>
        </div>
    </div>
</template>

<script>
import { pendingDetailValidator } from '../../../store/modules/customer/validator'

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        pendingDetail: {
            type: Object,
            required: true,
            validator: pendingDetailValidator
        },
        dataAlias: {
            type: String,
            default: null
        }
    },
    computed: {
        statuses() {
            let result = []
            if (this.pendingDetail.oa_status === 'closed') {
                return [{ value: 'Freeze', className: 'normal__text--red' }]
            }
            if (this.pendingDetail.oa_status === 'rejected' && !this.pendingDetail.required.length) {
                return [{ value: 'OA Rejected', className: 'normal__text--red' }]
            }
            if (this.pendingDetail.oa_status === 'reviewing' && !this.pendingDetail.required.length) {
                return [{ value: 'Reviewing', className: 'normal__text--secondary' }]
            }
            if (this.pendingDetail.oa_status === 'rejected' && this.pendingDetail.required.length) {
                result.push({ value: 'OA Rejected', className: 'normal__text--red' })
            }
            this.pendingDetail.required.forEach((required, index) => {
                let key = required.toString().toUpperCase()
                if (index >= this.pendingDetail.required.length - 1) {
                    key += ' Required'
                }
                result.push({ value: key, className: 'normal__text--orange' })
            })
            return result
        }
    }
}
</script>
